import {
  Component,
  EventEmitter,
  Inject,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CustomValidators } from '../../../../../../../../shared/extensions/CustomValidators';
import {
  CreateCatalogueGroup,
  UpdateCatalogueGroup,
} from '../../../data-access/models/create-update-catelogue.model';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../../../../../material/material.module';
import { CatalogueGroup } from '../../../../../data-access/models/catalogue-data.model';

@Component({
  selector: 'app-update-catalogue-group-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  templateUrl: './update-catalogue-group-dialog.component.html',
  styleUrl: './update-catalogue-group-dialog.component.css',
})
export class UpdateCatalogueGroupDialogComponent implements OnInit {
  private readonly dialogRef: DialogRef<UpdateCatalogueGroupDialogComponent> =
    inject(DialogRef<UpdateCatalogueGroupDialogComponent>);

  @Output() updateEvent: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  dataToUpdate: CatalogueGroup;

  constructor(@Inject(DIALOG_DATA) public data: CatalogueGroup) {
    this.dataToUpdate = data;
    this.form = new FormGroup({
      name: new FormControl(data.name, CustomValidators.required),
    });
  }

  ngOnInit() {}

  submit() {
    let model: UpdateCatalogueGroup = {
      id: this.dataToUpdate.dataCatalogueGroupID,
      Name: this.form.get('name').value,
    };

    this.updateEvent.emit(CreateEvent.create<UpdateCatalogueGroup>(model));
    this.dialogRef.close();
  }

  cancel() {
    this.updateEvent.emit(CreateEvent.cancel<UpdateCatalogueGroup>());
    this.dialogRef.close();
  }
}
