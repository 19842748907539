import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapContainerComponent } from './map-container/map-container.component';
import { MapRoutingModule } from './map-routing.module';
import { MaterialModule } from '../material/material.module';
import { LayersComponent } from './features/layers/layers.component';
import { SharedModule } from '../shared/shared.module';
import { FeatureLayersComponent } from "./features/property/features/feature-layers/features/feature-layers/feature-layers.component";
import { PropertyModule } from './features/property/Property.module';
import { PropertiesService } from './features/property/data-access/properties.service';

@NgModule({
    exports: [
        MapContainerComponent
    ],
    imports: [
        MaterialModule,
        PropertyModule,
        SharedModule,
        MapRoutingModule,
        CommonModule,
        FeatureLayersComponent,
        MapContainerComponent,
    ],
})
export class MapModule { }
