<div class="layer-control"    [ngClass]="{
  'layer-control--preview': hasPreview(),
  'layer-control--selected': selected(),
  'layer-control--warning': !canActivate(),
}"

>


  <div class="layer-control__icon">
    <input
      tuiSwitch
      type="checkbox"
      [formControl]="visibilityControl"
      class="layer-control__toggle"
      stopPropagation
    />
  </div>
  <div class="layer-control__content">
    <span appTooltip [appTooltip]="title()" class="layer-control__title">{{title()}}</span>

    <div class="layer-control__slider">
      <app-opacity-slider  [formControl]="opacityControl"></app-opacity-slider>
    </div>

    @if (actionsRef()) {
      <div class="layer-control__actions">
        <ng-container *ngTemplateOutlet="actionsRef()"></ng-container>
      </div>
    }
    @if (visible() && loading() && canActivate()) {
      <div class="layer-control__loader">
        <tui-loader></tui-loader>
      </div>
    }
     @if(headerActionsRef() != null)
      {
        <div class="layer-control__header-actions">
          <ng-container *ngTemplateOutlet="headerActionsRef()"></ng-container>
        </div>
      }

  </div>
  @if(preview() != null)
  {
    <div class="layer-control__preview">
      <ng-container *ngTemplateOutlet="preview()"></ng-container>
    </div>
  }


</div>
<div [@slideToggle]="canActivate() ? 'closed' : 'open'" class="layer-control__warning warning">
  <tui-notification ttuStatus="warning">
    Zoom in to enable
  </tui-notification>
</div>
