import { Directive, ElementRef, HostListener, Renderer2, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[draggable]',
  standalone:true
})
export class DraggableDirective {
  private startY: number;
  private startHeight: number;
  private moveListener: () => void;
  private upListener: () => void;

  @Output() heightChange = new EventEmitter<number>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.startY = event.clientY;
    this.startHeight = this.el.nativeElement.parentElement.offsetHeight;

    this.moveListener = this.renderer.listen('window', 'mousemove', (moveEvent) => this.onMouseMove(moveEvent));
    this.upListener = this.renderer.listen('window', 'mouseup', () => this.onMouseUp());
  }

  onMouseMove(event: MouseEvent) {
    const deltaY = event.clientY - this.startY;
    const newHeight = this.startHeight - deltaY;
    this.heightChange.emit(newHeight);
  }

  onMouseUp() {
    if (this.moveListener) {
      this.moveListener();
    }
    if (this.upListener) {
      this.upListener();
    }
  }
}
