
<div

class="layers"
>

<tui-accordion>
@for (layer of layerGroup().children.slice().reverse(); track
layer().title){

  @switch (layer().type) {

    @case ('group') {
      <tui-accordion-item class="no-padding" [id]="layer().title">
        <app-layer-control   cdkDrag    [map]="map()" [layer]="layer().layer">

        </app-layer-control>
        <ng-template tuiAccordionItemContent>
          <tui-accordion>

            <app-layer-group-control [map]="map()" [layerGroup]="layer()" >

            </app-layer-group-control>
          </tui-accordion>
          </ng-template>
      </tui-accordion-item>

    } @case ('vector') {

        <tui-accordion-item [id]="layer().title" class="no-arrow no-right-border" cdkDrag [showArrow]="false">
          <app-layer-control       [map]="map()" [layer]="layer().layer">
            <ng-template layerControlPreview >
              <style-renderer
              #renderer
              [kind]="layer().kind"
              [style]="layer().layer.getStyle()"
              [featureProperties]="layer().featureProperties"
            ></style-renderer></ng-template>
            <ng-template layerControlHeaderActions>
              @if (layer().poppedOut) {
                <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
              }
              @else {
                <button (click)="layersService.popout(layer)" class="button button--icon"><i class="fa fa-arrow-right-from-bracket"></i></button>
              }

              @if(layer().hasRemoveFn)
              {
                <button class="button button--icon" (click)="layer().removeFn($event)">
                  <span><i class="fa fa-trash"></i></span>
                </button>
              }

            </ng-template>
          </app-layer-control>
        </tui-accordion-item>
    } @case ('image') {
      <tui-accordion-item class="no-padding">
      <app-layer-control [map]="map()" [layer]="layer().layer">
        <ng-template layerControlHeaderActions>
          @if (layer().poppedOut) {
            <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
          }
          @else {
            <button (click)="layersService.popout(layer)" class="button button--icon"><i class="fa fa-arrow-right-from-bracket"></i></button>
          }

          @if(layer().hasRemoveFn)
          {
            <button class="button button--icon" (click)="layer().removeFn($event)">
              <span><i class="fa fa-trash"></i></span>
            </button>
          }

        </ng-template>
      </app-layer-control>
      <ng-template tuiAccordionItemContent><app-layer-legend [layer]="layer()" ></app-layer-legend></ng-template>
    </tui-accordion-item>

    }


    @default { }


  }
}
</tui-accordion>
</div>
