export class Result<T> {
  public success: boolean;
  public model?: T | null;

  constructor(success: boolean, model: T | null = null) {
    this.success = success;
    this.model = model;
  }

  static ok<U>(model: U): Result<U> {
    return new Result<U>(true, model);
  }

  static cancel<U>(): Result<U> {
    return new Result<U>(false, null);
  }

  static delete<U>(model: U): Result<U> {
    return new Result<U>(false, model);
  }
}
