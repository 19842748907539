<div class="title-bar">
  <div class="title">Create Project Layer</div>
  <div class="window-controls">
    <button (click)="cancel()"><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <form class="grid--row grid gap--md" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="" />
      <mat-error *ngIf="form.get('name').errors?.['required']"
        >This field is required!</mat-error
      >
    </mat-form-field>

    <div class="wrapper">
      <mat-slide-toggle formControlName="isLandUse"
        >Land Use Layer</mat-slide-toggle
      >
      @if(form.get('isLandUse').value == true)
      {
          <mat-form-field subscriptSizing="dynamic"  appearance="outline">
            <mat-label>Land Use Type</mat-label>
            <mat-select formControlName="landUseType">
              @for (landUseType of landUseTypes(); track landUseType.landUseTypeID) {
                <mat-option [value]="landUseType">{{landUseType.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
      }
    </div>


    <div class="header header-background"><span>Set Feature Type</span></div>
    <div class="custom-radio-group">
      <label appTooltip="Polygons" class="custom-radio">
        <input type="radio" formControlName="kind" value="Polygon" />
        <span class="checkmark"><i class="fas fa-draw-polygon"></i></span>
      </label>
      <label appTooltip="Lines" class="custom-radio">
        <input type="radio" formControlName="kind" value="LineString" />
        <span class="checkmark"><i class="fas fa-minus"></i></span>
      </label>
      <label appTooltip="Points" class="custom-radio">
        <input type="radio" formControlName="kind" value="Point" />
        <span class="checkmark"><i class="fas fa-map-marker-alt"></i></span>
      </label>
    </div>

    <div class="header header-background">
      <span>Set Layer Styles</span></div>
    <app-rule-table
      [(styleRule)]="style"
      [geometryKind]="this.form.get('kind').value"
    ></app-rule-table>
  </form>
</div>
<div class="footer">
  <button
    [disabled]="form.invalid"
    type="button"
    class="button button--blue"
    (click)="submit()"
  >
    <span class="fa fa-save button-icon"></span>Submit
  </button>
</div>
