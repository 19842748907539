

<ng-template actionPortal>
  <div class="grid grid-cols-3 px-2 items-center flex-grow-1">
  <h3 class="justify-self-center text-base font-semibold text-gray-800  col-start-2" >Create Property</h3>
  </div>
</ng-template>

<div class="d-flex create-property-group">
  <button class="button button--blue" (click)="showToolbar('digitize')">
    <span class="fa fa-pencil button-icon"></span>
    Digitize Property Boundary
  </button>
  <button class="button button--blue" (click)="showToolbar('cadastre')">
    <span class="fa fa-location-dot button-icon"></span>
    Create From Cadastre
  </button>
</div>


<ng-template #digitizeTemplate>
  <app-toolbar position="top">
    <digitize-property-overlay (createEvent)="handleCreateEvent($event)"></digitize-property-overlay>
    <i
      appTooltip="Digitize the polygon/s, once done click the (tick)"
      toolTipPosition="right"
      [triggerOnClick]="true"
      class="fa fa-info-circle">
    </i>
  </app-toolbar>
</ng-template>


<ng-template #cadastreSelectTemplate>
<app-toolbar>
  <select-property-cadastre-overlay (createEvent)="handleCreateEvent($event)"></select-property-cadastre-overlay>
  <i
    appTooltip="Create a property boundary from cadastre, once done click the (tick)"
    toolTipPosition="right"
    [triggerOnClick]="true"
    class="fa fa-info-circle">
  </i>
</app-toolbar>
</ng-template>
