import { CovertWKTFeature } from "src/app/shared/helpers/transformations";
import { LayerDTO } from "./feature-layers.service";
import { signal } from "@angular/core";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { createFeature } from "src/app/shared/helpers/feature.helper";
import { Feature } from "ol";
import { createLayer } from "src/app/shared/helpers/layer.helper";
import VectorImageLayer from "ol/layer/VectorImage";
import { EMPTY, map, mergeMap, Observable, of, tap } from "rxjs";
import { UserFeatureDTO } from "src/app/map/features/layers/layers.component";
import LayerGroup from "ol/layer/Group";
import { EditContextAction, LayerEditContextAction } from "./context-actions";
import { ModalService } from "src/app/@core/services/modal/modal.service";
import { DeleteConfirmationComponent } from "src/app/shared/components/dialogs/delete-confirmation/delete-confirmation.component";
import { Result } from "ts-results";

export const setupLayerFeatures = (layer: LayerDTO) =>
{
  const source = layer.mapLayer.getSource() as VectorSource;
  source.clear();

  const olFeatures: Feature[] = [];

   layer.features.forEach((feature) => {

    const olFeature = createFeature(feature.geom,{
      name: feature.name,
      ...feature.attributes,
      featureID: feature.featureID,
      layerID: layer.layerID,
    });



    feature.olFeature = olFeature;
    olFeatures.push(olFeature);
  });

  source.addFeatures(olFeatures);

  return olFeatures;
}

export const processLayerFeatures = ({}) => map((featureLayer: LayerDTO) => {
  const olFeatures = featureLayer.features.map(feature =>
    createFeature(feature.geom, {
      name: feature.name,
      ...feature.attributes,
      featureID: feature.featureID,
      layerID: featureLayer.layerID,
    })
  );

  featureLayer.mapLayer.getSource().addFeatures(olFeatures);

  const processedFeatures = featureLayer.features.map((feature, index) => ({
    ...feature,
    olFeature: olFeatures[index],
    isEditing: signal(false),
  }));

  return  {...featureLayer, features:processedFeatures };
})

export const processFeatureLayer = ({parser}) => map((featureLayer: LayerDTO) => {


  const featureLayerOptions = {
    source: new VectorSource(),
    visible: true,
    title: featureLayer.name,
    id: featureLayer.layerID,
    contextActions: [EditContextAction, LayerEditContextAction],
    zIndex: 100 + featureLayer.sortOrder
  }


    if (featureLayer.style) {
      parser.writeStyle(featureLayer.style).then((style) => {
        olLayer.setStyle(style.output);
    });
  }
  const olLayer = createLayer<VectorLayer<any>>(VectorLayer,featureLayerOptions )





  return {...featureLayer,mapLayer:olLayer };
});

export const promptUnsavedChangesConfirmation = ({modal}: {modal: ModalService}): Observable<void> =>  {
  return modal
    .showConfirmation('You have unsaved changes, are you sure you want to proceed?')
    .pipe(
      mergeMap((result) => {
        if (result.err || (result.ok && result.val === false)) {
          return EMPTY;
        }
        return of(undefined);
      }));
}

export const promptDeleteConfirmation = ({modal}: {modal: ModalService}): Observable<void> =>  {
  return modal
    .showComponent<DeleteConfirmationComponent, Result<boolean, any>>(DeleteConfirmationComponent,{type:'Layer', details: 'this layer' }).closed
    .pipe(
      mergeMap((result) => {
        if (result.err || (result.ok && result.val === false)) {
          return EMPTY;
        }
        return of(undefined);
      }));
}


export const addLayerToMap = ({layerGroup}) => tap((featureLayer: LayerDTO) => {
  layerGroup.getLayers().extend([featureLayer.mapLayer]);
});

export const removeLayerFromGroup = ({layerGroup, state}) => tap((featureLayer: LayerDTO) => {
  const {layerID} = featureLayer;

  const existingLayer = state().featureLayersMap[layerID];

  if(existingLayer == null)
  {
    return;
  }
  const {mapLayer} = existingLayer;

  layerGroup.getLayers().remove(mapLayer);
});

//   const olLayer = createLayer<VectorLayer<any>>(VectorLayer,featureLayerOptions )
//   const vs = new VectorSource();
//   const vl = new VectorLayer({
//     properties: {
//       ...layer,
//       // contextActions: <ContextMenuAction[]>[
//       //   {
//       //     label: 'Edit',
//       //     action: (olFeature) => {
//       //       const layerWithFeature =
//       //         this.propertyLayers.value.find((lyr) =>
//       //           lyr.features.some(
//       //             (feature) =>
//       //               feature.featureID === olFeature.get('featureID')
//       //           )
//       //         );

//       //       if (layerWithFeature) {
//       //         const featureDTO = layerWithFeature.features.find(
//       //           (feature) =>
//       //             feature.featureID === olFeature.get('featureID')
//       //         );

//       //         if (!this.router.url.endsWith('/digitize')) {
//       //           this.router
//       //             .navigate([
//       //               'map',
//       //               'property',
//       //               this.propertyID,
//       //               'digitize',
//       //             ])
//       //             .then(() => {
//       //               this.editFeature(featureDTO);
//       //               this.currentlySelectedLayer.set(layerWithFeature)
//       //               this.selectedUserFeature.next(featureDTO);
//       //             });
//       //         } else {
//       //           this.editFeature(featureDTO);
//       //           this.currentlySelectedLayer.set(layerWithFeature)
//       //           this.selectedUserFeature.next(featureDTO);
//       //         }
//       //       } else {
//       //         console.error('Feature not found');
//       //       }
//       //     },
//       //   },
//       //   {
//       //     label: 'Edit Layer',
//       //     action: () => {
//       //       if (!this.router.url.endsWith('/digitize')) {
//       //         this.router
//       //           .navigate([
//       //             'map',
//       //             'property',
//       //             this.propertyID,
//       //             'digitize',
//       //           ])
//       //           .then(() => {
//       //             this.editLayer(layer);
//       //           });
//       //       } else {
//       //         this.editLayer(layer);
//       //       }
//       //     },
//       //   },
//       // ],
//     },
//     source: vs,
//     visible: true,
//   });

//   vl.set('title', layer.name);

//   if (layer.style) {
//       parser.writeStyle(layer.style).then((style) => {
//         olLayer.setStyle(style.output);
//     });
//   }

//   layer.isEditing = signal(false);
//   layer.mapLayer = olLayer;

//   const olFeatures = setupLayerFeatures(layer);

//   return layer;



// }


interface ProcessLayerFeatureParams {
  featureLayer: LayerDTO;
  userFeature: UserFeatureDTO;
  updateIndex?: number
}
interface FeatureCreatedResponse {
  layerID: number,
  userFeature: UserFeatureDTO
}

interface FeatureDeletedResponse {
  layerID: number,
  featureID: number
}

interface FeatureUpdatedResponse {
  layerID: number,
  userFeature: UserFeatureDTO
}



interface LayerDeletedResponse {
  layerID: number
}

interface LayerUpdatedResponse {
  layerID: number,
  featureLayer: LayerDTO
}


export const layerFeatureCreated = ({state}) => map<FeatureCreatedResponse,ProcessLayerFeatureParams>((response: FeatureCreatedResponse, $index) => {

  const {userFeature, layerID} = response;

  const layer = state().featureLayersMap[layerID];


  return {
    featureLayer: layer,
    userFeature: userFeature,
  };


});

export const layerFeatureDeleted = ({state}) => map<FeatureDeletedResponse,LayerDTO>((response: FeatureDeletedResponse, $index) => {

  const {featureID, layerID} = response;

  const layer = state().featureLayersMap[layerID] as LayerDTO;


  const featureIndex = layer.features.findIndex(feature => feature.featureID == featureID);

  const {olFeature} = layer.features[featureIndex];

  layer.mapLayer.getSource().removeFeature(olFeature);


  return {
    ...layer,
    features: [              ...layer.features.slice(0, featureIndex),
      ...layer.features.slice(featureIndex + 1),]
  }

});

export const layerFeatureUpdated = ({state}) => map<FeatureUpdatedResponse,ProcessLayerFeatureParams>((response: FeatureUpdatedResponse, $index) => {

  const {userFeature, layerID} = response;

  const layer = state().featureLayersMap[layerID] as LayerDTO;

  const {featureID} = userFeature;


  const featureIndex = layer.features.findIndex(feature => feature.featureID == featureID);

  const {olFeature} = layer.features[featureIndex];

  layer.mapLayer.getSource().removeFeature(olFeature);

  const updatedLayer = {
    ...layer,
    features: [              ...layer.features.slice(0, featureIndex),
      ...layer.features.slice(featureIndex + 1),]
  };


  return {featureLayer: updatedLayer,userFeature:userFeature, UpdateIndex: featureIndex  }

});



export const getFeatureLayer = ({state}) =>  map<LayerDeletedResponse,LayerDTO>((response: LayerDeletedResponse, $index) => {

  const {layerID} = response;
  const layer = state().featureLayersMap[layerID];
  return layer;

});

export const processLayerFeature = () =>
  map(({ featureLayer, userFeature, updateIndex }: ProcessLayerFeatureParams) => {
    const olFeature = createFeature(userFeature.geom, {
      name: userFeature.name,
      ...userFeature.attributes,
      featureID: userFeature.featureID,
      layerID: featureLayer.layerID,
    });

    featureLayer.mapLayer.getSource().addFeature(olFeature);

    const updatedFeature = {
      ...userFeature,
      olFeature: olFeature,
    };

    if(updateIndex !== null && updateIndex !== undefined)
    {
      return {
        ...featureLayer,
        features:[  ...featureLayer.features.slice(0, updateIndex),
          updatedFeature,
          ...featureLayer.features.slice(updateIndex),]
      };
    }

    return {
      ...featureLayer,
      features: [...featureLayer.features, updatedFeature],
    };
  });


  // export const updateStateOperator = ({ state }) =>
  //   map((featureLayer: LayerDTO) => ({
  //     featureLayers: [...state().featureLayers, featureLayer],
  //     featureLayersMap: {
  //       ...(state().featureLayersMap || {}),
  //       [featureLayer.layerID]: featureLayer,
  //     },
  //   }));

  export const featuresReducer = ({state}) => map((action: any) => {

    const {featureEditMap} = state();

      const { layerID, featureID, feature, type } = action;

      switch (type) {
        case 'add':
          if (featureEditMap[layerID]?.[featureID]) {
            return EMPTY;
          }

          return {
            featureEditMap: {
              ...featureEditMap,
              [layerID]: {
                ...(featureEditMap[layerID] || {}),
                [featureID]: feature,
              },
            }
          };

        case 'remove':
          if (!featureEditMap[layerID]) {
            return {
              featureEditMap: {
                ...featureEditMap,
                }
          }
          }

          const { [featureID]: removedFeature, ...remainingFeatures } = featureEditMap[layerID];

          if (Object.keys(remainingFeatures).length === 0) {
            const { [layerID]: removedLayer, ...remainingLayers } = featureEditMap;
            return {featureEditMap: remainingLayers};
          }

          return {
            ...featureEditMap,
            [layerID]: remainingFeatures,
          };

        default:
          return {
            featureEditMap: {
              ...featureEditMap,
              }
        };
      }

  })


    // export const updateStateOperator = ({ state, }) =>
    //   map((featureLayer: LayerDTO) => {
    //     const currentState = state();
    //     const { featureLayers, featureLayersMap, vectorLayers } = currentState;
    //     const { layerID, mapLayer } = featureLayer;

    //     const existingLayer = featureLayersMap[layerID];

    //     let updatedFeatureLayers: LayerDTO[];
    //     let updatedVectorLayers: VectorLayer<any>[];
    //     let updatedFeatureLayersMap: Record<number, LayerDTO>;

    //     if (existingLayer) {


    //       const featureLayerIndex = featureLayers.findIndex((layer) => layer.layerID === layerID);
    //       const vectorLayerIndex = vectorLayers.findIndex((layer:VectorLayer<any>) => layer === mapLayer);

    //       if (featureLayerIndex === -1) {
    //         console.warn(
    //           `Layer with ID ${layerID} exists in featureLayersMap but not in featureLayers array. Adding it to the array.`
    //         );
    //         updatedFeatureLayers = [...featureLayers, featureLayer];

    //       } else {
    //         updatedFeatureLayers = [
    //           ...featureLayers.slice(0, featureLayerIndex),
    //           featureLayer,
    //           ...featureLayers.slice(featureLayerIndex + 1),
    //         ];

    //         updatedVectorLayers = [
    //           ...vectorLayers.slice(0, vectorLayerIndex),
    //           mapLayer,
    //           ...vectorLayers.slice(vectorLayerIndex + 1),
    //         ]
    //       }

    //       updatedFeatureLayersMap = {
    //         ...featureLayersMap,
    //         [layerID]: featureLayer,
    //       };
    //     } else {

    //       updatedFeatureLayers = [...featureLayers, featureLayer];
    //       updatedVectorLayers = [...vectorLayers,mapLayer]
    //       updatedFeatureLayersMap = {
    //         ...featureLayersMap,
    //         [layerID]: featureLayer,
    //       };

    //     }

    //     const sortedFeatureLayers = [...updatedFeatureLayers].sort((a, b) => b.sortOrder - a.sortOrder);

    //     console.log("sorted FeatureLayers", sortedFeatureLayers);


    //     return {
    //       featureLayers: sortedFeatureLayers,
    //       featureLayersMap: updatedFeatureLayersMap,
    //       vectorLayers: updatedVectorLayers
    //     };
    //   });

    export const updateStateOperator = ({ state }) =>
      map((featureLayer: LayerDTO) => {
        const currentState = state();
        const { featureLayers, featureLayersMap, vectorLayers } = currentState;
        const { layerID, mapLayer, sortOrder } = featureLayer;

        const existingLayer = featureLayersMap[layerID];

        let updatedFeatureLayers: LayerDTO[] = [...featureLayers];
        let updatedVectorLayers: VectorLayer<any>[] = [...vectorLayers];
        let updatedFeatureLayersMap: Record<number, LayerDTO>;

        if (existingLayer) {
          // Remove the existing layer and reinsert it in the correct position
          const featureLayerIndex = featureLayers.findIndex((layer) => layer.layerID === layerID);
          const vectorLayerIndex = vectorLayers.findIndex((layer: VectorLayer<any>) => layer === mapLayer);

          if (featureLayerIndex !== -1) {
            updatedFeatureLayers.splice(featureLayerIndex, 1); // Remove from old position
          }

          if (vectorLayerIndex !== -1) {
            updatedVectorLayers[vectorLayerIndex] = mapLayer; // Update map layer
          } else {
            updatedVectorLayers.push(mapLayer); // Add map layer if not found
          }

          updatedFeatureLayersMap = {
            ...featureLayersMap,
            [layerID]: featureLayer,
          };
        } else {
          // If the layer doesn't exist in featureLayersMap, add it
          updatedVectorLayers.push(mapLayer);

          updatedFeatureLayersMap = {
            ...featureLayersMap,
            [layerID]: featureLayer,
          };
        }

        // Insert the featureLayer into the correct position based on sortOrder
        const indexToInsert = binarySearchInsertIndex(updatedFeatureLayers, featureLayer);
        updatedFeatureLayers.splice(indexToInsert, 0, featureLayer);

        return {
          featureLayers: updatedFeatureLayers,
          featureLayersMap: updatedFeatureLayersMap,
          vectorLayers: updatedVectorLayers,
        };
      });



      export const binarySearchInsertIndex =(featureLayers: LayerDTO[], newLayer: LayerDTO): number =>  {
        let low = 0;
        let high = featureLayers.length - 1;

        while (low <= high) {
          const mid = Math.floor((low + high) / 2);
          const midLayer = featureLayers[mid];

          // Descending order: newLayer.sortOrder > midLayer.sortOrder
          if (newLayer.sortOrder < midLayer.sortOrder) {
            low = mid + 1; // Look further down the array
          } else {
            high = mid - 1; // Look further up the array
          }
        }

        return low; // Return the index where the layer should be inserted
      }


      export const layerDeletedOperator = ({ state, layerGroup }) =>
        map((layerID: number) => {
          const currentState = state();
          const { featureLayers, featureLayersMap, vectorLayers } = currentState;

          const existingLayer = featureLayersMap[layerID];

          const {mapLayer} = existingLayer;

          layerGroup.getLayers().remove(mapLayer);


          let updatedFeatureLayers: LayerDTO[];
          let updatedVectorLayers: VectorLayer<any>[];

          if (!existingLayer) {

            return {
              featureLayers, featureLayersMap, vectorLayers
            }

          }

          const featureLayerIndex = featureLayers.findIndex((layer) => layer.layerID === layerID);
          const vectorLayerIndex = vectorLayers.findIndex((layer:VectorLayer<any>) => layer === mapLayer);

            updatedFeatureLayers = [
              ...featureLayers.slice(0, featureLayerIndex),
              ...featureLayers.slice(featureLayerIndex + 1),
            ];

            updatedVectorLayers = [
              ...vectorLayers.slice(0, vectorLayerIndex),
              ...vectorLayers.slice(vectorLayerIndex + 1),
            ]


            const { [layerID]: _, ...updatedFeatureLayersMap} = featureLayersMap;


          return {
            featureLayers: updatedFeatureLayers,
            featureLayersMap: updatedFeatureLayersMap,
            vectorLayers: updatedVectorLayers
          };
        });
