<button
  appTooltip="Pivot Tool"
  class="esk-tool"
  [ngClass]="{ 'esk-tool--active': active() === true }"
  (click)="active.set(!active())"
>
  <i class="fa fa-pie-chart"></i>
</button>


@if(active())
{
  <app-toolbar>
    <button class="esk-tool" (click)="cancel()">
      <span class="fa fa-times"></span>
    </button>
    <button class="esk-tool" (click)="confirm()">
      <span class="fa fa-check"></span>
    </button>
    <button appTooltip="Clear Pivots" class="esk-tool" (click)="clear()">
      <i class="fa fa-trash"></i>
    </button>
  </app-toolbar>
}
