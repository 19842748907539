import { Injectable, OutputRefSubscription } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EskTool } from 'src/app/map/util/esk-tool-interface';

@Injectable({
  providedIn: 'root'
})
export class EskToolService {

private readonly tools = new Map<string,EskTool>();
private isToolActive = new Subject<boolean>();

private readonly toolSubscriptions = new Map<string, OutputRefSubscription>();

constructor() {

}

registerTool(id: string,component: EskTool)
{
  this.tools.set(id,component);
  const subscription = component.active.subscribe((isActive: boolean) => {
    this.checkActiveTools();
  });


  this.toolSubscriptions.set(id, subscription);

}

  toolActive(id: string)
  {
    this.tools.forEach((tool, key) => {
      if (key !== id) {
        tool.active.set(false);
      }
    });

    this.checkActiveTools();
  }

  removeTool(id: string)
  {

    const subscription = this.toolSubscriptions.get(id);
    if (subscription) {
      subscription.unsubscribe();
      this.toolSubscriptions.delete(id);
    }

    this.tools.delete(id);
    this.checkActiveTools();

  }

  private checkActiveTools() {
    const anyActive = Array.from(this.tools.values()).some(tool => tool.active());
    this.isToolActive.next(anyActive);
  }

  toolActiveChanges() {
    return this.isToolActive.asObservable();
  }

}
