@if (layer().legendURL && layer().sourceType != 'ArcGISRest') {
  <img [src]="layer().legendURL" alt="" srcset="" />
  } @else if(layer().legendURL && layer().sourceType == 'ArcGISRest') { @for
  (legendItem of legend(); track $index) {
    <div class="flex gap--2">
      <img [src]="legendItem.imageSrc" alt="" />
      <span class="flex align-center gap--md"
        >{{ legendItem.label }}</span
      >
    </div>
  } }
