<section #sheet id="pdf-container" class="test sheet padding-5mm">
  <div #map class="map"></div>
  <div>
    @for (item of state.elements(); track $index) {
      @if (item.visible()) {

        @if (item.inputType() === 'EDITOR'){
          <pdf-draggable [position]="item.position()" (positionChanged)="item.position.set($event)">
            <div class="ql-editor editor-renderer" [innerHTML]="item.content() | safeHtml"></div>
          </pdf-draggable>
        }

        @else if (item.inputType() === 'MAP_SCALE') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
            <pdf-map-scale [mapScale]="state.mapScale()"></pdf-map-scale>
        </pdf-draggable>
        }

        @else if (item.inputType() === 'IMAGE') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
          <img src="assets\laguna_bay_logo.png" alt="" />
        </pdf-draggable>

        }

        @else if (item.inputType() === 'PROPERTY_DETAILS') {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
        <app-pdf-property-details
        [selectedFields]="item.content()"
        [property]="state.property()"
      >
      </app-pdf-property-details>
        </pdf-draggable>

        }
        @else if (item.inputType() === 'PROPERTY_LAYERS')
        {
          <pdf-draggable
          (positionChanged)="
            item.position.set($event)
          "
          [position]="item.position()"
        >
            <div class="legend-container">
              @for (layer of state.featureLayerLegendMetaData(); track $index) {

                <span class="flex items-center gap-1 text-sm text-gray-700 bg-white bg-opacity-60 px-2 py-1 shadow-md">
                  <legend-icon [rule]="layer.style.rules[0]"></legend-icon>
                  <span>{{ layer.name }} - {{ layer.area | number:'1.0-0' }}(ha)</span>
                </span>


              }
            </div>
            </pdf-draggable>
        }

      }
    }

  </div>
</section>
