import { Injectable, inject, signal } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { tag } from '@turf/turf';
import { BehaviorSubject, EMPTY, NEVER, Observable, catchError, combineLatest, delayWhen, distinctUntilChanged, filter, first, from, fromEvent, fromEventPattern, interval, map, merge, of, pairwise, retry, retryWhen, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LayerDTO } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { PdfPrintDefinition } from '../pdf/data-access/models/models';
import { debug } from 'ngxtension/debug';
import { AuthState } from '../Auth/state';
import { TuiHintService } from '@taiga-ui/core';
import { toObservable } from '@angular/core/rxjs-interop';


@Injectable()
export class SignalRService {


  private readonly authentication = inject(AuthState);



  connected$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly endpoint = environment.hubUrl;

  accessToken = signal(null);

  constructor() {

    combineLatest([
      this.authentication.state.refreshStatus$.pipe(pairwise()),
      this.connected$
    ]).pipe(
      distinctUntilChanged((prev, curr) => {
        const [prevPair, prevConnected] = prev;
        const [currPair, currConnected] = curr;
        return prevPair[1] === currPair[1] && prevConnected === currConnected;
      }),
      debug("Signal-R"),
      switchMap(([[prevStatus, currStatus], connected]) => {

        if((prevStatus == 'IN PROGRESS' && currStatus == 'FAILED') && connected)
        {
          return this.stopConnection();
        }
        else if(prevStatus == 'IN PROGRESS' && currStatus == 'SUCCESSFUL' && connected)
        {
          return EMPTY;
        }

        else if (prevStatus == 'NOT STARTED' && currStatus == 'NOT STARTED'
        )
        {
          return EMPTY;
        }

        else if (prevStatus == 'IN PROGRESS' && currStatus == 'SUCCESSFUL' && !connected)
        {
          return this.startConnection();
        }

        return EMPTY;
      })
    ).subscribe();

    combineLatest([
      this.authentication.state.authenticated$,
      this.connected$,
      toObservable(this.accessToken)
    ]).pipe(
      distinctUntilChanged((prev, curr) => prev[0] === curr[0] && prev[1] === curr[1] && prev[2] === curr[2]),
      debug("Signal-R"),
      switchMap(([authenticated, connected, accessToken]) => {



        if(accessToken != null)
          return EMPTY;


        if((authenticated && connected) || (!authenticated && !connected)) {
          return EMPTY;
        } else if(!authenticated && connected) {
          return this.stopConnection();
        }
        return this.startConnection();
      })
    ).subscribe();



    const reconnecting$ = fromEventPattern(
      (handler) => this.hubConnection.onreconnecting(handler),
    );

    const closed$ = new Observable<boolean>((observer) => {

      this.hubConnection.onclose((error) => {
        observer.next(false)
      })

    });

    const reconnected$ = fromEventPattern(
      (handler) => this.hubConnection.onreconnected(handler),
    );


    merge(
      closed$
    ).pipe(    debug("SignalRConnectionStatus")).subscribe(status => this.connected$.next(status));
  }
  hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
  .withUrl(this.endpoint, {
    accessTokenFactory: () => this.accessToken(),
  })
  .withAutomaticReconnect()
  .build();



  public stopConnection() {
    return from(this.hubConnection?.stop()).pipe(tap(() => {
      this.connected$.next(false);
    }));
  }

  public startConnection(token?: string) {


    if(token != null)
    this.accessToken.set(token);

    return from(this.hubConnection.start()).pipe(
      tap(() => {
        this.connected$.next(true);
        window.addEventListener('beforeunload', () => {
          this.hubConnection.stop();
        });
      }),
      retry({
        delay: (error, retryCount) => {

          return new Promise((res) => setTimeout(res, 2000));
        },
      })
    );
  }

  public joinRoom(jobID: string) {
    return from(this.hubConnection.invoke('JoinRoom', jobID));
  }

  public leaveRoom(jobID: string) {
    return from(this.hubConnection.invoke('LeaveRoom', jobID));
  }

  public streamProperties()
  {
    return signalRStreamToObservable<Property>(this.hubConnection.stream("StreamChatMessages"))
  }

  public streamPropertyLayers(propertyID: string, layerIDs: number[] | null)
  {
    return signalRStreamToObservable<LayerDTO>(this.hubConnection.stream("StreamPropertyLayers", propertyID,layerIDs))
  }



  public getPrintDetails(jobID: string) {
    return from(this.hubConnection.invoke<PdfPrintDefinition>('GetPDFDetailsAsync', jobID));
  }

  public onFileReady(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('FileReady', (jobID) => {
        observer.next(jobID);
      });
    });
  }

public onReconnecting(): Observable<boolean> {
  return new Observable<boolean>((observer) => {

    this.hubConnection.onreconnecting((error) => {
      observer.next(false)
    })

    this.hubConnection.onreconnected((jobID) => {
      observer.next(true)
      observer.complete();
    });
  });
}

public checkConnectionStatus(): Observable<boolean> {
  return interval(1000).pipe(
    startWith(false),
    switchMap(() => {
      return this.hubConnection.state === signalR.HubConnectionState.Connecting ? of(false) : this.hubConnection.state === signalR.HubConnectionState.Connected ? of(true) : of(false)
    }),
    distinctUntilChanged(),
    filter(status => status === true),
    first()

  );
}

createEventObservable = (eventName: string): Observable<any> => {
  return fromEvent(this.hubConnection, eventName).pipe(
    catchError((error) => {
      console.error(`Error with event ${eventName}:`, error);
      return of(null);
    }))
}
}



export function signalRStreamToObservable<T>(stream:signalR.IStreamResult<any>) {
  return new Observable<T>((subscriber) => {
      const signalRSubscription = stream.subscribe({
          next: (item) => {
              subscriber.next(item);
          },
          error: (err) => {
              subscriber.error(err);
          },
          complete: () => {
              subscriber.complete();
          },
      });

      return () => {
          signalRSubscription.dispose();
      };
  });
}
