import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, effect, input, model } from '@angular/core';
import { Collection, Feature, Map } from 'ol';
import { Draw, Interaction, Snap } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'map-snap',
  templateUrl: './snap.component.html',
  styleUrls: ['./snap.component.css'],
  standalone:true,
  imports:[CommonModule, TooltipDirective]
})
export class SnapComponent implements OnInit, OnDestroy {

  active = model(true);

  disabled = input(false);

  baseInteraction = input.required<Interaction>();

  map = input.required<Map>();

  snapLayers = input.required<any[]>();
  snapFeatures = input<Feature[]>([]);


  private snapInteractions = [];

  constructor() {

    effect(() => {
      const snapFeatures = this.snapFeatures();
      const map = this.map();


      if(snapFeatures.length == 0)
        return;

      const snap = new Snap({
        features: new Collection(snapFeatures)
      });


      map.addInteraction(snap)



    })

    effect(() => {
      this.snapInteractions = [];
      const snapLayers = this.snapLayers();
      const snapFeatures = this.snapFeatures();


      if(snapFeatures.length > 0)
      {
        const snap = new Snap({
          features: new Collection(snapFeatures)
        });


        this.snapInteractions.push(snap);

      }


      snapLayers.forEach(lyr => {
        let snap = new Snap(
          {
            source: lyr.getSource()
          }
        )

        this.snapInteractions.push(
          snap
          )
      });

      this.initSnapInteractions();
    });

    effect(() => {

      const active = this.active();
      const baseInteraction = this.baseInteraction();
      const disabled = this.disabled();

      if(baseInteraction == null)
        {
          this.removeSnapInteractions();
          return;
        }

        if(baseInteraction != null && !this.map().getInteractions().getArray().includes(baseInteraction) && !disabled)
          {
            this.map().addInteraction(baseInteraction);
          }

      if(!active || disabled)
        {
          this.removeSnapInteractions();
        }
        else
        {
          this.initSnapInteractions();
        }




    })
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
      this.removeSnapInteractions();
  }

  toggleTool()
  {
    this.active.set(!this.active());
  }

  initSnapInteractions()
  {
    this.snapInteractions.forEach(interaction => {
      this.map().addInteraction(interaction);
    })
  }
  removeSnapInteractions()
  {
    this.snapInteractions.forEach(interaction => {
      this.map().removeInteraction(interaction);
    })
  }

}
