import { Injectable, inject, signal } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { RoutesExtended, SidebarData } from '../sidebar-types';

@Injectable()
export class SidebarService {
  private pageMap: Map<String, SidebarData> = new Map();

  public drawer: MatDrawer;

  opened: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public pages = signal([]);

  constructor(public route: ActivatedRoute, public router: Router) {}

  setSidebarRoutes(mapRoutes: RoutesExtended) {
    mapRoutes
      .filter((page) => page.data)
      .sort((a, b) => a.data.sidebar.position - b.data.sidebar.position)
      .forEach((page) =>
        this.pageMap.set(
          page.path.includes('/:') ? page.path.split('/:')[0] : page.path,
          page.data.sidebar
        )
      );

    //patch to allow loading of routes dynamically:

    this.pages.set(Array.from(this.pageMap, (v) => v[1]));

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.deactivateAllPages();
        let url = event.urlAfterRedirects;
        let pageName = url.split('/')[2];
        if (this.pageMap.has(pageName)) {
          this.pageMap.get(pageName).active = true;
        }
      });
  }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  close() {
    this.drawer.close();
  }

  open() {
    this.drawer.open();
  }

  getPageMap() {
    return this.pageMap;
  }

  private deactivateAllPages() {
    this.pageMap.forEach((page) => (page.active = false));
  }
}
