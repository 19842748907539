import { Injectable } from '@angular/core';
import { BaseEndpointService, IEndpoint } from 'src/app/@core/interfaces/IEndpoint';
import { PropertySuitability } from '../models/property-suitability.model';

@Injectable({
  providedIn: 'root'
})
export class PropertySuitabilityService extends BaseEndpointService implements IEndpoint {

constructor()
{
  super({route:'property-suitability'})
}
  getAll() {
    return this.http.get<Array<PropertySuitability>>(this.endpoint)
  }
  get(id: string) {
    throw new Error('Method not implemented.');
  }
  delete(id: string) {
    throw new Error('Method not implemented.');
  }
  update(id: String, model: {}) {
    throw new Error('Method not implemented.');
  }

}
