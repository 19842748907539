import { CdkAccordionItem } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, effect, inject, input, OnInit, output, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, switchMap, catchError, of, map } from 'rxjs';
import { state } from 'src/app/map/features/layers/data-access/services/layers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layer-legend',
  templateUrl: './layer-legend.component.html',
  styleUrls: ['./layer-legend.component.css'],
  standalone:true,
  imports:[CommonModule]
})
export class LayerLegendComponent implements OnInit {

  private readonly http: HttpClient = inject(HttpClient);
  layer = input<state>();

  legendObservable$ = toObservable(this.layer).pipe(
    filter(
      ({ sourceType, legendURL }) =>
        sourceType === 'ArcGISRest' && legendURL != null
    ),
    switchMap(({ legendURL, featureProperties }) =>
      this.http
        .get<any>(`${environment.apiUrl}data-catalogue/proxy?url=${legendURL}`)
        .pipe(
          catchError((error) => {
            console.error('Error fetching legend:', error);
            return of(JSON.stringify({ layers: [] }));
          }),
          map((response) => ({ response, featureProperties }))
        )
    ),
    map(({ response, featureProperties }) => {
      console.log(response, featureProperties);
      const layersToSelect = featureProperties['LAYERS'];
      const parsedResponse = JSON.parse(response);
      const legend = [];

      const processLegendItems = (legendItems) => {
        legendItems.forEach(({ label, imageData }) => {
          legend.push({
            label,
            imageSrc: `data:image/png;base64,${imageData}`,
          });
        });
      };

      if (layersToSelect == null) {
        parsedResponse.layers.forEach(({ legend }) =>
          processLegendItems(legend)
        );
      } else {
        const layerId = parseInt(layersToSelect.split(':')[1]);
        const legendDefinition = parsedResponse.layers.find(
          ({ layerId: id }) => id === layerId
        );
        if (legendDefinition) {
          processLegendItems(legendDefinition.legend);
        }
      }

      return legend;
    })
  );

  legend = toSignal(this.legendObservable$);

  lastLayerAdded: number = 0;

  arcGISLegend: { imageSrc: string; label: string }[] = [];
  ngOnInit() {}


}
