import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
  viewChild,
  viewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FeatureLayersService,
  LayerDTO,
  UserFeatureDTO,
} from '../../data-access/feature-layers.service';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material/material.module';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { DrawFeaturesOverlayComponent } from '../draw-features-overlay/draw-features-overlay.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
  CdkDragHandle,
} from '@angular/cdk/drag-drop';
import {
  Kind,
  StyleRendererComponent,
} from 'src/app/shared/components/style-renderer/style-renderer.component';
import { StyleEditorComponent } from 'src/app/shared/components/style-renderer/style-editor/style-editor.component';
import { SelectPropertyOverlayComponent } from '../../../../ui/select-property-overlay/select-property-overlay.component';
import { ActivatedRoute, ActivationEnd, RouterModule } from '@angular/router';
import { FeatureLayersToolbarComponent } from '../../ui/feature-layers-toolbar/feature-layers-toolbar.component';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { Select, Translate } from 'ol/interaction';
import { Collection, Feature } from 'ol';
import { TranslateComponent } from 'src/app/map/ui/translate/translate.component';
import { MapService } from 'src/app/map/data-access/map.service';
import { ModifyComponent } from 'src/app/map/ui/modify/modify.component';
import { EskToolDirective } from 'src/app/shared/directives/EskTool.directive';
import { PropertiesService } from '../../../../data-access/properties.service';
import { SnapComponent } from 'src/app/map/ui/snap/snap.component';
import { SelectComponent } from 'src/app/map/ui/select/select.component';
import { createResult } from '../../ui/create-feature-dialog/create-feature-dialog.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { LegendIconComponent } from 'src/app/shared/components/style-renderer/legend-icon/legend-icon.component';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { ProjectLayerFeatureItemComponent } from '../../ui/project-layer-feature-item/project-layer-feature-item.component';
import { IntersectComponent } from 'src/app/map/ui/intersect/intersect.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditProjectLayerComponent } from '../../ui/edit-project-layer/edit-project-layer.component';
import { LayerOpacityDirective } from 'src/app/map/features/layers/utils/layer-opacity.directive';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { LayerControlPreviewDirective } from 'src/app/shared/features/layer-control/layer-control-preview.directive';
import { LayerControlActionsDirective } from 'src/app/shared/features/layer-control/layer-control-actions.directive';
import {
  TuiAccordion,
  TuiAccordionItem,
  tuiItemsHandlersProvider,
} from '@taiga-ui/kit';
import { StopPropagationDirective } from 'src/app/shared/directives/stop-propagation.directive';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';
import { TuiScrollable, TuiScrollbar } from '@taiga-ui/core';
import {
  click,
  doubleClick,
  pointerMove,
  singleClick,
} from 'ol/events/condition';
import { featureEach } from '@turf/turf';
import { EskToolService } from 'src/app/shared/features/esk-tool/EskTool.service';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';
import { DoughnutModifyComponent } from 'src/app/map/ui/doughnut-modify/doughnut-modify.component';
import { CreateScenarioComponent } from '../../ui/create-scenario/create-scenario.component';
import { ScenarioService } from '../../data-access/scenario/scenario.service';
import { Result } from 'ts-results';
import {
  CreateScenarioRequest,
  GetScenarioRequest,
  UpdateScenarioRequest,
} from '../../data-access/scenario/models/models';
import { ScenarioPickerComponent } from '../../ui/scenario-picker/scenario-picker.component';
import { derivedAsync } from 'ngxtension/derived-async';
import { signalSlice } from 'ngxtension/signal-slice';
import { derivedFrom } from 'ngxtension/derived-from';
import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ToolbarContainerComponent } from '../../../../../../ui/toolbar-container/toolbar-container.component';
import { CovertWKTFeature } from 'src/app/shared/helpers/transformations';
import VectorSource from 'ol/source/Vector';
import { FeatureLayerState } from '../../data-access/state';
import { PropertiesState } from '../../../../data-access/state';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';
import { PropertyLayers } from '../../../../data-access/spatial';
import TileSource from 'ol/source/Tile';
import { SpatialService } from '../../data-access/spatial';
import { ScenarioState } from '../../data-access/scenario/state';
import {TuiSkeleton} from '@taiga-ui/kit';
import { ActionPortalDirective } from 'src/app/shared/features/action-portal/directives/action-portal.directive';
import { UpdateScenarioComponent } from '../../ui/update-scenario/update-scenario.component';
import { LayerControlDragDirective } from 'src/app/shared/features/layer-control/layer-control-drag.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-feature-layers',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    DrawFeaturesOverlayComponent,
    CdkAccordionModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    SelectPropertyOverlayComponent,
    DragDropModule,
    StyleEditorComponent,
    StyleRendererComponent,
    FeatureLayersToolbarComponent,
    TranslateComponent,
    ModifyComponent,
    SnapComponent,
    EskToolDirective,
    SelectComponent,
    TooltipDirective,
    LegendIconComponent,
    ProjectLayerFeatureItemComponent,
    IntersectComponent,
    MatExpansionModule,
    LayerOpacityDirective,
    LayerControlComponent,
    LayerControlPreviewDirective,
    LayerControlActionsDirective,
    LayerControlDragDirective,
    TuiAccordion,
    TuiAccordionItem,
    StopPropagationDirective,
    TuiScrollbar,
    OverflowPaddingDirective,
    DoughnutModifyComponent,
    ScenarioPickerComponent,
    OverlayModule,
    ToolbarComponent,
    StopPropagationDirective,
    TuiSkeleton,
    ActionPortalDirective,
    RouterModule
  ],
  templateUrl: './feature-layers.component.html',
  styleUrl: './feature-layers.component.css',
  providers: [LayerControlServiceService, ScenarioService],
  host: {
    class: 'p-4 inline-block w-full',
  },
})
export class FeatureLayersComponent implements OnInit, OnDestroy {
  public readonly state = inject(FeatureLayerState);
  public readonly spatial = inject(SpatialService);
  public readonly scenariosState = inject(ScenarioState);
  public readonly propertiesState = inject(PropertiesState);
  public readonly propertyLayers = inject(PropertyLayers);
  public readonly mapService: MapService = inject(MapService);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly eskToolService: EskToolService = inject(EskToolService);
  private overlay: Overlay = inject(Overlay);
  private injector = inject(Injector);
  private viewContainerRef = inject(ViewContainerRef);

  modify = viewChildren(ModifyComponent);
  featureItems = viewChildren<ElementRef<any>>('featureItem');

  featureDrawToolbarTemplate = viewChild<TemplateRef<any>>(
    'featureDrawToolbarTemplate'
  );
  layerEditToolbarTemplate = viewChild<TemplateRef<any>>(
    'layerEditToolbarTemplate'
  );
  featureEditToolbarTemplate = viewChild<TemplateRef<any>>(
    'featureEditToolbarTemplate'
  );

  params = toSignal(this.activatedRoute.paramMap);
  eskToolActive = toSignal(this.eskToolService.toolActiveChanges());

  private propertyID = computed(() => {
    const params = this.params();

    return params.get('id') ?? null;
  });

  selectedScenario = signal<GetScenarioRequest>(null);

  selectedFeature = signal(null);
  hoveredFeature = signal(null);

  selectedFeatureItem = computed(() => {
    const featureItems = this.featureItems();


    const featureEditTarget = this.state.featureEditTarget();

    if(featureEditTarget == null)
      return null;

    const featureItem = featureItems.find((component) => {
      return component.nativeElement.id == `feature-item-${featureEditTarget.featureID}`;
    });
    if (featureItem == null) {
      return null;
    }

    return featureItem.nativeElement;
  });

  scrollEffect = effect(() => {
    if (this.selectedFeatureItem()) {
      this.selectedFeatureItem().scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  });

  propertyBoundaryFeature = computed(() => {
    const activeProperty = this.propertiesState.activeProperty();

    if (activeProperty == null) return null;

    return activeProperty.boundaryFeature;
  });

  public form: UntypedFormGroup;

  public style;

  hoverInteraction = computed(() => {
    const layers = this.state.vectorLayers();

    if (layers.length == 0) return null;

    const hoverInteraction = new Select({
      condition: pointerMove,
      layers: [...layers],
      style: null,
    });

    hoverInteraction.on('select', (event) => {
      const selectedFeatures = event.selected;
      if (selectedFeatures.length > 0) {
        const feature = selectedFeatures[0];

        this.hoveredFeature.set(feature.get('featureID'));
      } else {
        this.hoveredFeature.set(null);
      }
    });
    return hoverInteraction;
  });

  clickInteraction = computed(() => {
    const layers = this.state.vectorLayers();

    if (layers.length == 0) return null;

    const clickInteraction = new Select({
      condition: singleClick,
      style: null,
      layers: [...layers],
    });

    clickInteraction.on('select', (event) => {
      event.mapBrowserEvent.stopPropagation();
      event.mapBrowserEvent.preventDefault();

      const selectedFeatures = event.selected;
      if (selectedFeatures.length > 0) {
        const feature = selectedFeatures[0];

        event.mapBrowserEvent.stopPropagation();
        event.mapBrowserEvent.preventDefault();

        const layer = this.state.featureLayersMap()[feature.get('layerID')];

        if (layer) {
          this.state.selectLayer(layer);
        }

        this.selectedFeature.set(feature.get('featureID'));

        clickInteraction.getFeatures().clear();
      }
    });
    return clickInteraction;
  });

  drawTypeDefinitions: { drawType: Kind; icon: string; layerKind: Kind, active:boolean }[] = [
    { drawType: 'Point', icon: ' fas fa-map-marker-alt', layerKind: 'Point',active: false },
    { drawType: 'Polygon', icon: 'fas fa-draw-polygon', layerKind: 'Polygon', active: true },
    { drawType: 'Circle', icon: 'far fa-circle', layerKind: 'Polygon',active: false },
    { drawType: 'LineString', icon: 'fas fa-minus', layerKind: 'LineString',active: false },
  ];

  highlightedFeature;

  selectedLayerControl = new FormControl();

  overlayRef: OverlayRef;

  constructor() {
    this.overlayRef = this.overlay.create({});

    this.selectedLayerControl.valueChanges.pipe().subscribe((data) => {
      this.state.selectLayer(data);
    });

    effect(() => {
      const layerEditTarget = this.state.selectedLayer();

      if (layerEditTarget == null) {
        return;
      }

      untracked(() => {
        this.selectedLayerControl.setValue(layerEditTarget);
      });
    });

    effect(
      () => {
        const propertyID = this.propertyID();

        if (propertyID == null) return;

        this.propertiesState.setActivePropertyID(this.propertyID());
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const hoverInteraction = this.hoverInteraction();
      const clickInteraction = this.clickInteraction();

      if (hoverInteraction != null)
        this.mapService.getMap().addInteraction(hoverInteraction);

      //  if(clickInteraction != null)
      //   this.mapService.getMap().addInteraction(clickInteraction)
    });

    effect(() => {
      const activeTool = this.eskToolActive();
      const editing = this.state.isEditing();
      const hoverInteraction = this.hoverInteraction();
      const clickInteraction = this.clickInteraction();

      if (activeTool || editing) {
        if (hoverInteraction != null)
          this.mapService.getMap().removeInteraction(hoverInteraction);

        if (clickInteraction != null)
          this.mapService.getMap().removeInteraction(clickInteraction);
        return;
      }

      if (hoverInteraction != null)
        this.mapService.getMap().addInteraction(hoverInteraction);

      //  if(clickInteraction != null)
      //   this.mapService.getMap().addInteraction(clickInteraction)
    });

    combineLatest([
      toObservable(this.featureDrawToolbarTemplate),
      toObservable(this.layerEditToolbarTemplate),
      toObservable(this.featureEditToolbarTemplate),
      toObservable(this.state.layerDigitizeTargetID),
      toObservable(this.state.layerEditTarget),
      toObservable(this.state.featureEditTarget),
    ]).subscribe(
      ([
        featureDrawToolbarTemplate,
        layerEditToolbarTemplate,
        featureEditToolbarTemplate,
        layerDigitizeTargetID,
        layerEditTarget,
        featureEditTarget,
      ]) => {
        let template: TemplatePortal<any>;

        if (this.overlayRef.hasAttached()) this.overlayRef.detach();

        if (
          layerDigitizeTargetID &&
          layerEditTarget == null &&
          featureEditTarget == null
        ) {
          template = new TemplatePortal(
            featureDrawToolbarTemplate,
            this.viewContainerRef
          );
        } else if (layerEditTarget != null) {
          template = new TemplatePortal(
            layerEditToolbarTemplate,
            this.viewContainerRef
          );
        } else if (featureEditTarget != null) {
          template = new TemplatePortal(
            featureEditToolbarTemplate,
            this.viewContainerRef
          );
        }

        if (template != null) {
          const toolbarRef = this.overlayRef.attach(template);
        }
      }
    );
  }

  ngOnDestroy(): void {
    const hoverInteraction = this.hoverInteraction();
    const clickInteraction = this.clickInteraction();

    if (hoverInteraction != null)
      this.mapService.getMap().removeInteraction(hoverInteraction);

    // if(clickInteraction != null)
    //  this.mapService.getMap().removeInteraction(clickInteraction)
  }

  layerOpenChange(isOpen: boolean, layer: any): void {
    if (!isOpen && this.state.selectedLayer()?.layerID === layer.layerID) {
      this.state.selectLayer(null);
    } else if (isOpen) {
      this.state.selectLayer(layer);
    }
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [CustomValidators.required]),
    });
  }

  onHover(feature) {
    if (!feature) {
      return;
    }

    const highlightStyle = new Style({
      fill: new Fill({ color: 'rgba(255,0,0,0.35)' }),
      stroke: new Stroke({ color: 'rgba(255,0,0,1)', width: 1 }),
    });

    feature.setStyle(highlightStyle);
  }

  deactivateAllModifyComponents() {
    const modifyComponents = this.modify();

    modifyComponents.forEach((component) => {
      component.active.set(false);
    });
  }

  onMouseOut(feature) {
    if (!feature) {
      return;
    }
    feature.setStyle(null);
  }

  onClick(feature) {
    if (feature) {
      this.mapService.zoomToFeature(feature);
    }
  }

  createFeature(feature: Feature, layer) {
    this.modalService
      .showCreateFeature(feature, layer)
      .pipe(
        filter((data: createResult) => data.shouldCreate),
        switchMap((data) =>
          this.state.createFeature([layer.layerID, data.model])
        )
      )
      .subscribe((data) => {});
  }

  createLayer() {
    this.modalService
      .showCreateLayerDialog()
      .pipe(
        filter((result) => result.create),
        switchMap((result) => this.state.createLayer(result.model))
      )
      .subscribe();
  }

  zoomTo() {
    this.mapService.zoomToFeature(this.propertiesState.activeProperty().boundaryFeature);
  }


  // downloadFile(layerID, srid: number = 4326): void {
  //   this.modalService
  //     .showDownloadLayer()
  //     .pipe(
  //       switchMap((data) => {
  //         return this.featureLayerService.downloadGeoJson(layerID, data.srid);
  //       })
  //     )
  //     .subscribe((response) => {
  //       const blob = new Blob([response.body], {
  //         type: 'application/geo+json',
  //       });

  //       const contentDisposition = response.headers.get('Content-Disposition');

  //       let filename = 'download.json';
  //       if (contentDisposition) {
  //         filename = contentDisposition
  //           .split(';')[1]
  //           .split('filename')[1]
  //           .split('=')[1]
  //           .trim();
  //       }

  //       const url = window.URL.createObjectURL(blob);
  //       const anchor = document.createElement('a');
  //       anchor.href = url;
  //       anchor.download = filename;
  //       anchor.click();
  //       window.URL.revokeObjectURL(url);
  //     });
  // }


  createScenario() {
    this.modalService.showComponent<CreateScenarioComponent, Result<CreateScenarioRequest | void, Error>>(
      CreateScenarioComponent,
      {}
    ).closed.pipe(
      filter(result => !result.err && result.val !== undefined),
      switchMap(result => from(this.scenariosState.create(result.val as CreateScenarioRequest))),
      catchError(error => {
        return EMPTY;
      })
    ).subscribe(data => {

    });
  }

  updateScenario(selectedScenario: GetScenarioRequest) {
    this.modalService.showComponent<UpdateScenarioComponent, Result<UpdateScenarioRequest | void, Error>>(
      UpdateScenarioComponent,
      {selectedScenario}
    ).closed.pipe(
      filter(result => !result.err && result.val !== undefined),
      switchMap(result => from(this.scenariosState.update({
        scenarioID: selectedScenario.scenarioID,
        model:result.val as UpdateScenarioRequest
      }))),
      catchError(error => {
        return EMPTY;
      })
    ).subscribe(data => {

    });
  }


  onSelectDelete(layer: LayerDTO, featuresToDelete: Array<Feature>) {
    const feature = featuresToDelete.length > 0 ? featuresToDelete[0] : null;

    if (feature == null) {
      return;
    }

    const featureID = feature.get('featureID');
    const name = feature.get('name');

    const userFeature = layer.features.find(
      (feature) => feature.featureID == featureID
    );

    this.onDeleteFeature(layer, userFeature, name);
  }

  onDeleteFeature(
    layer: LayerDTO,
    feature: UserFeatureDTO,
    featureName: string
  ) {
    this.showFeatureLayerDeleteModal(
      layer.layerID,
      feature.featureID,
      featureName
    )
      .pipe(
        filter((result) => result != false),
        switchMap(() =>
          this.state.deleteFeature([layer.layerID, feature.featureID])
        )
      )
      .subscribe();
  }

  showFeatureLayerDeleteModal(layerID, featureID, featureName) {
    return this.modalService.showConfirmDelete('Feature', featureName).pipe(
      filter((result) => result.ok),
      map((result) => result.val)
    );
  }

  editLayerDetails(layer: LayerDTO) {
    const ref = this.modalService.showComponent<EditProjectLayerComponent, any>(
      EditProjectLayerComponent,
      {}
    );

    ref.componentInstance.projectLayer.set(layer);

    ref.componentInstance.update.subscribe((result) => {
      ref.close();

      if (!result.success) {
        return;
      }

      this.state.updateLayer([layer.layerID, result.model]);
    });
  }

  onDrop(event) {
    const { item, currentIndex, previousIndex } = event;
    const { data }: { data: LayerDTO } = item;

   this.state.updateLayerSortOrder({currentIndex,previousIndex,layer:data})
  }
}
