<h2 mat-dialog-title>Export Spatial Data</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="form"
  >
<section class="grid grid--row gap--md">

  <mat-form-field>
    <mat-label>Layer Format</mat-label>
    <mat-select formControlName="format">
      <mat-option value="geojson">GeoJSON</mat-option>
      <mat-option value="kml">KML</mat-option>
      <mat-option value="wkt">WKT</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>SRID</mat-label>
    <mat-select formControlName="srid">
      <mat-option value="4326">EPSG:4326</mat-option>
    </mat-select>
  </mat-form-field>

</section>


  </form>

</mat-dialog-content>
<mat-dialog-actions class="actions" align="end">
  <button class="button button--muted button--xs" (click)="this.dialogRef.close()">Cancel</button>
  <button class="button button--green-fill button--xs" (click)="download()">Export</button>
</mat-dialog-actions>
a
