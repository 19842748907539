import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { MaterialModule } from 'src/app/material/material.module';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { CreateCatalogueGroup } from '../../../data-access/models/create-update-catelogue.model';

@Component({
  selector: 'app-create-catalogue-group-dialog',
  templateUrl: './create-catalogue-group-dialog.component.html',
  styleUrls: ['./create-catalogue-group-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
})
export class CreateCatalogueGroupDialogComponent implements OnInit {
  private readonly dialogRef: DialogRef<CreateCatalogueGroupDialogComponent> =
    inject(DialogRef<CreateCatalogueGroupDialogComponent>);

  @Output() createEvent = new EventEmitter();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', CustomValidators.required),
    });
  }

  ngOnInit() {}

  submit() {
    let model: CreateCatalogueGroup = {
      Name: this.form.get('name').value,
    };

    this.createEvent.emit(CreateEvent.create<CreateCatalogueGroup>(model));
    this.dialogRef.close();
  }

  cancel() {
    this.createEvent.emit(CreateEvent.cancel<CreateCatalogueGroup>());
    this.dialogRef.close();
  }
}
