import { Component, OnInit, Signal, effect, input, output, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, map, startWith } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import {TuiLabel, TuiTitle} from '@taiga-ui/core';
import {TuiSliderComponent,TuiDataListWrapper} from '@taiga-ui/kit';
import { TuiSelectModule } from '@taiga-ui/legacy';
import {TuiTextfield,TuiDataList} from '@taiga-ui/core';
import {TUI_DEFAULT_INPUT_COLORS, TuiInputColorModule,TuiInputNumberModule, tuiInputNumberOptionsProvider} from '@taiga-ui/legacy';
import { LegendIconComponent } from '../../../legend-icon/legend-icon.component';

interface PointStyleDefinition {
  color?: string | null;
  visibility?: boolean | null;
  width?: number | null;
  opacity?: number | null;
  fillOpacity?: number | null;
  wellKnownName?: string;
  strokeColor?: string | null;
  strokeWidth?: number | null;
  strokeOpacity?: number | null;
  rotate?: number | null;
}

@Component({
  selector: 'app-point-style-form',
  templateUrl: './point-style-form.component.html',
  styleUrls: ['./point-style-form.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ColorPickerModule,
    TuiLabel,
    TuiSliderComponent,
    TuiTitle,
    TuiInputColorModule, TuiTextfield,
    TuiInputNumberModule,
    TuiDataListWrapper,
    TuiDataList,
    TuiSelectModule,
    LegendIconComponent
  ],
})
export class PointStyleFormComponent implements OnInit {

  styleDefinition: Signal<PointStyleDefinition> = input();

  form!: FormGroup;
  styleChanged = output<any>();
  protected readonly palette = TUI_DEFAULT_INPUT_COLORS;
  options = [
    'circle',
    'square',
    'triangle',
    'star',
    'cross',
    'x',
    'shape://carrow',
    'shape://dot',
    'shape://horline',
    'shape://oarrow',
    'shape://plus',
    'shape://slash',
    'shape://times',
    'shape://vertline',
  ];
  preview = signal({
    name: 'null',
    symbolizers: [],
    filter: null,
  })

  constructor() {
    this.form = new FormGroup({
      kind: new FormControl('Mark'),
      color: new FormControl('#0E1058'),
      visibility: new FormControl(true),
      radius: new FormControl(3),
      opacity: new FormControl(null),
      fillOpacity: new FormControl(null),
      wellKnownName: new FormControl(this.options[0]),
      strokeColor: new FormControl(null),
      strokeWidth: new FormControl(null),
      strokeOpacity: new FormControl(null),
      rotate: new FormControl(null),
    });

    effect(() => {
      const styleDefinition = this.styleDefinition();

      Object.keys(styleDefinition).forEach((key) => {
        const control = this.form.controls[key] as FormControl;
        const value = styleDefinition[key];

        if (control == null) {
          return;
        }
        control.setValue(value, { emitEvent: true });
      });
    });

    this.form.valueChanges
      .pipe(
        startWith(this.filterNullValues(this.form.getRawValue())),
        map((values: PointStyleDefinition) => this.filterNullValues(values)),
        debounceTime(100)
      )
      .subscribe((data) => {
        this.styleChanged.emit(data);
        this.preview.update(preview => ({...preview, symbolizers: [data]}))
      });
  }

  private filterNullValues(
    values: PointStyleDefinition
  ): Partial<PointStyleDefinition> {
    const filteredValues: Partial<any> = {};
    (Object.keys(values) as (keyof PointStyleDefinition)[]).forEach((key) => {
      if (values[key] !== null) {
        filteredValues[key] = values[key];
      }
    });
    return filteredValues;
  }

  ngOnInit() {}
}
