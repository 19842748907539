<mat-form-field subscriptSizing="dynamic" appearance="outline">
  <mat-label>Base Map</mat-label>
  <mat-select class="basemap-select" [formControl]="selectedBaseMapForm">
    <mat-option
      appTooltip
      [appTooltip]="layer.get('title')"
      class="basemap-option"
      *ngFor="let layer of layers()"
      [value]="layer"
      >{{ layer.get("title") }}</mat-option
    >
  </mat-select>
</mat-form-field>
