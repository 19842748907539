import {
  computed,
  effect,
  inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  delay,
  delayWhen,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  EMPTY,
  endWith,
  filter,
  finalize,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  pairwise,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  LayerState,
  FeatureState,
  LayerDTO,
  UserFeatureDTO,
  FeatureLayersService,
} from './feature-layers.service';
import { signalSlice } from 'ngxtension/signal-slice';
import { createInjectable } from 'ngxtension/create-injectable';
import VectorSource from 'ol/source/Vector';
import { CreateFeatureLayer } from '../ui/create-feature-layer/create-feature-layer.component';
import { SpatialService } from './spatial';
import { PropertiesService } from '../../../data-access/properties.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { PropertiesState } from '../../../data-access/state';
import { ToggleButton } from 'primeng/togglebutton';
import { Property } from '../../../data-access/models/property.model';
import {
  layerFeatureCreated,
  processFeatureLayer,
  processLayerFeature,
  processLayerFeatures,
  updateStateOperator,
  addLayerToMap,
  layerDeletedOperator,
  removeLayerFromGroup,
  layerFeatureDeleted,
  layerFeatureUpdated,
  featuresReducer,
  promptUnsavedChangesConfirmation,
  promptDeleteConfirmation,
} from './utils';
import OlParser from 'geostyler-openlayers-parser';
import { signalSlice2 } from '../../../signal-slice';
import { SignalRService } from 'src/app/@core/services/signalR.service';
import { ConvertFeatureToWKT } from 'src/app/shared/helpers/transformations';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { ScenarioState } from './scenario/state';
import { debug } from 'ngxtension/debug';
import { layersControl } from 'src/app/map/features/layers/utils/layers.control';
import { area, combine } from '@turf/turf';
import { filterNil } from 'ngxtension/filter-nil';
import { GetScenarioRequest } from './scenario/models/models';
import { AuthState } from 'src/app/@core/Auth/state';

interface DragEventData {
  currentIndex: number;
  previousIndex: number;
  layer: LayerDTO;
}


export const FeatureLayerState = createInjectable(
  () => {
    const featureLayerService = inject(FeatureLayersService);
    const featureLayersSpatialService = inject(SpatialService);
    const authService = inject(AuthState);
    const scenarioState = inject(ScenarioState);
    const modal = inject(ModalService);
    const propertiesState = inject(PropertiesState);
    const parser = new OlParser();
    const signalR = inject(SignalRService);

    const layerCreated = signalR.createEventObservable('LayerCreated');
    const layerUpdated = signalR.createEventObservable('LayerUpdated');
    const layerDeleted = signalR.createEventObservable('LayerDeleted');
    const layerSortOrderUpdated = signalR.createEventObservable('LayerSortOrderUpdated');
    const layerAreaUpdated = signalR.createEventObservable('LayerAreaUpdated').pipe(
      map((data: [number, number]) => ({
        layerID: data[0],
        area: data[1],
      })));

    const loadTrigger = combineLatest<[number[] | null, any, any]>([
      scenarioState.selectedScenario$.pipe(
        map((scenario: GetScenarioRequest)=> scenario?.featureLayers),
        startWith(null)
      ),
      propertiesState.activePropertyID$.pipe(startWith(null)),
      propertiesState.propertiesLoaded$.pipe(filter(val => val === true)),
    ]);

    const featureCreated = signalR.createEventObservable('FeatureCreated').pipe(
      map((data: [number, UserFeatureDTO]) => ({
        layerID: data[0],
        userFeature: data[1],
      }))
    );
    const featureUpdated = signalR.createEventObservable('FeatureUpdated').pipe(
      map((data: [number, UserFeatureDTO]) => ({
        layerID: data[0],
        userFeature: data[1],
      }))
    );

    const featuresUpdated = signalR
      .createEventObservable('FeaturesUpdated')
      .pipe(
        map((data: [number, UserFeatureDTO[]]) => ({
          layerID: data[0],
          userFeatures: data[1],
        })),
        mergeMap(({ layerID, userFeatures }) =>
          from(userFeatures).pipe(
            map((userFeature: UserFeatureDTO) => ({
              layerID,
              userFeature,
            }))
          )
        )
      );




    const featureDeleted = signalR.createEventObservable('FeatureDeleted').pipe(
      map((data: [number, number]) => ({
        layerID: data[0],
        featureID: data[1],
      }))
    );

    const INITIAL_STATE = {
      featureLayers: [],
      vectorLayers: [],
      featureLayersMap: {},
      overview: {
        propertyID: 0,
        propertyArea: 0,
        cumulativeArea: 0,
        percentageOfProperty: 0,
        balance: 0,
      },
      selectedLayer: null,
      layerEditTarget: null,
      layerDigitizeTargetID: null,
      featureEditTarget: null,
      featureEditMap: {},
      editType: null,
      editTarget: null,
      loading: true,
      canSort: true,
    };




    const STATE = signalSlice2({
      initialState: INITIAL_STATE,
      sources: [
        (state) => combineLatest([state.featureLayers$.pipe(filterNil()),propertiesState.activeProperty$.pipe(filterNil())]).pipe(map(([featureLayers, activeProperty]) => {

            const cumulativeArea = (featureLayers.filter((layer) => layer.isLandUse).reduce((total, featureLayer) => total + featureLayer.area, 0)).toFixed(2);

            const {areaHaCalculated} = activeProperty;

            const PercentageOfProperty = (cumulativeArea / areaHaCalculated) * 100;

            const balance = (areaHaCalculated - cumulativeArea).toFixed(2);;

            return {overview: {
              propertyID: activeProperty.propertyID,
              propertyArea: areaHaCalculated,
              cumulativeArea: cumulativeArea,
              percentageOfProperty: PercentageOfProperty,
              balance: balance,
            }}


        })),
        (state) =>
          featureLayersSpatialService.addModifyFeature$.pipe(
            featuresReducer({ state })
          ),
        (state) =>
          featureLayersSpatialService.removeModifyFeature$.pipe(
            featuresReducer({ state })
          ),
        (state) =>
          loadTrigger.pipe(
            tap(() => {
              featureLayersSpatialService.projectLayersGroup
                .getLayers()
                .clear();
            }),
            switchMap(([layerIDs, propertyID]) => {
              if (propertyID != null) {
                return of(propertyID).pipe(
                  switchMap(() => from(STATE.resetState())),
                  tap(() => {
                    featureLayerService.setResourceParams({
                      propertyID: propertyID,
                    });
                  }),
                  switchMap(() =>
                    signalR.connected$.pipe(
                      filter((connected) => connected),
                      take(1)
                    )
                  ),
                  switchMap(() =>
                    signalR.streamPropertyLayers(propertyID, layerIDs).pipe(
                      finalize(() => {
                          STATE.setLoading(false);
                      }),
                      catchError((error) => {
                        console.error(
                          'Error streaming property layers:',
                          error
                        );
                        return of(null);
                      }),
                      filter((stream) => stream !== null)
                    )
                  ),
                  delayWhen(() =>
                    propertiesState.propertiesLoaded$.pipe(
                      filter((ready) => ready)
                    )
                  ),
                  processFeatureLayer({ parser }),
                  processLayerFeatures({}),
                  addLayerToMap({
                    layerGroup: featureLayersSpatialService.projectLayersGroup,
                  }),
                  updateStateOperator({ state })
                );
              } else {
                return of().pipe(
                  map(() => ({ ...INITIAL_STATE })),
                  tap(() => {
                  })
                );
              }
            }),
            startWith(({loading:true})),
            catchError((error) => {
              console.error('Error in activePropertyID$ pipeline:', error);
              return EMPTY;
            })
          ),
          authService.state.authenticated$.pipe(
            filter((authenticated) => authenticated === false),
            map(() => INITIAL_STATE)
          ),
        (state) =>
          layerCreated.pipe(
            processFeatureLayer({ parser }),
            processLayerFeatures({}),
            addLayerToMap({
              layerGroup: featureLayersSpatialService.projectLayersGroup,
            }),
            updateStateOperator({ state })
          ),
        (state) =>
          layerDeleted.pipe(
            layerDeletedOperator({
              state,
              layerGroup: featureLayersSpatialService.projectLayersGroup,
            })
          ),

        (state) =>
          layerUpdated.pipe(
            removeLayerFromGroup({
              state,
              layerGroup: featureLayersSpatialService.projectLayersGroup,
            }),
            processFeatureLayer({ parser }),
            processLayerFeatures({}),
            addLayerToMap({
              layerGroup: featureLayersSpatialService.projectLayersGroup,
            }),
            updateStateOperator({ state })
          ),
          (state) => layerSortOrderUpdated.pipe(map((updatedLayerSortArray: {layerID, sortOrder}[]) => {

            const {featureLayersMap} = state();

            const updatedLayers = updatedLayerSortArray.map<LayerDTO>(({layerID, sortOrder}) => {
              const existingLayer = featureLayersMap[layerID] as LayerDTO | undefined;
              if (!existingLayer) return null;

              const {mapLayer} = existingLayer;
              mapLayer.setZIndex(sortOrder);
              return {...existingLayer, sortOrder: sortOrder, mapLayer}
            }).filter((layer): layer is LayerDTO => layer !== null);



            return updatedLayers;


          }),
          concatMap((layers) => from(layers)),
          updateStateOperator({ state })),


          (state) =>
    layerAreaUpdated.pipe(debug("area updated"),map(({layerID,area}) => {

      const {featureLayersMap, featureLayers} = state();

      const existingLayer = featureLayersMap[layerID];
      const featureLayerIndex = featureLayers.findIndex((layer) => layer.layerID === layerID);

      const updatedLayer = {...existingLayer, area: area};

      const updatedLayers = [   ...featureLayers.slice(0, featureLayerIndex),
        updatedLayer,
        ...featureLayers.slice(featureLayerIndex + 1)]

      const updatedFeatureLayersMap = {
        ...featureLayersMap,
        [layerID]: updatedLayer,
      };


      return {featureLayers:updatedLayers, featureLayersMap:updatedFeatureLayersMap  }


    }))
          ,

        (state) =>
          featureCreated.pipe(
            layerFeatureCreated({ state }),
            processLayerFeature(),
            updateStateOperator({ state })
          ),
        (state) =>
          featureDeleted.pipe(
            layerFeatureDeleted({ state }),
            updateStateOperator({ state })
          ),
        (state) =>
          featureUpdated.pipe(
            layerFeatureUpdated({ state }),
            processLayerFeature(),
            updateStateOperator({ state })
          ),
        (state) =>
          featuresUpdated.pipe(
            layerFeatureUpdated({ state }),
            processLayerFeature(),
            updateStateOperator({ state })
          ),

         (state) => scenarioState.selectedScenario$.pipe(debug("Selected Scenario"),
            map(selectedScenario => ({ canSort: selectedScenario == null }))
          )
      ],

      selectors: (state) => ({
        isLayerEditing: () => (state.layerEditTarget() == null ? false : true),
        isDigitizing: () => (state.layerDigitizeTargetID() == null ? false : true),
        isLayerSelected: () => (state.selectedLayer() == null ? false : true),
        isEditing: () => state.editTarget() != null,
        isFeatureEditing: () =>
          state.featureEditTarget() == null ? false : true,
        layerEditTargetID: () => state.layerEditTarget()?.layerID,
        featureEditTargetID: () => state.featureEditTarget()?.featureID,
      }),
      actionSources: {
        createLayer: (state, action$: Observable<CreateFeatureLayer>) =>
          action$.pipe(
            switchMap((model) => featureLayerService.createLayer(model)),
            switchMap(() => EMPTY)
          ),

        updateLayer: (state, action$: Observable<[number, any]>) =>
          action$.pipe(
            switchMap(([layerID, model]) =>
              featureLayerService.updateLayer(layerID, model)
            ),
            switchMap(() => EMPTY)
          ),
        deleteLayer: (state, action$: Observable<number>) =>
          action$.pipe(
            switchMap((layerID) =>  promptDeleteConfirmation({modal}).pipe(
              map(() => layerID)
            )),
            switchMap((layerID) => featureLayerService.deleteLayer(layerID)),
            switchMap(() => EMPTY)
          ),

        toggleDigitize: (state, action$: Observable<LayerDTO | null>) => action$.pipe(switchMap((userLayer) => {
          const hasUnsavedChanges = Object.keys(state().featureEditMap).length > 0;

          if (hasUnsavedChanges) {
            return promptUnsavedChangesConfirmation({modal}).pipe(
              map(() => userLayer)
            );
          }

          return of(userLayer);
        }),map(featureLayer =>
          {

            const {layerDigitizeTargetID} = state();
            const digitizeTargetID = layerDigitizeTargetID === featureLayer.layerID ? null : featureLayer.layerID;

            return {layerDigitizeTargetID:digitizeTargetID, layerEditTarget: null, featureEditTarget:null,editType: null,selectedLayer: featureLayer,
              editTarget: null, }
          }
         )),

        selectLayer: (state, action$: Observable<LayerDTO | null>) =>
          action$.pipe(
            map((layer) => {
              const {selectedLayer, layerEditTarget} = state();

              if (selectedLayer != null && layer == null) {
                return { selectedLayer: null };
              }

              return { selectedLayer: layer };
            })
          ),
        editLayer: (state, action$: Observable<LayerDTO>) =>
          action$.pipe(
            switchMap((userLayer) => {
              if (Object.keys(state().featureEditMap).length > 0) {
                return modal
                  .showConfirmation(
                    'You have unsaved changes, are you sure you want to proceed?'
                  )
                  .pipe(
                    switchMap((result) => {
                      if (result.err) return EMPTY;

                      if (result.ok && result.val === false) return EMPTY;

                      return of(userLayer);
                    })
                  );
              }
              return of(userLayer);
            }),
            map((layer) => ({
              layerEditTarget: layer,
              selectedLayer: layer,
              layerDigitizeTargetID:null,
              featureEditTarget: null,
              editType: 'layer',
              editTarget: layer,
            }))
          ),
        cancelLayerEdit: (state, action$: Observable<void>) =>
          action$.pipe(
            map(() => ({
              layerEditTarget: null,
              editType: null,
              editTarget: null,
            }))
          ),
        editFeature: (state, action$: Observable<UserFeatureDTO>) =>
          action$.pipe(
            switchMap((userFeature) => {
              if (Object.keys(state().featureEditMap).length > 0) {
                return modal
                  .showConfirmation(
                    'You have unsaved changes, are you sure you want to proceed?'
                  )
                  .pipe(
                    switchMap((result) => {
                      if (result.err) return EMPTY;

                      if (result.ok && result.val === false) return EMPTY;

                      return of(userFeature);
                    })
                  );
              }
              return of(userFeature);
            }),
            map((feature) => ({
              featureEditTarget: feature,
              layerEditTarget: null,
              editType: 'feature',
              editTarget: feature,
              layerDigitizeTargetID:null,
            }))
          ),

        cancelFeatureEdit: (state, action$: Observable<void>) =>
          action$.pipe(
            map(() => ({
              featureEditTarget: null,
              editType: null,
              editTarget: null,
            }))
          ),
        createFeature: (state, action$: Observable<[number, any]>) =>
          action$.pipe(debug("Creating feature"),
            switchMap(([layerID, model]) =>
              featureLayerService.createFeature(layerID, model)
            ),
            switchMap(() => EMPTY)
          ),
        deleteFeature: (state, action$: Observable<[number, number]>) =>
          action$.pipe(
            switchMap(([layerID, featureID]) =>
              featureLayerService.deleteFeature(layerID, featureID)
            ),
            switchMap(() => EMPTY)
          ),

        saveFeatureChanges: (state, action$: Observable<[number, number]>) =>
          action$.pipe(
            map(([layerID, featureID]) => {
              const feature = state().featureEditMap[layerID][featureID];

              const wkt = ConvertFeatureToWKT(feature);

              return [layerID, featureID, wkt];
            }),
            switchMap(([layerID, featureID, wkt]) =>
              featureLayerService.updateFeature(layerID, featureID, wkt)
            ),
            map(() => ({
              featureEditTarget: null,
              editType: null,
              editTarget: null,
            }))
          ),

        bulkSaveFeatureChanges: (state, action$: Observable<number>) =>
          action$.pipe(
            map((layerID) => {
              const features = state().featureEditMap[layerID];

              const models = Object.keys(features).map((featureID) => {
                const feature = features[featureID];

                const wkt = ConvertFeatureToWKT(feature);

                return { featureID, wkt };
              });

              return { layerID, models };
            }),
            switchMap((updatePayload) =>
              featureLayerService.updateLayerFeatures(updatePayload)
            ),
            map(() => ({
              layerEditTarget: null,
              editType: null,
              editTarget: null,
            }))
          ),

        resetState: (state, action$: Observable<void>) =>
          action$.pipe(map(() => ({ ...INITIAL_STATE }))),

        setLoading: (state, action$: Observable<boolean>) =>
          action$.pipe(map((val) => ({ loading:val }))),

        updateLayerSortOrder: (state, action$: Observable<DragEventData>) =>
          action$.pipe(
            switchMap(({currentIndex,previousIndex,layer}) =>
              {
                const {layerID} = layer;
                const length = state().featureLayers.length;
                let reversedIndex = layer.sortOrder;

                if (currentIndex > previousIndex) {

                  reversedIndex = length - currentIndex - 1;
                } else if (currentIndex < previousIndex) {

                  reversedIndex = length - currentIndex - 1;;
                }


                return featureLayerService.updateLayerSortOrder(layerID, Math.abs(reversedIndex))
              }

            ),
            switchMap(() => EMPTY)
          ),
      },
    });

    const editTarget$ = STATE.editTarget$ as Observable<any>;

    const editType$ = STATE.editType$ as Observable<any>;

    const editTrigger = combineLatest([editTarget$, editType$]).pipe(
      map(([editTarget, editType]) => ({ type: editType, target: editTarget }))
    );

    editTrigger
      .pipe(pairwise(), takeUntilDestroyed())
      .subscribe(([prev, curr]) => {
        if (prev.type != null && prev.target != null)
          featureLayersSpatialService.modifySource.clear();

        if (curr.target == null) {
          return;
        }

        if (curr.type === 'feature') {
          featureLayersSpatialService.editFeature(curr.target);
        } else if (curr.type === 'layer') {
          featureLayersSpatialService.editLayer(curr.target);
        }
      });

    effect(() => {
      const isEditing = STATE.isEditing();

      if (isEditing) {
        featureLayersSpatialService.addModifyLayer();
        return;
      }

      featureLayersSpatialService.removeModifyLayer();
    },{allowSignalWrites: true});


    return STATE;
  },
  { providedIn: 'root' }
);
