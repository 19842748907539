import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, effect, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { LegendRenderer } from 'geostyler-legend';
import { from } from 'rxjs';

@Component({
  selector: 'legend-icon',
  templateUrl: './legend-icon.component.html',
  styleUrls: ['./legend-icon.component.css'],
  standalone: true,
  imports:[CommonModule]
})
export class LegendIconComponent implements OnInit {

  rule = input.required<any>();


  imageUrl = computed(async () => {

    const rule = this.rule();
    const icon = await this.renderer.getRuleIcon(rule)

    return icon ;

  });

  renderer = new LegendRenderer({
    maxColumnWidth: 300,
    maxColumnHeight: 300,
    overflow: 'auto',
    size: [600, 300]
  });

  constructor() {


   }

  ngOnInit() {
  }

}
