import {AsyncPipe, PercentPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, forwardRef, model, signal} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {TUI_FALSE_HANDLER, tuiClamp} from '@taiga-ui/cdk';
import {TuiButton, TuiHint} from '@taiga-ui/core';
import {TuiSlider} from '@taiga-ui/kit';
import {BehaviorSubject, distinctUntilChanged, map, of, switchMap, timer} from 'rxjs';
import { StopPropagationDirective } from '../../directives/stop-propagation.directive';

@Component({
    standalone: true,
    imports: [TuiButton, TuiSlider, TuiHint, PercentPipe, AsyncPipe, FormsModule, StopPropagationDirective],
    selector: 'app-opacity-slider',
    templateUrl: './opacity-slider.component.html',
    styleUrls: ['./opacity-slider.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(pointerdown)': 'onKeydown(true)',
        '(document:pointerup)': 'onKeydown(false)',
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OpacitySlider),
            multi: true,
        },
    ],
})
export default class OpacitySlider implements ControlValueAccessor {
    protected min = 0;
    protected max = 1;
    protected value = model(1);

    protected readonly active$ = new BehaviorSubject(false);
    protected readonly showHint$ = this.active$.pipe(
        distinctUntilChanged(),
        switchMap((active) =>
            active ? of(true) : timer(1000).pipe(map(TUI_FALSE_HANDLER)),
        ),
    );

    // ControlValueAccessor methods
    public onChange: (value: number) => void = () => {};
    private onTouched: () => void = () => {};

    // Method to handle keydown events for active status
    protected onKeydown(show: boolean): void {
        this.active$.next(show);
    }

    // Method to handle changes in the slider value
    protected change(step: number): void {
        this.value.set(tuiClamp(this.value() + step, this.min, this.max));
        this.onChange(this.value());
    }

    // ControlValueAccessor - Write value to the component
    writeValue(value: number): void {
        this.value.set(value)
    }

    // ControlValueAccessor - Register onChange handler
    registerOnChange(fn: (value: number) => void): void {
        this.onChange = fn;
    }

    // ControlValueAccessor - Register onTouched handler
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // ControlValueAccessor - Set the control as disabled
    setDisabledState(isDisabled: boolean): void {
        // Handle disabling the component if needed
    }
}
