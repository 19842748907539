import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor() {}

  getTestTermsAccepted() {
    const testTermsAccepted = localStorage.getItem('testTermsAccepted');
    const testTermsAcceptanceExpires = localStorage.getItem(
      'testTermsAcceptanceExpires'
    );
    const domain = window.location.hostname.toLowerCase();
    const testDomains = [
      'test.lagunabay.eskspatial.com.au',
      'www.test.lagunabay.eskspatial.com.au',
    ];

    // using domain until environment includes test flag
    if (!environment.production) {
      if (
        testTermsAccepted &&
        testTermsAcceptanceExpires &&
        testTermsAccepted === 'true'
      ) {
        const expires = parseInt(testTermsAcceptanceExpires);
        if (expires > Date.now()) {
          return of(true);
        } else {
          localStorage.removeItem('testTermsAccepted');
          localStorage.removeItem('testTermsAcceptanceExpires');
          return of(false);
        }
      } else {
        return of(false);
      }
    }

    return of(true);
  }

  // set remember choice and start expiry from midnight
  setTestTermsAccepted(rememberChoice: boolean) {
    if (rememberChoice) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 7);
      expires.setHours(0, 0, 0, 0);

      localStorage.setItem('testTermsAccepted', 'true');
      localStorage.setItem(
        'testTermsAcceptanceExpires',
        expires.getTime().toString()
      );
    }
  }
}
