import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { PropertiesState } from '../../data-access/state';
import { Router, RouterModule } from '@angular/router';
import { filterNil } from 'ngxtension/filter-nil';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'select-property-info-page',
  template: `<div class="flex items-center gap-2 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative m-2" role="alert">
<span><i class="fa fa-info-circle text-3xl"></i></span>
  <p class="text-sm">
    Please select a property by clicking on one on the map or by double-clicking on one in the properties table.
  </p>
</div>`,
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class SelectPropertyInfoPageComponent implements OnInit {

  private readonly state = inject(PropertiesState);
  private readonly router = inject(Router);

  constructor() {

    // if there was a previous Active Property, we need to navigate to the PropertyDetails Page.
    this.state.activePropertyID$.pipe(filterNil(),takeUntilDestroyed()).subscribe(activePropertyID => {
      this.router.navigate([`/map/property`, activePropertyID]);
    })
   }

  ngOnInit() {

  }

}
