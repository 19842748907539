import { Component, ElementRef, OnInit, ViewContainerRef, effect, inject, viewChild } from '@angular/core';
import { AuthService } from './@core/Auth/auth.service';
import { ComponentFactory } from './@core/services/ComponentFactory/component-factory.service';
import { TokenService } from './@core/services/token.service';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { debug } from 'ngxtension/debug';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Laguna Bay Web Map';

  private readonly authService = inject(AuthService);
  private readonly componentFactory = inject(ComponentFactory);
  private readonly tokenService = inject(TokenService)
  private readonly onlineStatusService: OnlineStatusService = inject(OnlineStatusService);

  container = viewChild.required('componentContainer',{read: ViewContainerRef});

  constructor(private viewContainerRef:ViewContainerRef) {


    this.onlineStatusService.status.pipe(debug("Online Status")).subscribe((status: OnlineStatusType) => {

    });



    effect(()=>{
      const container = this.container();

      this.componentFactory.setTarget(container);
    })

  }
  ngOnInit(): void {

  }


}
