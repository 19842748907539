<div class="title-bar">
  <div class="title">ArcGIS Feature Layers</div>
  <div class="window-controls">
    <button (click)="cancel()"><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content get-capabilities-layer-content gap--md">
  @if (layers() === null) {
    <div>Retrieving layer list from ArcGIS Features</div>
  } @else if (layers().length === 0) {
    <div>No Valid Layers</div>
  } @else {
    <div class="d-flex flex-column gap--md">
      <input [formControl]="searchField" type="text" pInputText placeholder="Search" />
      <label id="layerSelectLabel">Select the layer</label>
    </div>
    <mat-radio-group aria-labelledby="layerSelectLabel" class="layer-radio-group" [(ngModel)]="selectedLayer">
      @for (capability of filteredLayers(); track capability) {
        <mat-radio-button class="layer-radio-button" [value]="capability">{{capability.name}}</mat-radio-button>
      }
    </mat-radio-group>
  }
</div>
<div class="footer">
  <button type="button" class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue flex gap--sm" (click)="setSelection()"
          [disabled]="isDisabled">Done</button>
</div>
