import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, computed, effect, Inject, inject, OnInit, output, signal, WritableSignal } from '@angular/core';
import { Ok, Result } from 'ts-results';
import { FeatureLayersService, LayerDTO } from '../../data-access/feature-layers.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective } from 'src/app/shared/features/dialog';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TuiCheckbox, TuiSwitch,  } from '@taiga-ui/kit';

import {TuiInputModule} from '@taiga-ui/legacy';
import { CreateScenarioRequest, GetScenarioRequest, UpdateScenarioRequest } from '../../data-access/models/models';

@Component({
  selector: 'app-update-scenario',
  templateUrl: './update-scenario.component.html',
  styleUrls: ['./update-scenario.component.css'],
  standalone:true,
  imports:[CommonModule,ReactiveFormsModule, TuiCheckbox, TuiSwitch, TuiInputModule, DialogComponent,DialogContentDirective,DialogFooterDirective,DialogHeaderDirective]
})
export class UpdateScenarioComponent implements OnInit {

  private readonly dialogRef: DialogRef<Result<UpdateScenarioRequest | void, Error>,UpdateScenarioComponent> = inject(DialogRef<Result<{} | void, Error>,UpdateScenarioComponent>);

  private readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);


  selectedScenario:WritableSignal<GetScenarioRequest> = signal(null);

  propertyLayers = toSignal(this.featureLayerService.GetAll(null,{select: ['LayerID','Name']}),{initialValue:[]});


  controlGroups = computed(() => {
    const layers = this.propertyLayers();
    const {featureLayers, name} = this.selectedScenario();

    this.layerForm.get('name').setValue(name);
    const controls = [];

    layers.forEach(layer => {
      const control =  this.createLayerControl(layer, featureLayers.includes(layer.layerID));
      controls.push(control);
    })

    return controls;
  })

  controlsAdded = effect(() => {

    const controls = this.controlGroups();
    const selectedLayersControl = this.layerForm.get('selectedLayers') as FormArray;

    controls.forEach((control) => {
      selectedLayersControl.push(control)
    });

  });

  layerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    selectedLayers: new FormArray([])
  });

  constructor(private fb: FormBuilder, @Inject(DIALOG_DATA) public data: {selectedScenario: GetScenarioRequest}) {

    this.selectedScenario.set(data.selectedScenario);
  }


  private createLayerControl(layer:LayerDTO, isSelected:boolean = true) {


    const control = new FormGroup({
      layer: new FormControl(layer),
      isSelected: new FormControl(isSelected),
    });

    return control;
  }

  get selectedLayers(): FormArray {
    return this.layerForm.get('selectedLayers') as FormArray;
  }


  ngOnInit() {
  }

  onClose()
  {
    this.dialogRef.close(Ok.EMPTY);
  }

  onSubmit(): void {

    const selectedLayerData = this.selectedLayers.controls
    .filter(control => control.get('isSelected')?.value)
    .map(control => {
      const layer = control.get('layer')?.value as LayerDTO;

      return layer.layerID;
    });

    const request: UpdateScenarioRequest = {
      name: this.layerForm.get('name')?.value,
      featureLayers: selectedLayerData
    };

      this.dialogRef.close(Ok(request));
  }


}
