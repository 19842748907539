<app-dialog (close)="onClose()">
  <ng-template eskDialogHeader>
    <h3>Update Scenario</h3>
  </ng-template>
  <ng-template eskDialogContent>
    <form [formGroup]="layerForm" (ngSubmit)="onSubmit()">
      <p class="text-xs text-gray-600 mb-6 leading-relaxed break-words whitespace-normal text-justify max-w-[50ch]">
        Choose a name and pick a list of layers to save as a Scenario for this property.
      </p>
      <tui-input formControlName="name">
        Type Scenario Name
        <input tuiTextfieldLegacy type="email" />
      </tui-input>

      <div class="flex items-center justify-start py-2.5 font-bold border-b border-gray-300">
        <div class="flex-1 text-left">Layer</div>
      </div>


      <ng-container *ngFor="let group of controlGroups(); let i = index; trackBy: trackByFn">
        <div class="flex items-center justify-start py-2.5 border-b border-gray-200">
          <div class="flex-1 flex items-center gap-2">
            <input
              tuiCheckbox
              type="checkbox"
              [formControl]="group.get('isSelected')"
            />
            <label class="flex items-center gap-2">
              {{ group.get('layer')?.value.name }}
            </label>
          </div>
        </div>
      </ng-container>

      <div class="flex justify-end mt-5">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-4 rounded mr-2"
          (click)="onClose()"
        >
          Cancel
        </button>
        <button
        [disabled]="layerForm.invalid || layerForm.pristine"
          class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded disabled:bg-blue-300 disabled:cursor-not-allowed"
          (click)="onSubmit()"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-template>
  <ng-template eskDialogFooter></ng-template>
</app-dialog>
