import { createInjectable } from "ngxtension/create-injectable";
import { signalSlice2 } from "../../../../signal-slice";
import { effect, inject } from "@angular/core";
import { PropertiesState } from "../../../../data-access/state";
import { ScenarioService } from "./scenario.service";
import { map, Observable, of, startWith, switchMap, tap } from "rxjs";
import { CreateScenarioRequest, GetScenarioRequest, UpdateScenarioRequest } from "./models/models";
import { debug } from "ngxtension/debug";


const INITIAL_STATE: {scenarios:GetScenarioRequest[], selectedScenario: GetScenarioRequest | null} = {
  scenarios: [],
  selectedScenario: null,
};


export const ScenarioState = createInjectable(
  () => {

    const propertiesState = inject(PropertiesState);

    const scenariosAPI = inject(ScenarioService);



    const STATE = signalSlice2({
      initialState: INITIAL_STATE,
      sources:[
        propertiesState.activePropertyID$.pipe(tap(propertyID =>
        {
          if(propertyID == null)
            return;


          scenariosAPI.setResourceParams({propertyID:propertyID})
        }

        ),switchMap((propertyID) => {
          if(propertyID == null)
            return of({...INITIAL_STATE});

          return scenariosAPI.getAll().pipe(map(scenarios => ({scenarios})))
        })),
      ],
      actionSources: {
        select: (state, action$: Observable<GetScenarioRequest>) => action$.pipe(map((selectedScenario) => ({selectedScenario: selectedScenario}))),
        create: (state, action$: Observable<CreateScenarioRequest>) => action$.pipe(switchMap(request => scenariosAPI.create(request)),tap(scenario => {
          STATE.select(scenario);
        }),map((scenario) => ({scenarios: [...state().scenarios, scenario]}))),
        update: (state, action$: Observable<{scenarioID: string, model: UpdateScenarioRequest}>) => action$.pipe(tap(() => STATE.select(null)),switchMap(request => scenariosAPI.update(request.scenarioID, request.model)),

        map((scenario) => {

          const {scenarios} = state();
          const existingModelIndex = scenarios.findIndex((layer) => layer.scenarioID === scenario.scenarioID);

          const updatedModels = [   ...scenarios.slice(0, existingModelIndex),
            scenario,
            ...scenarios.slice(existingModelIndex + 1)]

          return {scenarios:updatedModels, selectedScenario:scenario  }

        })),
        delete: (state, action$: Observable<string>) => action$.pipe(tap(() => STATE.select(null)),
        
        switchMap(scenarioID => scenariosAPI.delete(scenarioID).pipe(map(()=> scenarioID))),

        map((scenarioID) => {

          const {scenarios} = state();
          const existingModelIndex = scenarios.findIndex((layer) => layer.scenarioID === scenarioID);

          const updatedModels = [   ...scenarios.slice(0, existingModelIndex),
            ...scenarios.slice(existingModelIndex + 1)]

          return {scenarios:updatedModels, selectedScenario:null  }

        }))
      }
    })
    return STATE;
  })
