import { Feature } from "ol";
import { CovertWKTFeature } from "./transformations";

export const createFeature = (
  wkt: string,
  properties: { [key: string]: any }
): Feature => {
  const feature = CovertWKTFeature(wkt);
  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      feature.set(key, properties[key]);
    }
  }
  return feature;
};
