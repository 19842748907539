import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { AccountService } from '../../account.service';
import { MaterialModule } from 'src/app/material/material.module';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, RouterModule]
})
export class ForgotPasswordComponent implements OnInit {

  private readonly accountService: AccountService = inject(AccountService);
  forgotPasswordForm: UntypedFormGroup;
  error: string = null;
  requestSent = false;
  version:string = environment.version;;

  constructor() {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [CustomValidators.required,CustomValidators.email]),
    });

    this.forgotPasswordForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });
  }

  onSubmit(): void {
    let email = this.forgotPasswordForm.get('email').value as string;
    this.accountService.forgotPassword(email.toLowerCase()).subscribe({
      error: error => this.error = error,
      next: value =>  {this.requestSent = true;}
    })
  }


}
