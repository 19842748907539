import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends BaseEndpointService {

  constructor() {
    super({ route: 'geoserver' });
  }

  uploadFile(file: File): Observable<{ progress: number, response?: any }> {
    const formData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.endpoint}/import`, formData, {
      reportProgress: true
    });

    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, file))
    );
  }

  private getEventMessage(event: HttpEvent<any>, file: File): { progress: number, response?: any } {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        if (event.total === null) {
          return { progress: 0 };
        }
        const progress = Math.round((100 * event.loaded) / event.total);
        return { progress }; // Return progress update
      case HttpEventType.Response:
        // Return 100% progress and the response from the server
        return { progress: 100, response: event.body }; // event.body will have your server response (e.g., jobId)
      default:
        return { progress: 0 };
    }
  }
}
