<div class="wrapper">
  <div class="title-bar">
    <div class="title">Edit Property</div>
    <div class="window-controls">
      <button (click)="cancel()"><span class="fa fa-times"></span></button>
    </div>
  </div>
  <div class="content">
    <form [formGroup]="form" class="edit-property-form smaller-fields">
      <div class="d-flex form-rows">
        <div class="d-flex top-row">
          <div class="details">
            <div class="form-group-title field-title">
              <h3>Details</h3>
            </div>
            <mat-form-field appearance="outline" color="white">
              <mat-label>Project Name</mat-label>
              <input formControlName="projectName" matInput placeholder="" />
              <mat-error *ngIf="form.get('projectName').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Property Name</mat-label>
              <input formControlName="propertyName" matInput placeholder="" />
              <mat-error *ngIf="form.get('propertyName').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Property Address</mat-label>
              <input
                formControlName="propertyAddress"
                matInput
                placeholder=""
              />
              <mat-error
                *ngIf="form.get('propertyAddress').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Owner/Operator</mat-label>
              <input
                formControlName="propertyOwnerName"
                matInput
                placeholder=""
              />
              <mat-error
                *ngIf="form.get('propertyOwnerName').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Source Name</mat-label>
              <input formControlName="sourceName" matInput placeholder="" />
              <mat-error *ngIf="form.get('sourceName').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Process Leader</mat-label>
              <mat-select formControlName="processLeaderID">
                @for (user of processLeaders(); track $index) {
                <mat-option [value]="user.id">{{
                 user.firstName
                }} {{user.lastName}}</mat-option>
                }
                <mat-option [value]="null">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="category">
            <div class="form-group-title field-title">
              <h3>Category</h3>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Primary Class</mat-label>
              <mat-select formControlName="primaryClassID">
                @for (class of classes(); track $index) {
                <mat-option [value]="class.propertyClassID">{{
                  class.class
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Secondary Class</mat-label>
              <mat-select formControlName="secondaryClassID">
                @for (class of classes(); track $index) {
                <mat-option [value]="class.propertyClassID">{{
                  class.class
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Stage</mat-label>
              <mat-select formControlName="stageID">
                @for (class of stages(); track $index) {
                <mat-option [value]="class.propertyStageID">{{
                  class.name
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="statusID">
                @for (class of statuses(); track $index) {
                <mat-option [value]="class.propertyStatusID">{{
                  class.status
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Suitability</mat-label>
              <mat-select formControlName="suitabilityID">
                @for (class of suitabilities(); track $index) {
                <mat-option [value]="class.propertySuitabilityID">{{
                  class.name
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="values">
            <div class="form-group-title field-title">
              <h3>Values</h3>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>Estimated Price ($)</mat-label>
              <input
                formControlName="price"
                matInput
                placeholder=""
                type="number"
                class="no-spinners"
              />
              <mat-error *ngIf="form.get('price').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Last Updated</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="updatedAt"
                readonly
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
                disabled
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>MGA Zone</mat-label>
              <input
                formControlName="zone"
                matInput
                placeholder=""
                type="number"
                class="no-spinners"
              />
              <mat-error *ngIf="form.get('zone').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Calculated Area (Ha)</mat-label>
              <input
                readonly
                disabled
                formControlName="areaHaCalculated"
                matInput
                placeholder=""
              />
              <mat-error
                *ngIf="form.get('areaHaCalculated').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Displayed Area (Ha)</mat-label>
              <input
                formControlName="areaHaDisplay"
                matInput
                placeholder=""
              />
              <mat-error *ngIf="form.get('areaHaDisplay').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Water Allocation (ML)</mat-label>
              <input
                formControlName="waterAllocation"
                matInput
                placeholder=""
                type="number"
                class="no-spinners"
              />
              <mat-error
                *ngIf="form.get('waterAllocation').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Last Emissions Assessment</mat-label>
              <input
                type="date"
                formControlName="emissionAssessmentYear"
                matInput
                placeholder=""
              />
              <mat-error
                *ngIf="form.get('emissionAssessmentYear').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Total Emissions</mat-label>
              <input
                formControlName="totalEmissions"
                matInput
                placeholder=""
                pattern="^[0-9]*$"
              />
              <mat-error *ngIf="form.get('totalEmissions').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Environmental Certification</mat-label>
              <input
                formControlName="environmentalCertification"
                matInput
                placeholder=""
              />
              <mat-error
                *ngIf="form.get('environmentalCertification').errors?.['required']"
                >This field is required!</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="d-grid bottom-row">
          <div class="notes">
            <div class="form-group-title">
              <h3>Notes</h3>
            </div>
            <div class="notes-wrapper">
              <p-editor formControlName="notes"></p-editor>
            </div>
          </div>
          <div class="files">
            <div class="form-group-title">
              <h3>Files</h3>
            </div>
            <div class="files-wrapper">
              <div class="upload-section bg-blue">
                <p-fileUpload
                  #uploader
                  [url]="uploadUrl"
                  (onUpload)="onUpload($event)"
                  chooseLabel="Browse"
                  mode="basic"
                >
                </p-fileUpload>
              </div>
              <div class="files-section">
                <app-file-list
                  (deleteFile)="OnFileDelete($event)"
                  [files]="files"
                ></app-file-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <button type="button" class="button button--muted" (click)="cancel()">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      (click)="submit()"
      [disabled]="!isDirty()"
    >
      <span class="fa fa-save button-icon"></span>Submit
    </button>
  </div>
</div>
