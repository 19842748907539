import { inject } from "@angular/core";
import { map } from "lodash";
import { createInjectable } from "ngxtension/create-injectable";
import { Map as OlMap, View } from "ol";
import { Attribution, ScaleLine } from "ol/control";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Style, Stroke, Fill } from "ol/style";
import { from } from "rxjs";
import { BasemapsService } from "src/app/map/data-access/Basemaps.service";
import { PropertyLayers } from "src/app/map/features/property/data-access/spatial";

const boundaryStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255,0,0,1)',
    width: 1.5,
  }),
  fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
});


export const Spatial = createInjectable(
  () => {

    const baseMapsService = inject(BasemapsService);

    const propertyLayers = inject(PropertyLayers);


    const loadBaseMap = (selectedBaseMapDefinition) => {
      return from(baseMapsService.buildLayers([
        selectedBaseMapDefinition,
      ]));
    };

    const boundariesLayer = propertyLayers.boundariesLayer as VectorLayer<any>;
    const areaLayer = propertyLayers.areaLayer as VectorLayer<any>;
    boundariesLayer.setStyle(boundaryStyle);;
    areaLayer.setZIndex(99);




    const olMap = new OlMap({
      view: new View({
        center: transform([146.6, -30.83], 'EPSG:4326', 'EPSG:3857'),
        zoom: 5,
        maxZoom: 23,
      }),
      layers: [propertyLayers.areaLayer, propertyLayers.boundariesLayer],
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ],
    });


    return {map: olMap, loadBaseMap,areaSource: propertyLayers.areaLayer.getSource(),  boundariesSource: boundariesLayer.getSource() };
  });
