import {
  Component,
  OnInit,
  computed,
  effect,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ColorPickerModule } from 'ngx-color-picker';
import { LineStyleFormComponent } from './ui/line-style-form/line-style-form.component';
import { v4 as uuidv4 } from 'uuid';
import { PolygonStyleFormComponent } from './ui/polygon-style-form/polygon-style-form.component';
import { PointStyleFormComponent } from './ui/point-style-form/point-style-form.component';
import { TextStyleFormComponent } from './ui/text-style-form/text-style-form.component';
import { Symbolizer } from 'geostyler-style';
import { DialogRef } from '@angular/cdk/dialog';
import { Kind } from '../style-renderer.component';
import { LegendIconComponent } from '../legend-icon/legend-icon.component';
@Component({
  selector: 'app-style-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ColorPickerModule,
    LineStyleFormComponent,
    PolygonStyleFormComponent,
    PointStyleFormComponent,
    TextStyleFormComponent,
    LegendIconComponent
  ],
  templateUrl: './style-form.component.html',
  styleUrls: ['./style-form.component.css'],
})
export class StyleFormComponent implements OnInit {
  ref = inject(DialogRef<StyleFormComponent>);
  layerGeomKind = model<Kind>("Polygon");

  options = computed(() => {
    const geomKind = this.layerGeomKind();

    if(geomKind === "Circle" || geomKind == "Polygon" || geomKind == "MultiPolygon")
    {
      return ["Fill", "Text"];
    }
    else if (geomKind === "LineString")
    {
      return ["Line", "Text"];
    }
    else
    {
      return ["Mark","Text"]
    }
  })

  symbolizers = model<Symbolizer[]>([
    {
      kind: 'Fill',
      color: '#0E1058',
      visibility: true,
    },
  ]);

  symbolizerEffect = effect(() => {
    const symbolizers = this.symbolizers();

    symbolizers.forEach((value) => this.styleMap.set(uuidv4(), value));
  });

  symbolizersChanged = output<any>();

  addNewStyle() {
    this.styleMap.set(uuidv4(), {
      kind: 'Line',
      color: '#0E1058',
      visibility: true,
    });
  }
  styles: any[] = [{}];

  styleMap = new Map<string, any>();

  ngOnInit(): void {}

  styleChanged(key: string, event: any) {
    this.styleMap.set(key, event);
    this.symbolizersChanged.emit(Array.from(this.styleMap.values()));
  }

  cancel() {
    this.ref.close();
  }
}
