<app-dialog (close)="onClose()">
  <ng-template eskDialogHeader><h3>Create View</h3></ng-template>
  <ng-template eskDialogContent>

    <form [formGroup]="layerForm" (ngSubmit)="onSubmit()">

      <p class="subtitle-text">Choose a name and pick a list of data catalogue layers to save as a view for this property.</p>
    <tui-input formControlName="name">
      Type View Name
      <input
          tuiTextfieldLegacy
          type="email"
      />
  </tui-input>
  <div class="header">
    <div class="header-item">Catalogue Layer</div>
    <div class="header-item">Visibility</div>
  </div>

    @for (group of controlGroups(); track $index) {
      <div class="layer-row">
        <div class="layer-item">
          <input
          tuiCheckbox
          type="checkbox"
          [formControl]="group.get('isSelected')">

          <label class="checkbox-label">
            {{ group.get('layer')?.value.name }}
          </label>
          </div>
      <div class="layer-item">
        <input
          tuiSwitch
          type="checkbox"
          [formControl]="group.get('isVisible')">
        </div>
      </div>
    }

  </form></ng-template>
  <ng-template eskDialogFooter>  <button class="button button--muted" (click)="onClose()">Cancel</button>
    <button class="button button--blue" [disabled]="layerForm.invalid" (click)="onSubmit()">Confirm</button></ng-template>
</app-dialog>
