import { InjectionToken, ModelSignal } from "@angular/core";

export interface EskTool {
active: ModelSignal<boolean>
}


export const ESKTOOL_TOKEN = new InjectionToken<EskTool>('EskTool');


import { Provider, forwardRef } from '@angular/core';

export function provideEskTool(component: any): Provider {
  return {
    provide: ESKTOOL_TOKEN,
    useExisting: forwardRef(() => component),
  };
}
