import { inject, Injectable, signal } from '@angular/core';
import { Feature } from 'ol';

import { Modify } from 'ol/interaction';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import {
  BehaviorSubject,
  finalize,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import {
  ConvertFeatureToWKT,
  CovertWKTFeature,
} from 'src/app/shared/helpers/transformations';
import {
  UserFeatureDTO,
  LayerDTO,
  FeatureLayersService,
} from './feature-layers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextMenuAction } from 'src/app/shared/features/context-menu/context-menu.component';

const editStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 215, 0, 0.3)',
  }),
  stroke: new Stroke({
    color: '#ffd700',
    width: 3,
  }),
  image: new Circle({
    radius: 8,
    fill: new Fill({
      color: '#ffd700',
    }),
    stroke: new Stroke({
      color: '#ffffff',
      width: 2,
    }),
  }),
});
@Injectable({
  providedIn: 'root',
})
export class SpatialService {
  public route: ActivatedRoute = inject(ActivatedRoute);
  public router: Router = inject(Router);
  public featureLayersService = inject(FeatureLayersService);

  public readonly editTargetFeatures = new BehaviorSubject<Feature[]>([]);

  public projectLayersGroup = new LayerGroup({
    properties: { title: 'Property Layers' },
    layers: [],
  });

  public readonly modifySource = new VectorSource();
  public readonly modifyLayer = new VectorLayer({
    source: this.modifySource,
    style: editStyle,
    visible: true,
    zIndex: Infinity
  });

  addModifyFeature$ = fromEvent(this.modifySource, 'addfeature').pipe(
    map((event: VectorSourceEvent) => event.feature),
    mergeMap((feature) => {
      const geometry = feature.getGeometry();
      const { layerID, featureID } = feature.getProperties();
      const modifyGeometryInitial$ = fromEvent(geometry, 'change');

      const geometryChanged$ = fromEvent(feature, 'change:geometry').pipe(
        switchMap(() =>
          fromEvent(feature.getGeometry(), 'change').pipe(startWith(() => of()))
        )
      );

      return merge(modifyGeometryInitial$, geometryChanged$).pipe(
        map(() => ({ feature, layerID, featureID, type: 'add' }))
      );
    })
  );

  removeModifyFeature$ = fromEvent(this.modifySource, 'removefeature').pipe(
    map((event: VectorSourceEvent) => ({
      feature: event.feature,
      layerID: event.feature.get('layerID'),
      featureID: event.feature.get('featureID'),
      type: 'remove',
    }))
  );

  constructor() {}

  editFeature(userFeature: UserFeatureDTO) {
    const { olFeature } = userFeature;

    const modFeature = olFeature.clone();
    modFeature.setStyle(editStyle);
    this.modifySource.addFeature(modFeature);
  }

  addModifyLayer() {
    this.projectLayersGroup.getLayers().extend([this.modifyLayer]);
  }

  removeModifyLayer() {
    this.projectLayersGroup.getLayers().remove(this.modifyLayer);
  }

  editLayer(layer: LayerDTO) {
    const features = layer.mapLayer.getSource().getFeatures();
    const clonedFeatures = features.map((feature) => {
      const modFeature = feature.clone();
      modFeature.setStyle(editStyle);

      return modFeature;
    });
    this.modifySource.addFeatures(clonedFeatures);
  }
}
