import { Injectable, Signal, computed, signal } from "@angular/core";
import { FilterMetadata } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class PropertiesTableService
{
  containerHeight = signal(350);
  scrollPosition = signal(0);
  selectedRow = signal(null);
  filterMetaData: any;
  sortMetaData: any;


  numberOfRows:Signal<number>;
  indexOfSelectedItem:Signal<number>;


  constructor()
  {
    this.numberOfRows = computed(() => Math.floor(this.containerHeight() / 23))



  }
}
