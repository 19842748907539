import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TuiTabs } from '@taiga-ui/kit';

@Component({
  selector: 'hosted-data',
  templateUrl: './hosted-data.component.html',
  styleUrls: ['./hosted-data.component.css'],
  standalone:true,
  imports: [CommonModule, TuiTabs]
})
export class HostedDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
