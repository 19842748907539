<div class="popover">
    <p>Password must:</p>
    <ul class="status-list">
      <li [class.valid]="hasNumber">Have one number</li>
      <li [class.valid]="hasUpperCase">Have one uppercase character</li>
      <li [class.valid]="hasLowerCase">Have one lowercase character</li>
      <li [class.valid]="hasSpecialCharacter">Have one special character</li>
      <li [class.valid]="hasMinLength">Have 8 characters minimum</li>
    </ul>
  </div>
  