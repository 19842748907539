<div class="title-bar">
  <div class="title">Import</div>
  <div class="window-controls">
    <button (click)="cancel()"><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content gap--md">
  <input class="file-input" type="file" (change)="onFileSelected($event)" />
  <button class="upload-button" (click)="onUpload()">Upload</button>
  @if(progress > 0 && progress < 100)
  {
    <progress [value]="progress" max="100" class="progress-bar"></progress>
  }



@if(processingMessage())
{
  <div class="processing-message">{{ processingMessage() }}</div>
}


<console></console>

</div>

<div class="footer"></div>
