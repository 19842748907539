<div class="header">
  <div class="header-navigation">
    <ul>
      @for (pageDefinition of pages; track $index) {
      <li
        appTooltip
        [appTooltip]="pageDefinition.title"
        position="left"
        class="nav-item"
        [ngClass]="{ 'sidebar-page_active': pageDefinition.active }"
        [ngStyle]="
          (pages.length > 1 && $last) || pageDefinition.position == 'end'
            ? { 'margin-left': 'auto' }
            : {}
        "
      >
        <button
          (click)="_openPage.emit(pageDefinition)"
          [disabled]="pageDefinition.disabled"
          [ngStyle]="{ width: '100%', height: '100%' }"
        >
          <span><i [class]="pageDefinition.className"></i></span>
        </button>
      </li>
      }
    </ul>
  </div>


  <div class="flex p-1 min-h-[50px]">
    <div class="self-center justify-self-start flex-grow-1 space-x-4">
      @if(headerService.headerDetails()?.backButton) {
      <button
        appTooltip="Back"
        position="left"
        class="text-lg text-black p-0 m-0 hover:text-gray-800 focus:outline-none"
        [routerLink]="backRoute()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      }

      <action-portal></action-portal>
    </div>
    <button
      appTooltip="Collapse Sidebar"
      position="left"
      class="text-lg text-black p-0 m-0 hover:text-gray-800 focus:outline-none"
      style="place-self: center end"
      (click)="close()"
    >
      <i class="fa fa-angle-double-right"></i>
    </button>
  </div>

</div>
