import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, inject, signal } from '@angular/core';

import {
  FileUploadEvent,
  FileUploadModule,
  UploadEvent,
} from 'primeng/fileupload';
import { SignalRService } from 'src/app/@core/services/signalR.service';
import { environment } from 'src/environments/environment';
import { UploadService } from './upload.service';
import { TuiAlertService } from '@taiga-ui/core';
import { concatMap, delay, fromEvent, of } from 'rxjs';
import { ConsoleComponent } from 'src/app/shared/components/console/console.component';
import { ConsoleService } from 'src/app/shared/components/console/console.service';

@Component({
  selector: 'app-UploadShapeFile',
  templateUrl: './UploadShapeFile.component.html',
  styleUrls: ['./UploadShapeFile.component.css'],
  standalone: true,
  imports: [CommonModule, FileUploadModule,ConsoleComponent],
  providers:[SignalRService, ConsoleService]
})
export class UploadShapeFileComponent implements OnInit {
  private readonly dialogRef: DialogRef<UploadShapeFileComponent> = inject(
    DialogRef<UploadShapeFileComponent>
  );

  private readonly signalRService:SignalRService = inject(SignalRService);

  private readonly uploadService:UploadService = inject(UploadService);

  private readonly alerts: TuiAlertService = inject(TuiAlertService);

  private readonly consoleService: ConsoleService = inject(ConsoleService);

  layerName: string = '';

  styleUrl;

  uploaded = signal(false);
  uploading = signal(false);
  styleUploaded = signal(false);
  private uploadedFiles: any[] = [];
  selectedFile: File | null = null;
  progress = 0;
  message = '';
  processingMessage = signal(null)


  constructor() {}

  ngOnInit() {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  onUpload(): void {
    if (this.selectedFile) {
      this.uploadService.uploadFile(this.selectedFile).subscribe({
        next: (event) => {
          this.progress = event.progress;

          if (event.response) {
            this.signalRService.startConnection().subscribe(() => {
              this.signalRService.joinRoom(event.response).subscribe(() => {
                fromEvent(this.signalRService.hubConnection, 'ReceiveMessage')
                  .pipe(
                    concatMap((message: string) =>
                      of(message).pipe(delay(500))  // Process each message with a 500ms delay between them
                    )
                  )
                  .subscribe((message: string) => {
                    this.processingMessage.set(message);

                    this.consoleService.addMessage(message);

                  });
              });
            });
          }

          if (this.progress === 100) {
            this.message = 'File uploaded successfully!';
          }
        },
        error: (err) => {
          console.error('Error uploading file:', err);
          this.message = 'Error uploading file.';
        },
        complete: () => {
          console.log('Upload complete');
        }
      });

    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
