import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-test-environment-confirmation',
  templateUrl: './test-environment-confirmation.component.html',
  styleUrls: ['./test-environment-confirmation.component.css'],

})
export class TestEnvironmentConfirmationComponent {
  savePreference: boolean = false;
  @Output() savePreferenceChange = new EventEmitter<boolean>();

  constructor(
    public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) protected data
  ) {
    this.dialogRef.disableClose = true;
  }

  onYesClick(): void {
    // this.dialogRef.close(this.savePreference);
    this.savePreferenceChange.emit(this.savePreference);
  }
}
