import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { DraggableDirective } from './directives/draggable/draggable.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './components/dialogs/error/error.component';
import { ConfirmationComponent } from './components/dialogs/confirmation/confirmation.component';
import { DeleteConfirmationComponent } from './components/dialogs/delete-confirmation/delete-confirmation.component';
import { MaterialModule } from '../material/material.module';
import { TestEnvironmentConfirmationComponent } from './components/dialogs/test-environment-confirmation/test-environment-confirmation.component';

@NgModule({
  declarations: [NotFoundComponent, ErrorComponent, TestEnvironmentConfirmationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  exports:[
    NotFoundComponent,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class SharedModule { }
