  <button appTooltip="Edit Feature"  [ngClass]="{'esk-tool--active' : active()===true}" class="esk-tool" (click)="toggleTool()" ><i class="fa fa-draw-polygon"></i></button>
  <ng-content></ng-content>
  @if (active()) {

    <button appTooltip="Intersect with Property Boundary" [ngClass]="{'esk-tool--active' : intersect()===true}" (click)="intersect.set(!intersect()); sharedMethod()" class="esk-tool"><i class="fa fa-object-group"></i></button>

    <span class="flex flex-row align-center " >
      <button appTooltip="Difference with Layers" [ngClass]="{'esk-tool--active': difference() === true}"  (click)="difference.set(!difference())" class="esk-tool">
        <i class="fa fa-cut"></i>
      </button>
      <button appTooltip="Intersect Targets" [matMenuTriggerFor]="menu" class="menu-button">
        <i class="fa fa-chevron-down chevron-icon"></i>
      </button>
    </span>
  <mat-menu class="mat-menu" #menu="matMenu">
    <div class="flex flex-column">
      @for (item of _differenceTargetLayers(); track $index) {
        <mat-checkbox [formControl]="differenceTargets.get(item.name)" (click)="$event.stopPropagation()">{{item.name}}</mat-checkbox>
      }

    </div>
  </mat-menu>
  }


