import { Directive, Injector, OnDestroy, Renderer2, effect, inject } from '@angular/core';
import { ESKTOOL_TOKEN, EskTool } from 'src/app/map/util/esk-tool-interface';
import { EskToolService } from '../features/esk-tool/EskTool.service';
import { v4 as uuidv4 } from 'uuid';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MapService } from 'src/app/map/data-access/map.service';

@Directive({
  selector: '[EskTool]',
  standalone:true
})
export class EskToolDirective implements OnDestroy {

  private readonly eskToolService: EskToolService = inject(EskToolService);
  private readonly mapService: MapService = inject(MapService);
  private readonly renderer:Renderer2 = inject(Renderer2);
  private component!: EskTool;
  private unlisteners:Array<()=>void> = [];

  private readonly id = uuidv4();

  constructor(private injector: Injector) {
    this.initComponent();

    toObservable(this.component?.active).pipe(takeUntilDestroyed()).subscribe((active) => {
      if(active == null || !active)
        {
          return;
        }

        if(active)
        {
          this.unlisteners.push(this.renderer.listen('document', 'keydown', this.handleEscKeyPressed.bind(this)));
          this.mapService.removeMapEvents();
        }
        else
        {
          this.unlisteners.forEach(unlistener => {
            unlistener();
          })
          this.mapService.setupMapEvents();
        }



      this.eskToolService.toolActive(this.id);
    });
  }
  ngOnDestroy(): void {
      this.eskToolService.removeTool(this.id);
  }

  private handleEscKeyPressed(event:KeyboardEvent)
  {


    if(event.key == 'Escape')
      {
        this.component.active.set(false);
       event.stopPropagation();
        event.preventDefault();
      }

  }

  private initComponent() {
    try {

      this.component = this.injector.get<EskTool>(ESKTOOL_TOKEN);

      this.eskToolService.registerTool(this.id,this.component);
    } catch (error) {
      console.error('Host component does not implement EskTool interface', error);
    }
  }

  // Example method to call a method on the host component
  toggleHighlight() {
    if (this.component) {
      this.component.active.set(false);
    }
  }

}
