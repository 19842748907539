<ng-template actionPortal>
  <div class="grid grid-cols-3 px-2 items-center flex-grow-1">
  <h3 class="justify-self-center text-base font-semibold text-gray-800  col-start-2" >Profile</h3>
  </div>
</ng-template>

@if (user() == null) {
<div>
  <ngx-skeleton-loader class="profile-section" count="1"></ngx-skeleton-loader>
  <ngx-skeleton-loader class="profile-image" count="1"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
</div>
}
@else {
    <div class="profile-section">
        <div class="profile-image">
            <img (load)="imageLoaded = true" [hidden]="!imageLoaded" [alt]="user.firstName | initials:user.lastName">
            <div class="image-placeholder" [hidden]="imageLoaded">{{user().firstName | initials:user().lastName}}</div>
          </div>
        <h2>{{ user().firstName }} {{ user().lastName }}</h2>
        <h3 *ngxPermissionsOnly="['Administrator']">(Administrator)</h3>
        <p>{{ user().email }}</p>
      </div>
}
<mat-divider></mat-divider>
  <div class="flex flex-col items-center justify-center h-full space-y-4 px-4 w-full max-w-sm mx-auto">
    <a
      *ngxPermissionsOnly="['Administrator']"
      [routerLink]="['/admin']"
      class="block w-full text-center py-3 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium"
    >
      Admin Dashboard
    </a>
    <a
      (click)="resetPassword()"
      class="block w-full text-center py-3 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium cursor-pointer"
    >
      Change Password
    </a>
    <button
      *ngxPermissionsOnly="['Administrator']"
      (click)="authService.state.refresh()"
      class="block w-full text-center py-3 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium"
    >
      <i class="fa fa-sync-alt mr-2"></i>Refresh Token
    </button>
    <button
    *ngxPermissionsOnly="['Administrator']"
    (click)="disconnect()"
    class="block w-full text-center py-3 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium"
  >
    <i class="fa fa-sync-alt mr-2"></i>Disconnect Signal R
  </button>
  <button
  *ngxPermissionsOnly="['Administrator']"
  (click)="connect()"
  class="block w-full text-center py-3 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium"
>
  <i class="fa fa-sync-alt mr-2"></i>Connect Signal R
</button>

  </div>

  <!-- Logout Button at Bottom Right -->
  <button
    (click)="authService.state.logout()"
    class="fixed bottom-4 right-4 py-3 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium flex items-center"
  >
    <i class="fa fa-sign-out-alt mr-2"></i>Logout
  </button>


