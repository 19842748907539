import { OverlayContainer } from '@angular/cdk/overlay';

export class CustomOverlayContainer extends OverlayContainer {
  override _createContainer(): void {
    let container = document.querySelector('.custom-overlay-container'); // Adjust this selector to your desired container
    if (!container) {
      container = document.body; // Fallback to body if the container is not found
    }

    const overlayContainer = this._document.createElement('div');
    overlayContainer.classList.add('cdk-overlay-container');
    container.appendChild(overlayContainer);
    this._containerElement = overlayContainer;
  }
}