import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';

import { MapService } from '../data-access/map.service';
import { PropertiesService } from '../features/property/data-access/properties.service';
import { DigitizePropertyOverlayComponent } from '../features/property/features/digitize-property-overlay/digitize-property-overlay.component';
import { BottomSheetComponent } from '../features/property/ui/bottom-sheet/bottom-sheet.component';
import { SidebarComponent } from '../features/sidebar/sidebar.component';
import { GeocodeComponent } from '../ui/geocode/geocode.component';
import { ToolbarContainerComponent } from '../ui/toolbar-container/toolbar-container.component';
import { MeasureComponent } from '../ui/measure/measure.component';
import { MapInformationComponent } from '../ui/map-information/map-information.component';
import { ProjectionService } from '../data-access/projection.service';
import { AuthService } from 'src/app/@core/Auth/auth.service';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { AppSettingsService } from '../data-access/app-settings.service';
import { filter, from, mergeMap, switchMap, tap } from 'rxjs';
import { MapChildRoutes } from '../util/map-pages.config';
import { SidebarService } from '../features/sidebar/data-access/sidebar.service';
import { HeaderService } from '../features/sidebar/features/header/data-access/header.service';
import { RouterModule } from '@angular/router';
import { BasemapsSelectComponent } from 'src/app/shared/components/basemaps-select/basemaps-select.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { PivotMeasureComponent } from '../ui/pivot-measure/pivot-measure.component';
import { ZoomToExtent } from 'ol/control';
import { ZoomAreaComponent } from '../ui/zoom-area/zoom-area.component';
import { RuleTableComponent } from 'src/app/shared/components/style-renderer/rule-table/rule-table.component';
import { DataCatalogueService } from '../features/data-catalogue/data-access/services/data-catalogue.service';
import { EskToolDirective } from 'src/app/shared/directives/EskTool.directive';
import { CadastreControlComponent } from '../features/layers/ui/cadastre-control/cadastre-control.component';
import { toSignal } from '@angular/core/rxjs-interop';
import {TuiAccordion} from '@taiga-ui/kit';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';
import { LayerControlPreviewDirective } from 'src/app/shared/features/layer-control/layer-control-preview.directive';
import { LayerControlActionsDirective } from 'src/app/shared/features/layer-control/layer-control-actions.directive';
import { StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { LayersService } from '../features/layers/data-access/services/layers.service';
import { LayerLegendComponent } from 'src/app/shared/features/layer-legend/layer-legend.component';
import { ListAnimation } from 'src/app/shared/animations/animations';
import { LayerControlHeaderActionsDirective } from 'src/app/shared/features/layer-control/layer-control-header-actions.directive';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { TuiScrollbar } from '@taiga-ui/core';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';
import { ContextMenuAction, ContextMenuComponent } from 'src/app/shared/features/context-menu/context-menu.component';
import { Coordinate } from 'ol/coordinate';
import { Layer } from 'ol/layer';
import { EskToolService } from 'src/app/shared/features/esk-tool/EskTool.service';
import { FeatureLayerState } from '../features/property/features/feature-layers/data-access/state';
import { SignalRService } from 'src/app/@core/services/signalR.service';
import { PropertiesState } from '../features/property/data-access/state';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DigitizePropertyOverlayComponent,
    BottomSheetComponent,
    SidebarComponent,
    GeocodeComponent,
    ToolbarContainerComponent,
    MeasureComponent,
    MapInformationComponent,
    RouterModule,
    BasemapsSelectComponent,
    TooltipDirective,
    PivotMeasureComponent,
    ZoomAreaComponent,
    EskToolDirective,
    CadastreControlComponent,
    TuiAccordion,
    LayerControlComponent,
    LayerControlPreviewDirective,
    LayerControlActionsDirective,
    LayerControlHeaderActionsDirective,
    StyleRendererComponent,
  LayerLegendComponent,
  TuiScrollbar,
  OverflowPaddingDirective,
  ContextMenuComponent,
  ToolbarComponent
  ],
  providers: [SidebarService, HeaderService, LayerControlServiceService, SignalRService],
  animations:[ListAnimation, trigger('slideToggle', [
    state('open', style({
      height: '*',
      opacity: 1,
    })),
    state('closed', style({
      height: '0px',
      opacity: 0,
      overflow: 'hidden'
    })),
    transition('open <=> closed', [
      animate('300ms ease-in-out')
    ])
  ])]
})
export class MapContainerComponent implements OnInit, OnDestroy {
  public readonly mapService: MapService = inject(MapService);
  public readonly layersService: LayersService = inject(LayersService);
  public readonly authService: AuthService = inject(AuthService);
  public readonly projectionService: ProjectionService =
    inject(ProjectionService);
  public readonly propertiesService: PropertiesService =
    inject(PropertiesService);
    public readonly featureLayerState = inject(FeatureLayerState);
  public readonly modalService: ModalService = inject(ModalService);
  public readonly appSettingsService: AppSettingsService =
    inject(AppSettingsService);

   state = inject(PropertiesState);

  public sidebarService = inject(SidebarService);

  private readonly dataCatalogueService: DataCatalogueService = inject(DataCatalogueService);
  private readonly eskToolService: EskToolService = inject(EskToolService);

  public readonly routeConfig = MapChildRoutes;


  @ViewChild('map', { static: true }) map!: ElementRef;

  contextMenu = viewChild.required<ContextMenuComponent>(ContextMenuComponent);

  popoutLayers = toSignal(this.layersService.poppedOutLayers);

  showLayers = signal(true);

  toolActive = toSignal(this.eskToolService.toolActiveChanges(), {initialValue: false});


  toolEffect =  effect(() => {
    const toolActive = this.toolActive();

    if(toolActive == true)
    {
      this.mapService.removeMapEvents();
    }
    else
    {
      this.mapService.setupMapEvents();
    }
  })


  updateShowLayers() {
    this.showLayers.set(this.showLayers() ? false : true);
  }

  clickCoordinates: WritableSignal<Coordinate> = signal([0,0]);
  showContextMenu = signal(false);

  onRightClick(event: MouseEvent): void {

    // if(this.toolActive())
    // {
    //   return;
    // }

    event.preventDefault();

    let foundTopLayer = false;

    this.mapService.map.forEachFeatureAtPixel(
      this.mapService.map.getEventPixel(event),
      (feature,layer: Layer<any, any>) => {
        if (!foundTopLayer) {
          const actions: ContextMenuAction[] = layer.get('contextActions') || [];
          if (actions.length > 0) {
            this.contextMenu().open([event.pageX, event.pageY],actions, {data: feature});
            foundTopLayer = true;
          }
        }
      }
    );


  }

  // Hide context menu on map click
  onMapClick(): void {
    this.showContextMenu.set(false);
  }


  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe();
    this.mapService.setTarget(this.map.nativeElement);
    this.mapService.updateSize();






    this.appSettingsService
      .getTestTermsAccepted()
      .pipe(
        filter((accepted) => !accepted),
        switchMap(() =>
          this.modalService.showTestMessage().pipe(
            tap((savePreference) => {
              this.appSettingsService.setTestTermsAccepted(savePreference);
            })
          )
        )
      )
      .subscribe();

    this.mapService.unhideLayers();
    this.dataCatalogueService.loadGroups().pipe(
      switchMap(() => this.dataCatalogueService.getDefaultLayers()),
      switchMap((layers: number[]) => from(layers)),
      mergeMap((layerId: number) =>
        this.dataCatalogueService.loadLayerByID(layerId).pipe(
          tap(layerData => {
            this.dataCatalogueService.addLayerToMap(layerData,{visible: layerData.visibleByDefault});
          })
        )
      )
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.mapService.removeMapEvents();

    this.mapService.hideLayersForPrint();
  }
}
