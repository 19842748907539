import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, catchError, filter, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  CatalogueCategory,
  CatalogueCategoryQuery,
  CatalogueCategoryWithState,
  CatalogueGroup,
  CatalogueGroupWithState,
  CatalogueLayer,
  CatalogueLayerWithState,
  DataCatalogueLayerQuery,
  DataCatalogueSearchResult,
} from '../models/catalogue-data.model';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';
import { CreateCatalogueGroupDialogComponent } from '../../features/add-update/features/catalogue-group/create-catalogue-group-dialog/create-catalogue-group-dialog.component';
import { CustomDialogContainer } from '../../../../../@core/components/custom-dialog-container.component';
import { CreateEvent } from '../../../../../@core/events/createEvent';
import {
  CreateCatalogueCategory,
  CreateCatalogueGroup,
} from '../../features/add-update/data-access/models/create-update-catelogue.model';
import { FormGroup } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { CreateCatalogueCategoryDialogComponent } from '../../features/add-update/features/catalogue-category/create-catalogue-category-dialog/create-catalogue-category-dialog.component';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorImageLayer from 'ol/layer/VectorImage';
import { TileArcGISRest, TileWMS, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Tile } from 'ol/layer';
import { BasemapsService } from '../../../../data-access/Basemaps.service';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import {
  ArcGISMapLayers,
  WFSCapability,
  WMSCapability,
} from '../models/getcapabilities.model';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import CircleStyle from 'ol/style/Circle';
import { Type } from 'ol/geom/Geometry';
import { WFS } from 'ol/format';
import { defaultLayers } from 'src/app/map/util/basemaps';
import { startWithTap } from '../../features/catalogue-dialog/catalogue-dialog.component';
import { HttpParams } from '@angular/common/http';

/*
 I have added BaseEndpointService to your service to match the other data access services I have.
 at the moment it only has 2 fields

   protected endpoint: string;
  protected readonly http:HttpClient = inject(HttpClient);

  the endpoint is set in the constructor and appended with the route you specify in super({route: 'data-catalogue})

  so in this case it will end up being https://localhost:7008/api/v1/data-catalogue
*/
@Injectable({
  providedIn: 'root',
})
export class DataCatalogueService extends BaseEndpointService {

  public removeLayer = new BehaviorSubject(null);

  public addLayer = new BehaviorSubject(null);

  private readonly baseMapsService: BasemapsService = inject(BasemapsService);


  groups$ = new BehaviorSubject<CatalogueGroupWithState[]>([]);

  public addedLayers$ = new BehaviorSubject<CatalogueLayerWithState[]>([]);



  private readonly WMS_CAPABILITIES_ENDPOINT: string =
    `${this.endpoint}/wms-capabilities?url=` as const;

  private readonly WFS_CAPABILITIES_ENDPOINT: string =
    `${this.endpoint}/wfs-capabilities?url=` as const;

  private readonly ARC_GIS_REST_MAP_LAYERS_ENDPOINT: string =
    `${this.endpoint}/arc-gis-rest-map-layers?url=` as const;

  private readonly ARC_GIS_REST_FEATURE_LAYERS_ENDPOINT: string =
    `${this.endpoint}/arc-gis-rest-feature-layers?url=` as const;

  private COLOUR_LIST: string[] = [
    '#FFD1DC',
    '#FFCF99',
    '#FFFF99',
    '#B0E57C',
    '#99C5C4',
    '#C5A3FF',
    '#FFB3BA',
    '#FFDFBA',
    '#FFFFBA',
    '#BAFFC9',
    '#BAE1FF',
    '#D1BBFF',
    '#FFB347',
    '#77DD77',
    '#AEC6CF',
  ];

  private OPAQUE_COLOUR_LIST: string[] = [
    '#FFD1DC80',
    '#FFCF9980',
    '#FFFF9980',
    '#B0E57C80',
    '#99C5C480',
    '#C5A3FF80',
    '#FFB3BA80',
    '#FFDFBA80',
    '#FFFFBA80',
    '#BAFFC980',
    '#BAE1FF80',
    '#D1BBFF80',
    '#FFB34780',
    '#77DD7780',
    '#AEC6CF80',
  ];

  private COLOUR_LIST_TEMP: string[] = [...this.COLOUR_LIST];
  private OPAQUE_COLOUR_LIST_TEMP: string[] = [...this.OPAQUE_COLOUR_LIST];

  constructor() {
    super({ route: 'data-catalogue' });

  }

  loadGroups() {
   return this.http.get<CatalogueGroup[]>(`${this.endpoint}/groups`)
      .pipe(tap(data => {





        const groupsWithLoading: CatalogueGroupWithState[] = data.map(group => {

          const catalogueGroup: CatalogueGroupWithState = {
            ...group,
            loading: signal(false),
            categories: [],
            added: signal(false),
            layer: new LayerGroup({
              properties: {
                title: group.name,
              },

            }),
            hasRemoveFn: signal(false),
          };


          const groupRemoveFunction = (event) => {
            if (event) {
              event.stopPropagation();
            }

            this.removeLayer.next(catalogueGroup.layer);
            catalogueGroup.categories.forEach((category) => {

              category.added.set(false);



              category.layers.forEach((catalogLayer) => {
                catalogLayer.layer = null;
                catalogLayer.added.set(false);
                catalogLayer.removeFn = null;
                catalogLayer.hasRemoveFn.set(false);
              });
            });

            const layersCollection = catalogueGroup.layer.getLayers();

            layersCollection.forEach((layer) => {
              if (layer instanceof LayerGroup) {
                layer.getLayers().clear();
              }
            });

            layersCollection.clear();
            catalogueGroup.added.set(false);
          };

          catalogueGroup.hasRemoveFn.set(true);
          catalogueGroup.layer.set('removeFn', groupRemoveFunction);
          catalogueGroup.removeFn = groupRemoveFunction;



          return catalogueGroup
        });

        this.groups$.next(groupsWithLoading);

      }));
  }

  loadCatalogueCategoriesForGroup(dataCatalogueGroup: CatalogueGroupWithState) {
    this.http
    .get<CatalogueCategory[]>(`${this.endpoint}/categories?groupID=${dataCatalogueGroup.dataCatalogueGroupID}`)
    .pipe(
      startWithTap(() => {
        dataCatalogueGroup.loading.set(true);
      }),
      map(categories => this.updateCategoriesWithState(categories,dataCatalogueGroup)
      ),
      finalize(() => {
        setTimeout(() => {
          dataCatalogueGroup.loading.set(false);
        }, 200);

      })
    )
    .subscribe({
      next: (response) => {
        const currentData = this.groups$.value;

        const updatedEntities = currentData.map(group => {
          if (group.dataCatalogueGroupID === dataCatalogueGroup.dataCatalogueGroupID) {
            return { ...group, categories: response };
          }
          return group;
        });

        this.groups$.next(updatedEntities);
      },
      error: (error) => {
        console.error('Error loading data', error);
      }
    });
  }

  private updateCategoriesWithState(categories: CatalogueCategory[], dataCatalogueGroup: CatalogueGroupWithState): CatalogueCategoryWithState[] {
    return categories.map(category => {
      const existingCategory = dataCatalogueGroup.categories.find(c => c.dataCatalogueCategoryID === category.dataCatalogueCategoryID);

      const categoryWithState: CatalogueCategoryWithState = {
        ...category,
        hasRemoveFn: existingCategory?.hasRemoveFn ?? signal(false),
        loading: existingCategory?.loading ?? signal(false),
        added: existingCategory?.added ?? signal(false),
        layer: existingCategory?.layer ?? new LayerGroup({
          properties: {
            title: category.name,
          },
        }),
        layers: existingCategory?.layers ?? [],
        defaultLayers: existingCategory?.defaultLayers ?? [],
      };

      const categoryRemoveFn = (event: MouseEvent) => {
        if (event) {
          event.stopPropagation();
        }

        dataCatalogueGroup.layer.getLayers().remove(categoryWithState.layer);

        categoryWithState.added.set(false);
        categoryWithState.hasRemoveFn.set(false);
        categoryWithState.removeFn = null;

        categoryWithState.layers.forEach(layer => {
          layer.layer = null;
          layer.added.set(false);
          layer.removeFn = null;
          layer.hasRemoveFn.set(false);
        });

        if (dataCatalogueGroup.layer.getLayers().getLength() === 0) {
          const removeFn = dataCatalogueGroup.layer.get('removeFn');
          if (removeFn) removeFn();
        }
      };

      if (!existingCategory?.removeFn) {
        categoryWithState.layer.set('removeFn', categoryRemoveFn);
        categoryWithState.hasRemoveFn.set(true);
        categoryWithState.removeFn = categoryRemoveFn;
      }

      return categoryWithState;
    });
  }


  performSearch(groups: CatalogueGroupWithState[], searchTerm: string) {
    if (searchTerm.trim() === '') {
      return of(groups);
    }

    return this.searchCatalogue(searchTerm).pipe(
      map(searchResult => {
        const missingCategoryIds = searchResult.categories.filter(id =>
          !groups.some(g =>
            g.categories.some(c => c.dataCatalogueCategoryID === id)
          )
        );
        const missingLayerIds = searchResult.layers.filter(id =>
          !groups.some(g =>
            g.categories.some(c => c.layers.some(l => l.dataCatalogueLayerID === id))
          )
        );
        return { searchResult, missingCategoryIds, missingLayerIds };
      }),
      switchMap(({ searchResult, missingCategoryIds, missingLayerIds }) => {
        const categories$ = missingCategoryIds.length > 0
          ? this.loadCatalogueCategories({ Ids: missingCategoryIds })
          : of([]);  // If no missing categories, return an empty observable

        const layers$ = missingLayerIds.length > 0
          ? this.getLayers({ Ids: missingLayerIds })
          : of([]);  // If no missing layers, return an empty observable

        return categories$.pipe(
          switchMap(categoriesResult => {
            const categoriesByGroup = categoriesResult.reduce((acc, category) => {
              const groupId = category.dataCatalogueGroupID;
              if (!acc[groupId]) {
                acc[groupId] = [];
              }
              acc[groupId].push(category);
              return acc;
            }, {} as { [groupId: number]: CatalogueCategory[] });

            const updatedCategoriesByGroup = Object.keys(categoriesByGroup).reduce((acc, groupId) => {
              const group = groups.find(g => g.dataCatalogueGroupID === +groupId);
              if (group) {
                acc[groupId] = this.updateCategoriesWithState(categoriesByGroup[groupId], group);
                group.categories.push(...acc[groupId]);
              }
              return acc;
            }, {} as { [groupId: number]: CatalogueCategoryWithState[] });

            const updatedCategoriesResult = Object.values(updatedCategoriesByGroup).flat();

            return layers$.pipe(
              switchMap(layersResult => {
                const layersByCategory = layersResult.reduce((acc, layer) => {
                  const categoryId = layer.dataCatalogueCategoryID;
                  if (!acc[categoryId]) {
                    acc[categoryId] = [];
                  }
                  acc[categoryId].push(layer);
                  return acc;
                }, {} as { [categoryId: number]: CatalogueLayer[] });

                const updatedLayersByCategory = Object.keys(layersByCategory).reduce((acc, categoryId) => {
                  const category = updatedCategoriesResult.find(c => c.dataCatalogueCategoryID === +categoryId);
                  if (category) {
                    acc[categoryId] = this.updateLayersWithState(layersByCategory[categoryId], category);
                    category.layers.push(...acc[categoryId]);
                  }
                  return acc;
                }, {} as { [categoryId: number]: CatalogueLayerWithState[] });

                const updatedLayersResult = Object.values(updatedLayersByCategory).flat();

                return of({ searchResult, categories: updatedCategoriesResult, layers: updatedLayersResult });
              })
            );
          })
        );
      }),
      map(({ searchResult }) => {

        const filteredGroups = groups
        .filter(group => searchResult.groups.includes(group.dataCatalogueGroupID))
        .map(group => ({
          ...group,
          categories: group.categories
            .filter(category => searchResult.categories.includes(category.dataCatalogueCategoryID))
            .map(category => ({
              ...category,
              layers: category.layers.filter(layer => searchResult.layers.includes(layer.dataCatalogueLayerID))
            }))
        }));

      return filteredGroups;
      })
    );
  }

  searchCatalogue(searchTerm: string) {
    const url = `${this.endpoint}/search?term=${encodeURIComponent(searchTerm)}`;

    return this.http.get<DataCatalogueSearchResult>(url).pipe(
      catchError(error => {
        console.error('Error during search:', error);
        return [];  // Return an empty array or handle the error appropriately
      })
    );
  }



  getDefaultLayers()
  {
    return this.http.get<number[]>(`${this.endpoint}/layers/default`);
  }

  loadCatalogueGroups() {
    return this.http.get<CatalogueGroup[]>(`${this.endpoint}/groups`)
  }

  loadCatalogueCategories(query: Partial<CatalogueCategoryQuery> = {}): Observable<CatalogueCategory[]> {
    let queryString = '';

    if (query.Ids && query.Ids.length > 0) {
      queryString += `id=${query.Ids.join(',')}&`;
    }

    if (query.GroupIds && query.GroupIds.length > 0) {
      queryString += `groupIDs=${query.GroupIds.join(',')}&`;
    }

    if (query.Name) {
      queryString += `name=${query.Name}&`;
    }

    if (query.PageSize) {
      queryString += `pageSize=${query.PageSize}&`;
    }

    if (query.Page) {
      queryString += `page=${query.Page}&`;
    }

    queryString = queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;

    return this.http.get<CatalogueCategory[]>(`${this.endpoint}/categories${queryString == '' ? '' : `?query=${encodeURIComponent(queryString)}`}`);
  }


  getLayers(query: Partial<DataCatalogueLayerQuery> = {})
  {
    let queryString = '';

    if (query.Ids && query.Ids.length > 0) {
      queryString += `id=${query.Ids.join(',')}&`;
    }

    if (query.CategoryIDs && query.CategoryIDs.length > 0) {
      queryString += `categoryIDs=${query.CategoryIDs.join(',')}&`;
    }

    if (query.Name) {
      queryString += `name=${query.Name}&`;
    }

    if (query.PageSize) {
      queryString += `pageSize=${query.PageSize}&`;
    }

    if (query.Page) {
      queryString += `page=${query.Page}&`;
    }

    queryString = queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;



    return this.http.get<CatalogueLayer[]>(`${this.endpoint}/layers${queryString == '' ? '' : `?query=${encodeURIComponent(queryString)}`}`);
  }

  loadCatalogueLayers(catalogueCategory: CatalogueCategoryWithState)
  {
    this.http.get<CatalogueLayer[]>(`${this.endpoint}/layers?categoryID=${catalogueCategory.dataCatalogueCategoryID}`)
    .pipe(
      startWithTap(() => {
        catalogueCategory.loading.set(true);
      }),
      map(catalogueLayers => this.updateLayersWithState(catalogueLayers,catalogueCategory)),
      finalize(() => {
        setTimeout(() => {
          catalogueCategory.loading.set(false);
        }, 200);
      })
    )
    .subscribe({
      next: (response) => {

        const updatedGroups = this.groups$.getValue().map(group => {
          return {
            ...group,
            categories: group.categories?.map(category => {
              if (category.dataCatalogueCategoryID === catalogueCategory.dataCatalogueCategoryID) {
                return {
                  ...category,
                  layers: response
                };
              }
              return category;
            }) || null
          };
        });

        this.groups$.next(updatedGroups);
      },
      error: (error) => {
        console.error('Error loading data', error);
      }
    });
  }

  private updateLayersWithState(catalogueLayers: CatalogueLayer[], catalogueCategory: CatalogueCategoryWithState): CatalogueLayerWithState[] {
    return catalogueLayers.map(layer => {
      const existingLayer = catalogueCategory.layers.find(l => l.dataCatalogueLayerID === layer.dataCatalogueLayerID);

      const layerWithState: CatalogueLayerWithState = {
        ...layer,
        added: existingLayer?.added ?? signal(false),
        loading: existingLayer?.loading ?? signal(false),
        layer: existingLayer?.layer ?? null,
        hasRemoveFn: existingLayer?.hasRemoveFn ?? signal(false),
      };

      return layerWithState;
    });
  }


  addLayerToMap(catalogueLayer:CatalogueLayerWithState, options: {visible?: boolean, opacity?:number} = {visible: true, opacity:1})
  {
    const {group, category} = this.groups$.value.reduce<{ group: CatalogueGroupWithState, category: CatalogueCategoryWithState } | null>((acc, group) => {
      if (acc) return acc;

      const category = group.categories.find(category => category.dataCatalogueCategoryID === catalogueLayer.dataCatalogueCategoryID);

      return category ? { group, category } : acc;
    }, null);

    if(!group.added())
    {
      this.addLayer.next(group.layer);
      group.added.set(true);
    }

    if(!category.added())
    {

      group.layer.getLayers().push(category.layer);
      category.added.set(true);

    }

    if (catalogueLayer.added()){
      catalogueLayer.layer.setVisible(options.visible !== undefined ? options.visible : true);
      catalogueLayer.layer.setOpacity(options.opacity !== undefined ? options.opacity : 1);
      return;
    }


    const MapLayer = this.generateLayer(catalogueLayer as CatalogueLayer,options);


    catalogueLayer.layer = MapLayer
    const layerRemoveFn = (event: MouseEvent) => {
      category.layer.getLayers().remove(MapLayer);
      catalogueLayer.added.set(false);
      catalogueLayer.layer = null;
      catalogueLayer.removeFn = null;
      catalogueLayer.hasRemoveFn.set(false);

      const currentAddedLayers = this.addedLayers$.value.filter(data => data.dataCatalogueLayerID != catalogueLayer.dataCatalogueLayerID);

      this.addedLayers$.next([...currentAddedLayers]);


      if (category.layer.getLayers().getLength() === 0) {
        category.layer.get('removeFn')();
      }
    }

    MapLayer.set('removeFn', layerRemoveFn);

    catalogueLayer.removeFn = layerRemoveFn;
    catalogueLayer.hasRemoveFn.set(true);
    catalogueLayer.added.set(true);

    category.layer.getLayers().push(catalogueLayer.layer);

    const currentAddedLayers = this.addedLayers$.value;

    this.addedLayers$.next([...currentAddedLayers, catalogueLayer]);

  }

  loadLayerByID(catalogueLayerID: number) {
    const result = this.findLayerByID(catalogueLayerID);
    if (result) {
      return of(result.layer);
    } else {

      return this.http
        .get<CatalogueLayer>(`${this.endpoint}/layers/${catalogueLayerID}`)
        .pipe(
          map<CatalogueLayer, CatalogueLayerWithState>(catalogueLayer => ({
            ...catalogueLayer,
            added: signal(false),
            loading: signal(false),
            layer: null,
            hasRemoveFn: signal(false)
          })),
          switchMap(catalogueLayerWithState => {
            const category = this.findCategoryByID(catalogueLayerWithState.dataCatalogueCategoryID);

            if (category) {
              category.category.layers.push(catalogueLayerWithState);
              return of(catalogueLayerWithState);
            }


            return this.http
              .get<CatalogueCategory>(`${this.endpoint}/categories/${catalogueLayerWithState.dataCatalogueCategoryID}`)
              .pipe(
                map<CatalogueCategory, CatalogueCategoryWithState>(catalogueCategory => {
                  const categoryWithState: CatalogueCategoryWithState = {
                    ...catalogueCategory,
                    hasRemoveFn: signal(false),
                    loading: signal(false),
                    added: signal(false),
                    layer: new LayerGroup({
                      properties: {
                        title: catalogueCategory.name,
                      },
                    }),
                    layers: [],
                    defaultLayers: []
                  };

                  const categoryRemoveFn = (event: MouseEvent) => {
                    if (event) {
                      event.stopPropagation();
                    }

                    const dataCatalogueGroup = this.findGroupByID(catalogueLayerWithState.dataCatalogueGroupID);

                    if (dataCatalogueGroup) {
                      dataCatalogueGroup.layer.getLayers().remove(categoryWithState.layer);

                      categoryWithState.added.set(false);
                      categoryWithState.hasRemoveFn.set(false);
                      categoryWithState.removeFn = null;

                      categoryWithState.layers.forEach(layer => {
                        layer.layer = null;
                        layer.added.set(false);
                        layer.removeFn = null;
                        layer.hasRemoveFn.set(false);
                      });

                      if (dataCatalogueGroup.layer.getLayers().getLength() === 0) {
                        const removeFn = dataCatalogueGroup.layer.get('removeFn');
                        if (removeFn) removeFn();
                      }
                    }
                  };

                  categoryWithState.layer.set('removeFn', categoryRemoveFn);
                  categoryWithState.hasRemoveFn.set(true);
                  categoryWithState.removeFn = categoryRemoveFn;

                  return categoryWithState;
                }),
                switchMap(catalogueCategoryWithState => {
                  const group = this.findGroupByID(catalogueLayerWithState.dataCatalogueGroupID);

                  if (group) {
                    group.categories.push(catalogueCategoryWithState);
                    catalogueCategoryWithState.layers.push(catalogueLayerWithState);
                  }

                  // Return the created layer in all cases
                  return of(catalogueLayerWithState);
                })
              );
          }));
    }
  }


  findLayerByID(catalogueLayerID:number)
  {
    const result = this.groups$.value.reduce<{ group: CatalogueGroupWithState, category: CatalogueCategoryWithState, layer: CatalogueLayerWithState } | null>((acc, group) => {
      if (acc) return acc;

      const category = group.categories.find(category => {
        const layer = category.layers.find(layer => layer.dataCatalogueLayerID === catalogueLayerID);
        return layer ? { layer, category, group } : null;
      });

      if (category) {
        const layer = category.layers.find(layer => layer.dataCatalogueLayerID === catalogueLayerID);
        return layer ? { group, category, layer } : acc;
      }

      return acc;
    }, null);


    return result;
  }

  findCategoryByID(catalogueCategoryID: number)
  {
    const result = this.groups$.value.reduce<{ group: CatalogueGroupWithState, category: CatalogueCategoryWithState } | null>((acc, group) => {
      if (acc) return acc;

      const category = group.categories.find(category => category.dataCatalogueCategoryID === catalogueCategoryID);

      return category ? { group, category } : acc;
    }, null);

    return result;
  }

  findGroupByID(catalogueGroupID:number)
  {
    const result = this.groups$.value.find(group => group.dataCatalogueGroupID === catalogueGroupID) || null;
    return result;
  }


  loadCatalogueLayerSources() {
    return this.http.get<any[]>(`${this.endpoint}/layers/sources`);
  }

  loadCatalogueLayerTypes() {
    return this.http.get(`${this.endpoint}/layers/types`);
  }

  getLayer(layerID)
  {

  return this.http.get<CatalogueLayer>(`${this.endpoint}/layers/${layerID}`).pipe(map<CatalogueLayer,CatalogueLayerWithState>(catalogueLayer =>({...catalogueLayer, added: signal(false), loading: signal(false),layer:null, hasRemoveFn: signal(false)})))
  .pipe(tap(catalogueLayer => {
    const updatedGroups = this.groups$.getValue().map(group => {
      return {
        ...group,
        categories: group.categories?.map(category => {
          if (category.dataCatalogueCategoryID === catalogueLayer.dataCatalogueCategoryID) {
            return {
              ...category,
              layers: [...category.layers, catalogueLayer]
            };
          }
          return category;
        }) || null
      };
    });

    this.groups$.next(updatedGroups);
  }));
  }

  //what I am doing is switching to the loadCatalogue Observable but returning the original response down the chain,
  // this is so that we can retain the ID which we need to set the select.
  createGroup(request) {
    return this.http
      .post<string>(`${this.endpoint}/groups`, request)
      .pipe(
      );
  }

  updateGroup(request) {
    return this.http
      .patch<string>(`${this.endpoint}/groups/${request.id}`, {
        Name: request.Name,
      })
      .pipe(

      );
  }

  createCategory(request) {
    return this.http
      .post<string>(`${this.endpoint}/categories`, request)
      .pipe(

      );
  }

  updateCategory(request) {
    return this.http
      .patch<string>(`${this.endpoint}/categories/${request.id}`, {
        Name: request.Name,
        Description: request.Description,
        DataCatalogueGroupID: request.DataCatalogueGroupID,
      })
      .pipe(

      );
  }

  createLayer(request) {
    return this.http
      .post(`${this.endpoint}/layers`, request)
  }

  updateLayer(id, model) {
    return this.http
      .patch(`${this.endpoint}/layers/${id}`, model)
      .pipe();
  }

  deleteLayer(id: number) {
    return this.http
      .delete(`${this.endpoint}/layers/${id}`)
      .pipe();
  }

  createNewGroup(
    form: FormGroup,
    dialog: Dialog,
    loadDataTrigger$: BehaviorSubject<void>
  ) {
    let instance = dialog.open(CreateCatalogueGroupDialogComponent, {
      container: CustomDialogContainer,
    }).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueGroup>) =>
          this.createGroup(event.model)
        )
      )
      .pipe(tap(() => loadDataTrigger$.next()))
      .subscribe((response) => {
        let parts = response.split(':');
        const id = parseInt(parts[1].trim());
        form.get('dataCatalogueGroupID').setValue(id);
      });
  }

  createNewCategory(
    form: FormGroup,
    dialog: Dialog,
    loadDataTrigger$: BehaviorSubject<void>
  ): void {
    let instance = dialog.open(CreateCatalogueCategoryDialogComponent, {
      container: CustomDialogContainer,
      data: { dataCatalogueGroupID: -1 },
    }).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueCategory>) =>
          this.createCategory(event.model)
        )
      )
      .pipe(tap(() => loadDataTrigger$.next()))
      .subscribe((response) => {
        let parts = response.split(':');
        const id = parseInt(parts[1].trim());
        form.get('dataCatalogueCategoryID').setValue(id);
      });
  }

  generateLayer(cl: CatalogueLayer, options: {visible?: boolean, opacity?:number}) {
    let layer: VectorLayer<any> | TileLayer<any> | VectorImageLayer<any>;
    switch (cl.sourceType) {
      case 'VectorImage':
      case 'Vector':
      case 'WFS':
      case 'ArcGISFeatureService':
        layer = this.getVectorImageLayer(cl.url, cl.sourceType, cl.params) as VectorImageLayer<any>;
        if (cl.sourceType === 'ArcGISFeatureService') {
          if (this.COLOUR_LIST_TEMP.length === 0) {
            this.COLOUR_LIST_TEMP = [...this.COLOUR_LIST];
            this.OPAQUE_COLOUR_LIST_TEMP = [...this.OPAQUE_COLOUR_LIST];
          }
          const num: number = Math.floor(
            Math.random() * this.COLOUR_LIST_TEMP.length
          );
          const colour: string = this.COLOUR_LIST_TEMP.splice(num, 1)[0];
          const opaqueColour: string = this.OPAQUE_COLOUR_LIST_TEMP.splice(
            num,
            1
          )[0];

         fetch(`${cl.url}/${cl.params['LAYERS']}?f=json`).then(data => data.json().then(data => {
            const createUniqueValueStyleFunction = (renderer) => (feature) => {
              const fieldValue = feature.get(renderer.field1);
              const uniqueValueInfo = renderer.uniqueValueInfos.find(info => info.value == fieldValue);

              if (uniqueValueInfo) {
                  const [r, g, b, a] = uniqueValueInfo.symbol.color;
                  const color = `rgba(${r}, ${g}, ${b}, ${a / 255})`;  // Normalize alpha value (0-255)
                  const width = uniqueValueInfo.symbol.width || 1;

                  return new Style({
                      stroke: new Stroke({
                          color,
                          width,
                      }),
                  });
              }

              return new Style({
                  stroke:new Stroke({
                      color: 'rgba(0, 0, 0, 1)',
                      width: 1,
                  }),
              });
          };
          const createSimpleStyleFunction = (renderer) => {
            const { color, width } = renderer.symbol;
            const [r, g, b, a] = color;
            const strokeColor = `rgba(${r}, ${g}, ${b}, ${a / 255})`;  // Normalize alpha value (0-255)

            return new Style({
                stroke: new Stroke({
                    color: strokeColor,
                    width: width || 1,
                }),
            });
        };

        const createClassBreaksStyleFunction = (renderer) =>
          {
            return (feature) => {
              const fieldValue = feature.get(renderer.field);
              let prevClassMax = -Infinity;  // Start with negative infinity for the first class

              // Iterate through the class breaks
              for (const classBreakInfo of renderer.classBreakInfos) {
                  const classMaxValue = classBreakInfo.classMaxValue;
                  const classMinValue = classBreakInfo.classMinValue !== undefined
                      ? classBreakInfo.classMinValue
                      : prevClassMax;  // Use explicit min or inferred from previous max

                  // Check if the feature's value is within the current class range
                  if (fieldValue >= classMinValue && fieldValue <= classMaxValue) {
                      const [r, g, b, a] = classBreakInfo.symbol.color;
                      const color = `rgba(${r}, ${g}, ${b}, ${a / 255})`;
                      const outlineColor = `rgba(${classBreakInfo.symbol.outline.color.join(',')})`;
                      const width = classBreakInfo.symbol.outline.width || 1;
                  return new Style({
                    fill: new Fill({color}),
                      stroke: new Stroke({
                          color:outlineColor ,
                          width,
                      }),
                  });
              }
              prevClassMax = classMaxValue;
            }


              return new Style({
                  stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 1)',
                      width: 1,
                  }),
              });

          };

          }


      let styleFunction;
      const renderer = data.drawingInfo.renderer;

      if (renderer.type === 'uniqueValue') {
          styleFunction = createUniqueValueStyleFunction(renderer);
      } else if (renderer.type === 'classBreaks') {
          styleFunction = createClassBreaksStyleFunction(renderer);
      } else if (renderer.type === 'simple') {
          styleFunction = createSimpleStyleFunction(renderer);
      }

            const vl = layer as VectorImageLayer<any>;
            vl.setStyle(styleFunction);
          }))

          // layer.setStyle((feature: FeatureLike) =>
          // {
          //   console.log(feature.getProperties());
          //   return this._getFeatureStyle(feature, colour, opaqueColour)
          // }

          // );
        }
        break;
      case 'TileWMS':
        layer = this.getTileWMSLayer(cl.url, cl.params);
        layer.setProperties({
          legendURL: `${cl.url}?REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&LAYER=${cl.params['LAYERS']}`,
          ...cl.params,
        });
        break;
      case 'XYZ':
        layer = this.getTileXYZLayer(cl.url, cl.attribution);
        break;
      case 'ArcGISRest':
        let url: string = cl.url.endsWith('/')
          ? `${cl.url.substring(0, cl.url.length - 1)}`
          : `${cl.url}`;
        layer = this.getTileArcGISRestLayer(url, cl.params);
        layer.setProperties({ legendURL: url + '/legend?f=json' });
        break;
      default:
        break;
    }

    layer.set('title', cl.name);
    layer.setProperties({
      ...cl.params,
      title: cl.name,
      sourceType: cl.sourceType,
      visible: options.visible ? options.visible : false,
      opacity: options.opacity ? options.opacity : 1
    });
    return layer;
  }

  getProjection(url: string): string {
    const srs: string = url.split('&').filter((v) => v.includes('srsName'))[0];
    if (srs) {
      return srs;
    }
    return `EPSG: ${this.baseMapsService.default_epsg}`;
  }

  WMSGetCapabilities(url: string) {
    return this.http
      .get<WMSCapability[]>(`${this.WMS_CAPABILITIES_ENDPOINT}${url}`)
      .pipe(
        map((data) =>
          data.filter((value) => value.crsList.includes('EPSG:3857'))
        )
      );
  }

  WFSSGetCapabilities(url: string) {
    return this.http
      .get<WFSCapability[]>(`${this.WFS_CAPABILITIES_ENDPOINT}${url}`)
      .pipe(
        map((data) => {
          return data.filter((value) => value.defaultCRS.includes('EPSG:3857'));
        })
      );
  }

  ArcGISResGetMapLayers(url: string) {
    return this.http
      .get<ArcGISMapLayers>(`${this.ARC_GIS_REST_MAP_LAYERS_ENDPOINT}${url}`)
      .pipe(map((data) => data.layers));
  }

  ArcGISRestGetFeatureLayers(url: string) {
    return this.http
      .get<ArcGISMapLayers>(
        `${this.ARC_GIS_REST_FEATURE_LAYERS_ENDPOINT}${url}`
      )
      .pipe(map((data) => data.layers));
  }

  getVectorImageLayer(
    url: string,
    sourceType: string,
    params: object
  ): VectorImageLayer<VectorSource> {
    return new VectorImageLayer({
      source: new VectorSource({
        url: this._getUrlForSourceType(url, sourceType, params),
        format: sourceType === 'WFS' ? new WFS() : new GeoJSON(),
      }),
      style:  (feature) => {
        const attributes = feature.getProperties();
       console.log(attributes);

    },
      visible: true,
    });
  }

  getTileWMSLayer(url: string, params: object): TileLayer<TileWMS> {
    return new TileLayer({
      source: new TileWMS({
        url: url,
        params: { CRS: 'EPSG:28355', ...params },
      }),
      visible: true,
    });
  }

  getTileArcGISRestLayer(
    url: string,
    params: object
  ): TileLayer<TileArcGISRest> {
    url = url.endsWith('/') ? `${url.substring(0, url.length - 1)}` : `${url}`;
    return new Tile({
      source: new TileArcGISRest({
        url: url,
        params: params,
      }),
    });
  }

  getTileXYZLayer(url: string, attribution?: string): TileLayer<XYZ> {
    return new TileLayer({
      source: new XYZ({
        url: url,
        projection: this.getProjection(url),
        attributions: attribution ? attribution : '',
      }),
      visible: true,
    });
  }

  _processUrl(url: string): string {
    let processedUrl: string = url;
    if (processedUrl.includes('.json')) {
      return processedUrl;
    } else if (!processedUrl.includes('outputFormat')) {
      processedUrl = `${processedUrl}&outputFormat=GEOJSON`;
    }
    return processedUrl;
  }

  _getUrlForSourceType(
    url: string,
    sourceType: string,
    params: object
  ): string {
    switch (sourceType) {
      case 'VectorImage':
      case 'Vector':
        return this._processUrl(url);
      case 'WFS':
        return `${url}?service=WFS&request=GetFeature&typename=${params['LAYERS']}&outputFormat=application%2Fjson`;
      case 'ArcGISFeatureService':
        url = url.endsWith('/') ? `${url}` : `${url}/`;
        return `${url}${params['LAYERS']}/query?where=1=1&outFields=*&f=geojson`;
      default:
        return url;
    }
  }

  _getFeatureStyle(
    feature: FeatureLike,
    colour: string,
    opaqueColour: string
  ): Style {
    const type: Type = feature.getGeometry().getType();
    switch (type) {
      case 'Point':
      case 'MultiPoint':
        return new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({ color: colour }),
            stroke: new Stroke({ color: 'black', width: 1 }),
          }),
        });
      case 'Polygon':
      case 'MultiPolygon':
        return new Style({
          stroke: new Stroke({
            color: colour,
            width: 2,
          }),
          fill: new Fill({
            color: opaqueColour,
          }),
        });
      case 'LineString':
      case 'MultiLineString':
        return new Style({
          stroke: new Stroke({
            color: colour,
            width: 2,
          }),
        });
      default:
        const fill: Fill = new Fill({
          color: colour,
        });
        const stroke: Stroke = new Stroke({
          color: colour,
          width: 2,
        });
        return new Style({
          image: new Circle({
            fill: fill,
            stroke: stroke,
            radius: 5,
          }),
          fill: fill,
          stroke: stroke,
        });
    }
  }
}
