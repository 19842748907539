<div #draggableContainer class="test">
  <div draggable (heightChange)="onHeightChange($event)" class="tab">
    <button
      appTooltip="Attribute Table"
      tooltipPosition="top"
      (click)="close()"
    >
      <span><i class="fa fa-lg fa-table"></i></span>
    </button>
  </div>
  <p-table
    [value]="propertiesState.properties()"
    [scrollable]="true"
    [scrollHeight]="tableHeight()"
    [ngStyle]="{ height: tableHeight() }"
    [virtualScroll]="true"
    [virtualScrollItemSize]="42"
    styleClass="mt-3"
    #table
    selectionMode="single"
    (selectionChange)="state.selectedRow.set($event)"
    [selection]="state.selectedRow()"
    dataKey="propertyID"
    [globalFilterFields]="[
      'propertyName',
      'projectName',
      'state',
      'region',
      'propertyOwnerName',
      'sourceName',
      'propertyAddress'
    ]"
    (onSort)="onSort($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex items-center gap-4 justify-between">
        <div class="relative">
          <i class="fa fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
          <input
            #globalFilter
            type="text"
            class="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            (input)="table.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
          <button
            *ngIf="globalFilter.value.length > 0"
            class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            (click)="table.clear(); globalFilter.value = ''"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <button
          appTooltip="Export as CSV"
          tooltipPosition="left"
          class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          (click)="exportToCSV()"
        >
          <i class="fa fa-download"></i>
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <!-- Property Name -->
        <th
          pSortableColumn="propertyName"
          pFrozenColumn
          class="min-w-[150px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Property Name
            <p-sortIcon field="propertyName" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Project Name -->
        <th
          pSortableColumn="projectName"
          class="min-w-[150px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Project Name
            <p-sortIcon field="projectName" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Status -->
        <th
          pSortableColumn="status"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Status
            <p-sortIcon field="status" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Stage -->
        <th
          pSortableColumn="stage"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Stage
            <p-sortIcon field="stage" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Suitability -->
        <th
          pSortableColumn="suitability"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Suitability
            <p-sortIcon field="suitability" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Property ID -->
        <th
          pSortableColumn="propertyCadastralID"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Property ID
            <p-sortIcon field="propertyCadastralID" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- State -->
        <th
          pSortableColumn="state"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            State
            <p-sortIcon field="state" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Region -->
        <th
          pSortableColumn="region"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Region
            <p-sortIcon field="region" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Area (Ha) Title -->
        <th
          pSortableColumn="areaHaDisplay"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Area (Ha) Title
            <p-sortIcon field="areaHaDisplay" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Area (Ha) Calculated -->
        <th
          pSortableColumn="areaHaCalculated"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Area (Ha) Calculated
            <p-sortIcon field="areaHaCalculated" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Listing Date -->
        <th
          pSortableColumn="listingDate"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Listing Date
            <p-sortIcon field="listingDate" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Created -->
        <th
          pSortableColumn="createdAt"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Created
            <p-sortIcon field="createdAt" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Water Allocation -->
        <th
          pSortableColumn="waterAllocation"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Water Allocation
            <p-sortIcon field="waterAllocation" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Price -->
        <th
          pSortableColumn="price"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Price
            <p-sortIcon field="price" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Owner/Operator -->
        <th
          pSortableColumn="propertyOwnerName"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Owner/Operator
            <p-sortIcon field="propertyOwnerName" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Source -->
        <th
          pSortableColumn="sourceName"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Source
            <p-sortIcon field="sourceName" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Updated -->
        <th
          pSortableColumn="updatedAt"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Updated
            <p-sortIcon field="updatedAt" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Property Address -->
        <th
          pSortableColumn="propertyAddress"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Property Address
            <p-sortIcon field="propertyAddress" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Primary Class -->
        <th
          pSortableColumn="primaryClass"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Primary Class
            <p-sortIcon field="primaryClass" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Secondary Class -->
        <th
          pSortableColumn="secondaryClass"
          class="min-w-[100px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          <span class="flex items-center">
            Secondary Class
            <p-sortIcon field="secondaryClass" class="ml-2"></p-sortIcon>
          </span>
        </th>
        <!-- Actions -->
        <th
          pFrozenColumn
          alignFrozen="right"
          class="min-w-[200px] px-4 py-2 bg-gray-100 text-left font-medium text-gray-700"
        >
          Actions
        </th>
      </tr>

      <!-- Filters -->
      <tr>
        <!-- Property Name Filter -->
        <th pFrozenColumn class="px-4 py-2">
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="propertyName"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Project Name Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="projectName"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Status Filter -->
        <th class="px-4 py-2">
          <p-columnFilter field="status" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [options]="statuses()"
                placeholder="Any"
                class="w-full"
                (onChange)="filter($event.value)"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Stage Filter -->
        <th class="px-4 py-2">
          <p-columnFilter field="stage" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [options]="stages()"
                placeholder="Any"
                class="w-full"
                (onChange)="filter($event.value)"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Suitability Filter -->
        <th class="px-4 py-2">
          <p-columnFilter field="suitability" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [options]="suitabilities()"
                placeholder="Any"
                class="w-full"
                (onChange)="filter($event.value)"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Property ID Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="text"
            field="propertyCadastralID"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- State Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="text"
            field="state"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Region Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="text"
            field="region"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Area (Ha) Title Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="numeric"
            field="areaHaDisplay"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="number"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Area (Ha) Calculated Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="numeric"
            field="areaHaCalculated"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="number"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Listing Date Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="date"
            field="listingDate"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar
                appendTo="body"
                class="w-full"
                (onSelect)="filter($event)"
                dateFormat="dd/mm/yy"
              ></p-calendar>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Created Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="date"
            field="createdAt"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar
                appendTo="body"
                class="w-full"
                (onSelect)="filter($event)"
                dateFormat="dd/mm/yy"
              ></p-calendar>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Water Allocation Filter -->
        <th class="px-4 py-2"></th>
        <!-- Price Filter -->
        <th class="px-4 py-2"></th>
        <!-- Owner/Operator Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="propertyOwnerName"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Source Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="sourceName"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Updated Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            type="date"
            field="updatedAt"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar
                appendTo="body"
                class="w-full"
                (onSelect)="filter($event)"
                dateFormat="dd/mm/yy"
              ></p-calendar>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Property Address Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            matchMode="contains"
            type="text"
            field="propertyAddress"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                type="text"
                pInputText
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                (input)="filter($event.target.value)"
                placeholder="Filter"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Primary Class Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            field="primaryClass"
            matchMode="in"
            [showMenu]="false"
          >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [options]="classes()"
                placeholder="Any"
                class="w-full"
                (onChange)="filter($event.value)"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Secondary Class Filter -->
        <th class="px-4 py-2">
          <p-columnFilter
            field="secondaryClass"
            matchMode="in"
            [showMenu]="false"
          >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                appendTo="body"
                [ngModel]="value"
                [options]="classes()"
                placeholder="Any"
                class="w-full"
                (onChange)="filter($event.value)"
              ></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <!-- Actions Filter -->
        <th pFrozenColumn alignFrozen="right" class="px-4 py-2"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-property>
      <tr [pSelectableRowDblClick]="property"  class="hover:bg-gray-50 h-10">
        <!-- Property Name -->
        <td
          [appTooltip]="property.propertyName"
          pFrozenColumn
          class="px-4 py-2 text-gray-700"
        >
          {{ property.propertyName | truncateText:12 }}
        </td>
        <!-- Project Name -->
        <td [appTooltip]="property.projectName" class="px-4 py-2 text-gray-700">
          {{ property.projectName | truncateText:12 }}
        </td>
        <!-- Status -->
        <td class="px-4 py-2 text-gray-700">{{ property.status }}</td>
        <!-- Stage -->
        <td class="px-4 py-2 text-gray-700">{{ property.stage }}</td>
        <!-- Suitability -->
        <td class="px-4 py-2 text-gray-700">{{ property.suitability }}</td>
        <!-- Property ID -->
        <td class="px-4 py-2 text-gray-700">{{ property.propertyCadastralID }}</td>
        <!-- State -->
        <td class="px-4 py-2 text-gray-700">{{ property.state }}</td>
        <!-- Region -->
        <td class="px-4 py-2 text-gray-700">{{ property.region }}</td>
        <!-- Area (Ha) Title -->
        <td class="px-4 py-2 text-gray-700">{{ property.areaHaDisplay }}</td>
        <!-- Area (Ha) Calculated -->
        <td class="px-4 py-2 text-gray-700">
          {{ property.areaHaCalculated | number: "1.0-2" }}
        </td>
        <!-- Listing Date -->
        <td class="px-4 py-2 text-gray-700">
          {{ property.listingDate | date: "dd/MM/yyyy" }}
        </td>
        <!-- Created -->
        <td class="px-4 py-2 text-gray-700">
          {{ property.createdAt | date: "dd/MM/yyyy" }}
        </td>
        <!-- Water Allocation -->
        <td class="px-4 py-2 text-gray-700">{{ property.waterAllocation }}</td>
        <!-- Price -->
        <td class="px-4 py-2 text-gray-700">
          {{ property.price | currency }}
        </td>
        <!-- Owner/Operator -->
        <td class="px-4 py-2 text-gray-700">{{ property.propertyOwnerName }}</td>
        <!-- Source -->
        <td class="px-4 py-2 text-gray-700">{{ property.sourceName }}</td>
        <!-- Updated -->
        <td class="px-4 py-2 text-gray-700">
          {{ property.updatedAt | date: "dd/MM/yyyy" }}
        </td>
        <!-- Property Address -->
        <td class="px-4 py-2 text-gray-700">{{ property.propertyAddress }}</td>
        <!-- Primary Class -->
        <td class="px-4 py-2 text-gray-700">{{ property.primaryClass }}</td>
        <!-- Secondary Class -->
        <td class="px-4 py-2 text-gray-700">{{ property.secondaryClass }}</td>
        <!-- Actions -->
        <td pFrozenColumn alignFrozen="right" class="px-4 py-2">
          <div class="flex space-x-2">
            <button
              (click)="propertiesState.delete(property.propertyID)"
              class="bg-red-500 text-white px-3 py-1 flex-grow rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              appTooltip="Delete"
              tooltipPosition="left"
            >
              <i class="fa fa-trash"></i>
            </button>
            <button
              (click)="propertiesState.edit(property.propertyID)"
              class="bg-blue-500 text-white px-3 py-1 flex-grow rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              appTooltip="Edit"
              tooltipPosition="left"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
