import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { inject } from '@angular/core';
import { AccountService } from '../account.service';

export function AdminGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
  const accountService = inject(AccountService);
  const router = inject(Router);

  return accountService.isAdmin().pipe(
    map((isAdmin) => {
      if (!isAdmin) {
        router.navigate(['']);
        return false;
      }
      return true;
    }),
    catchError(() => {
      router.navigate(['/login']);
      return of(false);
    })
  );
}
