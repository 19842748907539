import { Component, OnInit, inject, input  } from '@angular/core';
import { MapService } from '../../data-access/map.service';
import { Coordinate } from 'ol/coordinate';
import { TruncateTextPipe } from 'src/app/shared/pipes/truncate-text.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-map-information',
  templateUrl: './map-information.component.html',
  styleUrls: ['./map-information.component.css'],
  standalone:true,
  imports:[CommonModule]
})
export class MapInformationComponent implements OnInit {

  currentCoordinates = input.required<Coordinate>();

  currentUTMZone = input.required<number>();



  constructor() { }

  ngOnInit() {
  }

}
