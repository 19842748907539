import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayerControlServiceService {

constructor() { }
private selectedLayerSubject = new BehaviorSubject<any>(null);
  selectedLayer$ = this.selectedLayerSubject.asObservable();

  selectLayer(layerValue: any) {
    this.selectedLayerSubject.next(layerValue);
  }

  getSelectedLayer() {
    return this.selectedLayerSubject.getValue();
  }
}
