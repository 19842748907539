<h2 mat-dialog-title>Create Feature</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="form"
  >
<section class="grid grid--row gap--md">

  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput placeholder="">
    <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
  </mat-form-field>

  <div class="grid--row gap--sm " formArrayName="attributes">
    <div class="attributes-header">
      <span >Attributes</span>
      <button type="button" (click)="addAttribute()" class="button button--blue button--action justify-self-end">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    @for (item of attributes.controls; track $index) {
      <div class="grid--column gap--sm align-center justify-center" [formGroupName]="$index">
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
          <mat-label>Attribute Name</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
          <mat-label>Attribute Value</mat-label>
          <input formControlName="value" matInput >
          <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
        </mat-form-field>
        <button [disabled]="item.get('value').disabled"  type="button" (click)="removeAttribute($index)" class="button button--action button--danger">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    }
  </div>
</section>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="form.invalid"
      (click)="onSubmit()"
      type="submit"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>
