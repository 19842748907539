import { createInjectable } from "ngxtension/create-injectable";
import { signalSlice } from "ngxtension/signal-slice";
import {injectLocalStorage} from 'ngxtension/inject-local-storage'
import { catchError, concat, concatMap, delay, EMPTY, endWith, filter, finalize, fromEvent, map, merge, NEVER, Observable, of, pairwise, startWith, Subject, switchMap, tap, timer } from "rxjs";
import { environment } from "src/environments/environment";
import { effect, inject, Injectable } from "@angular/core";
import { SignalRService } from "../services/signalR.service";
import { signalSlice2 } from "src/app/map/features/property/signal-slice";
import { toObservable } from "@angular/core/rxjs-interop";
import { AuthService } from "./auth.service";
import { handleError } from "./utils";
import { ToastService } from "../services/toast/toast.service";
import { Router } from "@angular/router";
import { authResponseDTO } from "./models/authResponseDTO";

import { injectNetwork } from 'ngxtension/inject-network';


export const status = {
    successful: 'SUCCESSFUL',
    failed: 'FAILED',
    notStarted: 'NOT STARTED',
    refreshing: 'IN PROGRESS'
  } as const;

  type RefreshStatus = typeof status[keyof typeof status];



const INITIAL_STATE: {
    authenticated: boolean,
    refreshing: boolean,
    refreshStatus:RefreshStatus,
    refreshToken: string,
    accessToken: string
    error: boolean,
    errorMessage: string
} = {
    authenticated: false,
    refreshing: false,
    refreshStatus: 'NOT STARTED',
    accessToken: '',
    refreshToken: '',
    error: false,
    errorMessage: null
}

type STATE_TYPE = typeof INITIAL_STATE;


const JWT_KEY = `${environment.appPrefix}-jwt-token`;
const REFRESH_TOKEN = `${environment.appPrefix}-refresh-token`;

export const AuthState = createInjectable(() =>
{
    const authApi = inject(AuthService);
    const toastService = inject(ToastService);
    const router = inject(Router);
    const network = injectNetwork();
    const accessTokenAccessor = injectLocalStorage<string>(JWT_KEY, {storageSync: true});


    const refreshTokenAccessor = injectLocalStorage<string>(REFRESH_TOKEN, {storageSync: true});

    const accessTokenUpdated = toObservable(accessTokenAccessor.asReadonly())

    const refreshTokenUpdated = toObservable(refreshTokenAccessor.asReadonly());

    const loginAction$ = new Subject<{email: string, password:string}>();

    const logoutAction$ = new Subject<void>();

    const refreshAction$ = authApi.refreshToken().pipe(
      map<authResponseDTO, Partial<STATE_TYPE>>(() => ({
        refreshing: false,
        refreshStatus: 'SUCCESSFUL',
        authenticated: true
      })),
      startWith<Partial<STATE_TYPE>>({ refreshing: true, refreshStatus: 'IN PROGRESS' }),
      catchError(error => {
        state.logout();
        return of<Partial<STATE_TYPE>>({ refreshing: false, refreshStatus: 'FAILED', authenticated: false });
      }),
      tap(({ refreshing, refreshStatus }) => {
        state.setRefreshStatus({ refreshing, refreshStatus });
      }),
      finalize(() => {
        timer(100).subscribe(() => {
          state.setRefreshStatus({ refreshing: false, refreshStatus: 'NOT STARTED' });
        })

      })
    );

    const isAuthenticatedAction$ = authApi.isAuthenticated().pipe(tap((authenticated) => {
      state.isAuthenticated(authenticated)
    }));


    const onlineStatus$ = toObservable(network.online);

      merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
      });


        const state = signalSlice2(
            {
                initialState: INITIAL_STATE,
                sources: [

                    accessTokenUpdated.pipe(map(accessToken => ({accessToken}))),
                    refreshTokenUpdated.pipe(map(refreshToken => ({refreshToken}))),
                    loginAction$.pipe(   switchMap((model => authApi.login(model))),
                    map<any, Partial<STATE_TYPE>>((loginResponse) => ({authenticated: true, accessToken: loginResponse.token, refreshToken: loginResponse.refreshToken, refreshing: false, refreshStatus: 'NOT STARTED' })),
                    tap(() => {
                      router.navigate(['']);
                    }),
                    catchError(error => handleError(error))),

                    logoutAction$.pipe(switchMap(( ) => authApi.logout()),catchError(() => EMPTY), map(() => ({authenticated: false, accessToken: null, refreshToken: null})),tap(( )=> {
                        router.navigateByUrl('/login');
                    })),
                ],
                actionSources: {
                    login: loginAction$,
                    logout: logoutAction$,
                    refresh: (state, action$:Observable<void>) =>  action$.pipe(switchMap(() => refreshAction$.pipe(switchMap( () => EMPTY)))),
                    setRefreshStatus: (state, action$:Observable<{refreshing: boolean, refreshStatus: RefreshStatus, authenticated?:boolean}>) =>  action$,

                    isAuthenticated: (state, action$:Observable<boolean>) =>  action$.pipe(map(authenticated => ({authenticated})))
                }
            }
        )

        effect(() =>
        {
            const accessToken = state.accessToken();
            const refreshToken = state.refreshToken();


            refreshTokenAccessor.set(refreshToken);
            accessTokenAccessor.set(accessToken);
        }, {allowSignalWrites: true})

        effect(() => {

            const error = state.error();
            const message = state.errorMessage();

            if(error === false)
                return;

            toastService.showError(message);
        });

        effect(() => {
          console.log(state.refreshStatus())
        })

        effect(() => {
          console.log("Authenticated ",state.authenticated())
        })

    return {state, refreshToken: refreshAction$, logout: logoutAction$, isAuthenticated: isAuthenticatedAction$}
})
