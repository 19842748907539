import packageJson from '../../package.json';

export const environment = {
  production: false,
  apiUrl: 'https://dev.lagunabay.eskspatial.com.au/api/v1/',
  assetsUrl: 'https://dev.lagunabay.eskspatial.com.au/api/assets/',
  hubUrl: 'https://dev.lagunabay.eskspatial.com.au/hubs/notifications',
  version: packageJson.version,
  appPrefix: 'lb',
} as const;
