import { inject, runInInjectionContext } from "@angular/core";
import { Feature } from "ol";
import { ContextMenuAction } from "src/app/shared/features/context-menu/context-menu.component";
import { FeatureLayerState } from "./state";
import { Router } from "@angular/router";
import { PropertiesState } from "../../../data-access/state";



export const EditContextAction: ContextMenuAction = {
  label: 'Edit',
  action: (context, olFeature: Feature<any>) => {

    runInInjectionContext(context, () => {
      const state = inject(FeatureLayerState);
      const propertiesState = inject(PropertiesState);
      const router = inject(Router);

      const featureLayersMap = state.featureLayersMap();

      const {layerID, featureID} = olFeature.getProperties();

      const layer = featureLayersMap[layerID];

      const featureIndex = layer.features.findIndex(feature => feature.featureID == featureID);

      const userFeature = layer.features[featureIndex];

      if(!router.url.endsWith('/digitize'))
      {
        router
        .navigate([
          'map',
          'property',
          propertiesState.activePropertyID(),
          'digitize',
        ])
        .then(() => {
          state.editFeature(userFeature);
          state.selectLayer(layer);
        });
      }
      else
      {
        state.editFeature(userFeature);
        state.selectLayer(layer);
      }
  })




  }

};


export const LayerEditContextAction: ContextMenuAction = {
  label: 'Edit Layer',
  action: (context, olFeature: Feature<any>) => {

    runInInjectionContext(context, () => {
      const state = inject(FeatureLayerState);
      const propertiesState = inject(PropertiesState);
      const router = inject(Router);

      const featureLayersMap = state.featureLayersMap();

      const {layerID} = olFeature.getProperties();

      const layer = featureLayersMap[layerID];
      if(!router.url.endsWith('/digitize'))
      {
        router
        .navigate([
          'map',
          'property',
          propertiesState.activePropertyID(),
          'digitize',
        ])
        .then(() => {
          state.editLayer(layer);

        });
      }
      else
      {
        state.editLayer(layer);

      }
  })
  }
};

