<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title>General</mat-panel-title>
  </mat-expansion-panel-header>

  <form class="flex flex-column gap--2" [formGroup]="form">

    <tui-input-color
    class="b-form"
    [colors]="palette"
    formControlName="color"
>
 Color
</tui-input-color>


    <!-- <label class="align-center" style="width: 100%;" tuiLabel>
      <span tuiTitle>
        Opacity:
      </span>
      <input
    size="m"
    tuiSlider
    type="range"
    value="1"
    min="0"
    max="1"
    step="0.1"
    formControlName="opacity"
/>
  </label> -->
    <tui-input-color
    class="b-form"
    [colors]="palette"
    formControlName="outlineColor"
>
 Outline Color
</tui-input-color>

    <tui-input-number [step]="1" formControlName="outlineWidth" >Outline Width</tui-input-number>


    <div class="dash-pattern">
      <!-- <mat-label>Dash Pattern</mat-label>
      <button mat-icon-button (click)="addDash()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="removeDash()">
        <mat-icon>remove</mat-icon>
      </button> -->
    </div>
  </form>
</mat-expansion-panel>
<div class="preview-section">
  <h3>Preview</h3>
  <div class="preview-container">
    <legend-icon [rule]="preview()"></legend-icon>
  </div>
</div>
