import { CommonModule } from '@angular/common';
import { Component, computed, contentChild, ContentChild, effect, forwardRef, HostListener, inject, input, OnInit, output, signal, TemplateRef } from '@angular/core';
import {TuiPlatform} from '@taiga-ui/cdk';
import {TuiLoader, TuiNotification, type TuiSizeS} from '@taiga-ui/core';
import {TuiSwitch} from '@taiga-ui/kit';
import {TuiSliderComponent} from '@taiga-ui/kit';
import { TuiAccordionItem } from '@taiga-ui/kit';
import { LayerControlActionsDirective } from './layer-control-actions.directive';
import { LayerControlPreviewDirective } from './layer-control-preview.directive';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import VectorTileLayer from 'ol/layer/VectorTile';
import { switchMap, fromEvent, map, merge, scan, startWith, filter } from 'rxjs';
import { StopPropagationDirective } from '../../directives/stop-propagation.directive';
import { Map as OlMap } from 'ol';
import { LayerControlServiceService } from './LayerControlService.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import VectorImageLayer from 'ol/layer/VectorImage';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { TruncateTextPipe } from '../../pipes/truncate-text.pipe';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import OpacitySlider from '../../components/opacity-slider/opacity-slider.component';
import { LayerControlHeaderActionsDirective } from './layer-control-header-actions.directive';

@Component({
  selector: 'app-layer-control',
  templateUrl: './layer-control.component.html',
  styleUrls: ['./layer-control.component.css'],
  standalone:true,
  imports:[CommonModule, TuiSwitch,TuiLoader, LayerControlHeaderActionsDirective,	TuiSliderComponent, TuiAccordionItem, StopPropagationDirective, FormsModule,ReactiveFormsModule,TuiNotification, TooltipDirective, OpacitySlider],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LayerControlComponent),
      multi: true
    }
  ],
  animations: [
    trigger('slideToggle', [
      state('open', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      transition('open <=> closed', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class LayerControlComponent implements OnInit, ControlValueAccessor  {

  private readonly selectionService:LayerControlServiceService = inject(LayerControlServiceService);

  click = output();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if(event)
    {
      event.stopPropagation();
    }

    if(this.selectable())
    this.select();


  }

   value = input<any>(null);

   selectable = input(false);

  actionsSelector = contentChild(LayerControlActionsDirective)

  actionsRef = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.actionsSelector();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }
    return contentChild.templateRef;
  });

  headerActionsSelector = contentChild(LayerControlHeaderActionsDirective)

  headerActionsRef = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.headerActionsSelector();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }

    return contentChild.templateRef;
  })

  hasActions = computed(() => this.actionsRef() != null);

  a = contentChild(LayerControlPreviewDirective)

  preview = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.a();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }

    return contentChild.templateRef;
  });


  hasPreview = computed(() => this.preview() != null);


 layer = input.required<VectorTileLayer | VectorImageLayer<any> | LayerGroup | TileLayer<any>>();

  map = input.required<OlMap>();

  hasRequiredZoom = computed(() => {
    const layer = this.layer();

    if(layer == null)
    {
      return false
    }

    return layer.getMinZoom() == -Infinity ? false : true
  });

  requiredZoom = computed(() => {
    const layer = this.layer();
    const hasRequiredZoom = this.hasRequiredZoom();

    if(layer == null)
      {
        return -1
      }


    if(!hasRequiredZoom)
    {
      return -1;
    }

    return layer.getMinZoom();
  })

  currentMapZoom = toSignal(toObservable(this.map).pipe(switchMap(olMap => {
    return fromEvent(olMap, 'moveend').pipe(map(() => {
     return olMap.getView().getZoom()
    }))
  })));

  canActivate = computed(() => {
    const hasRequiredZoom = this.hasRequiredZoom();
    const requiredZoom = this.requiredZoom();
    const currentMapZoom = this.currentMapZoom();

    if(!hasRequiredZoom)
    {
      return true;
    }

    if(currentMapZoom >  requiredZoom)
    {
      return true;
    }

    return false;

  })
  loading = toSignal(toObservable(this.layer).pipe(
    switchMap(layer => {
      if (layer instanceof LayerGroup) {

        const layers = layer.getLayers().getArray().filter((layer): layer is VectorImageLayer<any> | VectorTileLayer | TileLayer<any> =>
          layer instanceof VectorImageLayer ||
          layer instanceof VectorTileLayer ||
          layer instanceof TileLayer
        );
        const sources = layers.map(l => l.getSource()).filter(source => source);

        const tileLoadStart$ = merge(...sources.map(source => fromEvent(source, 'tileloadstart').pipe(map(() => 1))));
        const tileLoadEnd$ = merge(...sources.map(source => fromEvent(source, 'tileloadend').pipe(map(() => -1))));

        return merge(tileLoadStart$, tileLoadEnd$).pipe(
          scan((acc, value) => acc + value, 0),
          startWith(0),
          map(loadingCount => loadingCount > 0)
        );
      } else if (layer instanceof VectorImageLayer || layer instanceof VectorTileLayer) {
        const source = layer.getSource();
        const tileLoadStart$ = fromEvent(source, 'tileloadstart').pipe(map(() => 1));
        const tileLoadEnd$ = fromEvent(source, 'tileloadend').pipe(map(() => -1));

        return merge(tileLoadStart$, tileLoadEnd$).pipe(
          scan((acc, value) => acc + value, 0),
          startWith(0),
          map(loadingCount => loadingCount > 0)
        );
      } else {
        return [false];
      }
    })
  ));

  visible = toSignal(toObservable(this.layer).pipe(switchMap(layer => fromEvent(layer,'change:visible').pipe(map(() => layer.getVisible()),startWith(layer.getVisible())))));

  opacity = toSignal(toObservable(this.layer).pipe(switchMap(layer => fromEvent(layer, 'change:opacity').pipe(map(() => layer.getOpacity()),startWith(layer.getOpacity())) )));

  title = computed(() => this.layer().get('title') ?? "UNKNOWN");

  opacityControl = new FormControl(1);

  visibilityControl = new FormControl();

  constructor() {

    this.opacityControl.valueChanges.pipe(filter(value => value != null)).subscribe(opacity => {
      this.layer().setOpacity(opacity);
    });

    this.visibilityControl.valueChanges.pipe(filter(value => value != null)).subscribe(visibility => {
      this.layer().setVisible(visibility);
    })

    effect(() => {

      const canActivate = this.canActivate();

      if(canActivate)
      {
        this.visibilityControl.enable();
      }
      else
      {
        this.visibilityControl.disable();
      }
    })


    effect(() => {

      const canActivate = this.visible();

      this.visibilityControl.setValue(canActivate,{emitEvent:false});

    })


    toObservable(this.opacity).subscribe(opacity => {
      const ctrlValue = this.opacityControl.getRawValue();

      if(ctrlValue == null || ctrlValue != opacity)
      {
        this.opacityControl.setValue(opacity,{emitEvent:false});
      }
    })

}

  ngOnInit() {
    if(this.selectable())
    {
      this.selectionService.selectedLayer$.subscribe((selectedValue) => {
        this.selected.set(this.value() === selectedValue);
      });
    }
  }

  toggleLayerVisibility() {

    const layer = this.layer();

    layer.setVisible(this.visible() ? false : true);
  }

  selected = signal(false);

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  select() {
    if (this.selected()) {
      this.selectionService.selectLayer(null);
      this.onChange(null);
    } else {
      this.selectionService.selectLayer(this.value());
      this.onChange(this.value());
    }
    this.onTouched();
  }

  writeValue(value: any): void {
    this.selected.set(value === this.value());
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }


}
