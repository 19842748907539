import { FeatureLayersComponent } from '../features/property/features/feature-layers/features/feature-layers/feature-layers.component';
import { LayersComponent } from '../features/layers/layers.component';
import { ProfileComponent } from '../features/profile/profile.component';
import { CreatePropertyComponent } from '../features/property/features/create-property/create-property.component';
import { PropertyDetailsComponent } from '../features/property/features/property-details/property-details.component';
import { RoutesExtended } from '../features/sidebar/sidebar-types';
import { ProfilePageComponent } from '../features/profile-page/profile-page.component';
import { PropertyPageContainerComponent } from '../features/property/features/property-page-container/property-page-container.component';
import { SelectPropertyInfoPageComponent } from '../features/property/ui/select-property-info-page/select-property-info-page.component';

export const MapChildRoutes: RoutesExtended = [
  {
    path: 'layers',
    component: LayersComponent,
    data: {
      sidebar: {
        route: 'layers',
        title: 'Layer Management',
        className: 'fa fa-layer-group',
        position: 3,
        active: false,
        disabled: false,
      },
      header: {
        route: 'layers',
        title: 'Layer Management',
      },
    },
  },
  {
    path: 'create-property',
    component: CreatePropertyComponent,
    data: {
      sidebar: {
        route: 'create-property',
        title: 'Create Property',
        className: 'fa fa-plus',
        position: 1,
        active: false,
        disabled: false,
      },
      header: {
        route: 'create-property',
        title: 'Create Property',
      },
    },
  },
  {
    path: 'property',
    component: PropertyPageContainerComponent,
    data: {
      sidebar: {
        route: 'property',
        title: 'Properties',
        className: 'fa fa-vector-square',
        position: 2,
        active: false,
        disabled: false,
      },
      header: {
        route: 'property',
        title: 'Properties',
      },
    },
    children: [
      {
        path: '',
        component: SelectPropertyInfoPageComponent,
        data: {
          sidebar: {
            route: 'property',
            title: 'Properties',
            className: 'fa fa-vector-square',
            position: 2,
            active: false,
            disabled: false,
          },
          header: {
            route: 'property',
            title: 'Properties',
          },
        },
      },
      {
        path: ':id',
        component: PropertyDetailsComponent,
        data: {
          sidebar: {
            route: ':id',
            title: 'Property',
            className: 'fa fa-vector-square',
            position: 2,
            active: false,
            disabled: false,
          },
          header: {
            route: ':id',
            title: 'Property',
            children: [
              {
                route: 'digitize',
                title: 'Property Layers',
                backButton: true,
                backRoute: ':id',
              },
            ],
          },
        },
      },
    ],
  },

  {
    path: 'property/:id/digitize',
    component: FeatureLayersComponent,
  },
  {
    path: 'settings',
    component: ProfilePageComponent,
    data: {
      sidebar: {
        route: 'settings',
        title: 'Settings',
        className: 'fa fa-cog',
        active: false,
        position: 4,
        disabled: false,
      },
      header: {
        route: 'settings',
        title: 'Settings',
      },
    },
  },
];
