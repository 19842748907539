import { Injectable } from '@angular/core';
import { BaseEndpointResource } from 'src/app/@core/interfaces/IEndpoint';
import { CreateScenarioRequest, GetScenarioRequest, UpdateScenarioRequest } from './models/models';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ScenarioService extends BaseEndpointResource<'properties/{propertyID}/scenarios'> {

  public scenarios = new BehaviorSubject<GetScenarioRequest[]>([])

constructor() {

  super({routeTemplate: 'properties/{propertyID}/scenarios'})
 }

 create(model: CreateScenarioRequest)
 {
    return this.http.post<GetScenarioRequest>(this.endpoint, model);
 }

 getAll()
 {
  return this.http.get<Array<GetScenarioRequest>>(this.endpoint).pipe(tap(data => this.scenarios.next(data)));
 }

 update(scenarioID: string, model: UpdateScenarioRequest)
 {
  return this.http.patch<GetScenarioRequest>(`${this.endpoint}/${scenarioID}`,model);
 }

 delete(scenarioID: string)
 {
  return this.http.delete(`${this.endpoint}/${scenarioID}`);
 }

 get(scenarioID: string)
 {
  return this.http.get<GetScenarioRequest>(`${this.endpoint}/${scenarioID}`);
 }

}
