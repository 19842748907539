import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/features/dialog/dialog.component';
import { DialogContentDirective } from 'src/app/shared/features/dialog/directives/dialog-content.directive';
import { DialogFooterDirective } from 'src/app/shared/features/dialog/directives/dialog-footer.directive';
import { DialogHeaderDirective } from 'src/app/shared/features/dialog/directives/dialog-header.directive';
import { Ok, Result } from 'ts-results';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
  standalone:true,
  imports: [CommonModule, DialogComponent,DialogContentDirective,DialogHeaderDirective,DialogFooterDirective]
})
export class ConfirmationComponent {
  public displayText: string;

  constructor(
    @Inject(DIALOG_DATA) public data,
    public dialogRef: DialogRef<Result<boolean,Error>,ConfirmationComponent>
  ) {
    this.displayText = data.displayText;
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close(Ok(false));
  }

  onYesClick(): void {
    this.dialogRef.close(Ok(true));
  }
}
