import { Directive, ElementRef, Renderer2, TemplateRef } from '@angular/core';

@Directive({
  selector: '[layerControlHeaderActions]',
  standalone:true
})
export class LayerControlHeaderActionsDirective {

  constructor(public templateRef: TemplateRef<any>, private el: ElementRef, private renderer: Renderer2) {}

}
