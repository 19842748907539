import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  standalone:true
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}
  transform(content: string): SafeHtml {


    return content == null ? '' : this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
