import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  model,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { DataCatalogueService } from '../../data-access/services/data-catalogue.service';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { CustomDialogContainer } from 'src/app/@core/components/custom-dialog-container.component';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { CreateCatalogueCategoryDialogComponent } from '../add-update/features/catalogue-category/create-catalogue-category-dialog/create-catalogue-category-dialog.component';
import {
  CreateCatalogueCategory,
  CreateCatalogueGroup,
  CreateCatalogueLayer,
  UpdateCatalogueCategory,
  UpdateCatalogueGroup,
} from '../add-update/data-access/models/create-update-catelogue.model';
import { CreateCatalogueLayerDialogComponent } from '../add-update/features/catalogue-layer/create-catalogue-layer-dialog/create-catalogue-layer-dialog.component';
import { CreateCatalogueGroupDialogComponent } from '../add-update/features/catalogue-group/create-catalogue-group-dialog/create-catalogue-group-dialog.component';
import { UpdateCatalogueGroupDialogComponent } from '../add-update/features/catalogue-group/update-catalogue-group-dialog/update-catalogue-group-dialog.component';
import { UpdateCatalogueCategoryDialogComponent } from '../add-update/features/catalogue-category/update-catalogue-category-dialog/update-catalogue-category-dialog.component';
import { UpdateCatalogueLayerDialogComponent } from '../add-update/features/catalogue-layer/update-catalogue-layer-dialog/update-catalogue-layer-dialog.component';
import { MiniMapService } from '../add-update/data-access/services/mini-map.service';
import { MaterialModule } from '../../../../../material/material.module';
import { UploadShapeFileComponent } from '../UploadShapeFile/UploadShapeFile.component';
import { LayerPreviewDefinition, MiniMapComponent } from '../add-update/data-access/services/mini-map/mini-map.component';
import { LayerDetailsComponent } from '../../ui/layer-details/layer-details.component';
import { TuiAccordion, TuiAccordionItem } from '@taiga-ui/kit';
import { ListAnimation } from 'src/app/shared/animations/animations';
import { toSignal } from '@angular/core/rxjs-interop';
import LayerGroup from 'ol/layer/Group';
import { HttpClient } from '@angular/common/http';
import { TuiLoader, TuiScrollbar } from '@taiga-ui/core';
import { TestComponentComponent } from '../../ui/test-component/test-component.component';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { StopPropagationDirective } from 'src/app/shared/directives/stop-propagation.directive';
import { CatalogueGroupWithState, CatalogueCategoryWithState, CatalogueLayer, CatalogueLayerWithState, CatalogueCategory, CatalogueGroup } from '../../data-access/models/catalogue-data.model';
import { DialogComponent } from 'src/app/shared/features/dialog/dialog.component';
import { DialogContentDirective } from 'src/app/shared/features/dialog/directives/dialog-content.directive';
import { DialogFooterDirective } from 'src/app/shared/features/dialog/directives/dialog-footer.directive';
import { DialogHeaderDirective } from 'src/app/shared/features/dialog/directives/dialog-header.directive';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';
import {TuiTabs} from '@taiga-ui/kit';
import { HostedDataComponent } from '../hosted-data/hosted-data/hosted-data.component';

export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    of(null).pipe(tap(callback), switchMap(() => source));
}


@Component({
  selector: 'app-catalogue-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    CdkAccordionModule,
    CdkListboxModule,
    TooltipDirective,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    MiniMapComponent,
    LayerDetailsComponent,
    TuiAccordion,
    TuiLoader,
    TuiScrollbar,
    TestComponentComponent,
    LayerControlComponent,
    StopPropagationDirective,
    DialogComponent,
    DialogContentDirective,
    DialogFooterDirective,
    DialogHeaderDirective,
    OverflowPaddingDirective,
    TuiTabs,
    HostedDataComponent
  ],
  templateUrl: './catalogue-dialog.component.html',
  styleUrl: './catalogue-dialog.component.css',
  animations:[ListAnimation],
  providers:[LayerControlServiceService]
})
export class CatalogueDialogComponent implements OnInit {

  private readonly customDialog: Dialog = inject(Dialog);
  private readonly httpClient: HttpClient = inject(HttpClient);


  activeTabIndex = model(0);


  groups = toSignal(this.catalogueService.groups$);



  onGroupExpanded(expanded: boolean, dataCatalogueGroup: CatalogueGroupWithState): void {
    if (!expanded) {
      return;
    }

    this.catalogueService.loadCatalogueCategoriesForGroup(dataCatalogueGroup)

  }


  onCategoryExpanded(expanded: boolean, catalogueCategory: CatalogueCategoryWithState) {
    if (!expanded) {
      return;
    }

    this.catalogueService.loadCatalogueLayers(catalogueCategory);
  }




  layerPreviewDefinition: Signal<LayerPreviewDefinition> = computed(() => {
    const selectedItem = this.selectedItem();

    if(selectedItem == null)
      {
        return null;
      }

      if ('url' in selectedItem && 'sourceType' in selectedItem) {
        const selectedLayer = selectedItem as CatalogueLayerWithState;
        return {
          url: selectedLayer.url,
          sourceType: selectedLayer.sourceType,
          attribution: selectedLayer.attribution,
          params: selectedLayer.params
        };
      }

      return null;
  });
  enableMap = computed(() => {
   return this.layerPreviewDefinition() != null ? true : false;
  });


  searchField: FormControl = new FormControl('');
  selected: FormControl<CatalogueCategoryWithState | CatalogueGroupWithState | CatalogueLayerWithState> = new FormControl<CatalogueCategoryWithState | CatalogueGroupWithState | CatalogueLayerWithState>(null);

   selectedItem = toSignal(this.selected.valueChanges.pipe(startWith(this.selected.getRawValue())))

   selectedCategory = computed(()=>{

    const selectedItem = this.selectedItem();

    if (selectedItem == null)
    {
      return null
    }

    if('dataCatalogueCategoryID' in selectedItem)
    {
      return selectedItem;
    }
      return null
   })

   selectedCategoryID = computed(()=>{

    const selectedCategory = this.selectedCategory();
    const selectedLayer = this.selectedLayer();

    if (selectedLayer !== null && selectedLayer.dataCatalogueCategoryID) {
      return selectedLayer.dataCatalogueCategoryID;
    } else if (selectedCategory !== null && selectedCategory.dataCatalogueCategoryID) {
      return selectedCategory.dataCatalogueCategoryID;
    } else {
      return null;
    }

   })
   selectedGroup = computed(()=>{

    const selectedItem = this.selectedItem();

    if (selectedItem == null)
    {
      return null
    }

    if('categories' in selectedItem)
    {
      return selectedItem;
    }
      return null
   })

   selectedLayer = computed(() => {
    const selection = this.selectedItem();

    if(selection == null)
    {
      return null;
    }

    if('dataCatalogueLayerID' in selection)
    {
      return selection;
    }

    return null;
  })

   selectedGroupID = computed(()=>{

    const selectedGroup = this.selectedGroup();
    const selectedCategory = this.selectedCategory();
    const selectedLayer = this.selectedLayer();

    if (selectedLayer !== null && selectedLayer.dataCatalogueGroupID) {
      return selectedLayer.dataCatalogueGroupID;
    } else if (selectedCategory !== null && selectedCategory.dataCatalogueGroupID) {
      return selectedCategory.dataCatalogueGroupID;
    } else if (selectedGroup !== null && selectedGroup.dataCatalogueGroupID) {
      return selectedGroup.dataCatalogueGroupID;
    } else {
      return null;
    }

   })

   title = computed(() => {
    const item = this.selectedItem();

    if(item == null)
    {
      return '';
    }

    return item.name;

  });

  onClick(data: any, selected: CatalogueCategoryWithState | CatalogueGroupWithState | CatalogueLayerWithState)
  {

    const selectedItem = this.selectedItem();
    if(selectedItem == selected && data.open)
    {
      return;
    }

    data.updateOpen(data.open ? false : true)

  }

  layerSelected = computed(() => {
    const selection = this.selectedItem();

    if(selection == null)
    {
      return false;
    }

    if('dataCatalogueLayerID' in selection)
    {
      return true;
    }

    return false;
  })

  searchTerm$: Observable<string> = this.searchField.valueChanges.pipe(
    startWith(this.searchField.value)
  );


  filteredData = toSignal(
    combineLatest([this.catalogueService.groups$, this.searchTerm$]).pipe(
      switchMap(([groups, searchTerm]) =>
        this.catalogueService.performSearch(groups,searchTerm)
      )
    )
  );


  ngOnInit(): void {

  }

  constructor(
    readonly catalogueService: DataCatalogueService,
    public dialogRef: DialogRef<string>
  ) {






    // this.filteredData$ = combineLatest([
    //   catalogueService.catalogueData$,
    //   searchTerm$,
    // ]).pipe(
    //   map(([catalogueData, searchTerm]) => {
    //     const searchTermLower = searchTerm.toLowerCase();

    //     return catalogueData.reduce((acc, group) => {
    //       const isGroupNameMatch = group.name
    //         .toLowerCase()
    //         .includes(searchTermLower);
    //       let groupToAdd = null;

    //       if (isGroupNameMatch) {
    //         groupToAdd = group;
    //       } else {
    //         const filteredCategories = group.categories.reduce(
    //           (matchingCategories, category) => {
    //             const isCategoryNameMatch = category.name
    //               .toLowerCase()
    //               .includes(searchTermLower);
    //             const matchingLayers = category.layers.filter((layer) =>
    //               layer.name.toLowerCase().includes(searchTermLower)
    //             );

    //             if (isCategoryNameMatch || matchingLayers.length > 0) {
    //               matchingCategories.push({
    //                 ...category,
    //                 layers: isCategoryNameMatch
    //                   ? category.layers
    //                   : matchingLayers,
    //               });
    //             }

    //             return matchingCategories;
    //           },
    //           []
    //         );

    //         if (filteredCategories.length > 0) {
    //           groupToAdd = {
    //             ...group,
    //             categories: filteredCategories,
    //           };
    //         }
    //       }

    //       if (groupToAdd) {
    //         acc.push(groupToAdd);
    //       }

    //       return acc;
    //     }, []);
    //   })
    // );


  }

  editLayer(layer: CatalogueLayerWithState): void {


    const instance = this.customDialog.open(
      UpdateCatalogueLayerDialogComponent,
      {
        container: CustomDialogContainer,
        data: layer,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        switchMap((value) => {
          if (value.create) {
            return this.catalogueService
              .updateLayer(layer.dataCatalogueLayerID, value.model)
              .pipe(map(() => value.model.DataCatalogueCategoryID));
          } else if (value.model !== null) {
            return value.model;
          }
        })
      )
      .subscribe((model: number) => {
      });
  }

  addGroup() {
    const instance = this.customDialog.open(
      CreateCatalogueGroupDialogComponent,
      {
        container: CustomDialogContainer,
      }
    ).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueGroup>) =>
          this.catalogueService.createGroup(event.model)
        )
      )
      .subscribe();
  }

  updateGroup(group: CatalogueGroup) {
    const instance = this.customDialog.open(
      UpdateCatalogueGroupDialogComponent,
      {
        container: CustomDialogContainer,
        data: group,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        filter((event: CreateEvent<UpdateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<UpdateCatalogueGroup>) =>
          this.catalogueService
            .updateGroup(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe();
  }

  addCategory(dataLayerGroupID: string) {
    const instance = this.customDialog.open(
      CreateCatalogueCategoryDialogComponent,
      {
        container: CustomDialogContainer,
        data: { dataCatalogueGroupID: dataLayerGroupID },
      }
    ).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueCategory>) =>
          this.catalogueService
            .createCategory(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe();
  }

  updateCategory(category: CatalogueCategory) {
    const instance = this.customDialog.open(
      UpdateCatalogueCategoryDialogComponent,
      {
        container: CustomDialogContainer,
        data: category,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        filter((event: CreateEvent<UpdateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<UpdateCatalogueCategory>) =>
          this.catalogueService
            .updateCategory(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe((model) => {

      });
  }

  addLayer() {

    const instance = this.customDialog.open(
      CreateCatalogueLayerDialogComponent,
      {
        container: CustomDialogContainer,
        data: {
          category: this.selectedCategoryID(),
          group: this.selectedGroupID(),
        },
        disableClose: true,
      }
    ).componentInstance;

    instance.createEvent
    .pipe(
      filter((event: CreateEvent<CreateCatalogueLayer>) => event.create),
      switchMap((event: CreateEvent<CreateCatalogueLayer>) =>
        this.catalogueService
          .createLayer(event.model).pipe(
            map((catalogueLayerID: number) => ({ catalogueLayerID, model: event.model }))
          )
      ),
      switchMap((data: { catalogueLayerID: number, model: CreateCatalogueLayer }) =>
        this.catalogueService.getLayer(data.catalogueLayerID)
      )
    )
    .subscribe();
  }

  uploadShapeFile() {
    this.customDialog.open(UploadShapeFileComponent, {
      container: CustomDialogContainer,
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
