<mat-table  [dataSource]="dataSource()" class="rule-table-class">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(element) : null"
        [checked]="selection.isSelected(element)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="move">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <button mat-icon-button (click)="moveUp(i)" [disabled]="i === 0">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="moveDown(i)"
        [disabled]="i === dataSource().data.length - 1"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Icon Column -->
  <ng-container matColumnDef="icon">
    <mat-header-cell *matHeaderCellDef> Style Preview </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <legend-icon (click)="openSymbolizerEditor(element)" [rule]="element"></legend-icon>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Style Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <input matInput placeholder="Filter" [(ngModel)]="element.name" />
    </mat-cell>
  </ng-container>

  <!-- Filter Column -->
  <ng-container matColumnDef="filter">
    <mat-header-cell *matHeaderCellDef> Filter </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <input matInput placeholder="Filter" [(ngModel)]="element.filter" />
    </mat-cell>
  </ng-container>

  <!-- Min Scale Column -->
  <ng-container matColumnDef="minScale">
    <mat-header-cell *matHeaderCellDef> Min. Scale </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <input matInput placeholder="Min. Scale" [(ngModel)]="element.minScale" />
    </mat-cell>
  </ng-container>

  <!-- Max Scale Column -->
  <ng-container matColumnDef="maxScale">
    <mat-header-cell *matHeaderCellDef> Max. Scale </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <input matInput placeholder="Max. Scale" [(ngModel)]="element.maxScale" />
    </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button
      (click)="openSymbolizerEditor(element)"
      class="button button--icon"
    >
      <i class="fa fa-pencil"></i>
    </button>

    </mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<div class="flex gap--xs">
  <button
(click)="addRule()"
class="button button--action  button--green-fill"
>
<i class="fa fa-plus"></i>
</button>
<button
(click)="removeRules()"
class="button button--action  button--danger"
[disabled]="selection.selected.length === 0"
>
<i class="fa fa-minus"></i>
</button>
</div>



