<div class="title-bar">
  <div class="title">Create Style</div>
  <div class="window-controls">
    <button (click)="cancel()"><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <div class="form-container">
    <mat-tab-group>
      @for (item of styleMap | keyvalue; track item.key) {

        <mat-tab [label]="item.value.kind">
          <div style="padding-block:var(--size-2)" >
            <form #form="ngForm">
              <mat-form-field appearance="outline">
                <mat-label>Kind</mat-label>
                <mat-select name="kind" id="kind" [(ngModel)]="item.value.kind">
                  <mat-option *ngFor="let option of options()" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>

            @if (item.value.kind === "Line") {

            <app-line-style-form
              id="{{ item.key }}"
              [styleDefinition]="item.value"
              (styleChanged)="styleChanged(item.key, $event)"
            ></app-line-style-form>
            } @else if(item.value.kind === "Fill") {
            <app-polygon-style-form
              id="{{ item.key }}"
              [styleDefinition]="item.value"
              (styleChanged)="styleChanged(item.key, $event)"
            ></app-polygon-style-form
            >}@else if (item.value.kind === "Mark") {

            <app-point-style-form
              id="{{ item.key }}"
              [styleDefinition]="item.value"
              (styleChanged)="styleChanged(item.key, $event)"
            ></app-point-style-form>
            } @else if (item.value.kind === "Text") {

            <app-text-style-form
              id="{{ item.key }}"
              [styleDefinition]="item.value"
              (styleChanged)="styleChanged(item.key, $event)"
            ></app-text-style-form>
            }
          </div>
        </mat-tab>

      }
      </mat-tab-group>


  </div>

<div class="footer"></div>
