<div #map class="mini-map"></div>
@if (loading()) {
  <div class="loading-overlay">
    <div class="loading-spinner"></div>
    @if (error()) {
      <p>Error!</p>
    }
    @else {
      <p>Loading...</p>
    }
  </div>
}
