import { CommonModule } from '@angular/common';
import { Component, computed, effect, Inject, inject, OnInit, output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { CatalogueLayerWithState } from '../../../data-catalogue/data-access/models/catalogue-data.model';
import { CreatePropertyViewDTO, CreatePropertyViewLayerDTO } from '../../data-access/models/property-view-model';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiCheckbox, TuiSwitch,  } from '@taiga-ui/kit';
import { DataCatalogueService } from '../../../data-catalogue/data-access/services/data-catalogue.service';
import { toSignal } from '@angular/core/rxjs-interop';
import {TuiInputModule} from '@taiga-ui/legacy';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Ok, Result } from 'ts-results';
import { DialogComponent } from 'src/app/shared/features/dialog/dialog.component';
import { DialogContentDirective } from 'src/app/shared/features/dialog/directives/dialog-content.directive';
import { DialogFooterDirective } from 'src/app/shared/features/dialog/directives/dialog-footer.directive';
import { DialogHeaderDirective } from 'src/app/shared/features/dialog/directives/dialog-header.directive';

@Component({
  selector: 'app-CreatePropertyView',
  templateUrl: './CreatePropertyView.component.html',
  styleUrls: ['./CreatePropertyView.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TuiCheckbox, TuiSwitch, TuiInputModule, DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective],
})
export class CreatePropertyViewComponent implements OnInit {

  submit = output<Result<CreatePropertyViewDTO, Error>>()

  onClose()
  {
    this.dialogRef.close();
  }

  private readonly catalogueService:DataCatalogueService = inject(DataCatalogueService);
  private readonly dialogRef: DialogRef<CreatePropertyViewComponent> = inject(DialogRef<CreatePropertyViewComponent>);

  layers = toSignal(this.catalogueService.addedLayers$);

  controlGroups = computed(() => {
    const layers = this.layers();
    const controls = [];

    layers.forEach(layer => {
      const control =  this.createLayerControl(layer);
      controls.push(control);
    })

    return controls;
  })

  controlsAdded = effect(() => {

    const controls = this.controlGroups();
    const selectedLayersControl = this.layerForm.get('selectedLayers') as FormArray;

    controls.forEach((control) => {
      selectedLayersControl.push(control)
    });

  });

  layerForm: FormGroup;

  constructor(private fb: FormBuilder, @Inject(DIALOG_DATA) public data: {propertyID: string}) {
    this.layerForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      selectedLayers: this.fb.array([]),
    });
  }

  ngOnInit(): void {
  }


  private createLayerControl(layer: CatalogueLayerWithState) {


    const control = new FormGroup({
      layer: new FormControl(layer),
      isSelected: new FormControl(true),
      isVisible:new FormControl( layer.layer.getVisible()),
      opacity: new FormControl(layer.layer.getOpacity())
    });

    return control;
  }

  get selectedLayers(): FormArray {
    return this.layerForm.get('selectedLayers') as FormArray;
  }

  onSubmit(): void {

    const selectedLayerData = this.selectedLayers.controls
    .filter(control => control.get('isSelected')?.value)
    .map(control => {
      const layer = control.get('layer')?.value as CatalogueLayerWithState;
      const isVisible = control.get('isVisible')?.value as boolean;
      const opacity = control.get('opacity')?.value as number;

      return {
        dataCatalogueLayerID: layer.dataCatalogueLayerID,
        isVisible: isVisible,
        opacity: opacity
      } as CreatePropertyViewLayerDTO;
    });

    const createPropertyViewDTO: CreatePropertyViewDTO = {
      propertyID: this.data.propertyID,
      name: this.layerForm.get('name')?.value,
      layers: selectedLayerData
    };

      this.submit.emit(Ok(createPropertyViewDTO));
  }
}

