
<div
  class="absolute top-6 left-1/2 transform -translate-x-1/2 xl:left-16 xl:translate-x-0 w-72 z-50 bg-white bg-opacity-80 rounded-md shadow-md"
>

  <div class="relative flex items-center p-3">

    <input
      class="flex-grow px-4 py-2 pr-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
      [(ngModel)]="searchText"
      type="text"
      placeholder="Enter Address"
      (keyup)="inputChanged()"
    />


    <button
      *ngIf="searchText"
      type="button"
      (click)="clear()"
      class="absolute inset-y-0 right-5 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
      aria-label="Clear"
    >
      <i class="fa fa-times"></i>
    </button>

  </div>


  <ul class="max-h-48 overflow-y-auto text-sm" overflowPadding>
    <li
      *ngFor="let result of suggestionList"
      (click)="search(result.text, result.magicKey)"
      class="px-3 py-2 cursor-pointer border-b border-gray-300 hover:bg-gray-100"
    >
      {{ result.text }}
    </li>
  </ul>
</div>
