import { Component, EventEmitter, TemplateRef, ViewContainerRef, effect, inject, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { combineLatest, filter, map, switchMap, tap } from 'rxjs';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { EditPropertyComponent } from '../edit-property/edit-property.component';
import { PropertiesService } from '../../data-access/properties.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { ConvertFeatureToWKT, Union } from 'src/app/shared/helpers/transformations';
import { Feature } from 'ol';
import { SelectPropertyCadastreOverlayComponent } from '../select-property-cadastre-overlay/select-property-cadastre-overlay.component';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { CreatePropertyEvent, DigitizePropertyOverlayComponent } from '../digitize-property-overlay/digitize-property-overlay.component';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { PropertiesState } from '../../data-access/state';
import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActionPortalDirective } from 'src/app/shared/features/action-portal/directives/action-portal.directive';
@Component({
  selector: 'app-create-property',
  standalone: true,
  imports: [CommonModule, DigitizePropertyOverlayComponent,SelectPropertyCadastreOverlayComponent, DialogModule, ToolbarComponent, TooltipDirective, ActionPortalDirective],
  templateUrl: './create-property.component.html',
  styleUrl: './create-property.component.css'
})
export class CreatePropertyComponent {


  private readonly componentFactoryService: ComponentFactory = inject(ComponentFactory);

  private readonly sidebarService: SidebarService = inject(SidebarService);

  private readonly toastService: ToastService = inject(ToastService);

  private readonly propertiesState = inject(PropertiesState);

  private readonly modalService: ModalService = inject(ModalService);

  private overlay: Overlay = inject(Overlay);

  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);



  digitizeTemplateRef = viewChild<TemplateRef<any>>('digitizeTemplate');
  cadastreSelectTemplateRef  = viewChild<TemplateRef<any>>('cadastreSelectTemplate');

  overlayRef = this.overlay.create({
  });



activeToolbar = signal<'cadastre' | 'digitize' | null>(null);


constructor() {

   effect(() => {
      const digitizeTemplateRef = this.digitizeTemplateRef();
      const cadastreSelectTemplateRef = this.cadastreSelectTemplateRef();
      const activeToolbar = this.activeToolbar();


      if(this.overlayRef.hasAttached())
        this.overlayRef.detach();


      if(activeToolbar == null)
      {
        return;
      }


      if(activeToolbar === 'digitize')
        {
          const toolbarPortal = new TemplatePortal(digitizeTemplateRef, this.viewContainerRef);
          const toolbarRef = this.overlayRef.attach(toolbarPortal);
        }
        else if (activeToolbar === 'cadastre')
        {
          const toolbarPortal = new TemplatePortal(cadastreSelectTemplateRef, this.viewContainerRef);
          const toolbarRef = this.overlayRef.attach(toolbarPortal);
        }


    })
}


  showToolbar(option:'digitize' | 'cadastre')
  {
    this.activeToolbar.set(option);
  }


   handleCreateEvent(event: CreatePropertyEvent)
  {
    this.modalService.showConfirmation(event.create ? "Are you sure you want to create this property?" : "Are you sure you want to cancel?").pipe(
      map(result => ({ result, event })),
  tap(({ result, event }) => {

    this.activeToolbar.set(null);

    if(result.err)
    {
      return;
    }
    if (!result.val && !event.create) {
      return;
    }
    else if (result.val && !event.create) {

      this.sidebarService.open();
      return;
    }
    else if(!result.val && event.create)
    {
      this.sidebarService.open();
      return;
    }

    this.sidebarService.open();
    this.propertiesState.create(event.feature);

  }))
.subscribe();


  }


}
