import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActionPortalService } from '../services/action-portal.service';

@Directive({
  selector: '[actionPortal]',
  standalone:true
})
export class ActionPortalDirective implements OnInit, OnDestroy {
  private actionPortal: TemplatePortal<any> | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private actionPortalService: ActionPortalService
  ) {}

  ngOnInit() {
    this.actionPortal = new TemplatePortal(this.templateRef, this.viewContainerRef);
    this.actionPortalService.setPortal(this.actionPortal);
  }

  ngOnDestroy() {
    this.actionPortalService.setPortal(null);
  }
}
