import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[stopPropagation]',
  standalone:true
})
export class StopPropagationDirective {
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
  }

  // Stops input event propagation (e.g., text input, slider)
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    event.stopPropagation();
  }

  // Stops change event propagation (e.g., checkbox, radio, select)
  @HostListener('change', ['$event'])
  onChange(event: Event): void {
    event.stopPropagation();
  }

  // Stops other relevant events (e.g., focus, blur)
  @HostListener('focus', ['$event'])
  onFocus(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('blur', ['$event'])
  onBlur(event: Event): void {
    event.stopPropagation();
  }
}
