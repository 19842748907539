import { Overlay, OverlayConfig } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { tap } from "rxjs";
import { SelectPropertyOverlayComponent } from "../features/property/ui/select-property-overlay/select-property-overlay.component";


@Injectable({
  providedIn: 'root'
})
export class MapOverlayService {

  private readonly overlay: Overlay = inject(Overlay);

  showPropertySelect(pixelLocation, properties)
  {
    const overlayRef = this.overlay.create(this.getOverlayConfig(pixelLocation));

    const overlayComponent = new ComponentPortal(SelectPropertyOverlayComponent);

    let ref = overlayRef.attach(overlayComponent);

    ref.setInput('properties', properties);


    overlayRef.backdropClick().subscribe(() => overlayRef.dispose())
    return ref.instance.propertySelected.pipe(tap(() => overlayRef.dispose()))
  }

  private getOverlayConfig(pixel): OverlayConfig {
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x: pixel[0] + 10, y: pixel[1] })
    .withPositions([
      {
        originX: 'center',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center'
      }
    ]);

      const scrollStrategy = this.overlay.scrollStrategies.reposition();


      return new OverlayConfig({
        positionStrategy: positionStrategy,
        scrollStrategy: scrollStrategy,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
    });

  }

}
