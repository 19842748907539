import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewRef,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { SignalRService } from '../../services/signalR.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  EMPTY,
  NEVER,
  combineLatest,
  delay,
  delayWhen,
  filter,
  from,
  fromEvent,
  fromEventPattern,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import OlMap from 'ol/Map';
import { Feature, View } from 'ol';
import { Attribution, ScaleLine } from 'ol/control';
import { transform } from 'ol/proj';
import { BasemapsService } from 'src/app/map/data-access/Basemaps.service';
import LayerGroup from 'ol/layer/Group';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { PropertiesService } from 'src/app/map/features/property/data-access/properties.service';
import { transformExtent } from 'ol/proj';
import { FeatureLayersService } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { Subject } from '@microsoft/signalr';
import { CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
import { PdfMapScaleComponent } from '../ui/pdf-map-scale/pdf-map-scale.component';
import { PdfPropertyDetailsComponent } from '../ui/pdf-property-details/pdf-property-details.component';
import { PdfDraggableComponent } from '../ui/pdf-draggable/pdf-draggable.component';
import { PdfDisclaimerComponent } from '../ui/pdf-disclaimer/pdf-disclaimer.component';
import { PdfEoiComponent } from '../ui/pdf-eoi/pdf-eoi.component';
import { PdfTitleComponent } from '../ui/pdf-title/pdf-title.component';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { basemapDefinitions } from 'src/app/map/util/basemaps';
import { Fill, Stroke, Style } from 'ol/style';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { LegendIconComponent } from 'src/app/shared/components/style-renderer/legend-icon/legend-icon.component';
import { PropertyLayers } from 'src/app/map/features/property/data-access/spatial';
import { GeneratePDFState } from './state';
import { Spatial } from './spatial';
import { filterNil } from 'ngxtension/filter-nil';
import { getMapBoxInPaper } from './util';
@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CdkDrag,
    PdfMapScaleComponent,
    PdfPropertyDetailsComponent,
    PdfDraggableComponent,
    PdfDisclaimerComponent,
    PdfEoiComponent,
    PdfTitleComponent,
    SafeHtmlPipe,
    LegendIconComponent
  ],
  providers: [GeneratePDFState,PropertyLayers, Spatial],
})
export class PdfComponent implements OnInit, AfterViewInit {


  private renderer: Renderer2 = inject(Renderer2);
  state = inject(GeneratePDFState)
  spatial = inject(Spatial);

  mapElement = viewChild.required<ElementRef<HTMLElement>>('map');


  sheetElement = viewChild.required<ElementRef<HTMLElement>>('sheet');

  mapEffect = effect(() => {
    const mapElement = this.mapElement();

    if(mapElement == null)
      return;

  this.spatial.map.setTarget(mapElement.nativeElement);

  })

  setupPaperEffect = effect(() => {
    const paperSize = this.state.paperDefinition().size;
    const paperOrientation = this.state.paperDefinition().orientation;

    this.renderer.removeClass(document.body, paperSize == 'A4' ? 'A3' : 'A4');
    this.renderer.removeClass(
      document.body,
      paperOrientation == 'Landscape' ? 'Portrait' : 'Landscape'
    );
    this.renderer.addClass(document.body, paperSize);
    this.renderer.addClass(document.body, paperOrientation);
  });

  constructor(private injector: Injector) {

      combineLatest([toObservable(this.mapElement).pipe(filterNil()),toObservable(this.sheetElement).pipe(filterNil()),this.state.paperDefinition$]).pipe(takeUntilDestroyed()).subscribe(([mapElement,sheetElement,paperDefinition]) => {

        const mapBoxInPaper = getMapBoxInPaper(mapElement.nativeElement, sheetElement.nativeElement,paperDefinition)

        this.state.setMapInPaperCoordinates(mapBoxInPaper)
      });

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {}



}
