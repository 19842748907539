import { inject, Injectable } from '@angular/core';
import { View } from 'ol';
import OlMap from 'ol/Map';
import { transform } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import { BasemapsService } from '../../../../../../data-access/Basemaps.service';
import { Layer } from 'ol/layer';
import { DataCatalogueService } from '../../../../data-access/services/data-catalogue.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MiniMapService {
  validity$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean>(
    null
  );
  dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataLoaderError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly baseMapsService: BasemapsService = inject(BasemapsService);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  private readonly initialCenter = [146.6, -30.83];
  private readonly initialZoom = 3;
  private layerToValidate: Layer<any> | null = null;
  private layerToPreview: Layer<any> | null = null;

  map!: OlMap;
  constructor() {
    this.init();
  }

  init() {
    this.map = new OlMap({
      layers: [],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.initialZoom,
        maxZoom: 23,
      }),
      controls: [],
    });

    this.setupBaseMaps();
    this.map.on('rendercomplete', () => {
      this.map.updateSize();
    });
  }

  async setupBaseMaps() {
    let layers = await this.baseMapsService.buildLayers();
    let baseMapsGroup = new LayerGroup({
      properties: { title: 'Background Maps' },
      layers: layers,
    });
    this.map.getLayers().insertAt(0, baseMapsGroup);
  }

  setTarget(element: any) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  validateNewLayer(
    url: string,
    sourceType: string,
    attribution?: string,
    params?: object
  ): void {
    if (this.layerToValidate !== null) {
      this.map.getLayers().remove(this.layerToValidate);
    }
    switch (sourceType) {
      case 'VectorImage':
      case 'Vector':
      case 'WFS':
      case 'ArcGISFeatureService':
        this.layerToValidate = this.dataCatalogueService.getVectorImageLayer(
          url,
          sourceType,
          params
        );
        this.layerToValidate.getSource().on('featuresloadend', () => {
          this.validity$.next(true);
        });
        this.layerToValidate.getSource().on('featuresloaderror', () => {
          this.validity$.next(false);
        });
        break;
      case 'TileWMS':
        this.layerToValidate = this.dataCatalogueService.getTileWMSLayer(
          url,
          params
        );
        this.layerToValidate.getSource().on('tileloadend', () => {
          this.validity$.next(true);
        });
        this.layerToValidate.getSource().on('tileloaderror', () => {
          this.validity$.next(false);
        });
        break;
      case 'ArcGISRest':
        this.layerToValidate = this.dataCatalogueService.getTileArcGISRestLayer(
          url,
          params
        );
        this.layerToValidate.getSource().on('tileloadend', () => {
          this.validity$.next(true);
        });
        this.layerToValidate.getSource().on('tileloaderror', () => {
          this.validity$.next(false);
        });
        break;
      case 'XYZ':
        this.layerToValidate = this.dataCatalogueService.getTileXYZLayer(
          url,
          attribution
        );
        this.layerToValidate.getSource().on('tileloadend', () => {
          this.validity$.next(true);
        });
        this.layerToValidate.getSource().on('tileloaderror', () => {
          this.validity$.next(false);
        });
        break;
      default:

        break;
    }
    this.map.addLayer(this.layerToValidate);
  }

  showInCatalogueMiniMap(
    url: string,
    sourceType: string,
    attribution?: string,
    params?: object
  ): void {
    this.dataLoaded$.next(false);
    this.dataLoaderError$.next(false);
    if (this.layerToPreview !== null) {
      this.map.getLayers().remove(this.layerToPreview);
    }
    try {
      switch (sourceType) {
        case 'VectorImage':
        case 'Vector':
        case 'WFS':
        case 'ArcGISFeatureService':
          this.layerToPreview = this.dataCatalogueService.getVectorImageLayer(
            url,
            sourceType,
            params
          );
          this.layerToPreview.getSource().on('featuresloadend', () => {
            this.dataLoaded$.next(true);
          });
          this.layerToPreview.getSource().on('tileloaderror', () => {
            this.dataLoaderError$.next(true);
          });
          break;
        case 'TileWMS':
          this.layerToPreview = this.dataCatalogueService.getTileWMSLayer(
            url,
            params
          );
          this.layerToPreview.getSource().on('tileloadend', () => {
            this.dataLoaded$.next(true);
          });
          this.layerToPreview.getSource().on('tileloaderror', () => {
            this.dataLoaderError$.next(true);
          });
          break;
        case 'ArcGISRest':
          this.layerToPreview =
            this.dataCatalogueService.getTileArcGISRestLayer(url, params);
          this.layerToPreview.getSource().on('tileloadend', () => {
            this.dataLoaded$.next(true);
          });
          this.layerToPreview.getSource().on('tileloaderror', () => {
            this.dataLoaderError$.next(true);
          });
          break;
        case 'XYZ':
          this.layerToPreview = this.dataCatalogueService.getTileXYZLayer(
            url,
            attribution
          );
          this.layerToPreview.getSource().on('tileloadend', () => {
            this.dataLoaded$.next(true);
          });
          this.layerToPreview.getSource().on('tileloaderror', () => {
            this.dataLoaderError$.next(true);
          });
          break;
        default:
          break;
      }
      this.map.addLayer(this.layerToPreview);
    } catch (error) {
      this.dataLoaderError$.next(true);
    }
  }
}
