
<div class="title-bar">
  <div class="title">Create Catalogue Category</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content gap--md">
  <form class="grid grid--row gap--md" [formGroup]="form">
    <mat-form-field color="primary" appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Name">
      <mat-error *ngIf="form.get('name').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
    <mat-form-field color="primary" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea rows="4" cols="45" formControlName="description" matInput placeholder="description"></textarea>
      <mat-error *ngIf="form.get('description').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
    <mat-form-field color="primary" appearance="outline">
      <mat-label>Group</mat-label>
      <mat-select formControlName="dataCatalogueGroupID" matSelect placeholder="Select Group">
        @for (group of groupsObservable | async; track group) {
          <mat-option [value]="group.dataCatalogueGroupID">{{group.name}}</mat-option>
        }
        <mat-option (click)="createNewGroup()" ><i class="fa fa-plus"></i> Add Group</mat-option>
        <mat-error *ngIf="form.get('dataCatalogueGroupID').errors">
          <!-- You can put your email validation error messages here -->
        </mat-error>
      </mat-select>
    </mat-form-field>
  </form>
</div>


<div class="footer">
  <button type="button"  class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue" (click)="submit()"><span class="fa fa-save button-icon"></span>Submit</button>
</div>

