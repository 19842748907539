import { CommonModule } from '@angular/common';
import { Component, effect, input, OnInit, output, Signal, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ColorPickerModule } from 'ngx-color-picker';
import { startWith, map, debounceTime } from 'rxjs';
import { AnimationStyleMetadata } from '@angular/animations';
import { LegendIconComponent } from '../../../legend-icon/legend-icon.component';
import {TuiTextfield} from '@taiga-ui/core';
import {TUI_DEFAULT_INPUT_COLORS, TuiInputColorModule,TuiInputNumberModule, tuiInputNumberOptionsProvider, TuiInputModule} from '@taiga-ui/legacy';

export interface TextStyleDefinition {
  kind?: string | null;
  visibility?: boolean | null;
  label?: string | null;
  color?: string | null;
  font?: string[] | null;
}

@Component({
  selector: 'app-text-style-form',
  templateUrl: './text-style-form.component.html',
  styleUrls: ['./text-style-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ColorPickerModule,
    LegendIconComponent,
    TuiInputColorModule,
    TuiInputModule
  ],
})
export class TextStyleFormComponent implements OnInit {

  styleDefinition: Signal<TextStyleDefinition> = input();


  form!: FormGroup;
  styleChanged = output<any>();

  fontOptions = [
    'Verdana',
    'Sans-serif',
    'Courier New',
    'Lucida Console',
    'Monospace',
    'Times New Roman',
    'Georgia',
    'Serif',

  ];
  protected readonly palette = TUI_DEFAULT_INPUT_COLORS;


  preview = signal({
    name: 'null',
    symbolizers: [],
    filter: null,
  })

  constructor() {
    this.form = new FormGroup({
      kind: new FormControl('Text'),
      visibility: new FormControl(true),
      label: new FormControl('Your Label', Validators.required),
      color: new FormControl(null),
      font: new FormControl([]),
    });

    effect(() => {
      const styleDefinition = this.styleDefinition();

      Object.keys(styleDefinition).forEach((key) => {
        const control = this.form.controls[key] as FormControl;
        const value = styleDefinition[key];

        if (control == null) {
          return;
        }
        control.setValue(value, { emitEvent: true });
      });
    });

    this.form.valueChanges
      .pipe(
        startWith(this.filterNullValues(this.form.getRawValue())),
        map((values: TextStyleDefinition) => this.filterNullValues(values)),
        debounceTime(100)
      )
      .subscribe((data) => {
        this.styleChanged.emit(data);
        this.preview.update(preview => ({...preview, symbolizers: [data]}))
      });
  }

  private filterNullValues(values: any): Partial<TextStyleDefinition> {
    const filteredValues: Partial<any> = {};
    (Object.keys(values) as (keyof TextStyleDefinition)[]).forEach((key) => {
      if (values[key] !== null) {
        filteredValues[key] = values[key];
      }
    });
    return filteredValues;
  }
  ngOnInit() {}
}
