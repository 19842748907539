import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface IEndpoint {
  getAll();
  get(id: string);
  delete(id: string);
  update(id: String, model: {});
}

export class BaseEndpointService {
  protected endpoint: string;
  protected readonly http: HttpClient = inject(HttpClient);

  constructor(options: { route: string }) {
    this.endpoint = environment.apiUrl + options.route;
  }

  setResourceID(selector, id) {
    this.endpoint = this.endpoint.includes(selector)
      ? this.endpoint.replace(selector, id)
      : this.endpoint;
  }
}

function entries<T extends { [K in keyof T]: string }>(obj: T): Array<[keyof T, string]> {
  return Object.entries(obj) as Array<[keyof T, string]>;
}

type ExtractParams<S extends string> =
  S extends `${infer _Start}{${infer Param}}${infer Rest}`
    ? Param | ExtractParams<Rest>
    : never;


export class BaseEndpointResource<RouteTemplate extends string> {
  protected endpointTemplate: string;
  protected endpoint: string;
  protected readonly http: HttpClient = inject(HttpClient);
  protected headers;


  protected currentParams: Partial<{ [K in ExtractParams<RouteTemplate>]: string }> = {};

  constructor(options: { routeTemplate: RouteTemplate }) {
    this.endpointTemplate = options.routeTemplate;
    this.endpoint = environment.apiUrl + options.routeTemplate;
  }

  setResourceParams(
    params: Partial<{ [K in ExtractParams<RouteTemplate>]: string }>
  ) {
    this.currentParams = { ...this.currentParams, ...params };

    this.endpoint = environment.apiUrl + this.endpointTemplate;


    for (const [key, value] of entries(this.currentParams)) {
      this.endpoint = this.endpoint.replace(
        `{${key}}`,
        encodeURIComponent(value)
      );
    }
  }

  setAccessToken(accessToken: string)
  {
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`
    });
  }


  resetParams() {
    this.currentParams = {};
    this.endpoint = environment.apiUrl + this.endpointTemplate;
  }


  getEndpoint(): string {
    return this.endpoint;
  }
}

