import { Injectable } from '@angular/core';
import { Overlay } from 'ol';
import VectorLayer from 'ol/layer/Vector';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {

  layers: Array<VectorLayer<any>> = [];

  toolTips: Array<Overlay> = [];

  private  isActive: boolean = false;


  constructor() { }


  getIsActive()
  {
    return this.isActive;
  }

  setIsActive(bool:boolean)
  {
    this.isActive = bool;
  }
}
