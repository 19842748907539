import {
  LineSymbolizer,
  MarkSymbolizer,
  PointSymbolizer,
  FillSymbolizer,
} from 'geostyler-style';
import { Kind } from '../style-renderer.component';

export const getGeoStylerType = (
  openLayersKind: Kind
): MarkSymbolizer | PointSymbolizer | LineSymbolizer | FillSymbolizer => {
  switch (openLayersKind) {
    case 'Polygon':
      return { kind: 'Fill', color: '#a94443' };
      break;
    case 'Point':
      return { kind: 'Mark', color: '#a94443', wellKnownName: 'circle' };
      break;
    case 'LineString':
      return { kind: 'Line', color: '#a94443' };
      break;

    default:
      return { kind: 'Fill', color: '#a94443' };
      break;
  }
};
