import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatString',
  standalone: true
})
export class FormatStringPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\bId\b/, 'ID')
      .replace(/^./, (str) => str.toUpperCase());
  }
}
