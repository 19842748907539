<div class="tab tab--bottom h-8">
  @if(propertyState.propertiesLoaded())
  {
    <button appTooltip="Attribute Table" tooltipPosition="top" (click)="openBottomSheet()"><span><i class="fa fa-lg fa-table"></i></span></button>
  }
  @else
  {
    <tui-loader class="inline-flex">

    </tui-loader>
  }
</div>
