<p><strong>Description:</strong>{{layer().description}}</p>
<p><strong>Layer Type:</strong> {{layer().layerType}}</p>
<p><strong>Source Type:</strong>{{layer().sourceType}}</p>
<p> <a [href]="layer().url" target="_blank">Map Server Link</a></p>
<p><strong>Attribution:</strong>{{layer().attribution}}</p>
<div class="checkboxes">
  <p><strong>Added By Default:</strong> {{ layer().addedByDefault ? 'Yes' : 'No' }}</p>
  <p><strong>Visible By Default:</strong> {{ layer().visibleByDefault ? 'Yes' : 'No' }}</p>
</div>
<!-- <div *ngIf="layer.attributes">
  <h3>Attributes</h3>
  <ul>
    <li>
      <strong>{{ layer.attributes.attributeName }}:</strong>
      {{ layer.attributes.attributeValue }}
    </li>
  </ul>
</div> -->
<p><strong>Group:</strong>{{layer().dataCatalogueGroup}}</p>
<p><strong>Category:</strong>{{layer().dataCatalogueCategory}}</p>