import {
  Component,
  computed,
  EventEmitter,
  Inject,
  inject,
  Output,
  Signal,
} from '@angular/core';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { MatError } from '@angular/material/form-field';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SharedModule } from '../../../../../../../../shared/shared.module';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import { MatOption } from '@angular/material/autocomplete';
import { toSignal } from '@angular/core/rxjs-interop';
import { WMSCapability } from 'src/app/map/features/data-catalogue/data-access/models/getcapabilities.model';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl } from '@angular/forms';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import {DialogComponent, DialogHeaderDirective, DialogContentDirective,DialogFooterDirective} from 'src/app/shared/features/dialog';

@Component({
  selector: 'app-list-wms-get-capabilities-layers',
  standalone: true,
  imports: [
    AsyncPipe,
    MatError,
    MatRadioButton,
    SharedModule,
    JsonPipe,
    MatOption,
    MatRadioGroup,
    InputTextModule,
    DialogComponent,
    DialogHeaderDirective,
    DialogFooterDirective,
    DialogContentDirective,
    CommonModule
  ],
  templateUrl: './list-wms-get-capabilities-layers.component.html',
  styleUrl: './list-wms-get-capabilities-layers.component.css',
})
export class ListWmsGetCapabilitiesLayersComponent {
  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  @Output() selectionEvent: EventEmitter<any> = new EventEmitter();
  capabilities: Signal<WMSCapability[]>;
  selectedLayer: WMSCapability;
  searchField: FormControl = new FormControl('');
  filteredCapabilities: Signal<WMSCapability[]>;

  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) data) {
    this.capabilities = toSignal(
      this.catalogueService.WMSGetCapabilities(data.url),
      { initialValue: null }
    );

    const searchFieldChanges = toSignal(
      this.searchField.valueChanges.pipe(map((value) => value.toLowerCase())),
      {
        initialValue: '',
      }
    );

    this.filteredCapabilities = computed(() =>
      this.capabilities().filter((cap) =>
        cap.title.toLowerCase().includes(searchFieldChanges())
      )
    );
  }

  cancel(): void {
    this.selectionEvent.emit(CreateEvent.cancel<any>());
    this.dialogRef.close();
  }

  setSelection(): void {
    this.selectionEvent.emit(
      CreateEvent.create<WMSCapability>(this.selectedLayer)
    );
    this.dialogRef.close();
  }

  get isDisabled(): boolean {
    return !this.selectedLayer;
  }
}
