import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[eskDialogContent]',
  standalone:true
})
export class DialogContentDirective {

  constructor(public templateRef: TemplateRef<any>) {}

}
