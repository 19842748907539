import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/features/dialog/dialog.component';
import { DialogContentDirective } from 'src/app/shared/features/dialog/directives/dialog-content.directive';
import { DialogFooterDirective } from 'src/app/shared/features/dialog/directives/dialog-footer.directive';
import { DialogHeaderDirective } from 'src/app/shared/features/dialog/directives/dialog-header.directive';
import { Err, Ok, Result } from 'ts-results';


@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
  standalone:true,
  imports:[CommonModule,DialogComponent,DialogContentDirective,DialogFooterDirective,DialogHeaderDirective]
})
export class DeleteConfirmationComponent {

  constructor(public dialogRef: DialogRef<Result<boolean,Error>,DeleteConfirmationComponent>,@Inject(DIALOG_DATA) public data: {type: string, details:string}) {

    if (!this.data) {
      this.data = {type: '{{entityType}}', details: '{{EntityDetails}}'};
  }
  }

  onCancel(): void {

    this.dialogRef.close(Err(new Error("Canceled")))
  }

  onConfirm(): void {
    this.dialogRef.close(Ok(true))
  }
}
