import { effect, inject, untracked } from "@angular/core";
import { createInjectable } from "ngxtension/create-injectable";
import { signalSlice } from "ngxtension/signal-slice";
import { BehaviorSubject, catchError, combineLatest, concatMap, delay, delayWhen, distinct, EMPTY, filter, finalize, flatMap, from, fromEvent, map, mergeMap, Observable, of, pairwise, startWith, Subject, switchMap, take, tap } from "rxjs";
import { AuthService } from "src/app/@core/Auth/auth.service";
import { PropertyClassService } from "src/app/@core/property-class/data-access/services/property-class.service";
import { SignalRService } from "src/app/@core/services/signalR.service";
import { addPropertyFeatures, createPropertyClassLayer, PropertyLayers, removePropertyFeatures } from "./spatial";
import { PropertyClass } from "src/app/@core/property-class/data-access/models/property-class.model";
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import { toObservable } from "@angular/core/rxjs-interop";
import { Property } from "./models/property.model";
import { PropertiesService } from "./properties.service";
import { Style, Stroke, Fill } from "ol/style";
import { create } from "lodash";
import { Feature } from "ol";
import { ConvertFeatureToWKT } from "src/app/shared/helpers/transformations";
import { FeatureLayersService } from "../features/feature-layers/data-access/feature-layers.service";
import { ModalService } from "src/app/@core/services/modal/modal.service";
import { EditPropertyComponent } from "../features/edit-property/edit-property.component";
import { Result } from "ts-results";
import { addPropertyFeaturesOperator, deletePropertyFeaturesOperator, deletePropertyStateOperator, processProperty, processPropertyOperator, updatePropertyFeaturesOperator, updatePropertyStateOperator, updateStateOperator } from "./utils";
import { signalSlice2 } from "../signal-slice";

const INITIAL_STATE = {
  properties: [],
  propertyMap: {},
  propertyClasses: {},
  propertiesLoaded: false,
  propertyClassesLoaded: false,
  activePropertyID: null,
  activeProperty: null,
  createdProperty: null
};

export const PropertiesState = createInjectable(() => {

  const authService = inject(AuthService);
  const signalR = inject(SignalRService);
  const propertyClassService = inject(PropertyClassService);
  const propertiesAPI = inject(PropertiesService);
  const propertyLayers = inject(PropertyLayers);
  const modalService = inject(ModalService);


  const propertyAdded = signalR.ensureConnected().pipe(filter(connected => connected),switchMap( () =>
    fromEvent(signalR.hubConnection,'PropertyCreated')));

  const propertyUpdated = signalR.ensureConnected().pipe(filter(connected => connected),switchMap( () =>
    fromEvent(signalR.hubConnection,'PropertyUpdated')));


  const propertyDeleted = signalR.ensureConnected().pipe(filter(connected => connected),switchMap( () =>
    fromEvent(signalR.hubConnection,'PropertyDeleted')));


  const propertiesState = signalSlice2({
    initialState: INITIAL_STATE,

    sources:[
      (state) =>
      authService.isLoggedIn$.pipe(distinct(),switchMap(isLoggedIn => {

        if(!isLoggedIn)
        {
          return EMPTY;
        }


        return state.propertyClassesLoaded$.pipe(
          filter(ready => ready),
          take(1),
          switchMap(() => signalR.ensureConnected()),
          switchMap((connected) => {

            if(!connected)
              return EMPTY;


          return signalR.streamProperties().pipe(finalize(() => {
            propertiesState.loaded()
          }));
          }))
      }),  map((property: Property) =>
        processProperty(property)),addPropertyFeaturesOperator({state,propertyLayers}),updateStateOperator({state})),

        propertyClassService.getAll().pipe(map(propertyClasses => {
        return propertyClasses.reduce((acc, propertyClass) => {
          acc[propertyClass.propertyClassID] = createPropertyClassLayer(propertyClass);
          return acc;
        }, {} as { [key: number]: {layer: VectorImageLayer<any>, source: VectorSource} });
      }),map(data => ({propertyClasses: data})),finalize(()=> {propertiesState.setPropertyClassesLoaded()})),
      (state) =>  propertyAdded.pipe(map((property: Property) => processProperty(property)),addPropertyFeaturesOperator({state,propertyLayers}),updateStateOperator({state})),
      (state) =>   propertyUpdated.pipe(
        processPropertyOperator(),
        updatePropertyFeaturesOperator({ state, propertyLayers }),
        updatePropertyStateOperator({ state })
      ),
      (state) => propertyDeleted.pipe(map((propertyID: string) => state().propertyMap[propertyID]),filter(property => property != null),deletePropertyFeaturesOperator({state, propertyLayers}),deletePropertyStateOperator({state}))
    ],
    selectors: (state) => ({
      propertyClassLayers: () => Object.entries(state.propertyClasses()).map(([id, spatial]) => spatial['layer']),
    }),

    actionSources: {
      loaded: (state, action$: Observable<void>) => action$.pipe(map(() => ({propertiesLoaded: true}))),
      setPropertyClassesLoaded: (state, action$: Observable<void>) => action$.pipe(map(() => ({propertyClassesLoaded: true}))),

      setActivePropertyID: (state, action$: Observable<string | null>) => action$.pipe(
        map((propertyID) =>  {
            return {activePropertyID: propertyID}
        }),
      ),

      setActiveProperty: (state, action$: Observable<Property>) => action$.pipe(
        map((property) => ({activeProperty: property})),
        tap(({activeProperty}) => {
          const previousActiveProperty = state().activeProperty;

          if(previousActiveProperty != null)
              previousActiveProperty.boundaryFeature.setStyle(null);

          if(activeProperty == null)
            return;

          const feature = activeProperty.boundaryFeature;

          feature.setStyle(
            new Style({
              stroke: new Stroke({
                color: 'rgba(255,0,0,1)',
                width: 1.5,
              }),
              fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
            })
          );
        })),

        create: (state, action$: Subject<Feature<any>>) =>
          action$.pipe(
            map(feature => ConvertFeatureToWKT(feature)),
            switchMap(wkt =>
              propertiesAPI.create(wkt).pipe(
              ),
            ),
            map(propertyID => ({ createdProperty: propertyID }))
          ),

        edit:  (state, action$: Observable<string>) => action$.pipe(map(propertyID => state().propertyMap[propertyID]),filter(val => val != null),switchMap((property) => modalService.showComponent<EditPropertyComponent, Result<any | void, Error>>(EditPropertyComponent,property).closed.pipe(filter(result => result.ok),map(result => ({propertyID: property.propertyID, model: result.val})))),switchMap(({propertyID, model}) => propertiesAPI.update(propertyID,model)),switchMap(() => EMPTY) ),
        delete: (state, action$: Observable<string>) => action$.pipe(

          map(propertyID => state().propertyMap[propertyID]),
          filter(property => property != null),

          switchMap((property) => modalService.showConfirmDelete('Property', property.propertyName).pipe(
            filter(result => result.ok),
            map(() => property)
          )),
          switchMap((property) => propertiesAPI.delete(property.propertyID)),
          switchMap(() => EMPTY)
        )
    }

  });


  propertiesState.activePropertyID$.pipe(pairwise(),switchMap(([prev, curr]) => {


    const leave$ = prev
    ? signalR.leaveRoom(prev)
    : of(null);

  const final =  leave$.pipe(
    switchMap(() => {
      return curr
        ? signalR.joinRoom(curr)
        : of(null);
    })
  )

  return signalR.ensureConnected().pipe(filter(connected => connected),switchMap(() => final));
  })).subscribe()

  combineLatest([toObservable(propertiesState.activePropertyID),toObservable(propertiesState.propertiesLoaded),toObservable(propertiesState.properties)]).subscribe(([activePropertyID, propertiesLoaded, properties])=> {

    if(activePropertyID == null && propertiesLoaded == false)
      return;


    if(activePropertyID == null)
      propertiesState.setActiveProperty(null);


    const activeProperty = properties.find(property => property.propertyID == activePropertyID);

    if(!activeProperty)
    {
      propertiesState.setActiveProperty(null);
    }
    propertiesState.setActiveProperty(activeProperty);
  })

  toObservable(propertiesState.propertyClassLayers).subscribe((propertyClassLayers:VectorImageLayer<any>[]) =>{
    propertyLayers.propertyClassesLayer.getLayers().extend(propertyClassLayers)
  });


  toObservable(propertiesState.createdProperty).pipe(filter(val => val != null)).subscribe(createdPropertyID => {
    propertiesState.setActivePropertyID(createdPropertyID);
    propertiesState.edit(createdPropertyID);
  })


  return propertiesState;
},);
// this.authService.isLoggedIn$.pipe(distinct()).subscribe((isLoggedIn) => {
//   if (isLoggedIn) {
//     this.getAll().subscribe();
//   } else {
//     this.clearAllData();
//     this._properties.next([]);
//   }
// });
