import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConsoleService {

messages = new BehaviorSubject([]);


constructor() { }

addMessage(message: string) {

  const currentValue = this.messages.value;

  this.messages.next([...currentValue, message]);

}



}
