<app-dialog (close)="cancel()">
 <ng-template eskDialogHeader>
  <tui-tabs [(activeItemIndex)]="activeTabIndex">
    <button
        tuiTab
    >
        Catalogue
    </button>
    <button

        tuiTab
    >
        My Data
    </button>
  </tui-tabs>
 </ng-template>
 <ng-template eskDialogContent>


  <div  class="content">
    @if (activeTabIndex() == 0) {
      <div class="data-catalogue">
        <input
          [formControl]="searchField"
          type="text"
          pInputText
          placeholder="Search"
         class="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
        <tui-scrollbar >
        <tui-accordion style="max-height: 500px;" overflowPadding  [@listAnimation]="groups()">
          @for (group of filteredData(); track group.dataCatalogueGroupID) {
            <tui-accordion-item [noPadding]="true" (openChange)="onGroupExpanded($event, group)" #groupAccordionItem >
              <app-test-component [formControl]="selected" [value]="group" (clicked)="onClick(groupAccordionItem,group)" >
                <span class="flex align-center gap--1">
                  <i class="fa" [ngClass]="groupAccordionItem.open ? 'fa-folder-open' : 'fa-folder'"></i>
                  <span>{{ group.name }}</span>
                </span>
              </app-test-component>
              <ng-template tuiAccordionItemContent>
                <tui-scrollbar>
                @if (group.loading()) {
                  <tui-loader></tui-loader>
                }
                @else {
                    <tui-accordion overflowPadding [@listAnimation]="group.categories" style="max-height: 500px;" class="tui-space_top-3">

                        @for (category of group.categories; track category.dataCatalogueCategoryID) {
                          <tui-accordion-item (openChange)="onCategoryExpanded($event, category)"     class="custom-accordion-item"  #categoryAccordionItem>
                            <app-test-component [formControl]="selected" [value]="category" (clicked)="onClick(categoryAccordionItem,category)">
                              <span class="flex align-center gap--1">
                                <i class="fa" [ngClass]="categoryAccordionItem.open ? 'fa-folder-open' : 'fa-folder'"></i>
                                <span>{{ category.name }}</span>
                              </span>

                            </app-test-component>

                            <ng-template tuiAccordionItemContent>
                              @if(category.loading())
                              {
                                <tui-loader></tui-loader>
                              }
                              @else {
                                <div class="example-listbox-container" [@listAnimation]="category.layers">
                                  @for (layer of category.layers; track  layer.dataCatalogueLayerID) {
                                    <app-test-component [formControl]="selected" [value]="layer">
                                      <div class="flex justify-between">
                                        <span class="flex align-center">{{layer.name}}</span>
                                        <button class="button button--action button--icon" appTooltip="Edit Layer" stopPropagation (click)="editLayer(layer)"
                                        >
                                          <i class="fa fa-pencil-alt"></i>
                                          </button>
                                      </div>
                                    </app-test-component>
                                  }
                                </div>
                              }
                            </ng-template>
                        </tui-accordion-item>
                        }


                  </tui-accordion>
                }
              </tui-scrollbar>
              </ng-template>
          </tui-accordion-item>
          }



      </tui-accordion>
      </tui-scrollbar>
      </div>

      <div class="data-catalogue_preview">
        <div class="catalogue_preview_header">
          <h3>{{ title() }}</h3>
          <div class="grid grid--column gap--xs align-center">
            @if (!layerSelected()) {
              <button
              class="button button--xl button--action button--brand"
              tooltipPosition="left"
              appTooltip="Add Layer"
              (click)="addLayer()"
            >
              <i class="fa fa-plus"></i>
            </button>
            }

            <button
              class="button button--xl button--action button--brand"
              tooltipPosition="left"
              appTooltip="Upload ShapeFile"
              (click)="uploadShapeFile()"
            >
              <i class="fa fa-upload"></i>
            </button>
          </div>
        </div>
        @if (enableMap()) {
          <div class="catalogue_preview_map">
            <mini-map [layerDefinition]="layerPreviewDefinition()"></mini-map>
          </div>
        }
        <div class="catalogue_preview_details">

          @if(selectedItem())
          {

            @if(layerSelected()) {
              <app-layer-details [layer]="selectedItem()" ></app-layer-details>

              @if (!selectedItem().added()) {

                <button
                  class="button button--brand"
                  (click)="
                    catalogueService.addLayerToMap(
                    selectedItem()
                    )
                  "
                >
                  Add Layer & Continue
                </button>
                <button
                  class="button button--brand"
                  (click)="
                    catalogueService.addLayerToMap(
                      selectedItem()
                    );
                    dialogRef.close()
                  "
                >
                  Add Layer & Close
                </button>
                }
            }

            @if (selectedItem().added()) {
              <button
              class="button button--danger"
              (click)="selectedItem().removeFn($event)"
            >
              Remove & Continue
            </button>
            <button
              class="button button--danger"
              (click)="selectedItem().removeFn($event); dialogRef.close()"
            >
              Remove & Close
            </button>
            }
          }

      </div>
      </div>
    }
    @else if(activeTabIndex() == 1)
    {
      <hosted-data></hosted-data>
    }

  </div>
   </ng-template>
</app-dialog>

