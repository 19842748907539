import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
  input,
} from '@angular/core';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { TruncateTextPipe } from 'src/app/shared/pipes/truncate-text.pipe';

interface SavedFileDTO {
  safeName: string;
  downloadUrl: string;
  unsafeName: string;
}

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
  standalone: true,
  imports: [CommonModule, TooltipDirective, TruncateTextPipe],
})
export class FileListComponent implements OnInit {
  private readonly http = inject(HttpClient);

  files = input<SavedFileDTO[]>();

  @Output() deleteFile = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  downloadFile(file: SavedFileDTO) {
    this.http
      .get(file.downloadUrl, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((response) => {
        this.saveFile(response.body, file.unsafeName);
      });
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
