import { DOCUMENT } from '@angular/common';
import {
  Directive,
  effect,
  ElementRef,
  HostListener,
  Inject,
  input,
  Input,
  OnDestroy,
  Renderer2,
  signal,
} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
  standalone: true
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') tooltipText!: string;

  tooltipPosition = input<'left' | 'right' | 'top' | 'bottom'>('right', {alias: 'position'});


  @Input('triggerOnClick') triggerOnClick: boolean = false; // Input to toggle trigger on click

  private tooltipElement!: HTMLElement | null;
  private hoverTimeoutId!: any;
  private tooltipVisible: boolean = false; // Track if the tooltip is visible

  constructor(private el: ElementRef, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {}

  isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  // Handle hover with a delay
  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent): void {
    event.stopPropagation();

    if (!this.tooltipVisible) {
      this.hoverTimeoutId = setTimeout(() => {
        if (!this.tooltipElement) {
          this.showTooltip();
        }
      }, 200);
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent): void {
    event.stopPropagation();

    // Clear the timeout when mouse leaves before the tooltip is shown
    clearTimeout(this.hoverTimeoutId);

    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  // Handle click to toggle tooltip based on the triggerOnClick input
  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    if (this.triggerOnClick) {
      event.stopPropagation();
      if (this.tooltipElement) {
        this.hideTooltip();
      } else {
        this.showTooltip();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  private showTooltip(): void {
    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.addClass(this.tooltipElement, 'esk-tooltip');
    this.renderer.addClass(this.tooltipElement, `esk-tooltip--${this.tooltipPosition()}`);
    this.renderer.setProperty(this.tooltipElement, 'textContent', this.tooltipText);

    this.renderer.appendChild(this.document.body, this.tooltipElement);

    const elRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltipElement.getBoundingClientRect();

    let top, left;
    const padding = 8;

    switch (this.tooltipPosition()) {
      case 'right':
        top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2);
        left = elRect.right + padding;
        break;
      case 'left':
        top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2);
        left = elRect.left - tooltipRect.width - padding;
        break;
      case 'top':
        top = elRect.top - tooltipRect.height - padding;
        left = elRect.left + (elRect.width / 2) - (tooltipRect.width / 2);
        break;
      case 'bottom':
        top = elRect.bottom + padding;
        left = elRect.left + (elRect.width / 2) - (tooltipRect.width / 2);
        break;
      default:
        top = elRect.top + (elRect.height / 2) - (tooltipRect.height / 2);
        left = elRect.right + padding;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);

    this.tooltipVisible = true; // Mark tooltip as visible
  }

  private hideTooltip(): void {
    this.renderer.removeChild(this.document.body, this.tooltipElement);
    this.tooltipElement = null;
    this.tooltipVisible = false; // Mark tooltip as hidden
  }
}
