import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { CatalogueLayer } from '../../data-access/models/catalogue-data.model';

@Component({
  selector: 'app-layer-details',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './layer-details.component.html',
  styleUrl: './layer-details.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerDetailsComponent { 

  layer = input.required<CatalogueLayer>();

  @HostBinding('class.layer-details') get invalid() { return true}
}
