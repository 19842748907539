import { AfterViewInit, ContentChildren, Directive, ElementRef, QueryList, Renderer2, TemplateRef } from '@angular/core';

@Directive({
  selector: '[layerControlDrag]',
  standalone:true
})
export class LayerControlDragDirective {




  constructor(public templateRef: TemplateRef<any>, private el: ElementRef, private renderer: Renderer2) {}





}
