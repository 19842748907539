import { Injectable, inject } from '@angular/core';
import { TokenService } from '../services/token.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, catchError, finalize, map, of, switchMap, tap, throwError } from 'rxjs';
import { authResponseDTO } from './models/authResponseDTO';
import { BaseEndpointService } from '../interfaces/IEndpoint';
import { Router } from '@angular/router';
import { getErrorMessage } from './models/errorMap';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseEndpointService {

  cleanHttpClient: HttpClient;

  constructor(handler: HttpBackend) {

    super({route: 'auth'});

    this.cleanHttpClient = new HttpClient(handler);
  }


  isAuthenticated(): Observable<boolean> {

    return this.http.get<{ isAuthenticated: boolean }>(this.endpoint + '/verify').pipe(
      map(response => response.isAuthenticated),
    );
  }


  login(model: {email:string, password:string}) {
    return this.http
      .post<authResponseDTO>(this.endpoint + '/login', {
      ...model
      })
  }

  refreshToken() {
    return this.cleanHttpClient
      .post<authResponseDTO>(this.endpoint + `/token/refresh`,{}, {withCredentials: true})
  }

  logout()
  {
    return this.http.get(this.endpoint + '/logout').pipe(  catchError((error) => {
      console.error('Error occurred:', error);
      return EMPTY;
    }));
  }

  handleError(errorRes)
  {
    let errorMessage = 'An unknown error occurred!'

    const errorDetail = errorRes.error != null ? errorRes.error.replaceAll(" ", "").split(":")[1] : null

    if (errorDetail) {
      errorMessage = getErrorMessage(errorDetail)
    }

    return throwError(() => new Error(errorMessage));
  }

}
