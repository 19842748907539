import { Injectable, effect, inject, signal } from '@angular/core';
import { Feature, MapBrowserEvent, View } from 'ol';
import {easeOut} from 'ol/easing'
import OlMap from 'ol/Map';
import { Attribution, ScaleLine } from 'ol/control';
import LayerGroup from 'ol/layer/Group';
import { BasemapsService } from './Basemaps.service';
import * as olProj from 'ol/proj';
import Wkt from 'ol/format/WKT';
import { transform } from 'ol/proj';
import { Select } from 'ol/interaction';
import { HttpClient } from '@angular/common/http';
import { DataCatalogueService } from '../features/data-catalogue/data-access/services/data-catalogue.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { PropertiesService } from '../features/property/data-access/properties.service';

import {
  BehaviorSubject,
  EMPTY,
  NEVER,
  Subject,
  combineLatest,
  concat,
  delay,
  delayWhen,
  fromEvent,
  map,
  of,
  pairwise,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import VectorTileLayer from 'ol/layer/VectorTile';
import { environment } from 'src/environments/environment';
import { MVT } from 'ol/format';
import VectorTileSource from 'ol/source/VectorTile';
import BaseLayer from 'ol/layer/Base';
import { VectorSourceEvent } from 'ol/source/Vector';
import { PropertiesState } from '../features/property/data-access/state';
import { PropertyEvents, PropertyLayers } from '../features/property/data-access/spatial';


@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly basemapsService: BasemapsService = inject(BasemapsService);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);

  private propertiesState = inject(PropertiesState);
  private propertyLayers = inject(PropertyLayers);
  private propertyEvents = inject(PropertyEvents);



  public popoutLayers = new BehaviorSubject<BaseLayer[]>([]);

  private readonly initialCenter = [146.6, -30.83];
  private readonly initialZoom = 4.8;
  public baseMapGroup = new LayerGroup({
    layers: [],
    name: 'Background Maps',
    fold: 'open',
  } as any);

  cadasterLayer = new VectorTileLayer({
    declutter:true,
    source: new VectorTileSource({
      format: new MVT({ featureClass: Feature, idProperty: 'cad_pid' }),
      url: environment.apiUrl + 'cadastre/{z}/{x}/{y}',
      maxZoom: 14,
      minZoom: 10,
    }),
    visible: false,
    minZoom: 10,
    style: (feature) =>    {

return  new Style({fill: new Fill({
  color: 'rgba(255,255,255,0.01)',

}),
text: new Text({
  font: '12px Calibri,sans-serif',
  fill: new Fill({ color: '#000' }),
  stroke: new Stroke({ color: '#fff', width: 2 }),
  text: feature.getId() + "",}),
stroke: new Stroke({color: 'rgba(255,74,255,1)', width: 2})
})

    }
     });

  public readonly featureMap: Map<string, Feature> = new Map();
  interaction: Select;
  public pointerCoordinate = signal(null);

  public zoomToFeatureEvent = new Subject<string>();

  selection: {} = {};

  map: OlMap = new OlMap({
    layers: [
      this.baseMapGroup,
      this.cadasterLayer,
      this.propertyLayers.PropertyLayerGroup,
    ],
    view: new View({
      center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
      zoom: this.initialZoom,
      maxZoom: 23,
    }),
    controls: [
      new Attribution(),
      new ScaleLine({
        bar: true,
        minWidth: 150,
      }),
    ],
  });

  public isZooming = fromEvent(this.map.getView(), 'change:resolution').pipe(
    map(() => this.map.getView().getZoom()),
    startWith(this.map.getView().getZoom()),
    pairwise(),
    switchMap(([prevZoom, newZoom]) => {
      if (prevZoom !== newZoom) {
        return concat(
          of(true),
          fromEvent(this.map, 'moveend').pipe(
            take(1),
            map(() => false)
          )
        );
      }
      return EMPTY;
    }));

  selectionLayer: any;

  eventKeys = new Set<EventsKey>();

  constructor() {


        effect(() => {
          const activeProperty = this.propertiesState.activeProperty();


          if(activeProperty == null)
          {
              return;
          }

          this.zoomToFeature(activeProperty.boundaryFeature);

        },{allowSignalWrites: true})

    this.init();

    this.dataCatalogueService.removeLayer.subscribe((layer) => {
      if (!layer) return;

      this.map.getLayers().remove(layer);
    });
    this.dataCatalogueService.addLayer.subscribe((layer) => {
      if (!layer) return;

      let index = this.map
        .getLayers()
        .getArray()
        .indexOf(this.propertyLayers.PropertyLayerGroup);

      this.map.getLayers().insertAt(index, layer);
    });


    this.cadasterLayer.set('title', 'Cadastre');
    this.cadasterLayer.set('poppedOut', true);
  }

  init() {


    this.setupBaseMaps();

    this.map.on('rendercomplete', (e) => {
      this.map.updateSize();
    });

    this.map.on('pointermove', (event) => {
      if (event.dragging) {
        return;
      }

      let coord = event.coordinate;

      let transformedCoord = olProj.toLonLat(coord);

      this.pointerCoordinate.set(transformedCoord);
    });
  }

  public setupMapEvents() {
    const CLICK_KEY = this.map.on('click', (e) => {
      this.propertyEvents.doubleClickHandler(e, this.map, (id) => {

      });
    });

    this.eventKeys.add(CLICK_KEY);
  }

  public resetMapView() {
    this.map
      .getView()
      .setCenter(transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'));

    this.map.getView().setZoom(this.initialZoom);

  }
  public removeMapEvents(): void {
    this.eventKeys.forEach((key) => {
      unByKey(key);
    });
    this.eventKeys.clear();
  }


  async setupBaseMaps() {
    const layers = (await this.basemapsService.buildLayers()) as any;

    layers.forEach((layer) => {
      this.baseMapGroup.getLayers().push(layer);
    });
  }

  getMap(): OlMap {
    return this.map;
  }

  setTarget(element) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  updateSize() {
    this.map.updateSize();
  }



  zoomToFeature(
    feature: Feature,
    padding: [number, number, number, number] = [60, 620, 60, 20]
  ) {
    this.map
      .getView()
      .fit(feature.getGeometry().getExtent(), { padding: padding, duration: 1500, easing: easeOut });
  }

  hideLayersForPrint() {
    this.propertyLayers.propertyClassesLayer.setVisible(false);
  }

  unhideLayers() {
    this.propertyLayers.propertyClassesLayer.setVisible(true);
  }

  getMapExtent() {
    return this.map.getView().calculateExtent();
  }

  addLayerToPopout(layer:BaseLayer)
  {
    const currentValue = this.popoutLayers.value;

    this.popoutLayers.next([...currentValue, layer]);
  }
}
