<section class="form-container">
  <header>
      <h1>Laguna Bay</h1>
  </header>
  <main>
      <form [formGroup]="loginForm"  (ngSubmit)="onSubmit()" class="login_form text-field-rounded-4">
          <mat-form-field  color="red" appearance="outline">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="example@example.com.au">
            <mat-error *ngIf="loginForm.get('email').errors">
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password">
            <mat-error *ngIf="loginForm.get('password').errors">

            </mat-error>
          </mat-form-field>

        <div class="actions">
          <button type="submit" [disabled]="!loginForm.valid" class="button button--brand button--full_width">Login</button>
        </div>

      </form>
  </main>
  <footer>
    @if(version)
    {
      <a class="forgot-password" routerLink="/forgot-password">Forgot Password?</a>
      <span class="version_information">{{version}}</span>
    }
  </footer>
</section>
