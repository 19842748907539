
<app-dialog (close)="onNoClick()">
  <ng-template eskDialogHeader>
    <h3>Confirm</h3>
  </ng-template>

  <ng-template eskDialogFooter>
    <button class="button button--muted button--xs" (click)="onNoClick()">No</button>
    <button class="button button--green-fill button--xs" (click)="onYesClick()">Yes</button>
  </ng-template>
  <ng-template eskDialogContent>
    <p [innerHtml]="displayText"></p>
  </ng-template>
</app-dialog>
