<section class="form-container">
  <header>
      <h1>Forgot Password</h1>
  </header>
  <main>

    @if (!requestSent) {
      <form [formGroup]="forgotPasswordForm"  (ngSubmit)="onSubmit()" class="forgot-password-form text-field-rounded-4">
        <mat-form-field color="red" appearance="outline">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="example@example.com.au">
        </mat-form-field>
      <div class="actions">
        <button type="submit" [disabled]="!forgotPasswordForm.valid" class="button button--brand button--full_width">Reset Password</button>
      </div>

      </form>
    }
    @else {
      <div>An email has been sent to you with instructions on how to reset your password</div>

    }



  </main>
  <footer>
    @if(version)
    {
      <a routerLink="/login"><span><i class="fa fa-arrow-left"></i></span> Back to login</a>
      <span class="version_information">{{version}}</span>
    }
  </footer>
</section>
