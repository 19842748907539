import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { PropertyViewDTO, CreatePropertyViewDTO, UpdatePropertyViewDTO, PropertyViewLayerDTO } from './models/property-view-model';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';


@Injectable({
  providedIn: 'root'
})
export class PropertyViewService extends BaseEndpointService {
  private propertyViewsSubject = new BehaviorSubject<PropertyViewDTO[]>([]);
  propertyViews$ = this.propertyViewsSubject.asObservable();

  constructor() {
    super({ route: 'property-views' });
  }


  getPropertyViews(propertyId: string): Observable<PropertyViewDTO[]> {
    return this.http.get<PropertyViewDTO[]>(`${this.endpoint}/properties/${propertyId}`).pipe(
      tap(views => this.propertyViewsSubject.next(views))
    );
  }


  getPropertyView(propertyViewId: string): Observable<PropertyViewDTO> {
    return this.http.get<PropertyViewDTO>(`${this.endpoint}/${propertyViewId}`);
  }


  createPropertyView(model: CreatePropertyViewDTO) {
    return this.http.post<string>(`${this.endpoint}`, model).pipe(
      switchMap((newId: string) => this.getPropertyView(newId)),
      tap((newView: PropertyViewDTO) => {
        const currentViews = this.propertyViewsSubject.getValue();
        this.propertyViewsSubject.next([...currentViews, newView]);
      })
    );
  }


  updatePropertyView(propertyViewId: string, model: UpdatePropertyViewDTO) {
    return this.http.put<void>(`${this.endpoint}/${propertyViewId}`, model).pipe(
      switchMap(() => this.getPropertyView(propertyViewId)),
      tap((updatedView: PropertyViewDTO) => {
        const currentViews = this.propertyViewsSubject.getValue();
        const index = currentViews.findIndex(view => view.propertyViewID === propertyViewId);
        if (index > -1) {
          currentViews[index] = updatedView;
          this.propertyViewsSubject.next([...currentViews]);
        }
      })
    );
  }


  deletePropertyView(propertyViewId: string): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${propertyViewId}`).pipe(
      tap(() => {
        const currentViews = this.propertyViewsSubject.getValue();
        const updatedViews = currentViews.filter(view => view.propertyViewID !== propertyViewId);
        this.propertyViewsSubject.next(updatedViews);
      })
    );
  }

  // Fetch layers associated with a specific property view
  getViewLayers(propertyViewId: string): Observable<PropertyViewLayerDTO[]> {
    return this.http.get<PropertyViewLayerDTO[]>(`${this.endpoint}/${propertyViewId}/layers`);
  }
}
