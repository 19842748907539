import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  Signal,
  ViewChild,
  ViewContainerRef,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { toSignal } from '@angular/core/rxjs-interop';

import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { delay, EMPTY, filter, map, of, switchMap } from 'rxjs';

import { PropertiesTableService } from './data-access/properties-table.service';
import { Dialog } from '@angular/cdk/dialog';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { SharedModule } from 'primeng/api';
import { DisplayDatePipe } from 'src/app/shared/pipes/display-date.pipe';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { PropertiesService } from '../../data-access/properties.service';
import { DraggableDirective } from 'src/app/shared/directives/draggable/draggable.directive';
import { Router } from '@angular/router';
import { PropertyClassService } from 'src/app/@core/property-class/data-access/services/property-class.service';
import { PropertyStageService } from 'src/app/@core/property-stage/data-access/services/property-stage.service';
import { PropertyStatusService } from 'src/app/@core/property-status/data-access/services/property-status.service';
import { PropertySuitabilityService } from 'src/app/@core/property-suitability/data-access/services/property-suitability.service';

import { MultiSelectModule } from 'primeng/multiselect';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { TruncateTextPipe } from 'src/app/shared/pipes/truncate-text.pipe';
import { PropertiesState } from '../../data-access/state';
@Component({
  selector: 'app-properties-table',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    FormsModule,
    ToggleButtonModule,
    SharedModule,
    DisplayDatePipe,
    CalendarModule,
    InputTextModule,
    DraggableDirective,
    MultiSelectModule,
    TooltipDirective,
    TruncateTextPipe
  ],
  templateUrl: './properties-table.component.html',
  styleUrl: './properties-table.component.css',
})
export class PropertiesTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('draggableContainer', { static: true })
  draggableContainer: ElementRef<HTMLElement>;

  @ViewChild('table', { static: true }) table: Table;

public readonly propertiesState = inject(PropertiesState);

public readonly propertyAPI = inject(PropertiesService);

  public readonly state: PropertiesTableService = inject(
    PropertiesTableService
  );
  private sidebarService: SidebarService = null;
  private readonly mapService: MapService = inject(MapService);
  private readonly renderer: Renderer2 = inject(Renderer2);
  private bottomSheetRef: MatBottomSheetRef<PropertiesTableComponent> = inject(
    MatBottomSheetRef<PropertiesTableComponent>
  );
  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private readonly modalService: ModalService = inject(ModalService);
  private readonly router: Router = inject(Router);
  private readonly propertyClassService: PropertyClassService =
    inject(PropertyClassService);
  private readonly propertyStageService: PropertyStageService =
    inject(PropertyStageService);
  private readonly propertySuitabilityService: PropertySuitabilityService =
    inject(PropertySuitabilityService);
  private readonly propertyStatusService: PropertyStatusService = inject(
    PropertyStatusService
  );

  private readonly toastService: ToastService = inject(ToastService);

  balanceFrozen: boolean = false;
  sidebarOpening: Signal<boolean>;
  filterApplied: Signal<boolean>;
  scrollContainer: HTMLElement;

  classes = toSignal(
    this.propertyClassService
      .getAll()
      .pipe(map((classes) => classes.map((cl) => cl.class)))
  );
  statuses = toSignal(
    this.propertyStatusService
      .getAll()
      .pipe(map((status) => status.map((status) => status.status)))
  );
  stages = toSignal(
    this.propertyStageService
      .getAll()
      .pipe(map((stages) => stages.map((stage) => stage.name)))
  );
  suitabilities = toSignal(
    this.propertySuitabilityService
      .getAll()
      .pipe(
        map((suitabilities) =>
          suitabilities.map((suitability) => suitability.name)
        )
      )
  );

  private resizeObserver: ResizeObserver;

  tableHeight;

  constructor(
    private el: ElementRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.sidebarService = data.sidebar;

    this.sidebarOpening = toSignal(
      this.sidebarService.drawer.openedStart.pipe(switchMap(() => of(true))),
      { initialValue: false }
    );

    effect(() => {
      if (this.sidebarOpening()) this.bottomSheetRef.dismiss();
    });

    effect(() => {
      if (!this.state.selectedRow()) {
        return;
      }

      this.router.navigate([
        `/map/property`,
        this.state.selectedRow().propertyID,
      ]);


      // [
      //   20,
      //   20,
      //   40 + this.state.containerHeight(),
      //   20,
      // ]);
      this.sidebarService.open();
    });

    this.tableHeight = computed(() => {
      return this.state.containerHeight() < 250
        ? '250px'
        : `${this.state.containerHeight() - 70}px`;
    });

    effect(() => {
      this.renderer.setStyle(
        this.draggableContainer.nativeElement,
        'height',
        `${this.state.containerHeight()}px`
      );
    });
  }
  ngOnInit(): void {
    this.table.onFilter.subscribe(() => {
      setTimeout(() => {
        this.state.containerHeight.set(this.state.containerHeight() + 1);
      });
    });

    if (this.state.filterMetaData) {
      Object.keys(this.state.filterMetaData).forEach((field) => {
        let filterValue = this.state.filterMetaData[field].value;
        let matchMode = this.state.filterMetaData[field].matchMode;
        this.table.filter(filterValue, field, matchMode);
      });
    }
  }

  ngAfterViewInit(): void {
    this.setupScrollPosition();
    this.initializeResizeObserver();
  }

  ngOnDestroy(): void {
    this.state.filterMetaData = this.table.filters;

    const activeFilters = Object.keys(this.state.filterMetaData)
      .filter(
        (key) =>
          this.state.filterMetaData[key] && this.state.filterMetaData[key].value
      )
      .reduce((obj, key) => {
        obj[key] = this.state.filterMetaData[key];
        return obj;
      }, {});

  }

  private initializeResizeObserver() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === this.draggableContainer.nativeElement) {
          const newHeight = entry.contentRect.height;
          this.onHeightChange(newHeight);
        }
      }
    });

    this.resizeObserver.observe(this.draggableContainer.nativeElement);
  }

  onHeightChange(newHeight: number) {
    const maxHeight = window.innerHeight * 0.8;
    const minHeight = window.innerHeight * 0.25;

    if (newHeight < minHeight) {
      this.state.containerHeight.set(minHeight);
    } else if (newHeight > maxHeight) {
      this.state.containerHeight.set(maxHeight);
    } else {
      this.state.containerHeight.set(newHeight);
    }
  }

  private setupScrollPosition() {
    this.scrollContainer = document.getElementsByClassName(
      'p-scroller'
    )[0] as HTMLElement;

    this.scrollContainer.addEventListener('scroll', (event) => {
      let target = event.target as HTMLElement;
      this.state.scrollPosition.set(target.scrollTop);
    });

    this.scrollContainer.scrollTop = this.state.scrollPosition();
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  getCurrentHeight() {
    return this.draggableContainer.nativeElement.offsetHeight;
  }



  onSort(event) {
    this.state.sortMetaData = event;
  }

  exportToCSV() {
    const currentfilterState = this.table.filters as any;
    const activeFilters = Object.keys(currentfilterState)
      .filter((key) => currentfilterState[key] && currentfilterState[key].value)
      .reduce((obj, key) => {
        obj[key] = currentfilterState[key];

        if (!(currentfilterState[key].value instanceof Array)) {
          obj[key].value = [obj[key].value.toString()];
        }

        return obj;
      }, {});

    this.propertyAPI
      .generateCSVExport(activeFilters, this.state.sortMetaData)
      .subscribe((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename =
          this.getFilenameFromContentDisposition(contentDisposition);
        this.saveFile(response.body, filename);
      });
  }

  private getFilenameFromContentDisposition(
    contentDisposition: string | null
  ): string {
    if (!contentDisposition) return 'defaultFilename.txt';
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
      contentDisposition
    );
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return 'defaultFilename.txt';
    }
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
