import { trigger, transition, style, animate } from "@angular/animations";

export const toolbarAnimation = trigger('toolbarAnimation', [
  transition(':enter', [
    style({ transform: '{{ enterTransform }}', opacity: 0 }),
    animate('300ms ease-in', style({ transform: 'translate(0, 0)', opacity: 1 })),
  ], { params: { enterTransform: 'translateY(-100%)' } }),

  transition(':leave', [
    animate('300ms ease-out', style({ transform: '{{ leaveTransform }}', opacity: 0 })),
  ], { params: { leaveTransform: 'translateY(-100%)' } }),
]);
