import { Injectable, inject } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { tag } from '@turf/turf';
import { BehaviorSubject, Observable, catchError, delayWhen, distinctUntilChanged, filter, first, from, fromEvent, interval, map, of, retry, retryWhen, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LayerDTO } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { PdfPrintDefinition } from '../pdf/data-access/models/models';


@Injectable()
export class SignalRService {
  private itemsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

  isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);

  alerts$ = this.itemsSubject.asObservable();

  private readonly endpoint = environment.hubUrl;

  constructor() {}
  hubConnection: signalR.HubConnection;

  public ensureConnected(): Observable<boolean> {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return of(true);
    }
    else if(this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connecting)
    {
      return this.checkConnectionStatus().pipe(filter(val => val))
    }

    else if (this.hubConnection &&
      (
       this.hubConnection.state === signalR.HubConnectionState.Reconnecting)) {
       return this.onReconnecting().pipe(filter(val => val),
          map(() => true),
          catchError(() => of(false))
        );

}
    else {
      return this.startConnection().pipe(map(() => true));
    }
  }

  public stopConnection() {
    this.hubConnection?.stop();
    this.isConnected.next(false);
  }

  public startConnection(token?: string) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.endpoint, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();

    return from(this.hubConnection.start()).pipe(
      tap(() => {
        window.addEventListener('beforeunload', () => {
          this.hubConnection.stop();
        });
      }),
      retry({
        delay: (error, retryCount) => {

          return new Promise((res) => setTimeout(res, 2000));
        },
      })
    );
  }

  public joinRoom(jobID: string) {
    return from(this.hubConnection.invoke('JoinRoom', jobID));
  }

  public leaveRoom(jobID: string) {
    return from(this.hubConnection.invoke('LeaveRoom', jobID));
  }

  public streamProperties()
  {
    return signalRStreamToObservable<Property>(this.hubConnection.stream("StreamChatMessages"))
  }

  public streamPropertyLayers(propertyID: string, layerIDs: number[] | null)
  {
    return signalRStreamToObservable<LayerDTO>(this.hubConnection.stream("StreamPropertyLayers", propertyID,layerIDs))
  }



  public getPrintDetails(jobID: string) {
    return from(this.hubConnection.invoke<PdfPrintDefinition>('GetPDFDetailsAsync', jobID));
  }

  public onFileReady(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('FileReady', (jobID) => {
        observer.next(jobID);
      });
    });
  }

public onReconnecting(): Observable<boolean> {
  return new Observable<boolean>((observer) => {

    this.hubConnection.onreconnecting((error) => {
      observer.next(false)
    })

    this.hubConnection.onreconnected((jobID) => {
      observer.next(true)
      observer.complete();
    });
  });
}

public checkConnectionStatus(): Observable<boolean> {
  return interval(500).pipe(
    startWith(false),
    switchMap(() => {
      return this.hubConnection.state === signalR.HubConnectionState.Connecting ? of(false) : this.hubConnection.state === signalR.HubConnectionState.Connected ? of(true) : of(false)
    }),
    distinctUntilChanged(),
    filter(status => status === true),
    first()
  );
}

createEventObservable = (eventName: string): Observable<any> => {
  return this.ensureConnected().pipe(
    filter((connected: boolean) => connected),
    switchMap(() => fromEvent(this.hubConnection, eventName)),
    catchError((error) => {
      console.error(`Error with event ${eventName}:`, error);
      return of(null);
    })
  );
}
}



export function signalRStreamToObservable<T>(stream:signalR.IStreamResult<any>) {
  return new Observable<T>((subscriber) => {
      const signalRSubscription = stream.subscribe({
          next: (item) => {
              subscriber.next(item);
          },
          error: (err) => {
              subscriber.error(err);
          },
          complete: () => {
              subscriber.complete();
          },
      });

      return () => {
          signalRSubscription.dispose();
      };
  });
}
