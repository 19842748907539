<table class="file-table">
  <tr *ngFor="let file of files()" class="file-item">
    <td [appTooltip]="file.unsafeName" appTooltip tooltipPosition="left" class="file-cell">{{ file.unsafeName | truncateText:30 }}</td>
    <td class="btn-cell">
      <button type="button" class="button del-btn" (click)="deleteFile.emit(file)" [appTooltip]="'Delete file'" appTooltip tooltipPosition="left">
        <span class="fa fa-trash button-icon"></span>
      </button>
    </td>
    <td class="btn-cell">
      <button type="button" class="button dwn-btn" (click)="downloadFile(file)" [appTooltip]="'Download file'" appTooltip tooltipPosition="left">
        <span class="fa fa-cloud-arrow-down button-icon"></span>
      </button>
    </td>
  </tr>
</table>
