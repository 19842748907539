import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Point } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertiesService } from 'src/app/map/features/property/data-access/properties.service';

import { BehaviorSubject, map, Subject, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';
import { SignalRService } from 'src/app/@core/services/signalR.service';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { PropertiesState } from 'src/app/map/features/property/data-access/state';
import { PDFPageLayout, PdfPrintDefinition } from './models/models';

@Injectable()
export class PdfService extends BaseEndpointService {


  constructor() {
    super({ route: 'pdf' });
  }

  getLayouts() {
    return this.http.get<Array<PDFPageLayout>>(`${this.endpoint}/layouts`);
  }

  getFile(jobID)
  {
    return  this.http.get(`${this.endpoint}/${jobID}`, {
            observe: 'response',
            responseType: 'blob',
          })
  }

  createPrintRequest(model: PdfPrintDefinition) {
    return this.http
      .post<string>(`${this.endpoint}/generate`, model);
  }




}
