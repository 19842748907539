import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Signal,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  Form,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Style, SymbolizerKind, TextSymbolizer } from 'geostyler-style';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { RuleTableComponent } from 'src/app/shared/components/style-renderer/rule-table/rule-table.component';
import { StyleEditorComponent } from 'src/app/shared/components/style-renderer/style-editor/style-editor.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { v4 } from 'node_modules/uuid';
import { Kind } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { getGeoStylerType } from 'src/app/shared/components/style-renderer/helpers/helpers';
import { combineLatest, startWith } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LegendRenderer } from 'geostyler-legend';
import { MatSelectModule } from '@angular/material/select';
import {cloneDeep} from 'lodash';

var defaultTextStyleDefinition: TextSymbolizer =  {
  kind: 'Text',
  label: '{{shape_area}} (ha)',
size: 18,
color: "#000000",
haloColor: "#ffffff",
haloWidth: 2,
haloOpacity:1,
haloWidthUnit: 'px',
rotate: 0,
opacity: 1,
anchor: "center"
};

@Component({
  selector: 'app-create-feature-layer',
  templateUrl: './create-feature-layer.component.html',
  styleUrls: ['./create-feature-layer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    StyleEditorComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TooltipDirective,
    RuleTableComponent,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule
  ],
})
export class CreateFeatureLayerComponent implements OnInit {
  @Output() createEvent = new EventEmitter<CreateEvent<CreateFeatureLayer>>();
  test2 = signal([]);

  private readonly httpClient:HttpClient = inject(HttpClient);

  landUseTypes = toSignal(this.httpClient.get(environment.apiUrl + "land-use-type"))

  form: FormGroup;
  model: Signal<CreateFeatureLayer>;
  style: WritableSignal<Style> = signal({
    name: v4(),
    rules: [
      {
        name: 'Default Style',
        symbolizers: [getGeoStylerType('Polygon')],
      },
      {
        name: 'Area Label',
        symbolizers: [
          {
            kind: 'Text',
            label: '{{shape_area}} (ha)',
          size: 18,
          color: "#000000",
          haloColor: "#ffffff",
          haloWidth: 2,
          rotate: 0,
          opacity: 1,
          anchor: "center"
          },
        ],
      },
    ],
  });

  constructor() {
    effect(() => {

      const style = this.style();
      const defaultRules = [];



    })

    this.form = new FormGroup({
      name: new FormControl('', CustomValidators.required),
      kind: new FormControl('Polygon'),
      isLandUse: new FormControl(false),
      landUseType: new FormControl()
    });


    this.form.get('isLandUse').valueChanges.subscribe(isLandUse => {
      const landUseTypeControl = this.form.get('landUseType');

      if(!isLandUse)
        {
          landUseTypeControl.setValue(null);
          landUseTypeControl.clearValidators();
          return;
        }

        landUseTypeControl.setValidators(Validators.required)


    });


    combineLatest([this.form.get('landUseType').valueChanges.pipe(startWith(this.form.get('landUseType').value)), this.form.get('kind').valueChanges.pipe(startWith(this.form.get('kind').value)), this.form.get('isLandUse').valueChanges.pipe(startWith(this.form.get('isLandUse').value))])
    .pipe(takeUntilDestroyed())
    .subscribe(([landUseType, kind, isLandUse]) => {

      const defaultRules = [];

      if (kind == 'LineString') {
        defaultRules.push({
          name: 'Length Label',
          symbolizers: [
            {
              label: '{{shape_length}} (m)',
              ...defaultTextStyleDefinition
            },
          ],
        });
      } else if (kind == 'Polygon') {
        defaultRules.push({
          name: 'Area Label',
          symbolizers: [
            {
              label: '{{shape_area}} (ha)',
              ...defaultTextStyleDefinition
            },
          ],
        });
      }

      if(!isLandUse || landUseType == null)
        {

          const defaultShapeRule =  {
            name: 'Default Style',
            symbolizers: [getGeoStylerType(kind)],
          }

          this.style.update( style => {
            return { ...style,
              rules: [defaultShapeRule,...defaultRules],}
              });
              return;

        }


          const defaultStyle =  cloneDeep(landUseType.style);

          defaultStyle.rules.push(...defaultRules)
          this.style.set(defaultStyle);

    });



    let formChanges = toSignal(
      this.form.valueChanges.pipe(startWith(this.form.getRawValue()))
    );

    this.model = computed(() => {
      return {
        name: formChanges().name,
        style: JSON.stringify(this.style()),
        kind: formChanges().kind,
        isLandUse: formChanges().isLandUse,
        landUseTypeID: formChanges().landUseType?.landUseTypeID ?? null
      };
    });
  }

  ngOnInit() {}


  submit() {
    this.createEvent.emit(CreateEvent.create<CreateFeatureLayer>(this.model()));
  }
  cancel() {
    this.createEvent.emit(CreateEvent.cancel());
  }
}

export interface CreateFeatureLayer {
  name: string;
  style?: any;
  kind: string;
  isLandUse: boolean;
  landUseTypeID: number;
}
