
<div class="title-bar">
  <div class="title">Create Catalogue Group</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content gap--md">
  <form class="grid grid--row gap--md" [formGroup]="form">
    <mat-form-field color="primary" appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Name">
      <mat-error *ngIf="form.get('name').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div class="footer">
  <button type="button"  class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue" (click)="submit()"><span class="fa fa-save button-icon"></span>Submit</button>
</div>

