import { of, throwError } from "rxjs";
import { getErrorMessage } from "./models/errorMap";

export const handleError = (errorRes) => 
{
  let errorMessage = 'An unknown error occurred!'

  const errorDetail = errorRes.error != null ? errorRes.error.replaceAll(" ", "").split(":")[1] : null

  if (errorDetail) {
    errorMessage = getErrorMessage(errorDetail)
  }

  return of({error: true, errorMessage})
}