<app-dialog (close)="onCancel()">
  <ng-template eskDialogHeader><h3>Delete {{data.type}}</h3></ng-template>
  <ng-template eskDialogContent><p>Are you sure you want to delete <b>{{data.details}}</b>?</p></ng-template>
  <ng-template eskDialogFooter>
    <button  (click)="onCancel()"id="cancelBtn" class="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300">
      Cancel
    </button>
    <button (click)="onConfirm()" id="confirmBtn" class="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700">
      Delete
    </button>
  </ng-template>
</app-dialog>
