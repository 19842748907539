import { Injectable, TemplateRef } from '@angular/core';
import { CdkPortalOutlet, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

@Injectable()
export class ActionPortalService {
private activePortal = new Subject<TemplatePortal | null>();

public readonly portal = this.activePortal.asObservable();

setPortal(portal: TemplatePortal | null)
{
  this.activePortal.next(portal);
}



}
