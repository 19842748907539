import { Component, OnInit, Signal, computed, effect, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule, Form } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { AccountService } from '../../account.service';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { generateComputedModel } from 'src/app/@core/utils/utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  standalone:true,
  imports:[CommonModule, MaterialModule, ReactiveFormsModule,FormsModule]
})
export class ResetPasswordComponent implements OnInit {

  private readonly router:Router = inject(Router);

  model: Signal<passwordResetToken>;

  form: UntypedFormGroup;
  error: string = null;
version: string;
  constructor(private route: ActivatedRoute, private accountService: AccountService, private toastService:ToastService) {

    this.version = environment.version;

     this.form = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
      'repeatPassword': new UntypedFormControl(null,CustomValidators.required),
      'token': new UntypedFormControl(null,CustomValidators.required),
      'email': new UntypedFormControl(null,CustomValidators.required),
    },{validators: [CustomValidators.passwordsMatch('password','repeatPassword')]});

    this.form.valueChanges.subscribe((changes) => {
      this.error = null;
    });



    this.model = generateComputedModel(new passwordResetToken(),this.form);



    this.route.queryParams.subscribe((params) => {
      if (params['token']) {
         this.form.get('token').setValue(params['token']);
         this.form.get('token').markAsDirty();
      }
      if(params['email'])
      {
        this.form.get('email').setValue(params['email']);
        this.form.get('email').markAsDirty();
      }
    });
  }

  ngOnInit(): void {

  }

  onSubmit(): void {

    if(this.form.invalid)
    {
      return;
    }


    this.accountService.resetPasswordWithToken(this.model()).subscribe({
      error: error => this.error = error,
      next: value =>  {

        this.toastService.showSuccess("Password Reset Successfully");
        this.router.navigate(['/login'])
    }
    })
  }



}



export class passwordResetToken {
  token?:string = ''
  email?:string = ''
  password?:string = ''
}
