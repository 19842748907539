import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';
import { ToastService } from 'src/app/@core/services/toast/toast.service';


@Injectable()
export class UsersService extends BaseEndpointService {

  private readonly toastService: ToastService = inject(ToastService);
  public users: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor() { 
    super({route: 'users'})
  }

  getAll()
  {
    return this.http.get<any[]>(this.endpoint).pipe(tap(data => this.users.next(data)));
  }

  get(userId: string)
  {
    return this.http.get(`${this.endpoint}/${userId}`);
  }

  create(model)
  {
    return this.http.post(this.endpoint, {...model}).pipe(tap(() => this.toastService.showSuccess("User Created")),switchMap(() => this.getAll()));
  }

  update(userId: string , model)
  {
    return this.http.patch(`${this.endpoint}/${userId}`,{...model}).pipe(tap(() => this.toastService.showSuccess("User Updated")));
  }

  delete(userId: string)
  {
    return this.http.delete(`${this.endpoint}/${userId}`).pipe(tap(() => this.toastService.showSuccess("User Deleted")),switchMap(() => this.getAll()));
  }

  roles()
  {
    return this.http.get(`${this.endpoint}/roles`);
  }

  resetPassword(id:string, password:string)
  {
    return this.http.post(`${this.endpoint}/${id}/reset-password`,{password}).pipe(tap(()=> this.toastService.showSuccess("Password Reset")))
  }

  GetProcessLeaders()
  {
    return this.http.get<any[]>(`${this.endpoint}/process-leaders`)
  }

}
