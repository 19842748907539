import { Component, EventEmitter, ModelSignal, OnDestroy, Output, inject, model } from '@angular/core';

import { InOut, InOut2, fadeInOut } from 'src/app/shared/animations/animations'
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MultiPolygon, Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import { MapService } from 'src/app/map/data-access/map.service';
import { click } from 'ol/events/condition';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { EskTool, provideEskTool } from 'src/app/map/util/esk-tool-interface';
import { EskToolDirective } from 'src/app/shared/directives/EskTool.directive';

export class CreatePropertyEvent {

  create: boolean;
  feature?: Feature<any>;

  constructor(create, feature = null) {

    this.create = create;
    this.feature = feature;
  }

  static create(feature: Feature<any>)
  {
    return new CreatePropertyEvent(true,feature);
  }

  static cancel()
  {
    return new CreatePropertyEvent(false);
  }
}

@Component({
  selector: 'digitize-property-overlay',
  templateUrl: './digitize-property-overlay.component.html',
  styleUrls: ['./digitize-property-overlay.component.css'],
  animations:[fadeInOut],
  providers:[provideEskTool(DigitizePropertyOverlayComponent)],
  standalone:true,
  imports:[TooltipDirective,EskToolDirective],
  host:{
    'class': 'flex gap-2 text-lg',
  },
  hostDirectives:[
    {
      directive: EskToolDirective
    }
  ]
})
export class DigitizePropertyOverlayComponent implements OnDestroy, EskTool {

  private readonly mapService: MapService = inject(MapService);
  private interaction: Draw;
  private map = this.mapService.getMap();
  public propertyBoundary: Feature<MultiPolygon>;
  private drawLayer: VectorLayer<any>;
  private drawSource: VectorSource;
  private coordinates: Array<Coordinate[][]> = [];
  active: ModelSignal<boolean> = model(false);

  @Output() createEvent = new EventEmitter<CreatePropertyEvent>();

  constructor()
  {
    this.setupDigitize();
    this.active.set(true)
  }

  setupDigitize()
  {
    this.drawSource = new VectorSource();
    this.drawLayer = new VectorLayer({
      source: this.drawSource
    });

    this.map.addLayer(this.drawLayer);


    this.interaction = new Draw({
      type: 'Polygon',
      source: this.drawSource,

    });

    this.map.addInteraction(this.interaction);
    this.interaction.on('drawend', event => {


      let newCoordinates = (event.feature.getGeometry() as Polygon).getCoordinates() as any;

      this.coordinates.push(newCoordinates);

      if(!this.propertyBoundary)
      {
        this.propertyBoundary = new Feature(new MultiPolygon(this.coordinates));

        this.drawSource.addFeature(this.propertyBoundary)
      }
      else
      {
        this.propertyBoundary.getGeometry().setCoordinates(this.coordinates);
      }


      this.drawSource.removeFeature(event.feature);
    })
  }

  finished()
  {
   this.createEvent.emit(CreatePropertyEvent.create(this.propertyBoundary));
  }

  cancel()
  {
    this.createEvent.emit(CreatePropertyEvent.cancel())
  }

  undo()
  {
    this.interaction.removeLastPoint();
  }

  ngOnDestroy(): void {
      this.map.removeInteraction(this.interaction);
      this.map.removeLayer(this.drawLayer);
      this.drawSource.clear();
      this.active.set(false)
  }



}
