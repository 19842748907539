export type ErrorType =
  | "INVALID_CREDENTIALS"
  | "EMAIL_EXISTS"
  | "EMAIL_NOT_CONFIRMED"
  | "ACCOUNT_DISABLED"
  | "INVALID_REQUEST";

   const ERROR_MESSAGE_MAP: { [key in ErrorType]: string } = {
    INVALID_CREDENTIALS: "Invalid Login Credentials",
    EMAIL_EXISTS: "An Account Already Exists With That Email",
    EMAIL_NOT_CONFIRMED: "This user has not yet been activated. Please check your email to find the activation email before logging in.",
    ACCOUNT_DISABLED: "Your account has been disabled, please contact an admin",
    INVALID_REQUEST: "Invalid client request",
  } as const;


  export function getErrorMessage(errorType: ErrorType): string {
    return ERROR_MESSAGE_MAP[errorType];
  }
