import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  computed,
  effect,
  inject,
  input,
  model,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { BehaviorSubject, Subject, filter, map, of, pairwise, startWith, tap } from 'rxjs';
import OlMap from 'ol/Map';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { compileClassDebugInfo } from '@angular/compiler';
import { toObservable } from '@angular/core/rxjs-interop';
@Component({
  selector: 'map-base-maps-select',
  templateUrl: './basemaps-select.component.html',
  styleUrls: ['./basemaps-select.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TooltipDirective,
  ],
})
export class BasemapsSelectComponent implements OnInit, AfterViewInit {
  BaseMapGroup = input<LayerGroup>();
  map = input.required<OlMap>();

  selectedBaseMap = model('');

  @Output() selectedBaseMapName = new EventEmitter();

  layers = computed(() => {
    const group = this.BaseMapGroup();

      if(group == null)
      {
        return [];
      }

      return group.getLayersArray();
  });

  defaultLayer = computed(() => {
    const layers = this.layers();

    if(layers == null)
    {
      return null
    }
return layers.find((layer) => layer.getVisible() == true);
  });

  a = toObservable(this.defaultLayer).pipe(startWith(null),filter(layer => layer != null)).subscribe((layer) => {
    this.selectedBaseMapForm.setValue(layer)
  })

  public selectedBaseMapForm = new FormControl();

  constructor() {
    this.selectedBaseMapForm.valueChanges
    .pipe(startWith(null), pairwise())
    .subscribe(([previousLayer, selectedLayer]) => {
      if (previousLayer) {
        previousLayer.setVisible(false);
      }
      selectedLayer.setVisible(true);
      this.selectedBaseMapName.emit(selectedLayer.get('title'));
      this.selectedBaseMap.set(selectedLayer.get('title'))
      this.map().render();
    });
  }

  init() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.selectedBaseMap.set(this.selectedBaseMapForm.value.get('title'))
  }


}
