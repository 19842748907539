<app-dialog (close)="cancel()">
<ng-template eskDialogHeader ><h3>WMS Layers</h3></ng-template>
<ng-template eskDialogContent >
  @if (capabilities() === null) {
    <div>Retrieving layer list from WMS Capabilities</div>
  } @else if (capabilities().length === 0) {
    <div>No Valid Layers</div>
  } @else {
    <div class="d-flex flex-column gap--md">
      <input [formControl]="searchField" type="text" pInputText placeholder="Search" />
      <label id="layerSelectLabel">Select the layer</label>
    </div>
    <mat-radio-group aria-labelledby="layerSelectLabel" class="layer-radio-group" [(ngModel)]="selectedLayer">
      @for (capability of filteredCapabilities(); track capability) {
        <mat-radio-button class="layer-radio-button" [value]="capability">{{capability.title}}</mat-radio-button>
      }
    </mat-radio-group>
  }

</ng-template>
<ng-template eskDialogFooter >
  <button type="button" class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue flex gap--sm" (click)="setSelection()"
          [disabled]="isDisabled">Done</button>
</ng-template>
</app-dialog>
