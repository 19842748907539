import { Injectable } from '@angular/core';
import { BaseEndpointService, IEndpoint } from '../../../interfaces/IEndpoint';
import { PropertyClass } from '../models/property-class.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyClassService extends BaseEndpointService implements IEndpoint {

constructor() {
  super({route:'property-class'})
}

  getAll() {
    return this.http.get<Array<PropertyClass>>(this.endpoint)
  }
  get(id: string) {
    throw new Error('Method not implemented.');
  }
  delete(id: string) {
    throw new Error('Method not implemented.');
  }
  update(id: String, model: {}) {
    throw new Error('Method not implemented.');
  }

}
