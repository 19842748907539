import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Injectable,
  inject,
  signal,
  WritableSignal,
  effect,
  computed,
} from '@angular/core';
import { Collection, Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import {
  BehaviorSubject,
  Subject,
  finalize,
  fromEvent,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import VectorSource from 'ol/source/Vector';
import { Draw, Modify, Translate } from 'ol/interaction';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';

import { MapService } from '../../../../../data-access/map.service';
import {
  BaseEndpointResource,
  BaseEndpointService,
} from 'src/app/@core/interfaces/IEndpoint';
import { environment } from 'src/environments/environment';
import LayerGroup from 'ol/layer/Group';
import {
  ConvertFeatureToWKT,
  CovertWKTFeature,
} from 'src/app/shared/helpers/transformations';
import { CreateFeatureLayer } from '../ui/create-feature-layer/create-feature-layer.component';
import { TranslateEvent } from 'ol/interaction/Translate';
import { ContextMenuAction } from 'src/app/shared/features/context-menu/context-menu.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Layer } from 'ol/layer';
import { filter } from 'lodash';

export type propertyLayerstate = {
  layer: VectorLayer<any>;
  visible: boolean;

  features: [
    {
      feature: Feature<Geometry>;
      isEditing: boolean;
    }
  ];
};

export type LayerState = {
  layer: VectorLayer<any>;
  visible: boolean;
  isEditing: boolean;
  expanded: boolean;
  selected: boolean;
  displayName: string;
  opacity: number;
};

export type FeatureState = {
  isEditing: boolean;
  feature: Feature<any>;
};

export interface LayerDTO {
  layerID: number;
  name: string;
  area: number;
  style?: any;
  features: UserFeatureDTO[];
  mapLayer?: VectorLayer<any>;
}

export interface UserFeatureDTO {
  featureID: number;
  name: string;
  geom: string;
  attributes: FeatureAttributes[];
  isEditing?: WritableSignal<boolean>;
  olFeature?: Feature<any>;
}

export interface FeatureAttributes {
  [key: string]: any;
}

export interface PropertyOverview {
  propertyID: number;
  propertyArea: number;
  cumulativeArea: number;
  percentageOfProperty: number;
  balance: number;
}

interface QueryParams {
  pageNumber?: number | null;
  pageSize?: number | null;
  searchTerm?: string | null;
  select?: string[] | null;
  id?: number[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureLayersService extends BaseEndpointResource<'properties/{propertyID}/layers'> {
  constructor() {
    super({ routeTemplate: 'properties/{propertyID}/layers' });
  }

  getOverview(options: QueryParams = {}) {
    const { pageNumber, pageSize, searchTerm, select, id } = options;
    const params: any = {};

    if (pageNumber != null) {
      params.pageNumber = pageNumber.toString();
    }

    if (pageSize != null) {
      params.pageSize = pageSize.toString();
    }

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    if (select) {
      params.select = select.join(',');
    }

    if (id) {
      params.id = id.join(',');
    }

    const queryString = new URLSearchParams(params).toString();

    const fullUrl = queryString
      ? `${this.endpoint}/overview?query=${encodeURIComponent(queryString)}`
      : this.endpoint + '/overview';

    return this.http.get<PropertyOverview>(fullUrl);
  }

  public GetAll(accessToken?: string, options: QueryParams = {}) {

    const { pageNumber, pageSize, searchTerm, select, id } = options;
    const params: any = {};

    if (pageNumber != null) {
      params.pageNumber = pageNumber.toString();
    }

    if (pageSize != null) {
      params.pageSize = pageSize.toString();
    }

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    if (select) {
      params.select = select.join(',');
    }

    if (id) {
      params.id = id.join(',');
    }

    const queryString = new URLSearchParams(params).toString();

    const fullUrl = queryString
      ? `${this.endpoint}?query=${encodeURIComponent(queryString)}`
      : this.endpoint;

      const headers = this.headers;

    return this.http.get<LayerDTO[]>(fullUrl, { headers });
  }

  deleteFeature(layerID: number, featureID: number) {
    return this.http.delete(
      `${this.endpoint}/${layerID}/features/${featureID}`
    );
  }

  updateLayerFeatures(updatePayload: { layerID: number; models: any[] }) {
    return this.http.patch(
      `${this.endpoint}/${updatePayload.layerID}/features`,
      {
        models: updatePayload.models,
      }
    );
  }

  getLayer(layerID) {
    return this.http.get<LayerDTO>(`${this.endpoint}/${layerID}`);
  }

  createFeature(layerID, model) {
    return this.http.post(`${this.endpoint}/${layerID}`, model);
  }

  updateFeature(layerID: number, featureID: number, wkt: string) {
    return this.http.patch(
      `${this.endpoint}/${layerID}/features/${featureID}`,
      {
        featureID: featureID,
        wkt: wkt,
      }
    );
  }

  createLayer(model: CreateFeatureLayer) {
    return this.http.post<number>(this.endpoint, model);
  }

  updateLayer(layerID: number, model: any) {
    return this.http.patch(`${this.endpoint}/${layerID}`, { ...model });
  }

  deleteLayer(layerID: number) {
    return this.http.delete(`${this.endpoint}/${layerID}`);
  }

  // downloadGeoJson(layerID: number, srid: number) {
  //   return this.http.get(
  //     `${environment.apiUrl}/properties/${this.propertyID}/layers/${layerID}/geojson?srid=${srid}`,
  //     { responseType: 'blob', observe: 'response' }
  //   );
  // }

  // private updateLayerFeatures(layerID: number) {
  //   return this.getLayer(layerID).pipe(
  //     switchMap((layer) => of(this.propertyLayers.value).pipe(
  //       map((projectLayers) => ({
  //         currentLayers: projectLayers,
  //         layer,
  //       }))
  //     )),
  //     tap((result) => {

  //       let targetLayer = result.currentLayers.find(layer => layer.layerID === result.layer.layerID) ?? null;
  //       const existingLayers = result.currentLayers.filter(layer => layer.layerID !== result.layer.layerID);

  //       if (targetLayer == null) {
  //         targetLayer = this.setupFeatureLayer(result.layer);
  //         this.projectLayersGroup.getLayers().insertAt(0, targetLayer.mapLayer());
  //       } else {

  //         targetLayer = Object.assign(targetLayer, result.layer);
  //         targetLayer = this.setupLayerFeatures(targetLayer);
  //       }
  //       const layers = [...existingLayers, targetLayer];

  //       this.propertyLayers.next(layers.sort((a, b) => a.layerID - b.layerID));
  //     })
  //   );
  // }
}
