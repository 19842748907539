import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import BaseEvent from 'ol/events/Event';
import { Type } from 'ol/geom/Geometry';
import BaseLayer from 'ol/layer/Base';
import BaseImageLayer from 'ol/layer/BaseImage';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorSource from 'ol/source/Vector';
import { BehaviorSubject, map } from 'rxjs';
import { MapService } from 'src/app/map/data-access/map.service';

export interface state {
  title: string;
  visible: 'visible' | 'hidden' | 'partial';
  opacity: number;
  kind?: Type;
  featureProperties?: {};
  layer: any;
  type: 'group' | 'vector' | 'image';
  children: WritableSignal<state>[];
  legendURL?: string;
  sourceType?: string;
  hasRemoveFn: boolean;
  hasRequiredZoom: boolean;
  canActivate: boolean;
  removeFn?: (event) => {};
  poppedOut: boolean;
}

// TODO: rebuilding the entire tree every-time seems like a waste, maybe do some checking / filtering to only re-create the needed ones. THIS REALLY NEEDS TO BE DONE!!!

@Injectable({
  providedIn: 'root',
})
export class LayersService {
  public readonly mapService: MapService = inject(MapService);
  public mapInstance = this.mapService.getMap();
  test: WritableSignal<state>[] = [];

  public poppedOutLayers = new BehaviorSubject<WritableSignal<state>[]>([]);

  private readonly test2 = new BehaviorSubject<BaseLayer[]>([]);

  layers;
  constructor() {
    this.buildLayersTree();

      this.test2.next(this.mapInstance
        .getLayers()
        .getArray()
        .filter(
          (layer) =>
            layer.get('title') != 'Background Maps' && layer.get('title') != null && layer.get('title') != 'Property Layers'
        )
  );



    this.mapInstance.getLayers().on(['add', 'remove'], (event: any) => {
      this.buildLayersTree();

      if (event.element) this.attachAddListenerToLayer(event.element);
    });

    this.mapInstance.getLayers().forEach((layer) => {
      this.attachAddListenerToLayer.call(this, layer);
    });
  }

  buildLayersTree() {
    this.test = [];

    this.layers = this.mapInstance
      .getLayers()
      .getArray()
      .filter(
        (layer) =>
          layer.get('title') != 'Background Maps' && layer.get('title') != null
      );



    this.layers.forEach((layer, index) => {
      if (layer instanceof LayerGroup) {
        let layerState = this.buildLayerGroupState(layer, null);
        this.test.push(layerState);
      } else {
        let layerState = this.buildLayerState(layer, null);
        this.test.push(layerState);
      }
    });
  }

  private buildLayerGroupState(layerGroup: LayerGroup, parentState) {
    let layersArray = layerGroup
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('title') != null);

    let state = signal<state>({
      title: layerGroup.get('title'),
      opacity: layerGroup.getOpacity(),
      visible: layerGroup.getVisible() ? 'visible' : 'hidden',
      layer: layerGroup,
      type: 'group',
      children: [],
      hasRemoveFn: layerGroup.get('removeFn') == null ? false : true,
      hasRequiredZoom: layerGroup.getMinZoom() == -Infinity ? false : true,
      canActivate: true,
      removeFn:
        layerGroup.get('removeFn') == null ? null : layerGroup.get('removeFn'),
      poppedOut: layerGroup.get('poppedOut') == null ? false : layerGroup.get('poppedOut')
    });

    if(state().poppedOut)
    {
      this.popout(state);
    }

    layersArray.forEach((layer, index) => {
      if (layer instanceof LayerGroup) {
        state().children.push(this.buildLayerGroupState(layer, state));
      } else {
        let childState = this.buildLayerState(layer, state);
        state().children.push(childState);
      }
    });

    return state;
  }

  private buildLayerState(
    layer: BaseLayer,
    parentState: WritableSignal<state>
  ) {
    let state = signal<state>({
      title: layer.get('title'),
      opacity: layer.getOpacity(),
      visible: layer.getVisible() ? 'visible' : 'hidden',
      layer: layer,
      type: 'image',
      featureProperties: layer.getProperties(),
      children: [],
      legendURL: layer.get('legendURL'),
      sourceType: layer.get('sourceType'),
      hasRemoveFn: layer.get('removeFn') == null ? false : true,
      hasRequiredZoom: layer.getMinZoom() == -Infinity ? false : true,
      removeFn: layer.get('removeFn') == null ? null : layer.get('removeFn'),
      canActivate: true,
      poppedOut: layer.get('poppedOut') == null ? false : layer.get('poppedOut')
    });


    if(state().poppedOut)
      {
        this.popout(state);
      }

    if (layer instanceof VectorLayer || layer instanceof VectorImageLayer) {
      state.set({ ...state(), type: 'vector' });

      let source = layer.getSource() as VectorSource;

      let eventKey;

      if (source.getFeatures().length > 0) {
        let feature = source.getFeatures()[0];
        let kind = feature.getGeometry().getType();
        let properties = feature.getProperties();
        delete properties['geometry'];

        state.set({ ...state(), kind: kind, featureProperties: properties });
      } else {
        eventKey = source.on('addfeature', (event) => {
          let kind = event.feature.getGeometry().getType();
          let properties = event.feature.getProperties();

          layer.set('featureProperties',properties);

          layer.addChangeListener

          delete properties['geometry'];

          state.set({ ...state(), kind: kind, featureProperties: properties });
        });
      }
    }

    if(layer instanceof VectorTileLayer)
    {
      state.set({ ...state(), type: 'vector',kind:'Polygon' });
    }

    return state;
  }

  popout(layerToAdd: WritableSignal<state>)
  {
    const currentLayers = this.poppedOutLayers.value;

    let alreadyAdded = false;

    currentLayers.forEach(layer => {
      if(layer().title == layerToAdd().title)
      {
        alreadyAdded = true;
      }
    })

    if(alreadyAdded)
      {
        return;
      }

    layerToAdd.update(layer => ({...layer, poppedOut: true}))



    this.poppedOutLayers.next([...currentLayers, layerToAdd]);
  }

  removePoppedOutLayer(layerToRemove:WritableSignal<state>)
  {
    const currentLayers = this.poppedOutLayers.value;

    layerToRemove.update(layer => ({...layer, poppedOut: false}))

    this.poppedOutLayers.next(currentLayers.filter(layer => layer().title != layerToRemove().title ))
  }

  private attachAddListenerToLayer(layer) {
    if (layer instanceof LayerGroup) {
      layer.getLayers().on(['add', 'remove'], (event: any) => {
        if (event.element) {
          this.attachAddListenerToLayer(event.element);
        }
        this.buildLayersTree();
      });
      layer.getLayers().forEach((subLayer) => {
        this.attachAddListenerToLayer.call(this, subLayer);
      });
    }
  }

}
