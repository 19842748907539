<tui-tabs
size="m"
vertical="left"
class="left"
>
<a

>
    Button
</a>
<a

>
    Tabs
</a>
<a
>
    Input
</a>
</tui-tabs>
