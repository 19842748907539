import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2, computed, effect, forwardRef, inject, input, model, output, signal } from '@angular/core';
import { Collection, Feature, Map as olMap } from 'ol';
import VectorSource from 'ol/source/Vector';
import { ESKTOOL_TOKEN, EskTool } from '../../util/esk-tool-interface';
import { Interaction, Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { Difference, Intersect, Intersects } from 'src/app/shared/helpers/transformations';
import VectorLayer from 'ol/layer/Vector';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { primaryAction } from 'ol/events/condition';
import { LineString, Polygon } from 'ol/geom';
import { EventsKey } from 'ol/events';

@Component({
  selector: 'app-base',
  template:'',
  standalone: true,
  imports:[CommonModule],

})
export class BaseComponent {
  intersectTargetFeatures = input([]);
  intersect = signal(true);

  differenceTargetLayers = input<Array<VectorLayer<any>>>();
  difference = signal(false);

  differenceTargets:FormGroup = new FormGroup([]);
  _differenceTargetLayers = computed(() => {

    const intersectingLayers = this.differenceTargetLayers();

    if(intersectingLayers == null)
      return [];


    const layerDefinition = []

    intersectingLayers.forEach(layer => {

      const name = layer.get('title') as string;

      if(name == null)
        {
          return;
        }

        layerDefinition.push({layer: layer, name: name});

      this.differenceTargets.addControl(name, new FormControl(false));
    });

    return layerDefinition;
  });



  handleIntersect(feature: Feature) {
    const shouldIntersect = this.intersect();
    const intersectTargets = this.intersectTargetFeatures().filter(target => target != null);



    if(!shouldIntersect)
      {

        return feature;

      }

      const output = feature.clone();

      intersectTargets.forEach(intersectTarget => {

        if(!Intersects(output, intersectTarget))
          return;

        const clippedFeature = Intersect(output, intersectTarget);
        output.setGeometry(clippedFeature.getGeometry());
      });

      return output;
  }

  handleDifference(feature:Feature)
  {

    const shouldDifference = this.difference();
    const differenceTargetLayers = this._differenceTargetLayers();

    if(!shouldDifference)
      {
       return feature;
      }

      const differenceTargets = this.differenceTargets.getRawValue();

      differenceTargetLayers.filter(lyr => differenceTargets[lyr.name] == true).flatMap(lyr => lyr.layer.getSource().getFeatures()).forEach(target => {

        if(feature.get('featureID') == target.get('featureID'))
          {
            return;
          }


        const intersects = Intersects(feature, target)

        if(!intersects)
          {
            return;
          }

        const clippedFeature = Difference(feature, target);

        feature.setGeometry(clippedFeature.getGeometry());
      });

      return feature;

  }
}

const baseClasses = ['flex','gap-2',]

@Component({
  selector: 'map-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.css'],
  standalone: true,
  imports:[CommonModule,TooltipDirective,ReactiveFormsModule, MatMenuModule, MatCheckboxModule],
  providers: [
    { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => ModifyComponent) }
  ],
  host:{
    '[class]': '_computedClass()',
  },
})
export class ModifyComponent extends BaseComponent implements OnInit, OnDestroy, EskTool  {

  private renderer: Renderer2 = inject(Renderer2);

  active = model(false);

  protected activeClasses = computed(() =>
  {
    const active = this.active();

    const activeClasses = ['border', 'border-gray-400', 'rounded-lg', 'px-1', 'shadow-inner', 'shadow-gray-500/30', 'bg-gray-100'];

    return active ? activeClasses : '';
  }
  )


  protected _computedClass = computed(() =>
    {
      const activeClasses = this.activeClasses();


      return   [...baseClasses,...activeClasses].join(' ');
    }
	);


  shouldRemove = input<boolean>(false);

  map = input.required<olMap>();

  source = input( new VectorSource());

  modifyEnd = output<Feature>();

  private contextmenuListener: EventListener;

  modifyInteraction = computed(() => {

      const source = this.source();

      const modifyInteraction = new Modify({
          source: source,
      });

      modifyInteraction.on('modifyend', (event: ModifyEvent) => {

        let feature = event.features.pop();

        if(feature == null)
          {
            return;
          }

          feature = this.handleIntersect(feature);

          feature = this.handleDifference(feature);


          this.modifyEnd.emit(feature)

      });



      return modifyInteraction;

  });



  constructor() {

    super();

    effect(() => {

      const active = this.active();
      const shouldRemove = this.shouldRemove();
      const modifyInteraction = this.modifyInteraction();

      if(modifyInteraction == null)
      {
        return;
      }

        if(!active || shouldRemove)
          {
            this.map().removeInteraction(modifyInteraction);
            document.removeEventListener('contextmenu', this.contextmenuListener);
            return;
          }

      document.addEventListener('contextmenu', this.contextmenuListener);

      this.map().addInteraction(modifyInteraction);

    });


   }



  ngOnInit() {
    this.contextmenuListener = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

    };


  }

  ngOnDestroy(): void {
      const modifyInteraction = this.modifyInteraction();

      if(modifyInteraction != null)
        {
          this.map().removeInteraction(modifyInteraction);
        }

        if (this.contextmenuListener) {
          document.removeEventListener('contextmenu', this.contextmenuListener);
        }

  }


  toggleTool()
  {
    this.active.set(!this.active());
  }
}
