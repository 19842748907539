<div class="title-bar">
  <div class="title">ArcGIS Rest Layers</div>
  <div class="window-controls">
    <button (click)="cancel()"><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content get-capabilities-layer-content gap--md">
  @if (layers() === null) {
    <div>Retrieving layer list from ArcGIS Rest Service</div>
  } @else if (layers().length === 0) {
    <div>No Valid Layers</div>
  } @else {
    <div class="d-flex flex-column gap--md">
      <input [formControl]="searchField" type="text" pInputText placeholder="Search" />
      <label id="layerSelectLabel">Select the layer</label>
    </div>
    <cdk-accordion>
      <mat-radio-group aria-labelledby="layerSelectLabel" class="layer-radio-group" [(ngModel)]="selectedLayer">
        @for (layer of filteredLayers(); track layer; ; let index = $index) {
          @if (layer.subLayerIds !== null && layer.subLayerIds.length > 0) {
            <cdk-accordion-item
              #accordionItem="cdkAccordionItem"
              role="button"
              tabindex="0"
              [attr.id]="'accordion-header-' + index"
              [attr.aria-controls]="'accordion-body-' + index">
              <div (click)="accordionItem.toggle()">
                {{ layer.name }}
                <span>
                  @if (accordionItem.expanded) {
                    <i class="fa-solid fa-chevron-up"></i>x
                  }
                  @else {
                    <i class="fa-solid fa-chevron-down"></i>
                  }
                </span>
              </div>
              <div
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">
                @for (sl of layer.subLayers; track sl) {
                  <mat-radio-button class="layer-radio-button" [value]="sl">{{sl.name}}</mat-radio-button>
                }
              </div>
            </cdk-accordion-item>
          } @else {
            <mat-radio-button class="layer-radio-button" [value]="layer">{{layer.name}}</mat-radio-button>
          }
        }
      </mat-radio-group>
    </cdk-accordion>
  }
</div>
<div class="footer">
  <button type="button" class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue flex gap--sm" (click)="setSelection()"
          [disabled]="isDisabled">Done</button>
</div>
