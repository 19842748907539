import { Injectable, computed, effect, inject } from '@angular/core';
import { MapService } from './map.service';
import { MapOverlayService } from './map-overlay.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectionService {
  private readonly mapService: MapService = inject(MapService);

  public CurrentUTMZone = computed(() => this.mapService.pointerCoordinate() == null ? 0 : this.getUTMCode(this.mapService.pointerCoordinate()[0]))


  constructor() {
 
  }

  getUTMCode(longitude) {
    return Math.floor((longitude + 180) / 6) + 1;
  }
}
