import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, computed, effect, input, output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, map, startWith } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TuiLabel, tuiNumberFormatProvider, TuiTitle} from '@taiga-ui/core';
import {TuiSliderComponent} from '@taiga-ui/kit';
import {TuiTextfield} from '@taiga-ui/core';
import {TUI_DEFAULT_INPUT_COLORS, TuiInputColorModule,TuiInputNumberModule, tuiInputNumberOptionsProvider} from '@taiga-ui/legacy';
import { LegendIconComponent } from "../../../legend-icon/legend-icon.component";

interface lineStyleDefinition {
  color?: string | null;
  visibility?: boolean | null;
  width?: number | null;
  perpendicularOffset?: number | null;
  opacity?: number | null;
}

@Component({
  selector: 'app-line-style-form',
  templateUrl: './line-style-form.component.html',
  styleUrls: ['./line-style-form.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    TuiLabel,
    TuiSliderComponent,
    TuiTitle,
    TuiInputColorModule, TuiTextfield,
    TuiInputNumberModule,
    LegendIconComponent
],

})
export class LineStyleFormComponent implements OnInit {


  styleDefinition: Signal<lineStyleDefinition> = input();

  styleChanged = output<any>();
  preview = signal({
    name: 'null',
    symbolizers: [],
    filter: null,
  })

  form!: FormGroup;

  protected readonly palette = TUI_DEFAULT_INPUT_COLORS;


  constructor() {
    this.form = new FormGroup({
      kind: new FormControl('Line'),
      color: new FormControl('#0E1058'),
      visibility: new FormControl(true),
      width: new FormControl(3),
      perpendicularOffset: new FormControl(null),
      opacity: new FormControl(null),
    });

    effect(() => {
      const styleDefinition = this.styleDefinition();

      Object.keys(styleDefinition).forEach((key) => {
        const control = this.form.controls[key] as FormControl;
        const value = styleDefinition[key];

        if (control == null) {
          return;
        }
        control.setValue(value, { emitEvent: true });
      });
    });

    this.form.valueChanges
      .pipe(
        startWith(this.filterNullValues(this.form.getRawValue())),
        map((values: lineStyleDefinition) => this.filterNullValues(values)),
        debounceTime(100)
      )
      .subscribe((data) => {
        this.styleChanged.emit(data);
        this.preview.update(preview => ({...preview, symbolizers: [data]}))
      });
  }

  private filterNullValues(
    values: lineStyleDefinition
  ): Partial<lineStyleDefinition> {
    const filteredValues: Partial<any> = {};
    (Object.keys(values) as (keyof lineStyleDefinition)[]).forEach((key) => {
      if (values[key] !== null) {
        filteredValues[key] = values[key];
      }
    });
    return filteredValues;
  }

  ngOnInit() {}
}
