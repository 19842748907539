import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { TokenService } from '../../services/token.service';

  export function AuthGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
      const authService = inject(AuthService);
      const router = inject(Router);


      return authService.isAuthenticated().pipe(
        map(isAuthenticated => {
          if (!isAuthenticated) {
            router.navigate(['/login']);
            return false;
          }
          return true;
        }),
        catchError(() => {
          router.navigate(['/login']);
          return of(false);
        })
      );
  }
