import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { BehaviorSubject, switchMap } from 'rxjs';
import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { DataCatalogueService } from 'src/app/map/features/data-catalogue/data-access/services/data-catalogue.service';
import { CreateCatalogueCategory } from '../../../data-access/models/create-update-catelogue.model';

@Component({
  selector: 'app-create-catalogue-category-dialog',
  templateUrl: './create-catalogue-category-dialog.component.html',
  styleUrls: ['./create-catalogue-category-dialog.component.css'],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  standalone: true,
})
export class CreateCatalogueCategoryDialogComponent implements OnInit {
  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly dialogRef: DialogRef<CreateCatalogueCategoryDialogComponent> =
    inject(DialogRef<CreateCatalogueCategoryDialogComponent>);
  private readonly dialog: Dialog = inject(Dialog);

  private loadDataTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );

  @Output() createEvent = new EventEmitter();

  form: FormGroup;

  groupsObservable = this.loadDataTrigger$.pipe(
    switchMap(() => this.catalogueService.loadCatalogueGroups())
  );

  constructor(
    @Inject(DIALOG_DATA) protected data: { dataCatalogueGroupID?: number }
  ) {
    this.form = new FormGroup({
      name: new FormControl('', CustomValidators.required),
      description: new FormControl(''),
      dataCatalogueGroupID: new FormControl(
        data.dataCatalogueGroupID ?? -1,
        CustomValidators.required
      ),
    });
  }

  ngOnInit() {}

  createNewGroup() {
    this.catalogueService.createNewGroup(
      this.form,
      this.dialog,
      this.loadDataTrigger$
    );
  }

  cancel() {
    this.createEvent.emit(CreateEvent.cancel<CreateCatalogueCategory>());
    this.dialogRef.close();
  }

  submit() {
    let model: CreateCatalogueCategory = {
      Name: this.form.get('name').value,
      Description: this.form.get('description').value,
      DataCatalogueGroupID: this.form.get('dataCatalogueGroupID').value,
    };

    this.createEvent.emit(CreateEvent.create<CreateCatalogueCategory>(model));
    this.dialogRef.close();
  }
}
