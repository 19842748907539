import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConsoleService } from './console.service';

@Component({
  selector: 'console',
  standalone:true,
  imports:[CommonModule],
  template: `
  @for (message of messages(); track $index) {
    <div class="console-log">
  {{ message }}
</div>
  }`,
  styles: `.console-log {
    white-space: pre-wrap;
  }`,
  host: {
    'style': `
      background-color: #1e1e1e;
      color: #dcdcdc;
      font-family: monospace;
      padding: 10px;
      min-height: 300px;
      overflow-y: auto;
      border-radius: 5px;
      border: 1px solid #444;
    `
  }
})
export class ConsoleComponent implements OnInit {

  private readonly consoleService: ConsoleService = inject(ConsoleService);

  messages = toSignal(this.consoleService.messages);

  constructor() {

    effect(() => {
      const messages = this.messages();

      this.scrollToBottom();
    })
   }

  ngOnInit() {
  }


  private scrollToBottom() {
    const container = document.querySelector('.console-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }

}
