<ng-template actionPortal>
  <div class="grid grid-cols-3 px-2 items-center flex-grow-1">
  <h3 class="justify-self-center text-base font-semibold text-gray-800  col-start-2" >Profile</h3>
  </div>
</ng-template>

@if (user() == null) {
<div>
  <ngx-skeleton-loader class="profile-section" count="1"></ngx-skeleton-loader>
  <ngx-skeleton-loader class="profile-image" count="1"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
</div>
}
@else {
    <div class="profile-section">
        <div class="profile-image">
            <img (load)="imageLoaded = true" [hidden]="!imageLoaded" [alt]="user.firstName | initials:user.lastName">
            <div class="image-placeholder" [hidden]="imageLoaded">{{user().firstName | initials:user().lastName}}</div>
          </div>
        <h2>{{ user().firstName }} {{ user().lastName }}</h2>
        <h3 *ngxPermissionsOnly="['Administrator']">(Administrator)</h3>
        <p>{{ user().email }}</p>
      </div>
}
<mat-divider></mat-divider>
<mat-nav-list class="mat-list-container">
  <a
    *ngxPermissionsOnly="['Administrator']"
    class="dashboard-button"
    mat-list-item
    [routerLink]="['/admin']"
    >Admin Dashboard</a
  >
  <a (click)="resetPassword()" mat-list-item>Change Password</a>
  <button class="logout-button" (click)="authService.logout()">
    <i class="fa fa-sign-out-alt"></i> Logout
  </button>
</mat-nav-list>
