import { Component, ElementRef, OnInit, computed, effect, inject, model, output, signal, viewChild } from '@angular/core';
import { Layer } from 'ol/layer';
import { BasemapsService } from 'src/app/map/data-access/Basemaps.service';
import { DataCatalogueService } from 'src/app/map/features/data-catalogue/data-access/services/data-catalogue.service';
import OlMap from 'ol/Map';
import View from 'ol/View';
import { transform } from 'ol/proj';
import { CommonModule } from '@angular/common';
import LayerGroup from 'ol/layer/Group';
import VectorSource from 'ol/source/Vector';
import { TileArcGISRest, TileWMS, Vector, VectorTile, XYZ } from 'ol/source';

export interface LayerPreviewDefinition {
  url: string,
  sourceType: string,
  attribution?: string,
  params?: object
}

@Component({
  selector: 'mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class MiniMapComponent implements OnInit {

  private readonly baseMapsService: BasemapsService = inject(BasemapsService);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  layerDefinition = model.required<LayerPreviewDefinition>();

  mapElement = viewChild.required<ElementRef<HTMLElement>>('map');

  loadErrored = output<boolean>();
  loaded = output<boolean>();


  loading = signal(false);
  error = signal(false);




  private readonly initialCenter = [146.6, -30.83];
  private readonly initialZoom = 2.5;
  private layer = computed(() => {
    const layerDefinition = this.layerDefinition();

    return layerDefinition == null ? null : this.buildLayer(layerDefinition);
  })

  private map = computed(() => {

    const mapElement = this.mapElement();

    return new OlMap({
      layers: [],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.initialZoom,
        maxZoom: 23,
      }),
      target: mapElement.nativeElement,
      controls: [],
    });
  });

  constructor() {

    effect(async () => {
      const map = this.map();

      const baseMapsGroup = await this.generateBaseMapGroup();

      map.getLayers().insertAt(0, baseMapsGroup);
    });

    effect(() => {
      const layer = this.layer();

      if(layer == null)
        {
          return;
        }

        this.addEventListeners(layer);
    });

    effect(() => {

      const map = this.map();
      const layer = this.layer();

      if(layer == null || map == null) return;

      if(map.getLayers().getLength() > 1)
        {
          map.getLayers().removeAt(1);
        }

      map.addLayer(layer);

    })
  }

  ngOnInit() {
  }


  async generateBaseMapGroup() {
    let layers = await this.baseMapsService.buildLayers();
    let baseMapsGroup = new LayerGroup({
      properties: { title: 'Background Maps' },
      layers: layers,
    });
    return baseMapsGroup;
  }

   buildLayer(layerDefinition: LayerPreviewDefinition)
  {
    switch (layerDefinition.sourceType) {
      case 'VectorImage':
      case 'Vector':
      case 'WFS':
      case 'ArcGISFeatureService':
        return this.dataCatalogueService.getVectorImageLayer(layerDefinition.url, layerDefinition.sourceType, layerDefinition.params);
      case 'TileWMS':
        return this.dataCatalogueService.getTileWMSLayer(layerDefinition.url, layerDefinition.params);
      case 'ArcGISRest':
        return this.dataCatalogueService.getTileArcGISRestLayer(layerDefinition.url, layerDefinition.params);
      case 'XYZ':
        return  this.dataCatalogueService.getTileXYZLayer(layerDefinition.url, layerDefinition.attribution);
      default:
        return null
    }
  }

  addEventListeners(layer) {
    const source = layer.getSource();
    if (source) {
      if (source instanceof VectorSource) {

        source.on('featuresloadstart', () => {
          this.loading.set(true);
          this.error.set(false);
          this.loadErrored.emit(false);

        })
        source.on('featuresloadend', () => {
            this.loaded.emit(true);
            this.loading.set(false);
            this.error.set(false);
            this.loadErrored.emit(false);
        });
        source.on('featuresloaderror', () => {
          this.loaded.emit(false);
          this.loadErrored.emit(true);
          this.error.set(true);
        });
      } else if (source instanceof TileWMS || source instanceof TileArcGISRest || source instanceof XYZ || source instanceof VectorTile) {

        source.on('tileloadstart', () => {
          this.loading.set(true);
          this.error.set(false);
          this.loadErrored.emit(false);
        });
        source.on('tileloadend', () => {
            this.loaded.emit(true);
            this.loadErrored.emit(false);
            this.loading.set(false);
            this.error.set(false);
        });
        source.on('tileloaderror', () => {
          this.loaded.emit(false);
          this.loadErrored.emit(true);
          this.error.set(true);
        });
      }
    }
  }

}
