import { trigger, state, style, transition, animate } from '@angular/animations';
import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'dialog-container',
  styles: [
    `
      :host {
        min-width: clamp(min(10vw, 20rem), 400px, max(90vw, 55rem));
        max-height: 95vh;
        overflow: hidden;
      }
    `,
  ],
  standalone: true,
  template: '<ng-template cdkPortalOutlet></ng-template>',
  imports: [DialogModule],
  animations: [
    trigger('dialogAnimation', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'scale(0.8)',
        })
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('void => *', [
        animate('300ms ease-out')
      ]),
      transition('* => void', [
        animate('200ms ease-in')
      ]),
    ]),
  ],
  host: {
    '[@dialogAnimation]': '',
    'class' : 'bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-2'
  },
})
export class CustomDialogContainer extends CdkDialogContainer {}
