import { Component, computed, contentChild, OnInit, output, TemplateRef } from '@angular/core';
import { DialogHeaderDirective } from './directives/dialog-header.directive';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { DialogFooterDirective } from './directives/dialog-footer.directive';
import { CommonModule } from '@angular/common';
import { TuiScrollbar } from '@taiga-ui/core';
import { OverflowPaddingDirective } from '../../directives/overflow-padding.directive';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  standalone: true,
  imports: [CommonModule, DialogContentDirective,DialogFooterDirective,DialogHeaderDirective, TuiScrollbar, OverflowPaddingDirective],
  host:{
    class: 'grid grid-rows-[50px_1fr_auto] h-full bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden mx-auto'
  }
})
export class DialogComponent implements OnInit {

  close = output();

  headerSelector = contentChild(DialogHeaderDirective)

  headerRef = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.headerSelector();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }

    return contentChild.templateRef;
  })


  contentSelector = contentChild(DialogContentDirective)

  contentRef = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.contentSelector();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }

    return contentChild.templateRef;
  })


  footerSelector = contentChild(DialogFooterDirective)

  footerRef = computed<TemplateRef<any> | null>(() =>
  {
    const contentChild = this.footerSelector();

    if(contentChild == null || contentChild == undefined)
    {
      return null
    }
    return contentChild.templateRef;
  })
  constructor() { }

  ngOnInit() {
  }

  onClose()
  {
    this.close.emit();
  }

}
