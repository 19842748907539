@if(property() != null)
{
  <table class="property-table">
    @for (field of selectedFields(); track $index) {
      <tr>
        <td>{{field | formatString}}</td>
        <td>{{ property()[field] }}</td>
      </tr>
    }
  </table>

}
