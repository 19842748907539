import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { signal, WritableSignal } from '@angular/core';
import { transformExtent } from 'ol/proj';
import { ElementLayoutDetails } from './models/models';

export const parseAndSignalElement = (element: any) =>
  Object.keys(element).reduce((newElement: any, key: string) => {
    let value;
    try {
      value = JSON.parse(element[key]);
      if (!Array.isArray(value)) {
        value = element[key];
      }
    } catch {
      value = element[key];
    }
    newElement[key] = signal(value);
    return newElement;
  }, {});

export const getExtent = ({ map, from = 'EPSG:3857', to = 'EPSG:4326' }) => {
  const extent3857 = map.getView().calculateExtent(map.getSize());

  return transformExtent(extent3857, from, to);
};

export const calculateMapScale = ({ units, resolution }) => {
  const INCHES_PER_UNIT = {
    m: 39.37,
    dd: 4374754,
  };
  const DOTS_PER_INCH = 72;

  const scale = Math.round(INCHES_PER_UNIT[units] * DOTS_PER_INCH * resolution);

  return scale;
};


export const mapElement = (element: ElementLayoutDetails) =>  {
  let state: Record<string, any> = {};
      for (let key in element) {
          if (Object.prototype.hasOwnProperty.call(element, key)) {
              const value = element[key]();
              state[key] = Array.isArray(value) ? JSON.stringify(value) : value;
          }
      }
  return state;
}

export const getFilenameFromContentDisposition = (
  contentDisposition: string | null
): string  => {
  if (!contentDisposition) return 'defaultFilename.txt';
  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
    contentDisposition
  );
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  } else {
    return 'defaultFilename.txt';
  }
}

export const saveFile = (data: Blob, filename: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(data);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

