import { Injectable } from '@angular/core';
import { BaseEndpointService, IEndpoint } from 'src/app/@core/interfaces/IEndpoint';
import { PropertyStage } from '../models/property-stage.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyStageService extends BaseEndpointService implements IEndpoint {

constructor() {

  super({route: 'property-stage'})
 }


  getAll() {
    return this.http.get<Array<PropertyStage>>(this.endpoint)
  }
  get(id: string) {
    throw new Error('Method not implemented.');
  }
  delete(id: string) {
    throw new Error('Method not implemented.');
  }
  update(id: String, model: {}) {
    throw new Error('Method not implemented.');
  }

}
