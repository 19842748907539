

<div class="p-4 bg-white rounded shadow">
  <span class="text-lg font-semibold">Select a Property</span>
  <div class="mt-4 grid gap-2">

    @for (property of properties(); track $index) {
      <button class="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300" (click)="select(property)">{{property.projectName ? property.projectName : property.propertyName}}</button>
    }

  </div>
</div>
