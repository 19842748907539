<button
  #zoomAreaButton
  appTooltip="Zoom to Area"
  class="esk-tool"
  [ngClass]="{ 'esk-tool--active': active === true }"
  (click)="toggle()"
>
  <i class="fa fa-search-plus"></i>
  <i class="fa-regular fa-square"></i>
</button>
