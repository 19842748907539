import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, Subscription, catchError, concat, filter, first, of, switchMap, take, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { AuthService } from '../Auth/auth.service';


const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userSub: Subscription;

  private exclusionList: string[] = [
    '/auth/login',
    '/auth/token/refresh',

  ];



  constructor(private tokenService:TokenService, private authService: AuthService) {

   }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isInExclusionList = this.exclusionList.some(url => req.url.includes(url));

    if(isInExclusionList)
    {
      return next.handle(req.clone({
        withCredentials: true
    }));
    }

    const authHeader = req.headers.get('Authorization');
    if (authHeader) {
      const clonedReq = req.clone({
        withCredentials: true,
        headers: req.headers });
        
      return next.handle(clonedReq);
    }

    let authReq = req;
    if (this.tokenService.getAccessToken()) {
      authReq = req.clone({withCredentials:true})
    }
    return next.handle(authReq).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
      const httpErrorCode: number = error['status'];
      switch (httpErrorCode) {
          case 404:
            return throwError(() => new Error(`${error}`));
          case 401:
            return this.handle401Error(authReq, next);
          default:
            return throwError(() => new Error(`${error}`));
         }
         } else {
           return throwError(() => new Error(`${error}`));
         }
     }));
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.isRefreshingToken$.pipe(
      first(),
      switchMap(refreshStatus => {
        switch (refreshStatus.status) {
          case 'SUCCESSFUL':
            return this.handleRequestWithToken(request,next);
          case 'FAILED':
            return throwError(() => Error('Token refresh failed'));
          case 'NOT STARTED':
            return this.authService.refreshToken().pipe(switchMap(() => this.handleRequestWithToken(request,next)))
          case 'IN PROGRESS':
            return this.authService.isRefreshingToken$.pipe(
              filter(({ status }) => status === 'SUCCESSFUL' || status === 'FAILED'),
              take(1),
              switchMap(updatedStatus => {
                if (updatedStatus.status === 'SUCCESSFUL') {
                  return this.handleRequestWithToken(request,next)
                } else {
                  this.authService.logout();
                  return throwError(() => Error('Token refresh failed after waiting'));
                }
              })
            );
          default:
            return throwError(() => Error('Unexpected refresh token status'));
        }
      })
    );
  }

  private handleRequestWithToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokenInHeader(request, this.tokenService.getAccessToken()));
  }

addTokenInHeader(
    request: HttpRequest<any>,
    token: string
): HttpRequest<any> {
    return request.clone({
       // setHeaders: { Authorization: 'Bearer ' + token, },
        withCredentials: true
    });
}
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
