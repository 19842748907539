<!-- <header class="dialog-titlebar">
  <ng-container *ngTemplateOutlet="headerRef()"></ng-container>
  <button class="button button--icon" (click)="onClose()"><i class="fa fa-x"></i></button>
</header>
<tui-scrollbar>
<section overflowPadding class="dialog-content">

    <ng-container *ngTemplateOutlet="contentRef()"></ng-container>

</section>
</tui-scrollbar>
<footer class="dialog-footer">
  <ng-container *ngTemplateOutlet="footerRef()"></ng-container>
</footer> -->


  <header class="flex justify-between items-center px-6 py-4 border-b dark:border-gray-700">

    <ng-container *ngTemplateOutlet="headerRef()"></ng-container>

    <button
      class="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none transition-colors duration-200"
      (click)="onClose()"
      aria-label="Close Dialog"
    >
      <i class="fa fa-times"></i>
    </button>
  </header>


  <tui-scrollbar>
    <section overflowPadding class="px-6 py-4 text-gray-700 dark:text-gray-200">
      <ng-container *ngTemplateOutlet="contentRef()"></ng-container>
    </section>
  </tui-scrollbar>


  <footer class="flex justify-end items-center px-6 py-4 border-t dark:border-gray-700 gap-4">
    <ng-container *ngTemplateOutlet="footerRef()"></ng-container>
  </footer>
