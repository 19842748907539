import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Signal, computed, effect, inject } from '@angular/core';
import {
  EMPTY,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';

import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import {
  BaseEndpointResource,
  BaseEndpointService,
  IEndpoint,
} from 'src/app/@core/interfaces/IEndpoint';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Property } from './models/property.model';


@Injectable({ providedIn: 'root' })
export class PropertiesService
  extends BaseEndpointResource<'properties'>
  implements IEndpoint
{
  private readonly modalService: ModalService = inject(ModalService);
  private readonly router: Router = inject(Router);





  constructor() {
    super({ routeTemplate: 'properties' });
  }


   hideAllFeaturesExcept(propertyID) {

    const hiddenStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0)', // Fully transparent stroke
        width: 0
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)' // Fully transparent fill
      })
    });

    // this.propertyGroup.getLayers().forEach(lyr => {
    //   if(lyr instanceof LayerGroup)
    //     return;

    //   if(lyr instanceof VectorLayer || lyr instanceof VectorImageLayer)
    //   {
    //     const source =  lyr.getSource() as VectorSource;

    //     source.getFeatures().forEach(feature => {
    //       const id = feature.get('id');
    //       if (id !== propertyID) {
    //         feature.setStyle(hiddenStyle);
    //       }
    //     })
    //   }
    // })
  }

  showPropertyDetails(propertyID) {
    this.router.navigate([`/map/property`, propertyID]);
  }

  get(propertyID: string) {



    return this.http
      .get<Property>(`${this.endpoint}/${propertyID}`, {headers: this.headers})
      .pipe(tap((property) =>  {
      }));
  }

  getSelectFields()
  {
    return this.http.get<Array<string>>(`${this.endpoint}/form`)
  }
  delete(propertyID: any) {
    return this.http
      .delete(`${this.endpoint}/${propertyID}`)
  }

  update(propertyID, model) {
    return this.http.patch<string>(`${this.endpoint}/${propertyID}`, model)
  }

  create(wkt:string) {
    return this.http.post<string>(this.endpoint, { wkt: wkt });
  }


  getAll() {
    return this.http.get<any[]>(this.endpoint).pipe(
      map((data) => {
        return data.map((item) => {
          return {
            ...item,
            listingDate: new Date(item.listingDate),
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          };
        });
      }),
    );
  }

  getFileURL(propertyID: string, safeName: string) {
    return `${this.endpoint}/${propertyID}/files/${safeName}` as const;
  }

  deleteFile(propertyID: string, file) {
    let url = this.getFileURL(propertyID, file.safeName);

    return this.modalService.showConfirmDelete('File', file.unsafeName).pipe(
      filter((result) => result.ok),   map(result => result.val),
      switchMap((result) =>
        {

          if(result == false)
             return EMPTY;

          return this.http.delete(url)
        })
    );
  }
  generateCSVExport(filters, sortBy) {
    return this.http.post(
      `${this.endpoint}/export/csv`,
      { filters: filters, sortBy: sortBy },
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  exportSpatialData(propertyID, format)
  {
    return this.http.get(`${this.endpoint}/${propertyID}/export?f=${format}`, { responseType: 'blob', observe: 'response' })
  }
}
