
<section class="form-container">
  <header>
      <h1>Reset Password</h1>
  </header>
  <main>
    <form [formGroup]="form"  (ngSubmit)="onSubmit()" class="reset-password-form text-field-rounded-4">
      <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
          <mat-error *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
            <div *ngIf="!form.get('password').errors['required']">
              Password is required.
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Repeat password</mat-label>
          <input matInput formControlName="repeatPassword" type="password">
          <mat-error *ngIf="form.get('repeatPassword').invalid && (form.get('repeatPassword').dirty || form.get('repeatPassword').touched)">
            <div *ngIf="!form.valid && form.get('password').touched  && form.get('repeatPassword').touched">
              Your passwords don't match
            </div>
          </mat-error>
        </mat-form-field>

    <div class="actions">
      <button type="submit" [disabled]="!form.valid" class="button button--brand button--full_width">Reset Password</button>
    </div>

    </form>
    <ng-container *ngIf="version">
      <span class="version_information">{{version}}</span>
    </ng-container>
  </main>
</section>
