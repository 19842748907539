import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import {MatDividerModule} from '@angular/material/divider'
import {MatListModule} from '@angular/material/list'
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { AccountService } from 'src/app/@core/Auth/account.service';
import { AuthService } from 'src/app/@core/Auth/auth.service';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { PasswordResetSelfComponent } from 'src/app/shared/components/password-reset-self/password-reset-self.component';
import { InitialsPipe } from 'src/app/shared/pipes/initials.pipe';
import { ActionPortalDirective } from 'src/app/shared/features/action-portal/directives/action-portal.directive';
import { AuthState } from 'src/app/@core/Auth/state';
import { SignalRService } from 'src/app/@core/services/signalR.service';


@Component({
  selector: 'profile-page',
  standalone: true,
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    NgxPermissionsModule,
    MatDividerModule,
    MatListModule,
    InitialsPipe,
    RouterModule,
    ActionPortalDirective
  ],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageComponent {

  public readonly authService = inject(AuthState);

  public readonly signalR = inject(SignalRService);


  private readonly modalService:ModalService = inject(ModalService);
  public readonly accountService:AccountService = inject(AccountService);

  user = toSignal(this.accountService.me(), {initialValue: null});
  imageLoaded = false;


  resetPassword()
  {
    this.modalService.showComponent<PasswordResetSelfComponent, any>(PasswordResetSelfComponent,{}).componentInstance.result.subscribe(result => {
      if(!result.success)
        {
          return;
        }

      this.accountService.resetPassword(result.model).subscribe()
    })
  }

  disconnect()
  {
    this.signalR.stopConnection().subscribe();
  }

  connect()
  {
    this.signalR.startConnection();
  }


}
