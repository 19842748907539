import { Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';

type ObjectConstraint = { [key: string]: any };

export function generateComputedModel<T extends ObjectConstraint>(
  baseModel: T,
  form: UntypedFormGroup
): Signal<T> {
  const changes: Signal<any> = toSignal(form.valueChanges);

  return computed(() => {
    let _updatedModel = { } as T;

    Object.keys(baseModel).forEach(key => {
      const value = baseModel[key];

      if(value === null || value === undefined)
        return; 

      (_updatedModel as any)[key] = value;
    })

    if (changes() == null) {
      return _updatedModel;
    }

    const currentChanges = changes();
    Object.keys(form.controls).forEach((key) => {
      if (form.controls[key].dirty && baseModel.hasOwnProperty(key)) {
        (_updatedModel as any)[key] = currentChanges[key];
      }
    });

    return _updatedModel;
  }, {});
}
