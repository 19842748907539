import { Injectable, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, Type, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComponentFactory {

    target: ViewContainerRef
  constructor(
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  setTarget(target: ViewContainerRef)
  {
    this.target = target
  }

  public appendComponentToBody<T>(component: Type<T>): ComponentRef<T> {
    const componentRef = this.target.createComponent(component);

   // this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;

   // document.body.appendChild(domElem);

    return componentRef;
  }
}
