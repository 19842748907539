<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title>General</mat-panel-title>
  </mat-expansion-panel-header>
  <form class="flex flex-column gap--2" [formGroup]="form">

    <tui-input formControlName="label">
      Template
      <input
          tuiTextfieldLegacy
      />
  </tui-input>

    <tui-input-color
    class="b-form"
    [colors]="palette"
    formControlName="color"
>
 Color
</tui-input-color>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Font</mat-label>
      <mat-select formControlName="font" multiple>
        <mat-option *ngFor="let option of fontOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  </form>
</mat-expansion-panel>
<div class="preview-section">
  <h3>Preview</h3>
  <div class="preview-container">
    <legend-icon [rule]="preview()"></legend-icon>
  </div>
</div>