import { BaseLayerType } from '../data-access/Basemaps.service';

export var basemapDefinitions: BaseLayerType[] = [
  {
    active: true,
    display_name: 'Bing Aerial with labels',
    epsg: 3857,
    // "extent_wgs84": "[143.82, -43.66, 148.48, -39.58]",
    id: 1,
    matrix_set: '',
    max_zoom: 19,
    min_zoom: 0,
    name: 'BING_Aerial_label',
    type: 'Bing',
    bing_key:
      'AppbT2soAvXyIHIrHzH1lHNO_YhAoKZfwLr6vMAz0_OHW9b5WvATS4KBO7ycDSu3',
    bing_imagery_set: 'AerialWithLabelsOnDemand',
    options: '',
    order: 1,
    visible: true,
  },
  {
    active: true,
    attribution_text:
      'Basemap \u00a9 Esri, Maxar, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
    attribution_url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer?f=json',
    display_name: 'Esri World Image',
    epsg: 3857,
    id: 3,
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_ARCGIS_WORLD',
    type: 'XYZ',
    options: '',
    order: 3,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: '73ac03d7-08d8-4b8a-a344-a02450fa3cd7',
  },
  {
    active: true,
    attribution_text: '© OpenStreetMap contributors',
    attribution_url: null,
    display_name: 'OpenStreetMap',
    epsg: 3857,
    id: 4,
    matrix_set: '',
    max_zoom: 19,
    min_zoom: 0,
    name: 'OSM_Base',
    type: 'XYZ',
    options: '',
    order: 2,
    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    visible: false,
    version_id: 'osm_version_1',
  },
  {
    active: true,
    attribution_text: '© The LIST, Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Topographic/MapServer?f=json',
    display_name: 'TAS: The LIST Topo',
    epsg: 900913,
    id: 'WMTS_LIST_TOPO',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_LIST_TOPO',
    type: 'XYZ',
    options: '',
    order: 2,
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Topographic/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_list_topo_version',
  },
  {
    active: true,
    attribution_text: '© The LIST, Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/ESgisMapBookPUBLIC/MapServer?f=json',
    display_name: 'TAS: ES Mapbook',
    epsg: 900913,
    id: 'WMTS_LIST_ES_MB',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_LIST_ES_MB',
    type: 'XYZ',
    options: '',
    order: 3,
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/ESgisMapBookPUBLIC/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_list_es_mb_version',
  },
  {
    active: true,
    attribution_text: '© The LIST, Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/TasmapRaster/MapServer?f=json',
    display_name: 'TAS: Tasmap Raster',
    epsg: 900913,
    id: 'WMTS_LIST_Tas_Raster',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_LIST_Tas_Raster',
    type: 'XYZ',
    options: '',
    order: 4,
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/TasmapRaster/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_list_tas_raster_version',
  },
  {
    active: true,
    attribution_text: '© The LIST, Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Orthophoto/MapServer?f=json',
    display_name: 'TAS: The LIST Ortho',
    epsg: 900913,
    id: 'WMTS_LIST_ORTHO',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_LIST_ORTHO',
    type: 'XYZ',
    options: '',
    order: 5,
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Orthophoto/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_list_ortho_version',
  },
  {
    active: true,
    attribution_text: '© The LIST, Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer?f=json',
    display_name: 'TAS: The LIST Hillshade',
    epsg: 900913,
    id: 'WMTS_LIST_HILL',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_LIST_HILL',
    type: 'XYZ',
    options: '',
    order: 6,
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_list_hill_version',
  },
  {
    active: true,
    attribution_text: '© SIX, NSW',
    attribution_url:
      'http://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer?f=json',
    display_name: 'NSW: SIX Photo',
    epsg: 900913,
    id: 'WMTS_SIX_ORTHO',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_SIX_ORTHO',
    type: 'XYZ',
    options: '',
    order: 7,
    url: 'http://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_six_ortho_version',
  },
  {
    active: true,
    attribution_text: '© SIX, NSW',
    attribution_url:
      'http://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Base_Map/MapServer?f=json',
    display_name: 'NSW: SIX Topo',
    epsg: 900913,
    id: 'WMTS_SIX_Topo',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_SIX_Topo',
    type: 'XYZ',
    options: '',
    order: 8,
    url: 'http://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Base_Map/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_six_topo_version',
  },
  {
    active: true,
    attribution_text: '© QLD Government',
    attribution_url:
      'https://spatial-gis.information.qld.gov.au/arcgis/rest/services/Basemaps/QldMap_Topo/MapServer?f=json',
    display_name: 'QLD: Topo',
    epsg: 900913,
    id: 'WMTS_QLD_Topo',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_QLD_Topo',
    type: 'XYZ',
    options: '',
    order: 9,
    url: 'https://spatial-gis.information.qld.gov.au/arcgis/rest/services/Basemaps/QldMap_Topo/MapServer/tile/{z}/{y}/{x}',
    visible: false,
    version_id: 'wmts_qld_topo_version',
  },
  {
    active: true,
    attribution_text: '© LINZ, New Zealand',
    attribution_url: null,
    display_name: 'NZ: LINZ Topo 50',
    epsg: 900913,
    id: 'WMTS_NZ_TOPO_50',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_NZ_TOPO_50',
    type: 'XYZ',
    options: '',
    order: 10,
    url: 'https://tiles-a.data-cdn.linz.govt.nz/services;key=0b53284c868440f4bb3f2df102628b3a/tiles/v4/layer=52343/EPSG:3857/{z}/{x}/{y}.png',
    visible: false,
    version_id: 'wmts_nz_topo_50_version',
  },
  {
    active: true,
    attribution_text: '© LINZ, New Zealand',
    attribution_url: null,
    display_name: 'NZ: LINZ Aerial',
    epsg: 900913,
    id: 'WMTS_NZ_Aerial',
    matrix_set: '',
    max_zoom: 23,
    min_zoom: 0,
    name: 'WMTS_NZ_Aerial',
    type: 'XYZ',
    options: '',
    order: 11,
    url: 'https://tiles-a.data-cdn.linz.govt.nz/services;key=0b53284c868440f4bb3f2df102628b3a/tiles/v4/set=2/EPSG:3857/{z}/{x}/{y}.png',
    visible: false,
    version_id: 'wmts_nz_aerial_version',
  },
];

export var defaultLayers = [
  {
    active: true,
    attribution_text: ' \u00a9 State of Tasmania',
    attribution_url:
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer?f=json',
    display_name: 'EnterpriseSuitabilityMapping2018',
    epsg: 3857,
    extent_wgs84_ulx: 143.82,
    extent_wgs84_uly: -43.66,
    extent_wgs84_lrx: 148.48,
    extent_wgs84_ury: -39.58,
    id: '72c16242-abe3-49a0-add0-52280603dd82',
    matrix_set: '',
    max_zoom: 18,
    min_zoom: 0,
    legend_layer_ids: new Set([5]),
    name: 'ESM_2018_Cherry',
    ol_type: 'ArcGISREST',
    params: {
      TRANSPARENT: 'true',
      OPACITY: 1,
      LAYERS: 'show:5',
      f: 'image',
      FORMAT: 'png',
    },
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/EnterpriseSuitabilityMapping2018/MapServer',
    visible: false,
    version_id: 'ae945263-6640-4eed-b675-266c280bd4db',
  },
];
