import {
  Component,
  EventEmitter,
  inject,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SharedModule } from '../../../../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../../../../../material/material.module';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CatalogueCategory } from '../../../../../data-access/models/catalogue-data.model';
import { CustomValidators } from '../../../../../../../../shared/extensions/CustomValidators';
import { BehaviorSubject, switchMap } from 'rxjs';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { UpdateCatalogueCategory } from '../../../data-access/models/create-update-catelogue.model';

@Component({
  selector: 'app-update-catalogue-category-dialog',
  templateUrl: './update-catalogue-category-dialog.component.html',
  styleUrls: ['./update-catalogue-category-dialog.component.css'],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  standalone: true,
})
export class UpdateCatalogueCategoryDialogComponent implements OnInit {
  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly dialog: Dialog = inject(Dialog);
  private readonly dialogRef: DialogRef<UpdateCatalogueCategoryDialogComponent> =
    inject(DialogRef<UpdateCatalogueCategoryDialogComponent>);
  private loadDataTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );

  @Output() updateEvent: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  catalogueCategoryToUpdate: CatalogueCategory;

  groupsObservable = this.loadDataTrigger$.pipe(
    switchMap(() => this.catalogueService.loadCatalogueGroups())
  );
  constructor(@Inject(DIALOG_DATA) protected data: CatalogueCategory) {
    this.catalogueCategoryToUpdate = data;
    this.form = new FormGroup({
      name: new FormControl(data.name, CustomValidators.required),
      description: new FormControl(''),
      dataCatalogueGroupID: new FormControl(
        data.dataCatalogueGroupID,
        CustomValidators.required
      ),
    });
  }

  ngOnInit() {}

  cancel() {
    this.updateEvent.emit(CreateEvent.cancel<UpdateCatalogueCategory>());
    this.dialogRef.close();
  }

  submit() {
    let model: UpdateCatalogueCategory = {
      id: this.catalogueCategoryToUpdate.dataCatalogueCategoryID,
      Name: this.form.get('name').value,
      Description: this.form.get('description').value,
      DataCatalogueGroupID: this.form.get('dataCatalogueGroupID').value,
    };

    this.updateEvent.emit(CreateEvent.create<UpdateCatalogueCategory>(model));
    this.dialogRef.close();
  }

  createNewGroup() {
    this.catalogueService.createNewGroup(
      this.form,
      this.dialog,
      this.loadDataTrigger$
    );
  }
}
