<div class="measure-type-group">
  <button
  class="esk-tool"
    [class.esk-tool--active]="selectedMeasureType() === 'Polygon'"
    (click)="selectMeasureType('Polygon')"
  >
  <i class="fa fa-draw-polygon"></i>
  </button>
  <button
 class="esk-tool"
    [class.esk-tool--active]="selectedMeasureType() === 'LineString'"
    (click)="selectMeasureType('LineString')"
  >
  <i class="fa fa-ruler"></i>
  </button>
  <button
 class="esk-tool"
    [class.esk-tool--active]="selectedMeasureType() === 'Circle'"
    (click)="selectMeasureType('Circle')"
  >
  <i class="fa fa-circle"></i>
  </button>
</div>
