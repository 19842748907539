import { trigger, transition, style, animate } from '@angular/animations';
import { Component, computed, input, OnInit } from '@angular/core';
import { toolbarAnimation } from './toolbar.animation';
import { twMerge } from 'tailwind-merge'

const baseClasses = [
  'bg-white',
  'bg-opacity-80',
  'text-black',
  'fixed',
  'inline-flex',
  'items-center',
  'gap-2',
  'p-2',
  'rounded-md',
  'shadow-md',
  'w-auto',
  'text-lg',
  'z-[99999]'
];

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  standalone:true,
  animations: [
    toolbarAnimation
  ],
  host: {
    '[@toolbarAnimation]': 'animationParams()',
    '[class]': '_computedClass()',
  },
})
export class ToolbarComponent implements OnInit {

  orientation = input<'horizontal' | 'vertical'>('horizontal');

  position = input<'top' | 'left'| 'right' |'bottom'>('top');

  userClasses = input<string>('',{alias: 'classes'})

  orientationClass = computed(() => this.orientation() === 'vertical' ? 'flex-col' : 'flex-row' );

  positionClasses = computed(() => {

    const position = this.position()

    switch (position) {
      case 'top':
        return ['top-1', 'left-1/2'];
      case 'bottom':
        return ['bottom-1', 'left-1/2',];
      case 'left':
        return ['left-1', 'top-1/2',];
      case 'right':
        return ['right-1', 'top-1/2',];
      default:
        return ['top-1', 'left-1/2',];
    }
  })


  animationTransforms = computed(() => {

    const position = this.position()

    switch (position) {
      case 'top':
        return { enterTransform: 'translateY(-50%)', leaveTransform: 'translateY(-50%)' };
      case 'bottom':
        return { enterTransform: 'translateY(50%)', leaveTransform: 'translateY(50%)' };
      case 'left':
        return { enterTransform: 'translateX(-50%)', leaveTransform: 'translateX(-50%)' };
      case 'right':
        return { enterTransform: 'translateX(-50%)', leaveTransform: 'translateX(-50%)' };
      default:
        return { enterTransform: 'translate(-50%, -100%)', leaveTransform: 'translate(-50%, -100%)' };
    }
  });

  animationParams = computed(() => {

    const animationTransforms = this.animationTransforms();

    return {value: '',
      params: animationTransforms
    }

  })

  protected _computedClass = computed(() =>

    {
      const orientationClass = this.orientationClass();
      const positionClasses = this.positionClasses();
      const userClasses = this.userClasses();

      const mergedClasses =  [...baseClasses, orientationClass,...positionClasses,...userClasses].join(' ')



      return  twMerge(mergedClasses) ;
    }
	);

  constructor() { }

  ngOnInit() {
  }

}
