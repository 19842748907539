import { Component, computed, effect, forwardRef, inject, input, model, ModelSignal, OnInit, output, signal, WritableSignal } from '@angular/core';
import { EskTool, ESKTOOL_TOKEN } from '../../util/esk-tool-interface';
import { Collection, Feature, Map as olMap } from 'ol';
import BaseLayer from 'ol/layer/Base';
import { Draw, Modify } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import { Style, Fill, Stroke, Image,Circle } from 'ol/style';
import { DrawEvent } from 'ol/interaction/Draw';
import { CircleToPolygon, Intersects, Intersect, Difference } from 'src/app/shared/helpers/transformations';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { filter, fromEvent } from 'rxjs';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { MapService } from '../../data-access/map.service';
import { CommonModule } from '@angular/common';
import { LineString } from '@turf/turf';
import { Polygon } from 'arcgis-rest-api';
import { primaryAction } from 'ol/events/condition';
import { ModifyEvent } from 'ol/interaction/Modify';
import { toSignal } from '@angular/core/rxjs-interop';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import * as _ from 'lodash';

@Component({
  selector: 'map-doughnut-modify',
  templateUrl: './doughnut-modify.component.html',
  styleUrls: ['./doughnut-modify.component.css'],
  standalone:true,
  imports:[CommonModule, TooltipDirective],
  providers: [
    { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => DoughnutModifyComponent) }
  ]
})
export class DoughnutModifyComponent implements OnInit, EskTool {

  private readonly dialogService:ModalService = inject(ModalService);
  public readonly mapService: MapService = inject(MapService);

  active: ModelSignal<boolean> = model(false);
  map = input.required<olMap>();
  userLayers = input<Array<VectorLayer<any> | VectorImageLayer<any>>>(null,{alias: 'layers'});
  userFeatures = input<Array<Feature>>(null,{alias: 'features'});

  modifiedFeatures = output<Array<Feature<any>>>({alias: 'modified'});


  fillColor: WritableSignal<string> = signal('rgba(255, 0, 0, 0.1)');
  strokeColor: WritableSignal<string> = signal('rgba(255, 0, 0, 1)');

  private source = signal(new VectorSource());

  private layer = computed( () => new VectorLayer({source: this.source(), style: this.style()}))


  private targetFeatures = computed(() => {
    const userLayers = this.userLayers();
    const userFeatures = this.userFeatures();

    const features = [];

    if(userLayers != null)
    {
      userLayers.forEach(layer => {
        const source = layer.getSource() as VectorSource<any>;
        features.push(...source.getFeatures())
      });
    }

    if(userFeatures != null)
    {
      features.push(...userFeatures)
    }

      return features;
  });

style = computed(() => {
  return new Style({
    fill: new Fill({ color: this.fillColor() }),
    stroke: new Stroke({ color: this.strokeColor() }),
    image: new Circle({
      fill: new Fill({ color: this.strokeColor() }),
      radius: 5,
    }),
  });
})

  drawInteraction = computed(() => {


      const drawInteraction = new Draw({
        type: "Polygon",
        source: this.source(),
        style: this.style(),
      });

      drawInteraction.on('drawend', () => {
        this.map().removeInteraction(this.drawInteraction())
        this.map().addInteraction(this.modifyInteraction());
        this.map().addLayer(this.layer());
      });


      return drawInteraction;

  });


  modifyInteraction = computed(() => {

    const source = this.source();

    const modifyInteraction = new Modify({
        source: source,

    });


    return modifyInteraction;

});


  constructor() {

    effect(() => {
      const active = this.active();
      const drawInteraction = this.drawInteraction();
      const modifyInteraction = this.modifyInteraction();

      if (drawInteraction == null) return;

      if (active) {
        this.map().addInteraction(drawInteraction);

      } else {
        this.map().removeInteraction(drawInteraction);
        this.map().removeInteraction(modifyInteraction);
        this.map().removeLayer(this.layer());
      }
    });

   }


  ngOnDestroy(): void {
    this.mapService.setupMapEvents();
    this.map().removeInteraction(this.drawInteraction());
    this.map().removeInteraction(this.modifyInteraction());
    this.map().removeLayer(this.layer());
    document.removeEventListener('keydown',this.hotKeyUndoLastPoint.bind(this) )
  }

  ngOnInit(): void {
    this.mapService.removeMapEvents();
    document.addEventListener('keydown', this.hotKeyUndoLastPoint.bind(this));
  }

  hotKeyUndoLastPoint(event: KeyboardEvent)
  {
    if(event.ctrlKey && event.key === 'z')
      {
        event.stopPropagation();
        event.preventDefault();

        this.drawInteraction()?.removeLastPoint();
      }
  }

  cancel()
  {
    this.dialogService.showConfirmation("Are you sure you want to cancel?").pipe(filter((result) => result.ok && result.val == true)).subscribe(() => {
      this.active.set(false);
    });

  }

  finished()
  {


    const drawnFeature = this.source().getFeatures()[0];
    this.dialogService.showConfirmation("Are you sure you want to preform a cut-out operation on this layer? ").pipe(filter((result) => result.ok && result.val == true)).subscribe(() => {
      const targetFeatures = this.targetFeatures();
      const updatedFeatures = [];

      targetFeatures.forEach(feature => {
      const intersects = Intersects(drawnFeature, feature );

      if(!intersects)
        return;

      const difference = Difference( feature, drawnFeature);

            feature.setGeometry(difference.getGeometry());

            updatedFeatures.push(feature);

    });
    this.source().clear();
    this.modifiedFeatures.emit(updatedFeatures);
    this.active.set(false);
  });

  }


}
