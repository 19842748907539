import LayerGroup from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import Layer, { Options } from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { ImageWMS, TileWMS } from 'ol/source';
import VectorSource from 'ol/source/Vector';


/**
 * Maps layer constructors to their respective options types.
 */
type LayerOptions<T extends Layer> =
  T extends VectorLayer<any> ? Options<VectorSource> :
  T extends VectorImageLayer<any> ? Options<VectorSource> :
  T extends ImageLayer<any> ? Options<ImageWMS> :
  T extends TileLayer<any> ? Options<TileWMS> :
  T extends LayerGroup ? Options<any> :
  any;

/**
 * Creates an OpenLayers layer of the specified type with given properties.
 *
 * @param layerType - The constructor of the layer type to create.
 * @param properties - An object containing properties to set on the layer.
 * @returns An instance of the specified layer type with properties set.
 */
export const createLayer = <T extends Layer>(
  layerType: new (options?: LayerOptions<T>) => T,
  properties: LayerOptions<T> & {}
): T => {
  const layer = new layerType(properties);

  // Set each property on the layer, overriding constructor options if necessary
  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      layer.set(key, properties[key]);
    }
  }

  return layer;
};
