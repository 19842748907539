import { map, tap } from 'rxjs';
import { Property } from './models/property.model';
import {
  addPropertyFeatures,
  removePropertyFeatures,
  clearPropertyLayers
} from './spatial';
import { createFeature } from 'src/app/shared/helpers/feature.helper';

export const updateStateOperator = ({ state }) =>
  map((property: Property) => ({
    properties: [property,...state().properties],
    propertyMap: {
      ...(state().propertyMap || {}),
      [property.propertyID]: property,
    },
  }));

export const processPropertyOperator = () =>
  map((property: Property) => processProperty(property));

export const processProperty = (property: Property | null) => {
  if (property == null) return null;

  if (property.primaryClassID != null) {
    const centroid = createFeature(property.centroidGeom, {
      primaryClassID: property.primaryClassID,
      id: property.propertyID,
    });

    property.propertyClassFeature = centroid;
  }

  const areaFeature = createFeature(property.geom, {
    id: property.propertyID,
    area: property.areaHaDisplay,
  });

  const boundaryFeature = createFeature(property.geom, {
    id: property.propertyID,
  });

  property.areaFeature = areaFeature;
  property.boundaryFeature = boundaryFeature;

  property.listingDate = new Date(property.listingDate);
  property.createdAt = new Date(property.createdAt);
  property.updatedAt = new Date(property.updatedAt);

  return property;
};

export const clearPropertyLayersOperator = ({ propertyLayers }) =>
  tap(() => {
    clearPropertyLayers(
      propertyLayers
    );
  });


export const addPropertyFeaturesOperator = ({ state, propertyLayers }) =>
  tap((property: Property) => {
    addPropertyFeatures(
      property,
      state().propertyClasses,
      propertyLayers.boundariesLayer,
      propertyLayers.managedBoundariesLayer,
      propertyLayers.areaLayer
    );
  });

export const updatePropertyStateOperator = ({ state }) =>
  map((property: Property) => {
    const properties = state().properties as Property[];
    const propertyMap = state().propertyMap || {};

    const index = properties.findIndex(
      (_property) => _property.propertyID === property.propertyID
    );
    let updatedProperties;
    let updatedPropertyMap = { ...propertyMap };

    if (index !== -1) {
      updatedProperties = [
        ...properties.slice(0, index),
        property,
        ...properties.slice(index + 1),
      ];
    } else {
      updatedProperties = [...properties, property];
    }

    updatedPropertyMap[property.propertyID] = property;

    return {
      properties: updatedProperties,
      propertyMap: updatedPropertyMap,
    };
  });

export const updatePropertyFeaturesOperator = ({ state, propertyLayers }) =>
  tap((property: Property) => {
    const properties = state().properties as Property[];
    const index = properties.findIndex(
      (_property) => _property.propertyID === property.propertyID
    );

    if (index !== -1) {
      const oldProperty = properties[index];

      removePropertyFeatures(
        oldProperty,
        state().propertyClasses,
        propertyLayers.boundariesLayer,
        propertyLayers.managedBoundariesLayer,
        propertyLayers.areaLayer
      );
    }

    addPropertyFeatures(
      property,
      state().propertyClasses,
      propertyLayers.boundariesLayer,
      propertyLayers.managedBoundariesLayer,
      propertyLayers.areaLayer
    );
  });

export const removePropertyFeaturesOperator = ({ state, propertyLayers }) =>
  tap((property: Property) => {
    removePropertyFeatures(
      property,
      state().propertyClasses,
      propertyLayers.boundariesLayer,
      propertyLayers.managedBoundariesLayer,
      propertyLayers.areaLayer
    );
  });

export const deletePropertyStateOperator = ({ state }) =>
  map((property: Property) => {
    const properties = state().properties as Property[];
    const propertyMap = state().propertyMap || {};

    const index = properties.findIndex(
      (_property) => _property.propertyID === property.propertyID
    );

    if (index !== -1) {
      const updatedProperties = [
        ...properties.slice(0, index),
        ...properties.slice(index + 1),
      ];

      const { [property.propertyID]: _, ...updatedPropertyMap } = propertyMap;

      return {
        properties: updatedProperties,
        propertyMap: updatedPropertyMap,
      };
    }

    return {
      properties,
      propertyMap,
    };
  });

export const deletePropertyFeaturesOperator = ({ state, propertyLayers }) =>
  tap((property: Property) => {
    removePropertyFeatures(
      property,
      state().propertyClasses,
      propertyLayers.boundariesLayer,
      propertyLayers.managedBoundariesLayer,
      propertyLayers.areaLayer
    );
  });
