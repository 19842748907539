import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  public errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
 this.errorMessage = data.errorMessage;

  }

}
