import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import {
  getAreaStyle,
  getPaddockStyle,
} from '../utils/map-syles/property.style';
import { Feature } from 'ol';
import { CovertWKTFeature } from 'src/app/shared/helpers/transformations';
import { Property } from './models/property.model';
import { createInjectable } from 'ngxtension/create-injectable';
import { effect, inject } from '@angular/core';
import { PropertiesState } from './state';
import { SpatialService } from '../features/feature-layers/data-access/spatial';
import LayerGroup from 'ol/layer/Group';
import { Style, Stroke, Fill } from 'ol/style';
import appRegex from 'src/app/shared/helpers/regex';
import { ActivatedRoute, Router } from '@angular/router';
import { MapOverlayService } from 'src/app/map/data-access/map-overlay.service';
import { createLayer } from 'src/app/shared/helpers/layer.helper';
import { EskToolService } from 'src/app/shared/features/esk-tool/EskTool.service';
import { toSignal } from '@angular/core/rxjs-interop';

export const createPropertyClassLayer = (propertyClass) => {

  const propertyClassLayerOptions = {
    source: new VectorSource(),
    style:(feature, resolution) => {
      return getPaddockStyle(feature, resolution, '');
    },
    title: propertyClass.class,
    primaryClassID: propertyClass.propertyClassID
  }

  const layer = createLayer<VectorImageLayer<any>>(VectorImageLayer,propertyClassLayerOptions);

  return { layer, source: layer.getSource() };
};


export const clearLayerGroup = (layerGroup: LayerGroup): void =>  {
  layerGroup.getLayers().forEach((layer) => {

    if (layer instanceof LayerGroup) {
      clearLayerGroup(layer);
    } else {

      const source = (layer as any).getSource?.();
      if (source && typeof source.clear === 'function') {
        source.clear();
      }
    }
  });
}



export const addPropertyFeatures = (
  property: Property,
  propertyClasses,
  boundariesLayer,
  managedBoundariesLayer,
  areaLayer
) => {
  if (property == null) {
    return;
  }

  if (property.stage != null && property.stage === 'Laguna Bay managed') {
    managedBoundariesLayer.getSource().addFeature(property.boundaryFeature);
  } else {
    boundariesLayer.getSource().addFeature(property.boundaryFeature);
  }

  areaLayer.getSource().addFeature(property.areaFeature);

  if (property.primaryClassID == null) {
    return;
  }

  try {
    propertyClasses[property.primaryClassID].source.addFeature(
      property.propertyClassFeature
    );
  } catch {
    console.warn(`Property Class: ${property.primaryClassID} was not found`);
  }
};

export const clearPropertyLayers = (propertyLayers) => {
  propertyLayers.boundariesLayer.getSource().clear();
  propertyLayers.areaLayer.getSource().clear();
  propertyLayers.managedBoundariesLayer.getSource().clear();

  clearLayerGroup(propertyLayers.propertyClassesLayer)
  }

export const removePropertyFeatures = (
  property,
  propertyClasses,
  boundariesLayer,
  managedBoundariesLayer,
  areaLayer
) => {
  if (property == null) {
    return;
  }

  if (
    property.stage != null &&
    property.stage.toUpperCase() === 'LAGUNA BAY MANAGED'
  ) {
    managedBoundariesLayer.getSource().removeFeature(property.boundaryFeature);
  } else {
    boundariesLayer.getSource().removeFeature(property.boundaryFeature);
  }

  areaLayer.getSource().removeFeature(property.areaFeature);

  if (property.primaryClassID == null) {
    return;
  }

  try {
    propertyClasses[property.primaryClassID].source.removeFeature(
      property.propertyClassFeature
    );
  } catch {
    console.warn(`Property Class: ${property.primaryClassID} was not found`);
  }
};

export const PropertyLayers = createInjectable(() => {
  const featureLayersSpatialService = inject(SpatialService);

  const boundariesLayerOptions = {
    source: new VectorSource(),
    title: 'Other Boundaries'
  }


  const propertyBoundariesLayer = createLayer(VectorImageLayer, boundariesLayerOptions)

  const areaLayerOptions = {
    source: new VectorSource(),
    title: 'Area (ha)',
    style: (feature, resolution) => {
      return getAreaStyle(feature, resolution, null);
    },
  }

  const propertyAreaLayer =  createLayer(VectorImageLayer, areaLayerOptions)


  const lagunaBayManagedBoundaries = createLayer(VectorImageLayer, {
    source: new VectorSource(),
    style: new Style({
      stroke: new Stroke({ color: 'rgba(255,0,255,1)' }),
      fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
    }),
    title: 'Laguna Bay Managed Boundaries'
  })

  const propertyClasses: LayerGroup = new LayerGroup({
    properties: { title: 'Property Classes' },
  });

  const propertyGroup: LayerGroup = new LayerGroup({
    layers: [
      propertyBoundariesLayer,
      lagunaBayManagedBoundaries,
      featureLayersSpatialService.projectLayersGroup,
      propertyAreaLayer,
      propertyClasses,
    ],
    properties: { title: 'Property' },
  });

  return {
    PropertyLayerGroup: propertyGroup,
    propertyClassesLayer: propertyClasses,
    boundariesLayer: propertyBoundariesLayer,
    managedBoundariesLayer: lagunaBayManagedBoundaries,
    areaLayer: propertyAreaLayer,
  };
});

export const PropertyEvents = createInjectable(() => {
  const state = inject(PropertiesState);
  const layers = inject(PropertyLayers);
  const router = inject(Router);
  const mapOverlay = inject(MapOverlayService);

  const handleDoubleClick = (e, map, callback) => {
    e.stopPropagation();



    const BUFFER_RESOLUTION_THRESHOLD = 80;
    const BUFFER_SCALING_FACTOR = 4;


    const pixel = e.pixel;
    const coordinate = map.getCoordinateFromPixel(pixel);

    const uniqueFeaturesMap = new Map();

    const resolution = map.getView().getResolution();

    const bufferDistance = resolution > BUFFER_RESOLUTION_THRESHOLD
    ? (resolution * BUFFER_SCALING_FACTOR)
    : 0;

    const bufferedExtent = [
      coordinate[0] - bufferDistance,
      coordinate[1] - bufferDistance,
      coordinate[0] + bufferDistance,
      coordinate[1] + bufferDistance,
    ];

    const collectFeatures = (source) => {
      if (bufferDistance === 0) {
        map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
          if (source === layer.getSource()) {
            const featureId = feature.get('id');
            if (featureId !== undefined && featureId !== null) {
              uniqueFeaturesMap.set(featureId, feature);
            }
          }
        });
      } else {
        source.forEachFeatureInExtent(bufferedExtent, (feature) => {
          const featureId = feature.get('id');
          if (featureId !== undefined && featureId !== null) {
            uniqueFeaturesMap.set(featureId, feature);
          }
        });
      }
    };
    collectFeatures(layers.boundariesLayer.getSource());
    collectFeatures(layers.managedBoundariesLayer.getSource());

    layers.propertyClassesLayer
      .getLayers()
      .forEach((layer: VectorImageLayer<any>) => {
        collectFeatures(layer.getSource());
      });

    const features = Array.from(uniqueFeaturesMap.values());

    if (features.length === 0) {
      if (appRegex.propertyRouteRegex.test(router.url)) {
        state.setActivePropertyID(null)
        router.navigate(['/map/property']);
      }

      return;
    }

    if (features.length === 1) {
      const propertyID = features[0].get('id');
      router.navigate(['/map/property', propertyID]);
      return;
    }

    const foundProperties = features.map<Property>((feat) => {
      const propertyID = feat.get('id');
      return state.propertyMap()[propertyID];
    });
    mapOverlay
      .showPropertySelect(e.pixel, foundProperties)
      .subscribe((selectedProperty) => {
        router.navigate(['/map/property', selectedProperty.propertyID]);
      });
  };

  return { doubleClickHandler: handleDoubleClick };
});
