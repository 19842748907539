import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Feature, Map } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

@Component({
  selector: 'geocode',
  templateUrl: './geocode.component.html',
  styleUrls: ['./geocode.component.css'],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class GeocodeComponent implements OnInit {
  @Input() map: Map;

  private endpoint =
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/';
  maxSuggestions = 5;
  token = `AAPK4e5d410195404741a3604d3ddb161e5fIWSD51IqZYzsWsmGekfOd8Gh_LV6SaGoJbGd8B_fzABf82pzVok6dpUbg6QuVuiq`;
  searchExtent = '113,-47, 182,-9';
  myHeaders = new Headers();
  requestOptions: object;
  vectorLayer: VectorLayer<any>;
  suggestionList: Array<any> = [];
  searchText: string;

  constructor() {
    this.myHeaders.append('Cookie', 'AGS_ROLES="419jqfa+uOZgYod4xPOQ8Q=="');
    this.requestOptions = {
      method: 'GET',
      headers: this.myHeaders,
      redirect: 'follow',
    };
  }

  ngOnInit(): void {}

  inputChanged() {
    this.suggest(this.searchText);
  }

  async suggest(keywords) {
    let response = await fetch(
      `${this.endpoint}suggest?text=${keywords}&f=pjson&countryCode=au&maxSuggestions=${this.maxSuggestions}&searchExtent=${this.searchExtent}`,
      this.requestOptions
    );
    let results = await response.json();

    this.suggestionList = results.suggestions;
  }

  async search(address, magicKey) {
    let response = await fetch(
      `${this.endpoint}findAddressCandidates?SingleLine=${address}&MagicKey=${magicKey}=&outFields=*&Token=${this.token}&forStorage=false&f=pjson`,
      this.requestOptions
    );
    let results = await response.json();

    if (results.candidates.length > 0) {
      this.center(results.candidates[0].location);
    }
  }

  center(location) {
    let transformedLocation = transform(
      [location.x, location.y],
      'EPSG:4326',
      'EPSG:3857'
    );

    if (this.vectorLayer) {
      this.map.removeLayer(this.vectorLayer);
    }

    const positionFeature = new Feature();

    this.vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature],
      }),
    });

    const pinStyle = new Style({
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.7)',
        }),
        stroke: new Stroke({
          color: '#000',
          width: 2,
        }),
      }),
      text: new Text({
        text: '📍',
        font: '16px Arial',
        offsetY: -12,
      }),
    });

    positionFeature.setGeometry(new Point(transformedLocation));
    positionFeature.setStyle(pinStyle);

    this.map.addLayer(this.vectorLayer);
    this.map.getView().setCenter(transformedLocation);
    this.map.getView().setZoom(15);
    this.suggestionList = [];
  }

  clear() {
    this.suggestionList = [];
    this.searchText = '';
    this.vectorLayer.getSource().clear();
  }
}
