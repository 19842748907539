import { CommonModule } from '@angular/common';
import { Component, forwardRef, HostListener, inject, input, OnInit, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';

@Component({
  selector: 'app-test-component',
  templateUrl: './test-component.component.html',
  styleUrls: ['./test-component.component.css'],
  standalone: true,
  imports:[CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TestComponentComponent),
      multi: true
    }
  ],
})
export class TestComponentComponent implements OnInit, ControlValueAccessor {

  private readonly selectionService: LayerControlServiceService = inject(LayerControlServiceService);

  value = input<any>(null);
  selected = signal(false);
  clicked = output();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if(event)
    {
      event.stopPropagation();
    }

    this.select();
    this.clicked.emit();
  }


  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  select() {
    if (this.selected()) {
      this.selectionService.selectLayer(null);
      this.onChange(null);
    } else {
      this.selectionService.selectLayer(this.value());
      this.onChange(this.value());
    }
    this.onTouched();
  }

  writeValue(value: any): void {
    this.selected.set(value === this.value());
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  constructor() { }

  ngOnInit() {
    this.selectionService.selectedLayer$.subscribe((selectedValue) => {
      this.selected.set(this.value() === selectedValue);
    });

  }

}
