import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostListener, OnDestroy, OnInit, Output, Renderer2, signal } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MaterialModule } from 'src/app/material/material.module';


export enum DrawType
{
  Point = "Point",
  LineString = "LineString",
  Polygon = "Polygon",
  Circle = "Circle"

}



export type MeasureSettingsChanged  = {
  measureType:DrawType.Circle | DrawType.LineString | DrawType.Polygon;
  measureColour?: string;
}

@Component({
  selector: 'measure-settings',
  templateUrl: './measure-settings.component.html',
  styleUrls: ['./measure-settings.component.css'],
  standalone:true,
  imports:[FormsModule,CommonModule, MaterialModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MeasureSettingsComponent),
      multi: true,
    },
  ],
})
export class MeasureSettingsComponent implements ControlValueAccessor  {

  public selection: DrawType = DrawType.LineString;

    selectedMeasureType = signal('')

    private onChange: (value: string) => void = () => {};
    private onTouched: () => void = () => {};

    writeValue(value: string): void {
      this.selectedMeasureType.set(value);

    }

    registerOnChange(fn: (value: string) => void): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
      // Handle the disabled state if needed
    }

    selectMeasureType(value: string) {
      this.selectedMeasureType.set(value);
      this.onChange(value);
      this.onTouched();
    }
}
