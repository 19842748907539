import {
  Component,
  computed,
  EventEmitter,
  Inject,
  inject,
  Output,
  Signal,
} from '@angular/core';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CustomValidators } from '../../../../../../../../shared/extensions/CustomValidators';
import { CommonModule } from '@angular/common';
import { UpdateCatalogueLayer } from '../../../data-access/models/create-update-catelogue.model';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { MaterialModule } from '../../../../../../../../material/material.module';
import { CatalogueLayer } from '../../../../../data-access/models/catalogue-data.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { ModalService } from '../../../../../../../../@core/services/modal/modal.service';
import { log } from 'ol/console';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-update-catalogue-layer-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
  ],
  templateUrl: './update-catalogue-layer-dialog.component.html',
  styleUrl: './update-catalogue-layer-dialog.component.css',
})
export class UpdateCatalogueLayerDialogComponent {
  @Output() updateEvent: EventEmitter<any> = new EventEmitter();

  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private loadDataTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );
  private readonly dialog: Dialog = inject(Dialog);
  readonly modalService: ModalService = inject(ModalService);
  form: FormGroup;

  categoryObservable = this.loadDataTrigger$.pipe(
    switchMap(() => this.catalogueService.loadCatalogueCategories())
  );

  catalogueLayerToUpdate: CatalogueLayer;
  layerSources$;
  layerSources: Signal<any[]>;
  layerTypes$ = this.catalogueService.loadCatalogueLayerTypes();

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) protected data: CatalogueLayer
  ) {
    this.catalogueLayerToUpdate = data;
    this.form = new FormGroup({
      name: new FormControl(this.catalogueLayerToUpdate.name, [
        CustomValidators.required,
      ]),
      description: new FormControl(this.catalogueLayerToUpdate.description, []),
      url: new FormControl(this.catalogueLayerToUpdate.url, []),
      outputFormat: new FormControl('', []),
      attribution: new FormControl(this.catalogueLayerToUpdate.attribution, []),
      layerTypeID: new FormControl(this.catalogueLayerToUpdate.layerTypeID, []),
      addedByDefault: new FormControl(
        this.catalogueLayerToUpdate.addedByDefault,
        []
      ),
      visibleByDefault: new FormControl(
        this.catalogueLayerToUpdate.visibleByDefault,
        []
      ),
      sourceTypeID: new FormControl(
        this.catalogueLayerToUpdate.sourceTypeID,
        []
      ),
      dataCatalogueCategoryID: new FormControl(
        this.catalogueLayerToUpdate.dataCatalogueCategoryID,
        []
      ),
      params: new FormArray([]),
    });

    this.form.get('url').disable();
    this.form.get('attribution').disable();
    this.form.get('layerTypeID').disable();
    this.form.get('sourceTypeID').disable();
    this.form.get('dataCatalogueCategoryID').disable();

    if (this.catalogueLayerToUpdate.params) {
      Object.keys(this.catalogueLayerToUpdate.params).forEach((key) =>
        this.addParam(key, this.catalogueLayerToUpdate.params[key], true)
      );
    }

    const sourceTypeChanges = toSignal(
      this.form.get('sourceTypeID').valueChanges
    );

    const selectedSourceType = computed(() =>
      this.layerSources().find((src) => src.id == sourceTypeChanges())
    );

    this.layerSources$ = this.form.get('layerTypeID').valueChanges.pipe(
      startWith(this.data.layerTypeID),
      filter((val) => val != null && val > -1),
      switchMap((selectedType) => {
        return this.catalogueService
          .loadCatalogueLayerSources()
          .pipe(map((data) => ({ selectedType, data })));
      }),
      map(({ selectedType, data }) =>
        data.filter((data) => data.layerTypeID == selectedType)
      )
    );

    this.layerSources = toSignal(this.layerSources$, { initialValue: [] });
  }

  createNewCategory() {
    this.catalogueService.createNewCategory(
      this.form,
      this.dialog,
      this.loadDataTrigger$
    );
  }

  submit() {
    let model = {};

    const changedValues = Object.keys(this.form.controls).filter(
      (key) => this.form.controls[key].dirty
    );

    for (let key of changedValues) {
      model[key] = this.form.controls[key].value;
    }

    const params = this._getLayerParameters();
    if (Object.keys(params).length > 0) {
      model['params'] = params;
    }
    this.updateEvent.emit(CreateEvent.create<UpdateCatalogueLayer>(model));

    this.dialogRef.close();
  }

  cancel(): void {
    this.updateEvent.emit(CreateEvent.cancel<UpdateCatalogueLayer>());
    this.dialogRef.close();
  }

  deleteLayerFromCatalogue(): void {
    this.modalService
      .showConfirmation(
        'Are you sure you want to remove this layer from the Catalogue? <br> This will mean that you can no longer add this layer to the map'
      )
      .pipe(
        filter((result) => result.val === true),
        switchMap(() =>
          this.catalogueService.deleteLayer(
            this.catalogueLayerToUpdate.dataCatalogueLayerID
          )
        )
      )
      .subscribe((d) => {
        this.updateEvent.emit(
          CreateEvent.delete<Observable<number>>(
            of(this.catalogueLayerToUpdate.dataCatalogueCategoryID)
          )
        );
        this.dialogRef.close();
      });
  }

  get params() {
    return this.form.get('params') as FormArray;
  }

  addParam(name = null, value = null, existing?: boolean) {
    const attributeFormGroup = new FormGroup({
      name: new FormControl(name, [CustomValidators.required]),
      value: new FormControl(value, [CustomValidators.required]),
    });
    if (existing) {
      attributeFormGroup.get('name').disable();
      attributeFormGroup.get('value').disable();
    }
    this.params.push(attributeFormGroup);
  }

  removeParam(index: number) {
    this.params.removeAt(index);
  }

  _getLayerParameters() {
    return this.params.controls
      .filter((key) => key.dirty)
      .reduce((acc, formGroup) => {
        const name = formGroup.get('name').value;
        acc[name] = formGroup.get('value').value;
        return acc;
      }, {});
  }
}
