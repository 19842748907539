import { trigger, state, style, transition, group, animate } from "@angular/animations";

export const accordionAnimation = [
  trigger('slideInOut', [
      state('in', style({
          'max-height': 'max-content', 'opacity': '1', 'visibility': 'visible', 'padding': 'var(--padding-size,16px)'
      })),
      state('out', style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden', 'padding':'0',
      })),
      transition('in => out', [group([
          animate('200ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('300ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('400ms ease-in-out', style({
              'visibility': 'hidden',
              'padding': '0'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('200ms ease-in-out', style({
              'max-height': 'max-content',
              'padding':'var(--padding-size,16px)'
          })),
          animate('400ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
]


export const accordionAnimation2 = [
  trigger('slideInOut', [
      state('in', style({
          'max-height': 'max-content', 'opacity': '1', 'visibility': 'visible', 'padding': 'var(--padding-size,16px)'
      })),
      state('out', style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden', 'padding':'0',
      })),
      transition('in => out', [group([
          animate('100ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('200ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('200ms ease-in-out', style({
              'visibility': 'hidden',
              'padding': '0'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('200ms ease-in-out', style({
              'max-height': 'max-content',
              'padding':'var(--padding-size,16px)'
          })),
          animate('400ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
]
