import { PaperDefinition } from "../data-access/models/models";

 export const calculateExtentCenter = (
    bbox: [number, number, number, number]
  ): [number, number] =>  {
    const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox;
    const centerLongitude = (minLongitude + maxLongitude) / 2;
    const centerLatitude = (minLatitude + maxLatitude) / 2;
    return [centerLongitude, centerLatitude];
  }

  export const getMapBoxInPaper = (mapElement: HTMLElement, sheetElement: HTMLElement, paperDefinition:PaperDefinition) => {
    const mapWindowHeight = mapElement.clientHeight;
    const mapWindowWidth = mapElement.clientWidth;
    const borderWidth = 0;

    const mapWindowOffsetLeft = mapElement.offsetLeft;
    const mapWindowOffsetTop = mapElement.offsetTop;

    const corners = [];

    const mm2inch = 25.4;
    const mm2Points = 72 / 25.4;
    const Points2mm = 25.4 / 72;

    const pageWidth =
    sheetElement.clientWidth + borderWidth * 2;
    const pageHeight =
    sheetElement.clientHeight + borderWidth * 2;

    // Calculate Page Width and Height Base Upon Page Size and Orientation
    let mapOrientation = paperDefinition.orientation.toLowerCase();
    let mapSize = paperDefinition.size;

    let pageWidthMm = null;
    let pageHeightMm = null;

    // Calculated in mm
    if (mapOrientation == 'portrait' && mapSize == 'A4') {
      pageWidthMm = 210 + borderWidth * Points2mm * 2;
      pageHeightMm = 297 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'landscape' && mapSize == 'A4') {
      pageWidthMm = 297 + borderWidth * Points2mm * 2;
      pageHeightMm = 210 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'portrait' && mapSize == 'A3') {
      pageWidthMm = 297 + borderWidth * Points2mm * 2;
      pageHeightMm = 420 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'landscape' && mapSize == 'A3') {
      pageWidthMm = 420 + borderWidth * Points2mm * 2;
      pageHeightMm = 297 + borderWidth * Points2mm * 2;
    }

    // Lower left is 0,0
    // Offset Based Upon the Percentage of the Paper - Calculated in Pixels
    corners[0] = (mapWindowOffsetLeft + borderWidth) / pageWidth; // Left
    corners[1] =
      1 - (mapWindowOffsetTop + borderWidth + mapWindowHeight) / pageHeight; // Bottom
    corners[2] =
      (mapWindowOffsetLeft - borderWidth + mapWindowWidth) / pageWidth; // Right - Remove Border Width
    corners[3] = 1 - (mapWindowOffsetTop + borderWidth) / pageHeight; // Top

    // Printing points for PDF - Convert Percentage of Paper to Pixels
    corners[0] = corners[0] * pageWidthMm * mm2Points;
    corners[1] = corners[1] * pageHeightMm * mm2Points;
    corners[2] = corners[2] * pageWidthMm * mm2Points;
    corners[3] = corners[3] * pageHeightMm * mm2Points;

    return corners;
  }

  export const convertBoundingBoxToCorners = (
    boundingBox: [number, number, number, number]
  ): number[] => {
    const [
      minimumLongitude,
      minimumLatitude,
      maximumLongitude,
      maximumLatitude,
    ] = boundingBox;

    // Lower-left corner (minimum longitude, minimum latitude)
    const lowerLeftCorner: [number, number] = [
      minimumLongitude,
      minimumLatitude,
    ];
    // Upper-right corner (maximum longitude, maximum latitude)
    const upperRightCorner: [number, number] = [
      maximumLongitude,
      maximumLatitude,
    ];
    // Upper-left corner (minimum longitude, maximum latitude)
    const upperLeftCorner: [number, number] = [
      minimumLongitude,
      maximumLatitude,
    ];
    // Lower-right corner (maximum longitude, minimum latitude)
    const lowerRightCorner: [number, number] = [
      maximumLongitude,
      minimumLatitude,
    ];

    // Return the coordinates in the specified format
    return [
      lowerLeftCorner[0],
      lowerLeftCorner[1],
      upperRightCorner[0],
      upperRightCorner[1],
      upperLeftCorner[0],
      upperLeftCorner[1],
      lowerRightCorner[0],
      lowerRightCorner[1],
    ];
  }
