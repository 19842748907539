import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { LayerControlComponent } from '../layer-control/layer-control.component';
import { LayerControlActionsDirective } from '../layer-control/layer-control-actions.directive';
import { LayerControlPreviewDirective } from '../layer-control/layer-control-preview.directive';
import { StyleRendererComponent } from '../../components/style-renderer/style-renderer.component';
import { TuiAccordion, TuiAccordionItem } from '@taiga-ui/kit';
import { LayersService, state } from 'src/app/map/features/layers/data-access/services/layers.service';
import { Map as OlMap } from 'ol';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import { LayerLegendComponent } from '../layer-legend/layer-legend.component';
import { LayerControlHeaderActionsDirective } from '../layer-control/layer-control-header-actions.directive';

@Component({
  selector: 'app-layer-group-control',
  templateUrl: './layer-group-control.component.html',
  styleUrls: ['./layer-group-control.component.css'],
  standalone:true,
  imports:[CommonModule,LayerLegendComponent, LayerControlComponent, LayerControlActionsDirective,LayerControlHeaderActionsDirective, LayerControlPreviewDirective, StyleRendererComponent,TuiAccordion,TuiAccordionItem]
})
export class LayerGroupControlComponent implements OnInit {

  public readonly layersService:LayersService = inject(LayersService);

  layerGroup = input<state>();
  map = input.required<OlMap>();

  drop(event: any) {
    var layer = event.item.data as BaseLayer;
    var layerGroup = event.container.data as LayerGroup;

    const originalIndex =
      layerGroup.getLayers().getArray().length - 1 - event.previousIndex;

    const reversedIndex =
      layerGroup.getLayers().getArray().length - 1 - event.currentIndex;

    moveItemInArray(this.layerGroup().children, originalIndex, reversedIndex);

    moveItemInArray(
      layerGroup.getLayers().getArray(),
      originalIndex,
      reversedIndex
    );

    this.map().render();
  }
  constructor() { }

  ngOnInit() {
  }

}
