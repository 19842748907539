import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  input,
} from '@angular/core';
import { HeaderService } from './data-access/header.service';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'src/app/material/material.module';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { RoutesExtended } from '../../sidebar-types';
import { ActionPortalComponent } from 'src/app/shared/features/action-portal/components/action-portal/action-portal.component';

@Component({
  selector: 'side-bar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [
    SharedModule,
    MaterialModule,
    CommonModule,
    TooltipDirective,
    RouterModule,
    ActionPortalComponent,
  ],
})
export class HeaderComponent implements OnInit {
  @Input('sideNav') drawer: MatDrawer;
  routeConfig = input.required<RoutesExtended>();
  routePrefix = input.required<string>();

  @Input('pages') pages;

  @Output('openPage') _openPage = new EventEmitter<any>();

  title = computed(() => this.headerService.headerDetails()?.title ?? "Unknown");

  backRoute = computed(() => this.headerService.headerDetails()?.backRoute ?? '../')

  constructor(
    public headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.headerService.setRouteConfig(this.routeConfig());
    this.headerService.setRoutePrefix(this.routePrefix());
  }

  goBack(route: string | Array<string>): void {
    this.headerService.navigateToParent(route);
  }

  test() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  close() {
    this.drawer.close();
  }
}
