
<div class="title-bar">
  <h2>Test Environment</h2>
</div>
<div class="content">
  <p>This system is used for evaluation purposes only. Any data entered here will not be reflected in the actual system.</p>
</div>
<mat-dialog-actions class="actions footer" align="end">
  <mat-checkbox class="accept-box" [(ngModel)]="savePreference" labelPosition="before">Don't show this again today</mat-checkbox>
  <button class="button button--green-fill button--xs" (click)="onYesClick()">I Understand</button>
</mat-dialog-actions>
