import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { SelectionModel } from '@angular/cdk/collections';
import { Kind, StyleRendererComponent } from '../style-renderer.component';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import OlStyleParser, { OlParserStyleFct } from 'geostyler-openlayers-parser';
import { Rule, Style as gsStyle } from 'geostyler-style';
import { Style } from 'ol/style';
import { BehaviorSubject } from 'rxjs';
import { getGeoStylerType } from '../helpers/helpers';
import { LegendIconComponent } from '../legend-icon/legend-icon.component';

@Component({
  selector: 'app-rule-table',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatIconModule,
    StyleRendererComponent,
    LegendIconComponent
  ],
  templateUrl: './rule-table.component.html',
  styleUrls: ['./rule-table.component.css'],
})
export class RuleTableComponent implements OnInit {
  private readonly modalService: ModalService = inject(ModalService);

  styleRule = model.required<gsStyle>();
  geometryKind = input.required<Kind>();

  dataSource = computed(
    () => new MatTableDataSource<Rule>(this.styleRule()?.rules ?? [])
  );

  displayedColumns: string[] = [
    'select',
    'move',
    'icon',
    'name',
    'actions',
  ];
  selection = new SelectionModel<Rule>(true, []);

  /**
   *
   */
  constructor() {
  
  }

  ngOnInit() {}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource().data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource().data.forEach((row) => this.selection.select(row));
  }

  getSelectedRows() {
    return this.selection.selected;
  }

  addRule() {
    const rules = this.styleRule().rules;
    rules.push({
      name: `Rule ${rules.length + 1}`,
      symbolizers: [getGeoStylerType(this.geometryKind())],
      filter: null,
    });
    this.styleRule.set({
      ...this.styleRule(),
      rules: rules,
    });
  }

  removeRules() {
    const selectedRows = this.getSelectedRows();
    const updatedData = this.dataSource().data.filter(
      (row) => !selectedRows.includes(row)
    );
    this.styleRule.set({ ...this.styleRule(), rules: updatedData });
    this.selection.clear();
  }

  moveUp(index: number) {
    if (index > 0) {
      const data = [...this.dataSource().data];
      const temp = data[index - 1];
      data[index - 1] = data[index];
      data[index] = temp;
      this.styleRule.set({ ...this.styleRule(), rules: data });
    }
  }

  moveDown(index: number) {
    if (index < this.dataSource().data.length - 1) {
      const data = [...this.dataSource().data];
      const temp = data[index + 1];
      data[index + 1] = data[index];
      data[index] = temp;
      this.styleRule.set({ ...this.styleRule(), rules: data });
    }
  }

  openSymbolizerEditor(rule: Rule) {

    const ref = this.modalService.showStyleForm();
    ref.componentInstance.symbolizers.set(rule.symbolizers);
    ref.componentInstance.layerGeomKind.set(this.geometryKind())

    ref.componentInstance.symbolizersChanged.subscribe((symbolizers) => {
      const updatedRule = { ...rule, symbolizers };
      const data = this.dataSource().data.map((r) =>
        r.name === rule.name ? updatedRule : r
      );
      this.styleRule.set({ ...this.styleRule(), rules: data });
    });
  }
}
