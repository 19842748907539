import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyDetailsComponent } from './features/property-details/property-details.component';
import { FeatureLayersComponent } from './features/feature-layers/features/feature-layers/feature-layers.component';
import { FeatureLayersService } from './features/feature-layers/data-access/feature-layers.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [PropertyDetailsComponent, FeatureLayersComponent],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class PropertyModule {}
